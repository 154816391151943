/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as widgetsHtml from "./widgetsHtml";
import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { _getWidgets, Layout, Media } from "./widgetJson";
import {
  uniqueID,
  deepClone,
  handleOnMouseLeave,
  handleOnMouseEnter,
  _setDndWidgetData,
  _dndWidgetData
} from "../functions";
import {PageBreakCompo} from "src/components-form/Common/PageBreakCompo";
import {TextInputCompo} from "src/components-form/Common/TextInputCompo";
import {RatingScaleCompo} from "src/components-form/Common/RatingScaleCompo";
import {SignatureCompo} from "src/components-form/Common/SignatureCompo";
import {SelectDropdownCompo} from "src/components-form/Common/SelectDropdownCompo";
import {CheckboxCompo} from "src/components-form/Common/CheckboxCompo";
import {CalculationCompo} from "src/components-form/Common/CalculationCompo";
import {ContentCompo} from "src/components-form/Common/ContentCompo";
import {ContactInformationCompo} from "src/components-form/Common/ContactInformationCompo";
import {TimePickerCompo} from "src/components-form/Common/TimePickerCompo";
import {DatePickerCompo} from "src/components-form/Common/DatePickerCompo";
import {ParagraphCompo} from "src/components-form/Common/ParagraphCompo";
import {ImageCompo} from "src/components-form/Common/ImageCompo";
import {VideoCompo} from "src/components-form/Common/VideoCompo";
import {ImageSliderCompo} from "src/components-form/Common/ImageSliderCompo";
import {FileUploadCompo} from "src/components-form/Common/FileUploadCompo";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import {AddressInformationCompo} from "../../components-form/Common/AddressInformationCompo";
import LabelCompo from "src/components-form/Common/LabelCompo";
import {LookupDropDownCompo} from "src/components-form/Common/LookupDropDownCompo";
import { toast } from "react-toastify";

function HTMLSectionAndRepeating ({props, compoType = "Section"}) {
  const [selectedWidgets, setSelectedWidgets] = useState([]);
  const [activeDropBox, setActiveDropBox] = useState([]);
  const [dragErrorStatus, setDragErrorStatus] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState([0, 0]);

  useEffect(() => {
    setSelectedWidgets([...props.data.childComponent]);
  }, [props.data]);
  const [selectedWidget, setSelectedWidget] = useState({});
  useEffect(() => {
    if (selectedWidget?.hasOwnProperty("element"))
      props.setSelectedWidget(selectedWidget);
  }, [selectedWidget]);
  useEffect(() => {
    if (props.data && props.data.childComponent) { //  && selectedWidgets.length > 0
      props.data.childComponent = [...selectedWidgets];
      props.UpdateSectionUpdate(props.data, props.index, props.sectionIndex);
    }
  }, [selectedWidgets]);
  useEffect(() => {
    props.setSelectedIndex(selectedIndex);
  }, [selectedIndex]);

  const onChangeFields = (item, e, opt = null) => {
  };

  function sectionHandleOnDrop(e, index) {
    let widgetType = _dndWidgetData();

    let width = 0;
    let usedWidth = 0;
    let totalWidth = 12;
    if (selectedWidgets[index] === undefined) {
      if (widgetType.path[0] < index) {
        index -= 1;
      } else {
        index += 1;
      }
    }
    selectedWidgets[index].childComponent.map((row, refVal) => {
      usedWidth += row.width;
    });
    let widthRemaining = totalWidth - usedWidth;
    if (widthRemaining > 6) {
      width = 6;
    } else {
      width = widthRemaining;
    }
    let widgetJson = {};
    if (typeof widgetType === "string") {
      if (_getWidgets()[widgetType] !== undefined) {
        widgetJson = deepClone(_getWidgets()[widgetType]);
      } else if (Layout[widgetType] !== undefined) {
        widgetJson = deepClone(Layout[widgetType]);
      } else {
        widgetJson = deepClone(Media[widgetType]);
      }

      widgetJson["id"] = `${widgetJson.element}_${uniqueID(4)}`;
    } else {
      widgetJson = widgetType;
    }

    if (
      widgetJson?.hasOwnProperty("minRequiredWidth") &&
      widgetJson.minRequiredWidth > width
    ) {
      setDragErrorStatus(true);
      toast.error(
        `Not allowed, minimum width required is ${widgetJson.minRequiredWidth}`
      );
    } else {
      selectedWidgets[index].childComponent.push(
        deepClone({ ...widgetJson, ...{ width: width } })
      );
      setSelectedWidgets([...selectedWidgets]);
    }
    try {
      _setDndWidgetData(null);
    } catch (e) {
    }
  }
  function handleOnDrop(e, index, subIndex) {
    let widgetType = _dndWidgetData();
    if (widgetType !== undefined || widgetType !== null) {
      if (widgetType) {
        let jsonData = {};
        if (typeof widgetType === "string") {
          if (_getWidgets()[widgetType] !== undefined) {
            jsonData.childComponent = [deepClone(_getWidgets()[widgetType])];
          } else if (Layout[widgetType] !== undefined) {
            jsonData.childComponent = [deepClone(Layout[widgetType])];
          } else {
            jsonData.childComponent = [deepClone(Media[widgetType])];
          }
          jsonData.childComponent[0]["id"] = `${
            jsonData.childComponent[0].element
          }_${uniqueID(4)}`;
          jsonData.childComponent[0].width = jsonData.childComponent[0].width
            ? jsonData.childComponent[0].width
            : 6;
          if (
            index !== undefined &&
            subIndex !== undefined &&
            selectedWidgets[index]
          ) {
            selectedWidgets[index].childComponent[subIndex].childComponent.push(
              jsonData
            );
          } else {
            selectedWidgets.push(jsonData);
          }
        } else {
          if (widgetType?.hasOwnProperty("json")) {
            selectedWidgets.push({ childComponent: [widgetType.json] });
          } else {
            selectedWidgets.push({ childComponent: [widgetType] });
          }
        }
        setSelectedWidgets([...selectedWidgets]);
      }
    }
    try {
      _setDndWidgetData(null)
    } catch (e) {
    }
  }

  function handleDragOver(e) {
    e.preventDefault();
  }

  function removeWidget(index, refVal) {
    if (selectedWidgets[index].childComponent.length > 1) {
      selectedWidgets[index].childComponent.splice(refVal, 1);
    } else {
      selectedWidgets.splice(index, 1);
    }
    setSelectedWidgets([...selectedWidgets]);
  }

  function UpdateSectionUpdate(data, index, sectionIndex) {
    selectedWidgets[index].childComponent[sectionIndex] = data;
    setSelectedWidgets([...selectedWidgets]);
  }
  function pasteFunction(index = undefined) {
    let jsonData = deepClone(props.copyWidgetJson);
    if (jsonData.element === "Copyform") {
      if (index === undefined) {
        selectedWidgets.push(...jsonData.jsonData);
      }
      setSelectedWidgets([...selectedWidgets]);
    } else {
      jsonData["id"] = `${jsonData.element}_${uniqueID(4)}`;
      jsonData.width = jsonData.width ? jsonData.width : 6;
      if (index !== undefined) {
        selectedWidgets[index].childComponent.push(jsonData);
      } else {
        selectedWidgets.push({ childComponent: [jsonData] });
      }
      setSelectedWidgets([...selectedWidgets]);
    }
  }
  function handleMoveWidgetOnDrag(e, json, pathArray) {
    _setDndWidgetData({ json: json, path: pathArray })
  }
  function removeWidgetAfterDrop(pathArray) {
    if (!dragErrorStatus) {
      if (selectedWidgets[pathArray[0]].childComponent.length > 1) {
        selectedWidgets[pathArray[0]].childComponent.splice(pathArray[1], 1);
      } else {
        selectedWidgets.splice(pathArray[0], 1);
      }
      setSelectedWidgets([...selectedWidgets]);
    } else {
      setDragErrorStatus(false);
    }
  }

  const getMapItems = (item, index) => {
    let usedWidth = 0;
    let totalWidth = 12;
    selectedWidgets[index].childComponent.map((row) => {
      usedWidth += row.width;
    });
    let widthRemaining = totalWidth - usedWidth;
    return (
      <div className="row" key={`section_widget_${compoType}${index}`}>
        {item.childComponent.map((row, refVal) => {
          let Tag = widgetsHtml[row.element];
          return (
            <div
              className={`col-lg-${
                row.width ? row.width : 6
              } position-relative`}
              key={`wid_${compoType}${row.id}`}
              onMouseLeave={() => {
                props.setActiveWidgetId((prevData) => {
                  let pop = prevData.previousActiveId.pop();
                  return {
                    activeId: pop,
                    previousActiveId: prevData.previousActiveId,
                  };
                });
              }}
              onMouseEnter={(e) => {
                props.setActiveWidgetId((prevData) => {
                  prevData.previousActiveId.push(prevData.activeId);
                  return {
                    activeId: row.id,
                    previousActiveId: prevData.previousActiveId,
                  };
                });
              }}
              draggable
              onDragStart={(e) => {
                if (!["Section", "RepeatingSection"].includes(row.element))
                  handleMoveWidgetOnDrag(e, row, [index, refVal]);
              }}
              onDragEnd={(e) => {
                if (!["Section", "RepeatingSection"].includes(row.element))
                  setTimeout(() => {
                    removeWidgetAfterDrop([index, refVal]);
                  }, 300);
              }}
            >
              <button
                className="addColumnButton"
                onClick={() => {
                  setActiveDropBox([index, refVal]);
                }}
              >
                <i className="fas fa-plus-circle"></i>
              </button>
              <div className="row">
                {activeDropBox.length > 0 &&
                  activeDropBox[0] === index &&
                  activeDropBox[1] === refVal && (
                    <div
                      className="col-lg-2"
                      onDrop={(e) => {
                        setActiveDropBox([]);
                        props.activeDropBoxHandleOnDrop(
                          e,
                          [index],
                          refVal,
                          widthRemaining
                        );
                      }}
                      onDragOver={handleDragOver}
                    >
                      <div
                        className="widgedropBox"
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                      >
                        {props.copyWidgetJson &&
                          props.copyWidgetJson?.hasOwnProperty("element") && (
                            <span className="icons">
                              <a
                                role="button"
                                type="button"
                                onClick={() => {
                                  pasteFunction(index);
                                }}
                              >
                                <i className="fas fa-paste"></i> <br />
                                Paste
                              </a>
                            </span>
                          )}
                        <span>Drop Fields Here</span>
                      </div>
                    </div>
                  )}
                <div
                  className={
                    activeDropBox[0] === index && activeDropBox[1] === refVal
                      ? "col-lg-10"
                      : "col"
                  }
                >
                  <div className="form-group">
                    <span
                      className="icons"
                      style={
                        props.activeWidgetId.activeId == row.id
                          ? {
                            display: "inline-flex",
                            zIndex: 1,
                          }
                          : {}
                      }
                    >
                      <a
                        role="button"
                        type="button"
                        onClick={() => {
                          setSelectedIndex([index, refVal]);
                          setSelectedWidget(deepClone(row));
                          props.setIsOpen(true);
                        }}
                      >
                        <i className="fa-regular fa-pen-to-square"></i> <br />{" "}
                        Edit
                      </a>
                      {!row.fixed ? (
                        <>
                          {!row?.hasOwnProperty("widthEditable") && (
                            <>
                              <a
                                role="button"
                                type="button"
                                onClick={() => {
                                  row.width > 2
                                    ? (row.width -= 1)
                                    : (row.width = row.width ? row.width : 4);
                                  selectedWidgets[index].childComponent[
                                    refVal
                                    ] = row;
                                  setSelectedWidgets([...selectedWidgets]);
                                }}
                              >
                                <i className="fa-solid fa-arrow-left"></i>{" "}
                                <br /> Shrink
                              </a>
                              <a
                                role="button"
                                disabled={row.width === 12}
                                type="button"
                                onClick={() => {
                                  if (widthRemaining > 0) {
                                    row.width < 12
                                      ? (row.width += 1)
                                      : (row.width = row.width ? row.width : 8);
                                    selectedWidgets[index].childComponent[
                                      refVal
                                      ] = row;
                                    setSelectedWidgets([...selectedWidgets]);
                                  }
                                }}
                              >
                                <i className="fa-solid fa-arrow-right"></i>{" "}
                                <br /> Grow
                              </a>
                              {compoType === "RepeatingSection" &&
                                <a
                                  role="button"
                                  disabled={row.width === 12}
                                  type="button"
                                  onClick={() => {
                                    if (widthRemaining > 0) {
                                      row.width < 12
                                        ? (row.width = row.width + widthRemaining)
                                        : (row.width = row.width ? row.width : 8);
                                      selectedWidgets[index].childComponent[refVal] =
                                        row;
                                      setSelectedWidgets([...selectedWidgets]);
                                    }
                                  }}
                                >
                                  <i className="fa-solid fa-arrow-right-arrow-left"></i>{" "}
                                  <br/> Justify
                                </a>
                              }
                              <a
                                role="button"
                                type="button"
                                onClick={() => [
                                  props.setCopyWidgetJson({...row}),
                                ]}
                              >
                                <i className="fa-solid fa-copy"></i>
                                <br/> Copy
                              </a>
                            </>
                          )}
                          <a
                            role="button"
                            type="button"
                            onClick={() => {
                              removeWidget(index, refVal);
                            }}
                          >
                            <i className="fa-solid fa-trash"></i> <br/> Delete
                          </a>
                          {/* <a role="button" type="button"><i className="fa-solid fa-ellipsis-vertical"></i> <br /> Move</a> */}
                        </>
                      ) : (
                        <></>
                      )}
                    </span>
                    {
                      <span className={`rowId ${props.showIds ? "show" : ""}`}>
                        {row.id}
                      </span>
                    }

                    <Tag
                      index={index}
                      data={row}
                      onChangeFields={onChangeFields}
                      handleOnDrop={handleOnDrop}
                      UpdateSectionUpdate={UpdateSectionUpdate}
                      sectionIndex={refVal}
                      setSelectedWidget={setSelectedWidget}
                      setIsOpen={props.setIsOpen}
                      setSelectedIndex={(arrayPath) => {
                        arrayPath.unshift(refVal);
                        arrayPath.unshift(index);
                        setSelectedIndex(arrayPath);
                      }}
                      setActiveWidgetId={props.setActiveWidgetId}
                      setCopyWidgetJson={props.setCopyWidgetJson}
                      activeWidgetId={props.activeWidgetId}
                      activeDropBoxHandleOnDrop={(
                        e,
                        arrayPath,
                        updateIndex,
                        widthRemaining
                      ) => {
                        arrayPath.unshift(refVal);
                        arrayPath.unshift(index);
                        props.activeDropBoxHandleOnDrop(
                          e,
                          arrayPath,
                          updateIndex,
                          widthRemaining
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {widthRemaining > 0 && (
          <div
            className="col"
            onDrop={(e) => sectionHandleOnDrop(e, index)}
            onDragOver={handleDragOver}
          >
            <div
              className="widgedropBox"
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            >
              {props.copyWidgetJson &&
                props.copyWidgetJson?.hasOwnProperty("element") && (
                  <span className="icons">
                    <a
                      role="button"
                      type="button"
                      onClick={() => {
                        pasteFunction(index);
                      }}
                    >
                      <i className="fas fa-paste"></i> <br />
                      Paste
                    </a>
                  </span>
                )}
              <span>Drop Fields Here</span>
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <div key={props.data.id} id={props.data.id}>
      <h4 className="sectionTitle">
        <LabelCompo data={props.data} defaultLabel={`Title Name`} showOptional={false} />
      </h4>
      <div className="widget-dropsecton">
        {compoType === "RepeatingSection" && <h5 className="subtitle">
          <img src={`/assets/img/cancelImg.svg`} alt={``}/>
          {props.data.itemLabel ? props.data.itemLabel : "Item"} 1
        </h5>}
        {selectedWidgets.map((row, index) => {
          return getMapItems(row, index);
        })}
        <div
          className="row"
          onDrop={handleOnDrop}
          onDragOver={handleDragOver}
          onClick={() => props.setIsOpen(false)}
        >
          <div className="col-lg-6">
            <div
              className="widgedropBox"
              style={{minHeight: "100px"}}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            >
              {props.copyWidgetJson &&
                props.copyWidgetJson?.hasOwnProperty("element") && (
                  <span className="icons">
                    <a
                      role="button"
                      type="button"
                      onClick={() => {
                        pasteFunction();
                      }}
                    >
                      <i className="fas fa-paste"></i> <br/>
                      Paste
                    </a>
                  </span>
                )}
              <span>Drop Fields Here</span>
            </div>
          </div>
        </div>
        {compoType === "RepeatingSection" && <Button
          disabled
          variant="outlined"
          startIcon={<AddIcon />}
        >
          {props.data.addButtonText ? props.data.addButtonText : "Add Item"}
        </Button>}
      </div>
    </div>
  );
};
export const TextInput = (props) => {
  return (
    <TextInputCompo
      isReadOnly={true}
      {...props}
    />
  );
};

export const CheckBoxes = (props) => {
  return (
    <CheckboxCompo
      isReadOnly={true}
      {...props}
    />
  );
};
export const DropDown = (props) => {
  return (
    <SelectDropdownCompo
      isReadOnly={true}
      {...props}
    />
  );
};
export const SelectDatePicker = (props) => {
  return (
    <DatePickerCompo
      isReadOnly={true}
      {...props}
    />
  );
};
export const FileUpload = (props) => {
  return (
    <FileUploadCompo
      isReadOnly={true}
      {...props}
    />
  );
};
export const Paragraph = (props) => {
  return (
    <ParagraphCompo
      isReadOnly={true}
      {...props}
    />
  );
};
export const PageBreak = (props) => {
  return (
    <PageBreakCompo {...props} />
  );
};
export const Section = (props) => {
  return (
    <>
      <HTMLSectionAndRepeating
        props={props}
        compoType={"Section"}
      />
    </>
  )
}

export const RepeatingSection = (props) => {
  return (
    <>
      <HTMLSectionAndRepeating
        props={props}
        compoType={"RepeatingSection"}
      />
    </>
  )
}

export const Image = (props) => {
  return (
    <ImageCompo
      isReadOnly={true}
      {...props}
    />
  );
};
export const Video = (props) => {
  return (
    <VideoCompo
      isReadOnly={true}
      {...props}
    />
  );
};
export const SelectTimePicker = (props) => {
  return (
    <TimePickerCompo
      isReadOnly={true}
      {...props}
    />
  );
};
export const RatingScale = (props) => {
  return (
    <RatingScaleCompo
      isReadOnly={true}
      {...props}
    />
  )
};
export const Signature = (props) => {
  return (
    <SignatureCompo
      isReadOnly={true}
      {...props}
    />
  )
};

export const ImageSlider = (props) => {
  return (
    <ImageSliderCompo
      isReadOnly={true}
      {...props}
    />
  )
};

export const ContactInformation = (props) => {
  return (
    <ContactInformationCompo
      isReadOnly={true}
      {...props}
    />
  );
};
export const AddressInformation = (props) => {
  return (
    <AddressInformationCompo
      isReadOnly={true}
      {...props}
    />
  );
};
export const Content = (props) => {
  return (
    <ContentCompo
      isReadOnly={true}
      {...props}
    />
  );
};

export const Calculation = (props) => {
  return (
    <CalculationCompo
      isReadOnly={true}
      {...props}
    />
  );
};
export const LookupDropDown = (props) => {
  return (
    <LookupDropDownCompo
      isReadOnly={true}
      {...props}
    />
  )
};