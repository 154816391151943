import {isEmpty} from "src/shared/functions/isEmpty";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {FormHelperText} from "@mui/material";
import * as React from "react";
import * as _ from "lodash";
 import Button from '@mui/material/Button';

import {_calMaxAndObtainedMarks} from "src/shared/functions";

export const QueCorrectIncorrect = ({child}) => {
  const totalMarks = _calMaxAndObtainedMarks(child);
  const totalObtainedMarks = _calMaxAndObtainedMarks(child, false);

  const isRightAns = !isEmpty(Number(totalObtainedMarks))
  return (
    !_.isNaN(Number(totalObtainedMarks)) ? (
      <>
        <FormHelperText
          variant="standard"
          error={!isRightAns}
          className={isRightAns ? "_text-success" : "_text-error"}
        >
          <p className="mb-0">{(isRightAns ? <>
            <Button className="correctButton" sx={{padding:"6px 16px", backgroundColor:"#3ea055"}}>
            <CheckIcon sx={{fontSize:"18px", marginRight:"5px", fontWeight:"700"}}/>Correct Answer</Button>
            </>
            : <><Button className="incorrectButton" sx={{padding:"6px 16px", backgroundColor:"#D21404"}}>
              <CloseIcon sx={{fontSize:"18px", marginRight:"5px", fontWeight:"700"}}/>Wrong Answer
              </Button>
              </>)}
          </p>
          <div>
            <p className="my-0 mt-2" style={{color:"#212B36"}}>Obtained Marks: {totalObtainedMarks}</p>
            <p className="mt-0"  style={{color:"#212B36"}}>Max Marks: {totalMarks}</p>
          </div>
        </FormHelperText>
      </>
    ) : (<></>)
  )
}