import { toast } from "react-toastify";
const customId = "custom-id-yes";

function toastMessages(messageType, message = "Something went wrong!") {
  const autoClose = 3000;
  if (message) {
    if (messageType === "success") {
      toast.dismiss();
      toast.success(message, {
        toastId: customId,
        autoClose
      });
    } else if (messageType === "dismiss") {
      toast.dismiss();
    } else if (messageType === "warn") {
      toast.dismiss();
      toast.warn(message, {
        toastId: customId,
        autoClose
      });
    } else {
      toast.error(message, {
        toastId: customId,
        autoClose
      });
    }
  }
}

export default toastMessages;
