import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { PermissionCheck } from "src/utils/common";
import { isEmpty } from "src/shared/functions/isEmpty";
import { ROLE_NAMES, SUPERROLESNAME } from "../../core/constants";

const RequireAuth = ({ page, permission }) => {
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const location = useLocation();
  let homeUrl = `/`;
  const userType = localStorage.getItem("_Temp_User_Type");
  if (page) {
    if (
      !Boolean(PermissionCheck(page, permission, loginUserInfo)) &&
      !Boolean(!isEmpty(loginUserInfo?.id))
    ) {
      homeUrl = Object.values(SUPERROLESNAME)?.includes(userType)
        ? "/admin/login"
        : "/login";
      localStorage.removeItem("_Temp_User_Type");
    }
  }

  return (page && PermissionCheck(page, permission, loginUserInfo)) ||
    (page == "Dashboard" && loginUserInfo?.id) ? (
    <Outlet />
  ) : !isEmpty(loginUserInfo?.id) ? (
    <Navigate to={`/unauthorized`} state={{ from: location }} replace />
  ) : (
    <Navigate to={homeUrl} state={{ from: location }} replace />
  );
};

export default RequireAuth;
