import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { alpha } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";

import { usePathname } from "src/routes/hooks";
import { RouterLink } from "src/routes/components";

import { useResponsive } from "src/hooks/use-responsive";

import Logo from "src/components/logo";
import Scrollbar from "src/components/scrollbar";

import { NAV } from "./config-layout";
import navConfig from "./config-navigation";
import * as Icons from "@mui/icons-material";
import { PermissionCheck } from "src/utils/common";
import { BootstrapTooltipUi } from "src/components/mui-common/BootstrapToolTip";

export default function Nav({ openNav, onCloseNav, toogle, ontoogle }) {
  const pathname = usePathname();
  const upLg = useResponsive("up", "md");
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const renderAccount = (
    <Box
      sx={{
        my: 3,
        mx: 2.5,
        py: 2,
        px: 2.5,
        display: "flex",
        borderRadius: 1.5,
        alignItems: "center",
      }}
      className="navbariconshift"
    >
      {ontoogle === false && (
        <img
          id="preview"
          src="/assets/icons/logo.svg"
          className="dashboardLogo"
          alt="Preview"
        ></img>
      )}
      <Box className="toggleIcon" onClick={toogle}>
        <span></span>
        <span></span>
        <span></span>
      </Box>
    </Box>
  );

  const renderMenu = (
    <Stack component="nav" spacing={0.5} sx={{ px: 2 }} className="navItems">
      {navConfig.map((item, index) => {
        if (item?.hasOwnProperty("key")) {
          if (PermissionCheck(item?.key, "read", loginUserInfo)) {
            return <NavItem key={`NavItem_0_${index}`} title={item.title} item={item} />;
          }
        } else {
          return <NavItem key={`NavItem_0_${index}`} title={item.title} item={item} />;
        }
      })}
    </Stack>
  );

  const [redirectURL] = React.useState("http://localhost:3000/");
  const handleUpgrade = async () => {
    try {
      const response = await fetch(
        "https://api.stripe.com/v1/billing_portal/sessions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          },
          body: `customer=cus_QMAJx02zd1x24M&return_url=${redirectURL}`, // Remove extra slash here
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch billing portal session");
      }

      const data = await response.json();
      const url = data.url;
      window.location.href = url;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const renderUpgrade = (
    <Box sx={{ px: 2.5, pb: 3, mt: 3 }}>
      <Stack
        alignItems="center"
        spacing={3}
      >
        <Button
          target="_blank"
          variant="contained"
          color="inherit"
          onClick={handleUpgrade}
        >
          Manage Plan
        </Button>
      </Stack>
    </Box>
  );
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 4 }} />

      {renderAccount}
      {renderMenu}
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
      }}
    >
      {upLg ? (
        <Box
          className="sideNav"
          sx={{
            height: 1,
            position: "fixed",
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
            background: "#fff",
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

function NavItem({ title,item }) {
  const pathname = usePathname();
  const active = item.path === "/" + pathname.split("/")[1];
  const IconResolver = ({ iconName, ...props }) => {
    const IconComponent = Icons[iconName];

    if (!IconComponent) {
      return null; 
    }

    return <IconComponent {...props} />;
  };
  return (
    <BootstrapTooltipUi
      title={title}
      arrow
      placement="right"
      className="123456"
    >
      <ListItemButton
        disabled={item.disabled}
        component={RouterLink}
        href={item.path}
        sx={{
          minHeight: 44,
          borderRadius: 0.75,
          typography: "body2",
          color: "text.secondary",
          textTransform: "capitalize",
          fontWeight: "fontWeightMedium",

          ...(active && {
            color: "#ee7000",
            fontWeight: "fontWeightSemiBold",
            bgcolor: "#f4f6f8",
            "&:hover": {
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
              color: "#ee7000",
            },
          }),
          "&:hover": {
            color: "#ee7000",
          },
        }}
      >
        <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
          <IconResolver iconName={item.icon} fontSize="small" />
        </Box>
        <Box component="span">{title}</Box>
      </ListItemButton>
    </BootstrapTooltipUi>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};
