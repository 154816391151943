import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import Iconify from "src/components/iconify";
import { Formik } from "formik";
import * as Yup from "yup";
import useAuth from "src/hooks/useAuth";
import * as React from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { apiGetMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import SelectAsync from "src/components-form/shared/SelectAsync";
import { ROLES } from "src/core/constants";
import { toast } from "react-toastify";

const initialValues = {
  user_name: "",
  email: "",
  password: "",
  confirm_password: "",
  company_id: "",
};
const validationSchema = Yup.object().shape({
  user_name: Yup.string().required("Name is required!"),
  email: Yup.string()
    .email("Invalid Email address")
    .required("Email is required!"),
  password: Yup.string()
    .min(6, "Password must be 6 character length")
    .required("Password is required!"),
  confirm_password: Yup.string()
    .required("Confirm Password is required!")
    .oneOf([Yup.ref("password")], "Passwords must match"),
  company_id: Yup.string().required("Company is required!"),
});

export default function RegisterView() {
  const { register } = useAuth();
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);
  const[showConfirmPassword,setShowConfirmPassword] =  useState(false)

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      let obj = {...values,role:ROLES.FORM_USER}
      await register(obj);
    } catch (e) {
      toast.error(e.data.message);
    } finally {
      setSubmitting(false);
    }
  };
  const [company, setCompany] = useState();

  const renderForm = (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({
        isSubmitting,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              autoComplete={``}
              name="user_name"
              label="Name"
              onBlur={handleBlur}
              value={values.user_name}
              onChange={handleChange}
              error={Boolean(errors.user_name && touched.user_name)}
              helperText={
                touched && touched.user_name && errors && errors.user_name
              }
            />

            <TextField
              autoComplete={``}
              name="email"
              label="Email address"
              onBlur={handleBlur}
              value={values.email}
              onChange={handleChange}
              error={Boolean(touched.email && errors.email)}
              helperText={touched && touched.email && errors && errors.email}
            />

            <FormControl fullWidth>
              <SelectAsync
                onChange={(event) => {
                  setCompany(event);
                  setFieldValue("company_id", event.value);
                }}
                searchType="signupcompanyid"
                selectedValue={company}
                placeholder="Select Company"
                error= {errors?.company_id}
              />
                {errors?.company_id && (
                  <div
                    style={{
                      color: "#e74c3c",
                      fontSize: "12px",
                      paddingLeft: "12px",
                      paddingTop: "4px",
                    }}
                  >
                    <span>{errors?.company_id}</span>
                  </div>
                )}
            </FormControl>

            <TextField
              autoComplete={``}
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              onBlur={handleBlur}
              value={values.password}
              onChange={handleChange}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password ? errors.password : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              autoComplete={``}
              name="confirm_password"
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              onBlur={handleBlur}
              value={values.confirm_password}
              onChange={handleChange}
              error={Boolean(
                touched.confirm_password && errors.confirm_password
              )}
              helperText={
                touched.confirm_password ? errors.confirm_password : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showConfirmPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <LoadingButton
            sx={{ mt: 3 }}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="inherit"
            loading={isSubmitting}
            className=" blueButton"
          >
            Register
          </LoadingButton>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ my: "13px" }}
          >
            <div
              variant="subtitle2"
              href="/login"
              className="loginfx"
            >
              Already have an account? Login
            </div>
          </Stack>
        </form>
      )}
    </Formik>
  );

  return (
    <Box height="100%">
      <Grid container height="100%" className="loginSection">
        <Grid item lg={6} className="imageSection">
          <span className="dot1">
            <img src="/assets/background/dots.svg" style={{ width: "200px" }} />
          </span>
          <span className="dot2">
            <img src="/assets/background/dots.svg" style={{ width: "200px" }} />
          </span>
          <span className="blob1">
            <img
              src="/assets/background/blob1.svg"
              style={{ width: "400px" }}
            />
          </span>
          <span className="blob2">
            <img
              src="/assets/background/blob1.svg"
              style={{ width: "400px" }}
            />
          </span>
          <img
            src="/assets/background/login.svg"
            style={{ height: "650px", width: "650px" }}
            alt="login"
          />
        </Grid>
        <Grid item lg={6}>
          <Stack>
            <Card
              className="cardBack"
              sx={{
                p: 5,
                width: 1,
                maxWidth: 500,
              }}
            >
              <Typography variant="h4" sx={{ textAlign: "center" }}>
              Sign up as a user.
              </Typography>
              <Typography variant="body2" sx={{ mt: 2, mb: 5 }}></Typography>
              {renderForm}
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
