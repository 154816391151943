import {
  Button,
  IconButton,
  TableCell,
  TableRow,
  Grid,
  FormControl,
  InputLabel,
  Table,
  TableContainer,
  TableBody,
  Card,
  TextField,
} from "@mui/material";
import { BootstrapDialog } from "src/components-form/Modals/ui/BootstrapDialog";
import { allApiUrl } from "src/api/apiRoute";
import React, { useState, useEffect } from "react";
import SelectAsync from "src/components-form/shared/SelectAsync";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import { apiPostMethod } from "src/api/rest";
import AssignForm from "./view/AssignForm";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { TrainingStatusTable } from "./view/trainingTable";
import Checkbox from "@mui/material/Checkbox";
import Loader from "src/components/Loader";
import dayjs from "dayjs";
import { calculateExpiryDate } from "src/shared/functions/string-conversion";
import { useSelector } from "react-redux";
import { ROLES } from "src/core/constants";
import TableHeadRow, {
  StyledTableCell,
  StyledTableNoData,
  StyledTableRow,
} from "src/components/mui-common/table-ui/TableHeadRow";
import { result } from "lodash";

export const AssignModal = ({
  setOpenModal,
  projectId,
  crewsFiltered,
  userId,
  setUserId,
  projectName = "",
  formData,
  setFormData,
  trainingData,
  apiTrainingCall,
  loader,
  setLoader,
  selectDueDate,
  linkedFormsModalOpen = null,
  setLinkedFormsModalOpen = null,
}) => {
  const {
    trainingStatus,
    trainingreqData,
    headLabeleRequired,
    selectedTrainings,
    handleChangeTrainingRequired,
    handleAssignTraining,
    disableTraining,
    trainingLoader,
  } = TrainingStatusTable();
  const today = dayjs();
  const [selectedDate, setSelectedDate] = useState(null);
  const [projectTraining, setProjectTraining] = useState([]);
  const [checkAssignTraining, setCheckAssignTraining] = useState(false);
  const [disableStatus, setDisabledStatus] = useState(true);
  const [disableAssign, setDisableAssign] = useState(true);
  const [assignLoader, setAssignLoader] = useState(false);
  const [crewMemberData, setCrewMemberData] = useState([]);
  const [form, setForm] = useState({
    form_id: "",
    due_date: "",
  });
  const handleFormChange = (event) => {
    setFormData(event);
    setForm({
      ...form,
      form_id: event.value,
    });
    if (linkedFormsModalOpen !== null) {
      setSelectedDate(dayjs(event?.due_date));
      setForm({
        ...form,
        due_date: new Date(event.due_date),
      });
    }
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setForm({
      ...form,
      due_date: new Date(date),
    });
  };
  const handleSubmit = () => {
    setAssignLoader(true);
    if (form?.form_id === "") {
      toast.error("Select a form first");
      setAssignLoader(false);
    } else if (form?.due_date === "") {
      toast.error("Select a valid due date");
      setAssignLoader(false);
    } else {
      let newArray = [];
      if (userId?.length != 0) {
        newArray.push(userId);
      } else {
        crewsFiltered?.length > 0 &&
          crewsFiltered?.map((item) => {
            newArray?.push(item.crew_member_user_id);
          });
      }

      let obj = {
        crew_member_ids: newArray,
      };
      if (linkedFormsModalOpen !== null) {
        apiPostMethod(
          `${allApiUrl?.CREW_PROJECT_CREW_ID}/${projectId}/${form?.form_id}/${userId}`,
          {}
        )
          .then((data) => {
            toast.success(data.message);
            setOpenModal(false);
            setFormData("");
            setAssignLoader(false);
            setLinkedFormsModalOpen(null);
          })
          .catch((err) => {
            setAssignLoader(false);
            toast.error(err.data.message);
          });
      } else {
        apiPostMethod(`${allApiUrl?.ASSIGN_PROJECT_FORM}/${projectId}`, form)
          .then((data) => {
            apiPostMethod(
              `${allApiUrl?.PROJECT_FORM_ALLOCATION}/${data?.data?.project_id}/${data?.data?.id}`,
              obj
            ).then((res) => {
              toast.success(res.message);
              setOpenModal(false);
              setFormData("");
              setAssignLoader(false);
            });
          })
          .catch((err) => {
            setAssignLoader(false);
            toast.error(err.data.message);
          });
      }
    }
  };

  useEffect(() => {
    if (formData && linkedFormsModalOpen == null) {
      apiTrainingCall();
    }
  }, [formData]);
  function trainingCount() {
    setLoader(true);
    let result = trainingData?.projectUsers?.map((pu) => {
      pu.count = 0;
      trainingData?.userTrainings?.forEach((ut) => {
        if (ut?.user_id == pu?.crew_member_user_id) {
          trainingData?.trainingsData?.forEach((td) => {
            if (td?.id == ut?.trainings_id) {
              pu.count = pu?.count + 1;
            }
          });
        }
      });

      return pu;
    });
    setProjectTraining(result);
    setLoader(false);
  }
  useEffect(() => {
    trainingCount();
    trainingStatus(trainingData, userId);
  }, [trainingData, userId]);

  useEffect(() => {
    if (form?.form_id !== "") {
      setDisableAssign(false);
      setDisabledStatus(false);
    } else {
      setDisableAssign(true);
      setDisabledStatus(true);
    }
  }, [form]);
  const handleScroll = () => {
    const scrollY = window.scrollY;
    const modal = document.querySelector(".confirmModal");
    if (modal) {
      modal.style.top = `calc(50% - ${scrollY}px)`;
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const closeModal = () => {
    setOpenModal(false);
  };
  const headLabel = [
    { id: "user_name", label: "User Name" },
    { id: "training-done", label: "Training Done" },
  ];
  useEffect(() => {
    if (selectDueDate) {
      setSelectedDate(dayjs(new Date(selectDueDate)));
    }
  }, [selectDueDate]);
  useEffect(() => {
    if (formData) {
      setForm({
        ...form,
        form_id: formData.value,
      });
    }
  }, [formData]);
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  useEffect(() => {
    let array = [];
    if (linkedFormsModalOpen !== null && formData == "") {
      crewsFiltered?.length > 0 &&
        crewsFiltered?.map((item) => {
          if (item?.crew_member_user_id == userId) {
            item.count = 0;
            array?.push(item);
          }
          setCrewMemberData(array);
        });
    } else if (linkedFormsModalOpen !== null && formData !== "") {
      crewsFiltered?.length > 0 &&
        crewsFiltered?.map((item) => {
          if (item?.crew_member_user_id == userId) {
            let result = trainingData?.projectUsers?.map((pu) => {
              pu.count = 0;
              if (item?.crew_member_user_id == pu?.crew_member_user_id) {
                trainingData?.userTrainings?.forEach((ut) => {
                  if (ut?.user_id == pu?.crew_member_user_id) {
                    trainingData?.trainingsData?.forEach((td) => {
                      if (td?.id == ut?.trainings_id) {
                        pu.count = pu?.count + 1;
                      }
                    });
                  }
                });
                return pu;
              }
            });
            result = result?.filter((option) => option !== undefined);
            setCrewMemberData(result);
          }
        });
    }
  }, [linkedFormsModalOpen, userId, crewsFiltered, trainingData]);
  return (
    <>
      <BootstrapDialog
        className="saveFormwidth"
        onClose={closeModal}
        width={`auto`}
        open={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Assign Form | {projectName}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel
                  style={{
                    backgroundColor: "#fff",
                    padding: "0 5px",
                  }}
                  shrink={true}
                >
                  Forms
                </InputLabel>
                <SelectAsync
                  onChange={handleFormChange}
                  searchType={
                    linkedFormsModalOpen == null
                      ? "project_JSA_FORM"
                      : "project_submited_form"
                  }
                  dataParam={{ filtered_data: "project" }}
                  selectedValue={formData}
                  projectId={projectId}
                  placeholder="Select Form"
                  className="multiSelectHeight project"
                  isDisabled={
                    loginUserInfo?.roleid[0] == ROLES.FORM_USER ? true : false
                  }
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl sx={{ width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className="dateWidth">
                    <DesktopDatePicker
                      label="Due date"
                      value={selectedDate}
                      onChange={handleDateChange}
                      disabled={
                        loginUserInfo?.roleid[0] === ROLES.FORM_USER ||
                        linkedFormsModalOpen !== null
                      }
                      renderInput={(params) => <TextField {...params} />}
                      minDate={today}
                    />
                  </div>
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item md={8}>
              <Card className="cardFix" sx={{ marginTop: 0 }}>
                <TableContainer sx={{ overflowy: "scroll", height: "400px" }}>
                  <Table
                    sx={{
                      minWidth: 450,
                    }}
                  >
                    <TableHeadRow headCells={headLabel} />
                    {linkedFormsModalOpen !== null && formData == "" ? (
                      <TableBody>
                        {crewMemberData?.length > 0 ? (
                          crewMemberData?.map((item, idx) => {
                            return (
                              <StyledTableRow
                                key={`styledTableRowAs_${idx}`}
                                className={
                                  item?.crew_member_user_id === userId
                                    ? "trainingActive"
                                    : ""
                                }
                                onClick={() => {
                                  setUserId(item?.crew_member_user_id);
                                }}
                              >
                                <StyledTableCell>
                                  {item?.user_name}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {item?.training_done || 0}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            {" "}
                            <TableCell colSpan={4} align="center">
                              No Data Found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    ) : linkedFormsModalOpen !== null && formData !== "" ? (
                      <TableBody>
                        {crewMemberData?.length > 0 ? (
                          crewMemberData?.map((item, idx) => {
                            return (
                              <StyledTableRow
                                key={`styledTableRowAs_${idx}`}
                                className={
                                  item?.crew_member_user_id === userId
                                    ? "trainingActive"
                                    : ""
                                }
                                onClick={() => {
                                  setUserId(item?.crew_member_user_id);
                                }}
                              >
                                <StyledTableCell>
                                  {item?.user_name}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {item?.count || 0}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            {" "}
                            <TableCell colSpan={4} align="center">
                              No Data Found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    ) : formData === "" ? (
                      <TableBody>
                        {crewsFiltered?.length > 0 ? (
                          crewsFiltered?.map((item, index) => {
                            return (
                              <StyledTableRow
                                key={`styledTableRowAs_${index}`}
                                className={
                                  item?.crew_member_user_id === userId
                                    ? "trainingActive"
                                    : ""
                                }
                                onClick={() => {
                                  setUserId(item?.crew_member_user_id);
                                }}
                              >
                                <StyledTableCell>
                                  {item?.user_name}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {item?.training_done || 0}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              No Data Found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    ) : (
                      <TableBody sx={{ overflowY: "auto", maxHeight: "350px" }}>
                        {loader ? (
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={8}
                              sx={{ padding: "28px" }}
                            >
                              <Loader />
                            </TableCell>
                          </TableRow>
                        ) : projectTraining?.length > 0 ? (
                          projectTraining?.map((item, index) => {
                            return (
                              <TableRow
                                key={index}
                                className={
                                  item?.crew_member_user_id == userId
                                    ? "trainingActive"
                                    : ""
                                }
                                onClick={() => {
                                  setUserId(item?.crew_member_user_id);
                                }}
                              >
                                <TableCell>{item?.user_name}</TableCell>
                                <TableCell>
                                  {item?.userDoneTrainings?.length}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              No Data Found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card className="cardFix" sx={{ marginTop: 0 }}>
                <TableContainer sx={{ overflowy: "scroll", height: "400px" }}>
                  <Table
                    sx={{
                      minWidth: 200,
                    }}
                  >
                    <TableHeadRow headCells={headLabeleRequired} />
                    <TableBody>
                      {loader ? (
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={8}
                            sx={{ padding: "28px" }}
                          >
                            <Loader />
                          </TableCell>
                        </TableRow>
                      ) : trainingreqData?.length > 0 ? (
                        trainingreqData?.map((item, index) => {
                          const isChecked = selectedTrainings.some(
                            (sel) => sel.index === index
                          );
                          const trainingId = item?.id;
                          return (
                            <TableRow key={index}>
                              <TableCell>
                                {item?.showCheckbox ? (
                                  <Checkbox
                                    sx={{ padding: 0 }}
                                    checked={isChecked}
                                    onChange={handleChangeTrainingRequired(
                                      index,
                                      trainingId
                                    )}
                                    disabled={userId == "" ? true : false}
                                  />
                                ) : (
                                  <Checkbox
                                    sx={{ padding: 0 }}
                                    checked={
                                      !item?.userDone ||
                                      calculateExpiryDate(
                                        item?.created_at,
                                        item?.validityperiod,
                                        false,
                                        true
                                      ).valid
                                        ? true
                                        : isChecked
                                    }
                                    disabled={
                                      !item?.userDone ||
                                      calculateExpiryDate(
                                        item?.created_at,
                                        item?.validityperiod,
                                        false,
                                        true
                                      ).valid
                                        ? true
                                        : false
                                    }
                                    onChange={handleChangeTrainingRequired(
                                      index,
                                      trainingId
                                    )}
                                  />
                                )}
                              </TableCell>
                              <TableCell>
                                {item?.name}{" "}
                                {item?.userDone == true &&
                                  item?.showCheckbox == false && (
                                    <span style={{ color: "green" }}>
                                      {item?.userDone == true &&
                                      item?.showCheckbox == false
                                        ? calculateExpiryDate(
                                            item?.created_at,
                                            item?.validityperiod,
                                            "chip"
                                          )
                                        : ""}
                                    </span>
                                  )}
                                {item?.userDone == undefined &&
                                  item?.showCheckbox == false && (
                                    <span style={{ color: "red" }}>
                                      {"(Assigned)"}
                                    </span>
                                  )}
                                {item?.userDone == undefined &&
                                  item?.showCheckbox == true && (
                                    <span style={{ color: "red" }}>
                                      {"(Pending)"}
                                    </span>
                                  )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3} align="center">
                            No Training Required
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
            <Grid item md={12}>
              <div
                style={{
                  display: "flex",
                  marginTop: "25px",
                  justifyContent: "center",
                  gap: "10px",
                }}
              ></div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={trainingLoader}
            loadingPosition="end"
            className={disableTraining === true ? "" : "blueButton"}
            disabled={disableTraining}
            endIcon={<SaveIcon />}
            variant="contained"
            onClick={() => {
              handleAssignTraining(userId);
              setOpenModal(false);
            }}
            sx={{
              p: "8px 25px",
              borderRadius: "4px",
              fontSize: "15px",
              textTransform: "capitalize",
            }}
          >
            <span>Assign Training</span>
          </LoadingButton>

          <LoadingButton
            loading={assignLoader}
            loadingPosition="end"
            endIcon={<SaveIcon />}
            variant="contained"
            className={
              (disableAssign === true) |
              (loginUserInfo?.roleid[0] == ROLES.FORM_USER)
                ? ""
                : "blueButton"
            }
            disabled={
              disableAssign || loginUserInfo?.roleid[0] == ROLES.FORM_USER
            }
            onClick={handleSubmit}
            sx={{
              p: "8px 25px",
              borderRadius: "4px",
              fontSize: "15px",
              textTransform: "capitalize",
            }}
          >
            <span>Assign Form</span>
          </LoadingButton>
          <Button
            className="blueButton cancel"
            sx={{ p: "8px 25px" }}
            onClick={() => setOpenModal(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {checkAssignTraining && (
        <AssignForm
          setCheckAssignTraining={setCheckAssignTraining}
          formData={formData}
          selectedDate={selectedDate}
          projectTraining={projectTraining}
        />
      )}
    </>
  );
};
