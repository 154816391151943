import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { RouterLink } from 'src/routes/components';

import Logo from 'src/components-form/logo';
import useAuth from "../../hooks/useAuth";
import {useSelector} from "react-redux";

export default function UnauthorizedView() {
  const { logout } = useAuth();
  const loginUserInfo = useSelector((state) => state.loginReducer.loginUserInfo
  );

  const renderHeader = (
    <Box
      component="header"
      sx={{
        top: 0,
        left: 0,
        width: 1,
        lineHeight: 0,
        position: 'fixed',
        p: (theme) => ({ xs: theme.spacing(3, 3, 0), sm: theme.spacing(5, 5, 0) }),
      }}
    >
      <Logo />
    </Box>
  );

  return (
    <>
      <Container>
        <Box
          sx={{
            py: 12,
            maxWidth: 480,
            mx: 'auto',
            display: 'flex',
            minHeight: '100vh',
            textAlign: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" sx={{ mb: 3 }}>
            Unauthorized!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            You do not have access to the requested page.
          </Typography>

          <Box
            component="img"
            src="/assets/illustrations/illustration_404.svg"
            sx={{
              mx: 'auto',
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />

          {(loginUserInfo && loginUserInfo.id) && (
            <Button size="large" variant="contained" onClick={() => logout()}>
              Logout
            </Button>
          )}
          <br />

          <Button href="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </Box>
      </Container>
    </>
  );
}
