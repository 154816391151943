import React, { createContext, useState } from "react";
import { apiPostMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import toastMessage from "src/components-form/ToastMessage";
import { saveLoginUserInfo, saveLoginUserToken } from "src/redux/action/login";
import { useDispatch } from "react-redux";
import { useRouter } from "src/routes/hooks";
import { ROLE_NAMES, SUPERADMINROLES } from "src/core/constants";
import { ROLES } from "src/core/constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const AuthContext = createContext({
  auth: null,
  login: () => {},
  register: () => {},
  forgotPassword: () => {},
  resetPassword: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const router = useRouter();
  const [auth, setAuth] = useState({});
  const login = async (email, password, companyid, portal) => {
    try {
      let obj = {
        email,
        password,
        companyid,
      };
      if (portal) {
        obj["portal"] = portal;
      }
      const response = await apiPostMethod(allApiUrl.USER_LOG_IN, obj);

      const userInfo = response?.data;
      if (
        userInfo &&
        (typeof userInfo.roleid === "string" ||
          typeof userInfo.roleid === "number")
      ) {
        userInfo.roleid = [userInfo.roleid];
      }
      try {
        userInfo.isUser =
          userInfo?.roleDetails?.name.toLowerCase() === ROLE_NAMES.USER;
      } catch (e) {}

      setAuth(userInfo);
      dispatch(saveLoginUserInfo(userInfo));
      dispatch(saveLoginUserToken(userInfo.token));
      localStorage.setItem("_Temp_User_Type", userInfo?.roleDetails?.name);
      toast.success(response.message);
      setTimeout(() => {
        router.replace("/");
      }, 100);
    } catch (e) {
      toast.error(e.data.message || e?.message);
      if (e?.data?.isPayment === false) {
        naviagte("/payment", { state: { email: email } });
      }
    }
  };
  const register = async (values) => {
    try {
      const response = await apiPostMethod(allApiUrl.USER_REGISTER, {
        user_name: values.user_name,
        email: values.email,
        password: values.password,
        company_id: values.company_id,
        role: values.role,
      });

      toast.success(response.message);
      router.replace("/login");
    } catch (e) {
      toast.error(e.data.message);
    }
  };
  const forgotPassword = async (email, companyId) => {
    try {
      let obj = {
        email,
        companyId,
      };
      const response = await apiPostMethod(allApiUrl.USER_FORGOT_PASSWORD, obj);
      const userInfo = response;
      toast.success(response.message);
      if (Object?.values(SUPERADMINROLES)?.includes(userInfo.roleid)) {
        router.replace("/admin/login");
      } else {
        router.replace("/login");
      }
    } catch (e) {
      toast.error(e.data.message);
    }
  };
  const resetPassword = async (password, urlToken) => {
    try {
      let obj = {
        password: password,
        token: urlToken,
      };
      const response = await apiPostMethod(allApiUrl.USER_RESET_PASSWORD, obj);
      const userInfo = response;
      toast.success(response.message);
      if (Object?.values(SUPERADMINROLES)?.includes(userInfo.roleid)) {
        router.replace("/admin/login");
      } else {
        router.replace("/login");
      }
    } catch (e) {
      toast.error(e.data.message);
    }
  };
  const logout = () => {
    if (auth.roleid[0] == ROLES.SUPER_ADMIN) {
      router.replace("/admin/login");
    } else {
      router.replace("/login");
    }
    setAuth({});
    dispatch(saveLoginUserToken(""));
    dispatch(saveLoginUserInfo({}));
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        login,
        register,
        forgotPassword,
        resetPassword,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
