import {
  Box,
  TextField,
  Card,
  styled,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiGetMethod, apiPostMethod, apiPutMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import PageHeader from "src/components-form/Common/PageHeader";
import { Helmet } from "react-helmet-async";

// -------------------------------------------------------------------

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
}));

export default function AddIndustry() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [value, setValue] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    is_active: true,
  });
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required!"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      try {
        setLoading(true);
        let obj;
        if (value == true) {
          obj = {
            name: values.name,
            is_active: values.is_active,
          };
        } else {
          obj = {
            is_active: values.is_active,
          };
        }

        {
          location?.pathname === "/industry/add-industry"
            ? apiPostMethod(`${allApiUrl?.ADD_INDUSTRY}`, obj)
                .then((data) => {
                  toast.success("Industry Added Successfully");
                  navigate("/industry");
                  setLoading(false);
                })
                .catch((err) => {
                  toast.error(err?.data?.message);
                  setLoading(false);
                })
            : apiPutMethod(`${allApiUrl?.EDIT_INDUSTRY}/${id}`, obj)
                .then((data) => {
                  toast.success("Edit Successfully");
                  navigate("/industry");
                  setLoading(false);
                })
                .catch((err) => {
                  toast.error(err?.data?.message);

                  setLoading(false);
                });
        }
      } catch (error) {
        toast.error(error.response.data.message);
        setLoading(false);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });
  function apiCall() {
    setLoader(true);
    apiGetMethod(`${allApiUrl?.GET_INDUSTRY_BYID}/${id}`)
      .then((data) => {
        setInitialValues(data.data[0]);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  }
  useEffect(() => {
    if (id) {
      apiCall();
    }
  }, [id]);
  const handleCancel = () => {
    navigate("/industry");
  };
  return (
    <>
      <Helmet>
        <title> Industries | {process.env.REACT_APP_APPLICATION_NAME} </title>
      </Helmet>
      <div className="flexTop">
        <PageHeader
          header={
            location?.pathname == "/industry/add-industry"
              ? "Add Industry"
              : "Edit Industry"
          }
          links={[
            {
              name: "Dashboard",
              path: "/",
            },
            {
              name: "Industry",
              path: "/industry",
            },
          ]}
        />
      </div>
      <Card elevation={3} sx={{ pt: "20px", mb: 3 }} className="cardFix">
        <CardHeader className="cardHeadBorder">
          <Title className="cardHead">
            {location?.pathname === "/industry/add-industry" ? "New" : "Edit"}{" "}
            Industry
          </Title>
        </CardHeader>
        <Grid container>
          <Grid item lg={12} width="100%">
            <Box p={4} height="100%">
              <FormikForm
                formik={formik}
                loading={loading}
                location={location}
                value={value}
                setValue={setValue}
                handleCancel={handleCancel}
              />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

function FormikForm({ formik, loading, location, setValue, handleCancel }) {
  const { values, errors, setFieldValue, submitForm, handleSubmit, touched } =
    formik;
  return (
    <FormikProvider value={formik}>
      <Form id="formIk" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid item lg={12}>
          <TextField
            fullWidth
            type="text"
            name="name"
            label="Industry Name"
            variant="outlined"
            className="inputHeight"
            value={values.name}
            onChange={(e) => {
              setFieldValue("name", e.target.value);
              setValue(true);
            }}
            helperText={touched.name && errors.name}
            error={Boolean(errors.name && touched.name)}
            sx={{ mb: 3 }}
          />
        </Grid>
        {location?.pathname !== "/industry/add-industry" && (
          <Grid item lg={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.is_active}
                label="Status"
                onChange={(e) => {
                  setFieldValue("is_active", e.target.value);
                }}
                error={Boolean(errors.industry && touched.industry)}
                sx={{ mb: 3, height: "46px" }}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        <div>
          <LoadingButton
            className="blueButton"
            type="button"
            color="primary"
            loading={loading}
            onClick={() => {
              submitForm();
            }}
            variant="contained"
            sx={{ mr: "10px" }}
          >
            {location?.pathname == "/industry/add-industry"
              ? "Add Industry"
              : "Update"}
          </LoadingButton>

          <Button className="blueButton cancel" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
}
