import * as _ from "lodash";
import {uniqueID} from "src/shared/functions";
import React from "react";
import { TextField} from "@mui/material";

const ChoiceType = {
  DROPDOWN: "DropDown",
  CHECKBOXES: "CheckBoxes"
}
export const ChoiceDropUi = (
  {
    mainLabel = "Options",
    editForm,
    dragOverItem,
    dragStart,
    dragEnter,
    drop,
    editElementProp,
    updateSelectedField,
    data
  }) => {

  return (
    <div className="form-group">
      <span className="d-block mb-2">
        <b>{mainLabel}</b>
      </span>
      { editForm.options &&
        editForm.options.length > 0 &&
        [...editForm.options].map((item, index) => {
          return (
            <div
              className={`choiceSec ${
                dragOverItem === index ? "active" : ""
              }`}
              onDragStart={() => dragStart(index)}
              onDragEnter={() => dragEnter(index)}
              onDragEnd={(e) => drop(e, "options")}
              key={`item_${item.id}`}
              draggable
            >
              <div className="w-100">
                <div className="d-flex justify-content-between">
                  <div>
                    <a role="button" type="button">
                      <i className="fa-solid fa-grip"></i>
                    </a>
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      name={editForm.id}
                      value={data.hasValues ? item.value : item.label} 
                      checked={_.includes(_.map(data.value, "id"), item.id)}
                      onChange={(e) => {
                        const el = {
                          element: data.element,
                          id: item.id,
                          val: (editForm.hasValues ? item.value : item.label)
                        };

                        if ((data.element === ChoiceType.DROPDOWN) || data.typeRadio) {
                          editElementProp((e.target.checked ? [el] : []), ["value"])
                        } else {
                          let tempDataVal = [...data.value];
                          if (e.target.checked) {
                            tempDataVal.push(el)
                          } else {
                            _.remove(tempDataVal, o => o.id === item.id)
                          }
                          editElementProp(tempDataVal, ["value"])
                        }
                      }}
                    />
                    &nbsp;<span className="small">Set Default</span>
                  </div>
                  <div>
                    {!item.disabled && (
                      <>
                      <a
                          role="button"
                          type="button"
                          onClick={() => {
                            editForm.options.splice(index, 1);
                            updateSelectedField(editForm);
                          }}
                        >
                          <i className="fa-solid fa-trash"></i>
                        </a>
                        &nbsp;
                        <a
                          role="button"
                          type="button"
                          onClick={() => {
                            const countNullVal = _.countBy(editForm.options, o => {
                              return (o?.label === "");
                            })
                            if (!countNullVal.true || (countNullVal.true && countNullVal.true < 1)) {
                              editForm.options.splice(index + 1, 0, {
                                id: `${data.element}_Option_${uniqueID(6)}`,
                                label: "",
                                value: "",
                                checked: false,
                                disabled: false,
                              });
                              updateSelectedField(editForm);
                            }
                          }}
                        >
                          <i className="fa-solid fa-plus"></i>
                        </a>
                      </>
                    )}
                  </div>
                </div>
                <TextField
                  fullWidth
                  multiline
                  label="Label"
                  disabled={item?.disabled}
                  className={`mt-3 ${
                    editForm.hasValues ? " keyField" : ""
                  }`}
                  type="text"
                  size="small"
                  value={item.label}
                  onChange={(e) => {
                    editElementProp(e.target.value, [
                      "options",
                      index,
                      "label",
                    ])
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    inputProps: {
                      onWheel: (e) => e.currentTarget.blur()
                    }
                  }}
                />
                {(!item.disabled && editForm.hasValues) && (
                  <TextField
                    fullWidth
                    label="Value"
                    className={`mt-3 `}
                    type="text"
                    size="small"
                    value={item.value}
                    onChange={(e) =>
                      editElementProp(e.target.value, [
                        "options",
                        index,
                        "value",
                      ])
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      inputProps: {
                        onWheel: (e) => e.currentTarget.blur()
                      }
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
    </div>
  )
}