import {
  evaluateExpression,
  highlightTextWithBackground,
  replaceLabelStrings
} from "src/shared/functions/string-conversion";
import {useEffect, useState} from "react";
import LabelCompo from "./LabelCompo";
import * as React from "react";
import {isEmpty} from "src/shared/functions/isEmpty";

export const CalculationCompo = ({ isReadOnly = true, data, form, onChangeFields }) => {
  const labelConv = !isReadOnly ? replaceLabelStrings(data?.label, form) : data?.label;

  const [calVal, setCalVal] = useState(evaluateExpression(data, form) || "");

  useEffect(() => {
    if (!isReadOnly) {
      onChangeFields(calVal)
    }
  }, [calVal]);

  useEffect(() => {
    if (!isReadOnly) {
      const newVal = evaluateExpression(data, form) || "";
      setCalVal(newVal)
    }
  }, [data, form]);
  return (
    <div key={data.id} id={data.id}>
      {
        isReadOnly ? (
          <div className="grid" style={{wordWrap: "break-word"}}>
            <form key={data.id} id={data.id}>
              <LabelCompo data={data}/>
              <div
                dangerouslySetInnerHTML={{
                  __html: !isEmpty(data.calculation)
                    ? highlightTextWithBackground(data.calculation)
                    : "Calculation(Text)",
                }}
              />
              <div>{data.helptext ? data.helptext : ""}</div>
            </form>
          </div>
        ) : (
          <div className="row">
            <div
              className="col-12">
              <LabelCompo
                data={data}
                labelConv={labelConv}
              />
              <input hidden type="text" id={`field_id_${data.id}`} value={calVal} readOnly/>
              <p className="mb-0">{calVal}</p>
              <p>
                <i>{data.helptext}</i>
              </p>
            </div>
          </div>
        )
      }
    </div>
  );
};
