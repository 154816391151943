import {InputAdornment, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import debounce from "lodash/debounce";

export const SearchDebounce = (
  {
    onSearch = (value) => {},
    searchPlaceholder="Search",
    debounceTime = 800,
    className = "", 
    sx= {}
  }
) => {
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearch = useCallback(
    debounce((query) => {
      onSearch(query);
    }, debounceTime),
    [debounceTime, onSearch]
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm, debouncedSearch, onSearch]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <TextField
      fullWidth
      type="text"
      size="small"
      className={`searchWhite ${className}`}
      label="Search"
      variant="outlined"
      placeholder={searchPlaceholder}
      value={searchTerm}
      sx={sx}
      onChange={handleInputChange}
      InputProps={{
        endAdornment: searchTerm ? (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              onClick={() => {
                setSearchTerm("");
              }}
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ) : (
          <></>
        ),
        inputProps: {
          onWheel: (e) => e.currentTarget.blur(),
        },
      }}
    />
  )
}