import {TableHead, TableRow} from "@mui/material";
import {PermissionCheck} from "../../../utils/common";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import * as React from "react";
import {useSelector} from "react-redux";
import {styled} from "@mui/material/styles";

export default function TableHeadRow(
  {
    numSelected,
    rowCount,
    headCells,
    permissionType,
    listData,
    setSelected
  }) {
  const loginUserInfo = useSelector((state) => state.loginReducer.loginUserInfo);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = listData.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([...[]]);
  };

  return (
    <TableHead className="tableHead">
      <TableRow>
        {headCells?.map((hCell) =>
          hCell.id === "checkbox" &&
          PermissionCheck(permissionType, "delete", loginUserInfo) ? (
            <TableCell padding="checkbox" key={hCell.id}>
              <Checkbox
                indeterminate={
                  numSelected > 0 && numSelected < rowCount
                }
                checked={
                  rowCount > 0 && numSelected === rowCount
                }
                onChange={handleSelectAllClick}
                inputProps={{ "aria-label": "select all forms" }}
              />
            </TableCell>
          ) : (
            <TableCell key={hCell.id} className="nowrap">
              {hCell.label}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  )
}

export const StyledTableNoData = (
  {colSpan}
) => {
  return (
    <StyledTableRow
      style={{
        height: 53,
      }}
    >
      <TableCell
        align="center"
        colSpan={colSpan}
      >
        No Data Found
      </TableCell>
    </StyledTableRow>
  )
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));