import {
  Box,
  TextField,
  Card,
  styled,
  Grid,
  Autocomplete,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiGetMethod, apiPutMethod } from "src/api/rest";
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { allApiUrl } from "src/api/apiRoute";
import SelectAsync from "src/components-form/shared/SelectAsync";
import { useDispatch, useSelector } from "react-redux";
import { COMPANYROLES, ROLES } from "src/core/constants";
import { saveLoginUserInfo } from "src/redux/action/login";
import { MuiTelInput } from "mui-tel-input";
import Button from "@mui/material/Button";

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
}));

export default function EditProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    phone_number: "",
    companyid: "",
    industryid: "",
    company_name: "",
    industry_name: "",
  });
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required!"),
    phone_number: Yup.string().required("Phone Number is required!"),
  });
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  function apiCall() {
    apiGetMethod(allApiUrl?.GET_USER_PROFILE).then((data) => {
      setInitialValues({
        ...initialValues,
        name: data.data[0]?.user_name,
        email: data.data[0]?.email,
        phone_number: data?.data[0]?.phone_number?.replace(/\s/g, ""),
        companyid: data?.data[0]?.company_id,
        industryid: data?.data[0]?.industry_id,
        company_name: data?.data[0]?.company_name,
        industry_name: data?.data[0]?.industry_name,
      });
      let userInfo = { ...loginUserInfo, user_name: data.data[0]?.user_name };
      dispatch(saveLoginUserInfo(userInfo));
    });
  }
  useEffect(() => {
    apiCall();
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      try {
        let obj = {
          user_name: values.name,
          phone_number: values.phone_number,
        };
        apiPutMethod(allApiUrl.UPDATE_USER_PROFILE, obj).then((data) => {
          toast.success(data.message);
          apiCall();
          navigate("/");
        });
      } catch (err) {
        toast.error(err.data.message);
      }
    },
  });
  const handleCancel = () => {
    navigate("/");
  };
  return (
    <>
      <Card elevation={3} sx={{ pt: "20px", mb: 3 }} className="cardFix">
        <CardHeader className="cardHeadBorder">
          <Title className="cardHead">Edit Profile</Title>
        </CardHeader>
        <Box p={4} height="100%">
          <FormikForm formik={formik} handleCancel={handleCancel} />
        </Box>
      </Card>
    </>
  );
}

function FormikForm({ formik, handleCancel }) {
  const [company, setCompany] = useState();
  const [industry, setIndustry] = useState();
  const [optionsRole, setOptionsRole] = useState([]);
  const { values, errors, setFieldValue, handleSubmit } = formik;
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  function getCompanyDetails() {
    apiGetMethod(
      `${allApiUrl?.GET_COMPANY_BYID}/${loginUserInfo?.companyid}`
    )
      .then((data) => {
        const results = [];
        setCompany({
          value: values?.companyid,
          label: data?.data[0]?.name,
        });
        setOptionsRole([{ key: "Select a role", value: "" }, ...results]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form id="formIk" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item lg={6}>
            <TextField
              fullWidth
              type="text"
              name="name"
              label="Name"
              variant="outlined"
              className="inputHeight"
              value={values?.name}
              onChange={(e) => {
                setFieldValue("name", e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={errors && errors.name}
              error={Boolean(errors.name)}
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              fullWidth
              type="email"
              name="email"
              label="Email"
              variant="outlined"
              className="inputHeight"
              value={values?.email}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={6}>
            <MuiTelInput
              fullWidth
              forceCallingCode
              defaultCountry={"US"}
              size="small"
              placeholder="Phone Number"
              name="phone_number"
              className="phoneHeight"
              value={values?.phone_number}
              onChange={(value) => {
                setFieldValue("phone_number", value);
              }}
              helperText={errors && errors.phone_number}
              error={Boolean(errors.phone_number)}
            />
          </Grid>
          {Object.values(COMPANYROLES)
            ?.filter((e) => e !== ROLES.COMPANY)
            ?.includes(loginUserInfo?.roleid[0]) && (
            <Grid item lg={6}>
              <TextField
                fullWidth
                type="text"
                name="company Name"
                label="company Name"
                variant="outlined"
                value={values?.company_name}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={true}
              />
            </Grid>
          )}
          {Object.values(COMPANYROLES)
            ?.filter((e) => e == ROLES.COMPANY)
            ?.includes(loginUserInfo?.roleid[0]) && (
            <Grid item lg={6}>
              <TextField
                fullWidth
                type="industry"
                name="industry_name"
                label="industry_name"
                variant="outlined"
                className="inputHeight"
                value={values?.industry_name}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          )}
          <Grid item lg={12}>
            <LoadingButton
              className="blueButton"
              type="button"
              color="primary"
              variant="contained"
              sx={{ mr: "10px" }}
              onClick={handleSubmit}
            >
              Update
            </LoadingButton>
            <Button className="blueButton cancel" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
