import * as React from "react";
import { useSelector } from "react-redux";
import Loadable from "../../components/Loadable";
import { lazy } from "react";
import { ROLES, SUPERADMINROLES } from "../../core/constants";

const Dashboard = Loadable(lazy(() => import("src/pages/dashboard")));
const FormListingPage = Loadable(lazy(() => import("src/pages/FormListing")));

export default function LandingPage() {
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );

  return (
    <>
      {loginUserInfo?.id &&
      loginUserInfo?.roleid?.find((role) =>
        [Object?.values(SUPERADMINROLES)]?.includes(role)
      ) ? (
        <Dashboard />
      ) : (
        <Dashboard />
      )}
    </>
  );
}
