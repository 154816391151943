import {
  Avatar,
  AvatarGroup,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import { apiDeleteMethod, apiGetMethod, apiPutMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import ConfirmModal from "src/components/ConfirmModal";
import { AssignModal } from "../AssignModal";
import { isEmpty } from "lodash";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import { BootstrapTooltipUi } from "src/components/mui-common/BootstrapToolTip";
import { Text2Line } from "src/components/TextLine";
import Checkbox from "@mui/material/Checkbox";
import LoadingSwitch from "src/components/mui-common/switch-ui-new";
import {formatDateFn} from "src/shared/functions/string-conversion";
import {
  StyledTableCell,
  StyledTableRow,
} from "src/components/mui-common/table-ui/TableHeadRow";

export const ProjectTableRow = (
  {
    id,
    item,
    apiCall,
    getHeadCellsFn,
    index,
    selected,
    handleClick,
  }) => {
  const [formData, setFormData] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(null);
  const [crewsFiltered, setCrewsFiltered] = useState([]);
  const [open, setOpen] = useState(null);
  const [assignTraining, setAssignTraining] = useState(false);
  const [trainingData, setTraningData] = useState({});
  const [userId, setUserId] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const handleChange = (event, item, idx) => {
    setIsUpdatingStatus(idx);
    let obj = {
      project_name: item?.project_name,
      location: item?.location,
      supervisor: item?.supervisor,
      client: item?.client,
      project_description: item?.project_description,
      location_lon: item?.location_lon,
      location_lat: item?.location_lat,
      is_active: item.is_active !== true,
    };
    const url = `${allApiUrl.EDIT_PROJECT}/${item?.id}`;
    if (url) {
      apiPutMethod(url, obj)
        .then((data) => {
          toast.success("Status successfully updated.");
          apiCall();
        })
        .catch((err) => {
          toast.error(err?.data.message);
        })
        .finally(() => {
          setIsUpdatingStatus(null);
        });
    } else {
      setIsUpdatingStatus(null);
    }
  };
  const deleteData = () => {
    apiDeleteMethod(`${allApiUrl?.DELETE_PROJECT}/${projectId}`)
      .then((data) => {
        setIsModalOpen(false);
        toast.success(data?.message);
        setOpen(null);
        apiCall();
      })
      .catch((err) => {
        toast.error(err.data?.message);
      });
  };

  function apiCrewsCall() {
    apiGetMethod(`${allApiUrl?.PROJECT_CREWS_MEMBER}/${id}`)
      .then((res) => {
        if (!isEmpty(res.data)) {
          setCrewsFiltered(res?.data);
        } else {
          setCrewsFiltered([...[]]);
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message);
      });
  }
  useEffect(() => {
    if (openModal) {
      apiCrewsCall();
    }
  }, [openModal]);
  function apiTrainingCall() {
    setLoader(true);
    apiGetMethod(
      `${allApiUrl?.PROJECT_REQUIRED_TRAINING}/${id}/${formData?.value}`
    )
      .then((res) => {
        if (!isEmpty(res.data)) {
          setTraningData(res?.data);
          setLoader(false);
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        setTraningData({});
        setLoader(false);
      });
  }
  useEffect(() => {
    if (openModal === false) {
      setFormData("");
      setTraningData({});
      setUserId([]);
    }
  }, [openModal]);
  return (
    <StyledTableRow
      //hover
      tabIndex={-1}
      role="checkbox"
      key={`styledTableRow_${index}`}
    >
      {getHeadCellsFn()?.map((headCell) =>
        <StyledTableCell
          padding={
            headCell.id === "checkbox" ? "checkbox" : "normal"
          }
          className="nowrapFull"
          key={`tableCell_${headCell.id}`}
        >
          {headCell.id === "checkbox" ? (
            <Checkbox
              disableRipple
              checked={selected.indexOf(item.id) !== -1}
              onChange={(event) => handleClick(event, item.id)}
            />
          ) : headCell.id === "status" ? (
            <FormControlLabel
              disabled={isUpdatingStatus === index}
              control={
                <LoadingSwitch
                  loading={isUpdatingStatus === index}
                  color="success"
                  checked={Boolean(item?.is_active)}
                  onChange={(e) => handleChange(e, item, index)}
                />
              }
              label={item?.is_active ? "Active" : "In Active"}
            />
          ) : headCell.id === "actions" ? (
            <div style={{ whiteSpace: "nowrap" }}>
              <BootstrapTooltipUi title="Assign Form" placement="top">
                <IconButton
                  className="outerborder mb-2"
                  aria-label="assign_form"
                  onClick={() => {
                    setOpenModal(true);
                    setProjectId(item?.id);
                  }}
                >
                  <ModelTrainingIcon />
                </IconButton>
              </BootstrapTooltipUi>
              <BootstrapTooltipUi title="View" placement="top">
                <IconButton
                  className="outerborder mb-2"
                  aria-label="View"
                  onClick={() =>
                    navigate(`/projects/address-projects/${item?.id}`, {
                      state: { address: item },
                    })
                  }
                >
                  <VisibilityIcon />
                </IconButton>
              </BootstrapTooltipUi>
              <BootstrapTooltipUi title="Edit" placement="top">
                <IconButton
                  className="outerborder mb-2"
                  aria-label="Edit"
                  onClick={() =>
                    navigate(`/projects/edit-projects/${item?.id}`)
                  }
                >
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </BootstrapTooltipUi>
              <BootstrapTooltipUi title="Delete" placement="top">
                <IconButton
                  className="outerborder mb-2"
                  aria-label="Delete"
                  onClick={() => {
                    setIsModalOpen(true);
                    setProjectId(item?.id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </BootstrapTooltipUi>
            </div>
          ) : headCell?.id === "project_name" ? (
            <div className="text-wrap">
              <span className="nowrap14">{item?.project_name}</span>
              <br />
              <Text2Line>{item?.location}</Text2Line>
            </div>
          ) : headCell?.id === "crew_data" ? (
            <AvatarGroup max={4} className="justify-content-end">
              {item?.crew_data?.length > 0 &&
                item?.crew_data.map((row, index) => (
                  <BootstrapTooltipUi
                    key={index}
                    title={row?.crew_user_name}
                    placement="top"
                  >
                    <Avatar alt={row?.crew_user_name.substring(0, 2)} src="">
                      {row?.crew_user_name.substring(0, 2)}
                    </Avatar>
                  </BootstrapTooltipUi>
                ))}
            </AvatarGroup>
          ) : headCell?.id === "supervisor_name" ? (
            <BootstrapTooltipUi title={item?.supervisor_name} placement="top">
              <Avatar alt={item?.supervisor_name?.substring(0, 2)} src="">
                {item?.supervisor_name?.substring(0, 2)}
              </Avatar>
            </BootstrapTooltipUi>
          ) : (
            headCell?.isDate ? formatDateFn(item[headCell.id]) : item[headCell.id]
          )}
        </StyledTableCell>
      )}
      <ConfirmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        deleteData={deleteData}
        content="Are you sure you want to delete this item? "
        setOpen={setOpen}
      />
      {openModal && (
        <AssignModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          status={item?.is_active}
          projectId={projectId}
          crewsFiltered={crewsFiltered}
          setAssignTraining={setAssignTraining}
          assignTraining={assignTraining}
          setUserId={setUserId}
          userId={userId}
          projectName={item?.project_name}
          formData={formData}
          setFormData={setFormData}
          apiTrainingCall={apiTrainingCall}
          trainingData={trainingData}
          disabledTraining={false}
          loader={loader}
          setLoader={setLoader}
        />
      )}
    </StyledTableRow>
  );
};
