import {
  Box,
  TextField,
  Card,
  styled,
  Grid,
  Autocomplete,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Iconify from "src/components/iconify";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiGetMethod, apiPostMethod, apiPutMethod } from "src/api/rest";
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Loader from "src/components/Loader";
import { allApiUrl } from "src/api/apiRoute";
import SelectAsync from "src/components-form/shared/SelectAsync";
import { useSelector } from "react-redux";
import { COMPANYROLES, ROLES, SUPERADMINROLES } from "src/core/constants";
import { MuiTelInput } from "mui-tel-input";
import Button from "@mui/material/Button";
import PageHeader from "src/components-form/Common/PageHeader";
import { Helmet } from "react-helmet-async";
import { getAddUserList, getUserRoleList } from "src/shared/functions";
import {
  _isCompanyAdminRole,
  _isSuperAdminRole,
} from "src/shared/functions/string-conversion";

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
}));

export default function AddUser() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [company, setCompany] = useState();
  const [initialValues, setInitialValues] = useState({
    user_name: "",
    email: "",
    password: "",
    companyid: "",
    phone_number: "",
    roleid: "",
    confirm_password: "",
    is_active: true,
  });
  const validationSchema = Yup.object().shape({
    user_name: Yup.string().required("Name is required!"),
    companyid: Yup.string().when("roleid", {
      is: (roleid) => {
        return roleid === ROLES.COMPANY;
      },
      then: (schema) => schema.required("Company Name is required!"),
      otherwise: () => Yup.string(),
    }),
    phone_number: Yup.string().required("Phone Number is required!"),
    password: Yup.string()
      .min(6, "Password must be 6 character length")
      .required("Password is required!"),
    email: Yup.string()
      .email("Invalid Email address")
      .required("Email is required!"),
    roleid: Yup.number().required("Role is required"),
    confirm_password: Yup.string().oneOf(
      [Yup.ref("password")],
      "Passwords must match"
    ),
  });
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const isCompanyAdminRole = _isCompanyAdminRole(loginUserInfo);
  const isSuperAdminRole = _isSuperAdminRole(loginUserInfo);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      try {
        setLoading(true);
        let obj = {
          user_name: values.user_name,
          email: values.email,
          phone_number: values.phone_number.replace(/\s/g, ""),
          is_active: values?.is_active,
        };
        if (loginUserInfo?.roleid[0] == ROLES.COMPANY) {
          obj["role"] = values.roleid;
          obj["password"] = values.password;
          obj["company_id"] = loginUserInfo?.companyid;
        } else if (location?.pathname == "/user/add-user") {
          obj["role"] = values.roleid;
          obj["password"] = values.password;
          if (values.roleid != ROLES.SUPER_ADMIN) {
            obj["company_id"] = values.companyid;
          }
        } else {
          obj["roleid"] = values.roleid;
          if (values.roleid != ROLES.SUPER_ADMIN) {
            obj["company_id"] = values.companyid;
          }
        }
        setLoading(false);
        {
          location?.pathname === "/user/add-user"
            ? apiPostMethod(
                isSuperAdminRole
                  ? `${allApiUrl?.ADMIN_REGISTER}`
                  : `${allApiUrl?.COMPANY_REGISTER}`,
                obj
              )
                .then((data) => {
                  toast.success("User Added Successfully");
                  navigate("/user");
                  setLoading(false);
                })
                .catch((err) => {
                  toast.error(err?.data?.message);

                  setLoading(false);
                })
            : apiPutMethod(
                isSuperAdminRole
                  ? `${allApiUrl?.SUPER_ADMIN_EDIT_REGISTER}/${id}`
                  : `${allApiUrl?.COMPANY_EDIT_REGISTER}/${id}`,
                obj
              )
                .then((data) => {
                  toast.success("Edit Successfull");
                  setLoading(false);
                  navigate("/user");
                })
                .catch((err) => {
                  toast.error(err?.data?.message);

                  setLoading(false);
                });
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });
  function apiUserCall() {
    setLoader(true);
    apiGetMethod(
      isSuperAdminRole
        ? `${allApiUrl?.USER_PROFILE_DETAIL}/${id}`
        : `${allApiUrl?.COMPANY_USER_PROFILE_DETAILS}/${id}`
    )
      .then((data) => {
        if (data.data[0].companyid == null) {
          data.data[0].companyid = "";
        }
        setInitialValues(data.data[0]);
        setCompany({
          value: data.data[0]?.companyid,
          label: data.data[0]?.company_name,
        });
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  }
  useEffect(() => {
    if (location?.pathname !== "/user/add-user") apiUserCall();
  }, [location]);
  const handleCancel = () => {
    navigate("/user");
  };
  return (
    <>
      <Helmet>
        <title>Users | {process.env.REACT_APP_APPLICATION_NAME} </title>
      </Helmet>
      <div className="flexTop">
        <PageHeader
          header={
            location?.pathname !== "/user/add-user"
              ? "Edit New User"
              : "Add New User"
          }
          links={[
            {
              name: "Dashboard",
              path: "/",
            },
            {
              name: "User",
              path: "/user",
            },
          ]}
        />
      </div>
      <Card elevation={3} sx={{ pt: "20px", mb: 3 }} className="cardFix">
        <CardHeader className="cardHeadBorder">
          <Title className="cardHead">
            {location?.pathname == "/user/add-user" ? "Add" : "Edit"} New User
          </Title>
        </CardHeader>
        <Box p={4} height="100%">
          <FormikForm
            formik={formik}
            loader={loader}
            location={location}
            handleCancel={handleCancel}
            company={company}
            setCompany={setCompany}
            loginUserInfo={loginUserInfo}
            isSuperAdminRole={isSuperAdminRole}
            isCompanyAdminRole={isCompanyAdminRole}
          />
        </Box>
      </Card>
    </>
  );
}

function FormikForm({
  formik,
  location,
  loader,
  handleCancel,
  company,
  setCompany,
  loginUserInfo,
  isSuperAdminRole,
  isCompanyAdminRole,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const[showConfirmPassword,setShowConfirmPassword] =  useState(false)
  const { values, errors, setFieldValue, submitForm, handleSubmit, touched } =
    formik;
  const [options, setOptions] = useState([]);
  const [companyError, setCompanyError] = useState(true);
  useEffect(() => {
    apiCallRoles();
  }, []);
  async function apiCallRoles() {
    const results = [];
    let obj = getAddUserList(loginUserInfo?.roleid[0]);
    results.push(...obj);
    setOptions([{ key: "Select a role", value: "" }, ...results]);
  }
  const handleChange = (event, value) => {
    setCompany(event);
    setFieldValue("companyid", event.value);
    setCompanyError(true);
  };
  const getRoleData = (id) => {
    let key = options.find((e) => e.value == id)?.key;
    if (id == ROLES.FORM_USER) {
      return { key: "Company User", value: id };
    }
    if (id == ROLES.COMPANY) {
      return { key: "Company Admin", value: id };
    }
    if (key !== undefined) {
      return { key, value: id };
    }
    return { key: "", value: "" };
  };
  return (
    <FormikProvider value={formik}>
      {loader && location.pathname !== "/user/add-user" ? (
        <Loader />
      ) : (
        <Form id="formIk" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container rowSpacing={0} columnSpacing={2}>
            <Grid item md={6} sm={12}>
              <FormControl fullWidth>
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  name="roleid"
                  options={options}
                  value={getRoleData(values.roleid)}
                  getOptionLabel={(option) => option.key}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Role"
                      // variant="outlined"
                      value={getRoleData(values.roleid)}
                      name="roleid"
                      className="heightFix fieldmarginBottom"
                      helperText={
                        touched && touched.roleid && errors && errors.roleid
                      }
                      error={Boolean(errors.roleid && touched.roleid)}
                    />
                  )}
                  // open={true}
                  onChange={(e, value) => {
                    setFieldValue("roleid", value?.value);
                  }}
                />
              </FormControl>
            </Grid>
            {isSuperAdminRole &&
              Object.values(COMPANYROLES)?.includes(values?.roleid) && (
                <Grid item md={6} sm={12} sx={{ mb: 3 }}>
                  <FormControl fullWidth>
                    {values?.companyid && (
                      <InputLabel
                        style={{ backgroundColor: "#fff", padding: "0 5px" }}
                        shrink={true}
                      >
                        Company
                      </InputLabel>
                    )}
                    <SelectAsync
                      onChange={handleChange}
                      searchType={
                        Object.values(COMPANYROLES)?.includes(
                          loginUserInfo?.roleid[0]
                        )
                          ? "selectCompanyid"
                          : "companyid"
                      }
                      selectedValue={company}
                      placeholder="Select Company"
                      error={errors?.companyid && companyError == false}
                    />
                    {errors?.companyid && companyError === false && (
                      <div
                        style={{
                          color: "#e74c3c",
                          fontSize: "12px",
                          paddingLeft: "12px",
                          paddingTop: "4px",
                        }}
                      >
                        <span>{errors?.companyid}</span>
                      </div>
                    )}
                  </FormControl>
                </Grid>
              )}
            <Grid item md={6} sm={12}>
              <TextField
                fullWidth
                type="text"
                name="user_name"
                label="User Name"
                variant="outlined"
                value={values.user_name}
                onChange={(e) => {
                  setFieldValue("user_name", e.target.value);
                }}
                helperText={touched.user_name && errors && errors.user_name}
                error={Boolean(errors.user_name && touched.user_name)}
                sx={{ mb: 3 }}
                className="inputHeight"
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <TextField
                fullWidth
                type="email"
                name="email"
                label="Email"
                variant="outlined"
                value={values.email}
                onChange={(e) => {
                  setFieldValue("email", e.target.value);
                }}
                className="inputHeight"
                helperText={touched && touched.email && errors && errors.email}
                error={Boolean(errors.email && touched.email)}
                sx={{ mb: 3 }}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <MuiTelInput
                fullWidth
                forceCallingCode
                defaultCountry={"US"}
                size="small"
                placeholder="Phone Number"
                name="phone_number"
                value={values?.phone_number}
                className="phoneHeight"
                onChange={(value, info) => {
                  setFieldValue("phone_number", info?.numberValue);
                }}
                helperText={
                  touched &&
                  touched.phone_number &&
                  errors &&
                  errors.phone_number
                }
                error={Boolean(
                  touched && touched.phone_number && errors.phone_number
                )}
                sx={{ mb: 3 }}
              />
            </Grid>
            {location.pathname === "/user/add-user" && (
              <>
                <Grid item md={6} sm={12}>
                  <TextField
                    fullWidth
                    autoComplete={``}
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    defaultValue={values.password}
                    onBlur={(e) => {
                      setFieldValue("password", e.target.value);
                    }}
                    sx={{ mb: 3 }}
                    className="inputHeight"
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            <Iconify
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <TextField
                    fullWidth
                    autoComplete={``}
                    name="confirm_password"
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    defaultValue={values.confirm_password}
                    className="inputHeight"
                    sx={{ mb: 3 }}
                    onBlur={(e) => {
                      setFieldValue("confirm_password", e.target.value);
                    }}
                    error={
                      touched.confirm_password &&
                      Boolean(errors.confirm_password)
                    }
                    helperText={
                      touched.confirm_password && errors.confirm_password
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            edge="end"
                          >
                            <Iconify
                              icon={
                                showConfirmPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            )}
            {location.pathname !== "/user/add-user" && (
              <Grid item md={6} sm={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.is_active}
                    label="Status"
                    onChange={(e) => {
                      setFieldValue("is_active", e.target.value);
                    }}
                    error={Boolean(errors.is_active && touched.is_active)}
                    sx={{ mb: 3, height: "46px" }}
                  >
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item md={6} sm={12}>
              <LoadingButton
                className="blueButton"
                type="button"
                color="primary"
                // loading={isSubmitting}
                onClick={() => {
                  submitForm();
                  setCompanyError(false);
                }}
                variant="contained"
                sx={{ mr: "10px" }}
              >
                {location.pathname === "/user/add-user"
                  ? " Add User"
                  : "Update"}
              </LoadingButton>

              <Button className="blueButton cancel" onClick={handleCancel}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </FormikProvider>
  );
}
