import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  Table,
  TableBody,
  TableContainer,
  Button,
} from "@mui/material";
import { BootstrapDialog } from "src/components-form/Modals/ui/BootstrapDialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";
import { useState } from "react";
import { apiDeleteMethod, apiPostMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import SelectAsync from "src/components-form/shared/SelectAsync";
import { toast } from "react-toastify";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { BootstrapTooltipUi } from "src/components/mui-common/BootstrapToolTip";
import { useSelector } from "react-redux";
import TableHeadRow, {
  StyledTableCell,
  StyledTableNoData,
  StyledTableRow,
} from "src/components/mui-common/table-ui/TableHeadRow";

const AssignCrewForm = ({
  setAssignModal,
  id,
  apiCrewsCall,
  headLabel,
  crewsFiltered,
}) => {
  const [addCrew, setAddCrew] = useState([]);
  const [assignLoader, setAssignLoader] = useState(false);
  const closeModal = () => {
    setAssignModal(null);
  };
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const handleChangeCrew = (event) => {
    setAddCrew([...event]);
  };
  const handleAddCrew = () => {
    const matched = [];
    const nonMatched = [];

    const addCrewIds = addCrew.map((item) => item.value);

    crewsFiltered.forEach((crew) => {
      if (addCrewIds.includes(crew.crew_member_user_id)) {
        matched.push(crew);
      } else {
        nonMatched.push(crew);
      }
    });
    if (nonMatched?.length > 0) {
      nonMatched?.map((item) => {
        handleDeleteCrew(item?.id);
      });
    } else {
      setAssignLoader(true);
      let obj = {
        userIds: addCrew.map((e) => e.value).join(", "),
      };
      apiPostMethod(`${allApiUrl.ADD_CREW}/${id}`, obj)
        .then((data) => {
          toast.success(data.message);
          setAssignModal(false);
          apiCrewsCall();
          setAssignLoader(false);
        })
        .catch((err) => {
          setAssignLoader(false);
          toast.error(err.data.message);
        });
    }
  };
  const handleDeleteCrew = (deleteCrew) => {
    apiDeleteMethod(`${allApiUrl?.DELETE_CREW_MEMBER}/${id}/${deleteCrew}`)
      .then((data) => {
        toast.success(data?.message);
        apiCrewsCall();
      })
      .catch((err) => {
        toast.error(err.data?.message);
      });
  };
  React.useEffect(() => {
    let array = [];
    if (crewsFiltered?.length > 0) {
      crewsFiltered?.map((item) => {
        let obj = {
          value: item?.crew_member_user_id,
          label: `${item?.user_name} (${loginUserInfo?.user_name})`,
          company_id: loginUserInfo?.companyid,
          phone_number: item?.phone_number,
          email: item?.user_email,
          crew_member_user_id: item?.crew_member_user_id,
        };
        array.push(obj);
      });
      setAddCrew(array);
    }
  }, [crewsFiltered]);
  React.useEffect(()=>{
    apiCrewsCall()
  },[])

  return (
    <Box>
      <BootstrapDialog
        className="saveFormwidth"
        onClose={closeModal}
        width={`auto`}
        open={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Assign Crew
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel
                  style={{
                    backgroundColor: "#fff",
                    padding: "0 5px",
                  }}
                  shrink={true}
                >
                  Crew
                </InputLabel>
                <SelectAsync
                  searchType="selectCompanyid"
                  placeholder="Select Crew"
                  className="multiSelectHeight project"
                  onChange={handleChangeCrew}
                  selectedValue={addCrew}
                  isMulti={true}
                />
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <Card>
                <Box>
                  <TableContainer sx={{ overflow: "unset" }}>
                    <Table
                      sx={{
                        minWidth: 800,
                        borderCollapse: "separate",
                        borderSpacing: "0px 8px",
                      }}
                    >
                      <TableHeadRow headCells={headLabel} />

                      <TableBody>
                        {crewsFiltered?.length ? (
                          crewsFiltered?.map((item, index) => {
                            return (
                              <StyledTableRow key={`styledTableRowCrew___${index}`}>
                                <StyledTableCell>{item?.user_name}</StyledTableCell>
                                <StyledTableCell>{item?.phone_number}</StyledTableCell>
                                <StyledTableCell>{item?.user_email}</StyledTableCell>
                                <StyledTableCell>
                                  <BootstrapTooltipUi
                                    title="Delete"
                                    placement="top"
                                  >
                                    <IconButton
                                      className="outerborder"
                                      aria-label="Delete"
                                      onClick={() => {
                                        handleDeleteCrew(item?.id);
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </BootstrapTooltipUi>
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })
                        ) : (
                          <StyledTableNoData colSpan={headLabel.length} />
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={assignLoader}
            loadingPosition="end"
            endIcon={<SaveIcon />}
            variant="contained"
            className={`blueButton`}
            onClick={handleAddCrew}
            sx={{ p: "8px 25px", fontSize: "15px" }}
          >
            <span>Assign Crew</span>
          </LoadingButton>
          <Button
            className="blueButton cancel"
            sx={{ p: "8px 25px" }}
            onClick={() => setAssignModal(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
};
export default AssignCrewForm;
