import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import PageHeader from "src/components-form/Common/PageHeader";
import Scrollbar from "src/components/scrollbar";
import { useNavigate } from "react-router-dom";
import { apiGetMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import {_handleChangePage, PermissionCheck} from "src/utils/common";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { formatDateFn } from "src/shared/functions/string-conversion";
import { Helmet } from "react-helmet-async";
import { PAGINATION_OBJECT } from "src/core/constants";
import {
  _setPaginationObjectFn,
  createQueryString,
} from "src/shared/functions";
import { ToolbarUiNew } from "src/components/mui-common/toolbar-ui-new";
import AlertDialog from "src/components-form/shared/AlertDialog";
import SelectAsync from "src/components-form/shared/SelectAsync";
import { handleCheckboxClick } from "src/sections/Table";
import { ProjectTableRow } from "./ProjectTableRow";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import {headCellProjectAll} from "src/components/mui-common/table-ui/headCells";
import TableRowsLoader from "../../../components/mui-common/table-ui/TableRowsLoader";
import {isEmpty} from "../../../shared/functions/isEmpty";
import {StyledTableNoData} from "../../../components/mui-common/table-ui/TableHeadRow";
export const Projects = () => {
  const navigate = useNavigate();
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const [selected, setSelected] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [statusFilter, setStatusFilter] = useState("all");
  const [dataFiltered, setDataFiltered] = useState([]);
  const [loader, setLoader] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);
  const [addCrew, setAddCrew] = useState(null);
  const [addSupervisor, setAddSupervisor] = useState(null);
  const headCellsAll = headCellProjectAll;

  useEffect(() => {
    if (paginationObject.triggerApi) {
      apiCall();
    }
  }, [paginationObject.triggerApi]);
  useEffect(() => {
    try {
      _handleChangePage({}, 0, setPaginationObject,setSelected)
    } catch (e) {}
  }, [searchTerm, statusFilter, addCrew, addSupervisor])

  function apiCall() {
    setLoader(true);
    let queryObject = {
      search: searchTerm || "",
      pageNumber: paginationObject.page,
      limit: paginationObject.rowsPerPage,
      is_active: statusFilter === "all" ? null : statusFilter,
      crew_id: addCrew?.map((item) => item.value).join(",") || null,
      supervisor_id: addSupervisor?.value || null,
    };

    const qryStr = createQueryString(queryObject);

    const url = allApiUrl?.GET_PROJECT_LIST;
    apiGetMethod(`${url}${qryStr}`)
      .then((data) => {
        setDataFiltered(data.data);
        setPaginationObject((prevState) => {
          return _setPaginationObjectFn(prevState, data.meta);
        });
      })
      .catch((err) => {
        setDataFiltered([...[]]);
        setPaginationObject(PAGINATION_OBJECT);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  const getHeadCellsFn = () => {
    return headCellsAll;
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = dataFiltered?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, id) => {
    setSelected(handleCheckboxClick(event, id, selected));
  };
  const handleChangeCrew = (event) => {
    setAddCrew(event);
    setSelected([])
  };
  const handleChangeSupervisor = (event) => {
    setAddSupervisor(event);
    setSelected([])
  };
  return (
    <>
      <Helmet>
        <title>Projects | {process.env.REACT_APP_APPLICATION_NAME} </title>
      </Helmet>
      <Box>
        <div className="flexTop">
          <PageHeader
            header={"Project"}
            links={[
              {
                name: "Dashboard",
                path: "/",
              },
            ]}
          />
          <div>
            {PermissionCheck("Projects", "create", loginUserInfo) && (
              <Button
                variant="contained"
                color="inherit"
                startIcon={<AddIcon />}
                onClick={() => navigate("/projects/add-projects")}
                className="blueButton"
              >
                Add Project
              </Button>
            )}
          </div>
        </div>
        <AlertDialog
          title={"Select Project"}
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          handleDelete={() => {
            setOpenDeleteModal(false);
          }}
        />
        <Card className="cardFix">
          <div className="allocation">
            <ToolbarUiNew
              numSelected={selected.length}
              title={`Projects`}
              onSearch={(query) => setSearchTerm(query)}
              showDeleteButton={true}
              onDeleteClick={() => setOpenDeleteModal(true)}
            />
          </div>
          <div style={{ padding: " 20px 15px" }}>
            <h6 className="mb-3">Filters:</h6>
            <Grid container columnSpacing={2}>
              <Grid item lg={3}>
                <FormControl sx={{ width: "100%", marginBottom: "5px" }}>
                  <InputLabel id="select-status">Status</InputLabel>
                  <Select
                    labelId="select-status"
                    id="selectStatus"
                    label="Status"
                    value={statusFilter}
                    onChange={(event) => {setStatusFilter(event.target.value);setSelected([])}}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>In Active</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={3}>
                <SelectAsync
                  isClearable
                  onChange={handleChangeCrew}
                  searchType="selectCompanyUser"
                  selectedValue={addCrew}
                  placeholder="Select Crew"
                  isMulti={true}
                  className="multiSelectHeight project height"
                  dataParam={{ isFilter: true }}
                />
              </Grid>
              <Grid item lg={3}>
                <SelectAsync
                  isClearable
                  onChange={handleChangeSupervisor}
                  searchType="selectCompanyUser"
                  selectedValue={addSupervisor}
                  placeholder="Select Supervisor"
                  className="asyncHeight training"
                  dataParam={{ isFilter: true }}
                />
              </Grid>
            </Grid>
          </div>
          <Scrollbar>
            <TableContainer className="tableResponsive">
              <Table sx={{ minWidth: 800 }}>
                <TableHead className="tableHead">
                  <TableRow>
                    {getHeadCellsFn().map((headCell) =>
                      headCell.id === "checkbox" ? (
                        <TableCell padding="checkbox" key={headCell.id}>
                          <Checkbox
                            indeterminate={
                              selected.length > 0 &&
                              selected.length < dataFiltered?.length
                            }
                            checked={
                              dataFiltered?.length > 0 &&
                              selected.length === dataFiltered?.length
                            }
                            onChange={handleSelectAllClick}
                            inputProps={{ "aria-label": "select all forms" }}
                          />
                        </TableCell>
                      ) : (
                        <TableCell key={headCell.id} className="nowrap">
                          {headCell.label}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loader ? (
                    <TableRowsLoader
                      rowsNum={10}
                      columnNum={getHeadCellsFn().length}
                    />
                  ) : isEmpty(dataFiltered) ? (
                    <StyledTableNoData colSpan={getHeadCellsFn().length} />
                  ) : (
                    dataFiltered?.map((item, index) => {
                      return (
                        <ProjectTableRow
                          getHeadCellsFn={getHeadCellsFn}
                          key={`${index}_p`}
                          id={item?.id}
                          item={item}
                          apiCall={apiCall}
                          created_at={formatDateFn(item?.created_at)}
                          updated_at={formatDateFn(item?.updated_at)}
                          index={index}
                          selected={selected}
                          handleClick={handleClick}
                        />
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePaginationCompo
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            setSelected={setSelected}
          />
          {/* </div> */}
        </Card>
      </Box>
    </>
  );
};
