import {
  Box,
  Card,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Scrollbar from "src/components/scrollbar";
import { apiDeleteMethod, apiGetMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import {
  _isSuperAdminRole,
  formatDateFn,
} from "src/shared/functions/string-conversion";
import PageHeader from "src/components-form/Common/PageHeader";
import { Helmet } from "react-helmet-async";
import { PAGINATION_OBJECT, TRAINING_STATUS } from "src/core/constants";
import {
  _handleRedirect,
  _setPaginationObjectFn,
  createQueryString,
} from "src/shared/functions";
import TableHeadRow, {
  StyledTableCell,
  StyledTableNoData,
  StyledTableRow,
} from "src/components/mui-common/table-ui/TableHeadRow";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import TableToolbar from "src/components/mui-common/table-ui/TableToolbar";
import TableRowsLoader from "src/components/mui-common/table-ui/TableRowsLoader";
import { headCellsAllocatedProjectForm } from "src/components/mui-common/table-ui/headCells";
import { isEmpty } from "src/shared/functions/isEmpty";
import { BootstrapTooltipUi } from "src/components/mui-common/BootstrapToolTip";
import StartIcon from "@mui/icons-material/Start";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ShareIcon from "@mui/icons-material/Share";
import SelectAsync from "src/components-form/shared/SelectAsync";
import SavedEntriesDrawer from "src/components-form/Modals/SavedEntriesDrawer";
import { AssignModal } from "../projects/AssignModal";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "src/components/ConfirmModal";
import { useSelector } from "react-redux";
import { _handleChangePage } from "../../utils/common";
export const AllocatedProjectForm = () => {
  const [selected, setSelected] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);
  const [searchTerm, setSearchTerm] = useState("");
  const [loader, setLoader] = useState(true);
  const headCells = headCellsAllocatedProjectForm;
  const [statusFilter, setStatusFilter] = useState("all");
  const [isLoadingCopy, setIsLoadingCopy] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [openDrawer, setOpenDrawer] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState("");
  const [selectDueDate, setSelectDueDate] = useState("");
  const [loaderTraining, setLoaderTraining] = useState(false);
  const [trainingData, setTrainingData] = useState();
  const [formIsModalOpen, setFormIsModelOpen] = useState(false);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(null);
  const [deleteId, setDeleteId] = useState({});
  const [trainingName, setTrainingName] = useState("");
  useEffect(() => {
    if (paginationObject.triggerApi) {
      apiCall();
    }
  }, [paginationObject.triggerApi]);
  useEffect(() => {
    try {
      _handleChangePage({}, 0, setPaginationObject, setSelected);
    } catch (e) {}
  }, [searchTerm, statusFilter, projectName]);

  function apiCall(value) {
    setLoader(true);
    let queryObject = {
      search: searchTerm || "",
      pageNumber: paginationObject.page,
      limit: paginationObject.rowsPerPage,
      project_form_status: statusFilter === "all" ? null : statusFilter,
      projectId: projectName?.value || null,
    };

    const qryStr = createQueryString(queryObject);

    apiGetMethod(`${allApiUrl?.ALLOCATED_PROJECT_FORM}${qryStr}`)
      .then((data) => {
        setDataFiltered(data.data);
        setPaginationObject((prevState) => {
          return _setPaginationObjectFn(prevState, data.meta);
        });
      })
      .catch((err) => {
        setDataFiltered([...[]]);
        setPaginationObject(PAGINATION_OBJECT);
      })
      .finally(() => {
        setLoader(false);
      });
  }
  const handleCopyLink = async (
    event,
    idx,
    allocated_project_form_id,
    shareable_auth_token,
    project_status
  ) => {
    setIsLoadingCopy(idx);
    let linkToCopy = `${process.env.REACT_APP_STRIPE_SUCCESS_URL}/allocated-project-form/form-detail/${allocated_project_form_id}?shareable_auth_token=${shareable_auth_token}&copyLink=${project_status}`;
    try {
      await navigator.clipboard.writeText(linkToCopy);

      setTimeout(() => {
        setIsLoadingCopy(null);
      }, 2000); // Reset copied status after 2 seconds
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  const handleChangeProject = (event) => {
    setProjectName(event);
  };
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const isSuperAdminRole = _isSuperAdminRole(loginUserInfo);

  function apiTrainingCall() {
    setLoaderTraining(true);
    let url = isSuperAdminRole
      ? `${allApiUrl?.PROJECT_REQUIRED_TRAINING}/${data?.created_by}/${formData?.value}`
      : `${allApiUrl?.COMPANY_PROJECT_REQUIRED_TRAINING}/${data?.id}/${formData?.value}`;
    apiGetMethod(`${url}`)
      .then((res) => {
        if (!isEmpty(res.data)) {
          setTrainingData(res?.data);
          setLoaderTraining(false);
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        setTrainingData({});
        setLoaderTraining(false);
      });
  }
  const handleDeleteForm = () => {
    apiDeleteMethod(
      `${allApiUrl?.COMPANY_USER_DELETE_PROJECT_ALLOCATION}/${deleteId.project_form_id}/${deleteId?.allocated_project_form_id}`
    )
      .then((data) => {
        setFormIsModelOpen(false);
        toast.success(data?.message);
        setOpen(null);
        apiCall();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err.data?.message);
      });
  };
  return (
    <>
      <Helmet>
        <title>
          Allocated Project Form | {process.env.REACT_APP_APPLICATION_NAME}
        </title>
      </Helmet>
      <Box>
        <SavedEntriesDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
        <PageHeader
          header={"Allocated Project Form"}
          links={[
            {
              name: "Dashboard",
              path: "/",
            },
          ]}
        />
        <Card className="cardFix">
          <TableToolbar
            title={`Allocated Project Form`}
            numSelected={selected.length}
            onSearch={(query) => setSearchTerm(query)}
            selected={selected}
            setSelected={setSelected}
            apiCall={apiCall}
          />

          <div style={{ padding: " 20px 15px" }}>
            <h6 className="mb-3">Filters:</h6>
            <Grid container columnSpacing={2}>
              <Grid item lg={3}>
                <FormControl sx={{ width: "100%", marginBottom: "5px" }}>
                  <InputLabel id="select-status">Status</InputLabel>
                  <Select
                    labelId="select-status"
                    id="selectStatus"
                    label="Status"
                    value={statusFilter}
                    onChange={(event) => setStatusFilter(event.target.value)}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"COMPLETED"}>Completed</MenuItem>
                    <MenuItem value={"PENDING"}>Pending</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={3}>
                <SelectAsync
                  isClearable
                  onChange={handleChangeProject}
                  searchType="ProjectUserList"
                  selectedValue={projectName}
                  placeholder="Select Project"
                  className="asyncHeight training"
                  dataParam={{isFilter: true}}
                />
              </Grid>
            </Grid>
          </div>
          <Scrollbar>
            <TableContainer>
              <Table>
                <TableHeadRow
                  numSelected={selected.length}
                  rowCount={dataFiltered.length}
                  headCells={headCells}
                  permissionType="Forms"
                  listData={dataFiltered}
                  setSelected={setSelected}
                />

                <TableBody>
                  {loader ? (
                    <TableRowsLoader
                      rowsNum={10}
                      columnNum={headCells.length}
                    />
                  ) : isEmpty(dataFiltered) ? (
                    <StyledTableNoData colSpan={headCells.length} />
                  ) : (
                    dataFiltered?.map((item, index) => {
                      return (
                        <StyledTableRow
                          role="checkbox"
                          tabIndex={-1}
                          key={`styledTableRow_${index}`}
                        >
                          {headCells.map((hCell) => (
                            <StyledTableCell
                              padding={
                                hCell.id === "checkbox" ? "checkbox" : "normal"
                              }
                              className="nowrapFull"
                              key={`tableCell_${hCell.id}`}
                            >
                              {hCell.id === "status" ? (
                                <>
                                  {item?.project_form_status ===
                                  "INPROGRESS" ? (
                                    <Chip label="INPROGRESS" color="primary" />
                                  ) : item?.project_form_status ===
                                    "PENDING" ? (
                                    <Chip label="PENDING" color="error" />
                                  ) : (
                                    <Chip label="COMPLETED" color="success" />
                                  )}
                                </>
                              ) : hCell.id === "actions" ? (
                                <>
                                  {item?.project_form_status ===
                                  TRAINING_STATUS.PENDING ? (
                                    <BootstrapTooltipUi
                                      title={"Submit Form"}
                                      placement="top"
                                    >
                                      <IconButton
                                        className="outerborder "
                                        aria-label="Start Training"
                                        onClick={() => {
                                          _handleRedirect(
                                            `/allocated-project-form/form-detail/${item?.allocated_project_form_id}`
                                          );
                                        }}
                                      >
                                        <StartIcon />
                                      </IconButton>
                                    </BootstrapTooltipUi>
                                  ) : (
                                    <BootstrapTooltipUi
                                      title="View"
                                      placement="top"
                                    >
                                      <IconButton
                                        className="outerborder"
                                        aria-label="Delete"
                                        onClick={() =>
                                          window.open(
                                            `/allocated-project-form/view-detail/${item?.allocated_project_form_id}?project_form_submitted_status="COMPLETED"`
                                          )
                                        }
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    </BootstrapTooltipUi>
                                  )}
                                  {item?.project_form_status ==
                                    TRAINING_STATUS.PENDING && (
                                    <BootstrapTooltipUi
                                      title="Edit"
                                      placement="top"
                                    >
                                      <IconButton
                                        className="outerborder"
                                        aria-label="Edit"
                                        onClick={() => {
                                          setOpenModal(true);
                                          setFormData({
                                            value: item?.form_id,
                                            label: item?.form_name,
                                          });
                                          setSelectDueDate(
                                            item?.due_date || item?.created_at
                                          );
                                          setTrainingName(item?.project_name);
                                          setData(item);
                                        }}
                                      >
                                        <DriveFileRenameOutlineIcon />
                                      </IconButton>
                                    </BootstrapTooltipUi>
                                  )}
                                  <BootstrapTooltipUi
                                    title={
                                      isLoadingCopy === index
                                        ? "Copied"
                                        : "Copy link"
                                    }
                                    placement="top"
                                  >
                                    <IconButton
                                      className="outerborder "
                                      aria-label="Shareable Link"
                                      disabled={isLoadingCopy === index}
                                      onClick={(e) =>
                                        handleCopyLink(
                                          e,
                                          index,
                                          item?.allocated_project_form_id,
                                          item?.shareable_auth_token,
                                          item?.project_form_status
                                        )
                                      }
                                    >
                                      {isLoadingCopy === index ? (
                                        <CheckCircleOutlineIcon />
                                      ) : (
                                        <ShareIcon />
                                      )}
                                    </IconButton>
                                  </BootstrapTooltipUi>
                                  {item?.project_form_status !==
                                    TRAINING_STATUS.COMPLETED && (
                                    <BootstrapTooltipUi
                                      title="Delete"
                                      placement="top"
                                    >
                                      <IconButton
                                        className="outerborder"
                                        aria-label="Delete"
                                        onClick={() => {
                                          setFormIsModelOpen(true);
                                          setDeleteId(item);
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </BootstrapTooltipUi>
                                  )}
                                </>
                              ) : hCell?.isDate ? (
                                formatDateFn(item[hCell.id])
                              ) : (
                                item[hCell.id]
                              )}
                            </StyledTableCell>
                          ))}
                        </StyledTableRow>
                      );
                    })
                  )}
                  <ConfirmModal
                    isModalOpen={formIsModalOpen}
                    setIsModalOpen={setFormIsModelOpen}
                    deleteData={handleDeleteForm}
                    content="Are you sure you want to delete this item? "
                    setOpen={setOpen}
                  />
                </TableBody>
                {openModal && (
                  <AssignModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    loader={loaderTraining}
                    setLoader={setLoaderTraining}
                    formData={formData}
                    setFormData={setFormData}
                    apiTrainingCall={apiTrainingCall}
                    selectDueDate={selectDueDate}
                    projectName={trainingName}
                  />
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePaginationCompo
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            setSelected={setSelected}
          />
        </Card>
      </Box>
    </>
  );
};
