import { Box, TextField, Card, styled, Grid } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiPostMethod } from "src/api/rest";
import * as React from "react";
import { allApiUrl } from "src/api/apiRoute";
import { useDispatch} from "react-redux";
import Button from "@mui/material/Button";
const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
}));

export default function ChangePassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  });
  const validationSchema = Yup.object().shape({
    oldpassword: Yup.string().required("Old Password is required!"),
    newpassword: Yup.string()
      .min(6, "Password must be 6 character length")
      .required("Password is required!"),
    confirmpassword: Yup.string()
      .required("Confirm Password is required!")
      .oneOf([Yup.ref("newpassword")], "Passwords must match"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const data = await apiPostMethod(allApiUrl.CHANGE_PASSWORD, values);
        toast.success(data?.message);
        navigate("/");
      } catch (error) {
        if (error && error.data && error.data.message) {
          toast.error(error.data.message);
        } else {
          toast.error("An error occurred while changing password.");
        }
      }
    },
  });
  const handleCancel = () => {
    navigate("/");
  };
  return (
    <>
      <Card elevation={3} sx={{ pt: "20px", mb: 3 }} className="cardFix">
        <CardHeader>
          <Title className="cardHead">Change Password</Title>
        </CardHeader>
        <Grid container>
          <Grid>
            <Box p={4} height="100%">
              <FormikForm formik={formik} handleCancel={handleCancel} />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

function FormikForm({ formik, handleCancel }) {
  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form id="formIk" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item lg={6}>
            <TextField
              fullWidth
              type="text"
              name="oldpassword"
              label="Old Password"
              variant="outlined"
              value={values?.oldpassword}
              onChange={(e) => {
                setFieldValue("oldpassword", e.target.value);
              }}
              helperText={errors && errors.oldpassword}
              error={Boolean(errors.oldpassword)}
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              fullWidth
              type="text"
              name="newpassword"
              label="New Password"
              variant="outlined"
              value={values?.newpassword}
              onChange={(e) => {
                setFieldValue("newpassword", e.target.value);
              }}
              helperText={errors && errors.newpassword}
              error={Boolean(errors.newpassword)}
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              fullWidth
              type="number"
              name="confirmpassword"
              label="Confirm Password"
              variant="outlined"
              value={values?.confirmpassword}
              onChange={(e) => {
                setFieldValue("confirmpassword", e.target.value);
              }}
              helperText={errors && errors.confirmpassword}
              error={Boolean(errors.confirmpassword)}
            />
          </Grid>

          <Grid item lg={12}>
            <LoadingButton
              className="blueButton"
              type="button"
              color="primary"
              variant="contained"
              sx={{ mr: "10px" }}
              onClick={handleSubmit}
            >
              Update
            </LoadingButton>
            <Button className="blueButton cancel" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
