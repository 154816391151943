import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const STRIPE_PRICING_TABLE_ID = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const STRIPE_SUCCESS_URL = process.env.REACT_APP_STRIPE_SUCCESS_URL;
const StripePricingTable = () => {
  const { state } = useLocation();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <stripe-pricing-table
      pricing-table-id={STRIPE_PRICING_TABLE_ID}
      publishable-key={STRIPE_PUBLISHABLE_KEY}
      customer-email={state?.email}
    />
  );
};

export default StripePricingTable;
