import {useEffect, useState} from "react";
import {replaceLabelStrings} from "src/shared/functions/string-conversion";
import LabelCompo from "./LabelCompo";
import {DesktopTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import * as React from "react";
import {isEmpty} from "src/shared/functions/isEmpty";
import {FormControl, FormHelperText} from "@mui/material";

export const TimePickerCompo = ({ isReadOnly, data, onChangeFields, form, isTrainingForm, isSubmitClicked = false, isEntryPage = false, isDraftSaved = false }) => {
  const [labelConv, setLabelConv] = useState(
    isReadOnly ? data.label : replaceLabelStrings(data?.label, form)
  );

  useEffect(() => {
    setLabelConv(isReadOnly ? data.label : replaceLabelStrings(data?.label, form));
  }, [data.label, form]);

  const [inputTime, setInputTime] = useState(isEntryPage ? data.userInputVal : data.value);
  useEffect(() => {
    if (!isReadOnly) {
      onChangeFields(inputTime);
    }
  }, [inputTime]);

  return (
    <div key={data.id} id={data.id}>
      <FormControl sx={{width: "100%"}} error={Boolean(!isReadOnly && data.required && isSubmitClicked && isEmpty(inputTime))}>
        <LabelCompo data={data} labelConv={labelConv} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopTimePicker
            disabled={!isDraftSaved && Boolean(isReadOnly || !!(isTrainingForm && inputTime) || (isEntryPage && inputTime))}
            okText={``}
            id={`field_id_${data.id}`}
            name={`field_name_${data.id}`}
            slotProps={{ textField: { size: 'small', fullWidth: true, required: data.required  } }}
            defaultValue={inputTime ? dayjs(inputTime) : null}
            format={`hh:mm A`}
            onChange={(e) => {
              if (!isReadOnly) {
                setInputTime(e.format());
              }
            }}
          />
        </LocalizationProvider>
        <FormHelperText>{Boolean(!isReadOnly && data.required && isSubmitClicked && !inputTime) && "This Field is required!"}</FormHelperText>
      </FormControl>
    </div>
  );
};