import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Scrollbar from "src/components/scrollbar";
import {
  PermissionCheck,
  handleCSVExport,
  handleImportCsv,
  _handleChangePage,
} from "src/utils/common";
import { useNavigate } from "react-router-dom";
import { apiGetMethod, apiPostMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import { handleCheckboxClick } from "src/components-form/Tables/functions";
import CertificateTableRow from "../certificate-table-row";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { PAGINATION_OBJECT, SUPERADMINROLES } from "src/core/constants";
import Box from "@mui/material/Box";
import PageHeader from "src/components-form/Common/PageHeader";
import {
  _isCompanyAdminRole,
  _isSuperAdminRole,
  formatDateFn,
} from "src/shared/functions/string-conversion";
import { Helmet } from "react-helmet-async";
import SelectAsync from "src/components-form/shared/SelectAsync";
import GetAppIcon from "@mui/icons-material/GetApp";
import { LoadingButton } from "@mui/lab";
import { headCellTrainingHistory } from "src/components/mui-common/table-ui/headCells";
import {
  _setPaginationObjectFn,
  createQueryString,
} from "src/shared/functions";
import TableHeadRow, {
  StyledTableNoData,
} from "src/components/mui-common/table-ui/TableHeadRow";
import TableToolbar from "src/components/mui-common/table-ui/TableToolbar";
import TableRowsLoader from "src/components/mui-common/table-ui/TableRowsLoader";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import { isEmpty } from "src/shared/functions/isEmpty";
import UploadIcon from "@mui/icons-material/Upload";
import { toast } from "react-toastify";
import { removeItemByKeyValue } from "src/shared/functions/array-conversion";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 475,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export const TrainingCertificatePage = () => {
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const isCompanyRole = _isCompanyAdminRole(loginUserInfo);
  const isSuperAdminRole = _isSuperAdminRole(loginUserInfo);

  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [loader, setLoader] = useState(true);
  const [status, setStatus] = useState("all");
  const [companyName, setCompanyName] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [open, setOpen] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);
  const [searchTerm, setSearchTerm] = useState("");
  const headCells = isCompanyRole
    ? removeItemByKeyValue(headCellTrainingHistory, ["company_name"])
    : headCellTrainingHistory;
  const [importCompanyName, setImportCompanyName] = useState(
    loginUserInfo?.companyid !== "" ? loginUserInfo?.companyid : null
  );
  const [csvFile, setCsvFile] = useState(null);

  useEffect(() => {
    if (paginationObject.triggerApi) {
      apiCall();
    }
  }, [paginationObject.triggerApi]);
  useEffect(() => {
    try {
      _handleChangePage({}, 0, setPaginationObject,setSelected);
    } catch (e) {}
  }, [searchTerm, status, companyName]);

  function apiCall(value) {
    setLoader(true);
    let queryObject = {
      search: searchTerm || "",
      pageNumber: paginationObject.page,
      limit: paginationObject.rowsPerPage,
      companyId: loginUserInfo?.companyid || companyName?.value,
      certStatus: status === "all" ? null : status,
    };
    const qryStr = createQueryString(queryObject);

    apiGetMethod(
      `${
        isCompanyRole
          ? allApiUrl?.COMPANY_TRAINING_CERTIFICATE
          : allApiUrl?.TRANING_CERTIFICATE_LIST
      }${qryStr}`
    )
      .then((data) => {
        setDataFiltered(data.data);
        setPaginationObject((prevState) => {
          return _setPaginationObjectFn(prevState, data.meta);
        });
      })
      .catch((err) => {
        setDataFiltered([...[]]);
        setPaginationObject(PAGINATION_OBJECT);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  const handleClick = (event, id) => {
    setSelected(handleCheckboxClick(event, id, selected));
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
    setSelected([]);
  };
  const handleCompanyChange = (event, value) => {
    setCompanyName(event);
    setSelected([]);
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setCsvFile(file);
  };
  const handleSampleDataFormat = () => {
    const data = [
      {
        trainings_id: "ab85fb3fe66e4a969acc3947ede8275e",
        note: "abcdefghijklmnopq",
        status: true,
        attchement: "file",
        user_id: "d22e1ac17f2d4c1e84b3fcba4bc42f74",
        obtained_marks: "51",
        training_done_date: "August 7, 2024 9:19 AM",
        training_name: "freghtrjh",
        user_name: "Ram Singh",
      },
      {
        trainings_id: "e62bbe49a5424829a9ee8271fa39f19a",
        note: "abcdefghijklmnopq",
        status: true,
        attchement: "file",
        user_id: "d22e1ac17f2d4c1e84b3fcba4bc42f74",
        obtained_marks: "51",
        training_done_date: "August 7, 2024 9:19 AM",
        training_name: "training",
        user_name: "Ram Singh",
      },
    ];
    const opts = {
      fields: [
        {
          label: "trainings_id",
          value: "trainings_id",
          default: "N/A",
        },
        {
          label: "note",
          value: "note",
          default: "N/A",
        },
        {
          label: "status",
          value: "status",
          default: "N/A",
        },
        {
          label: "attchement",
          value: "attchement",
          default: "N/A",
        },
        {
          label: "user_id",
          value: "user_id",
          default: "N/A",
        },
        {
          label: "obtained_marks",
          value: "obtained_marks",
          default: "N/A",
        },
        {
          label: "training_done_date",
          value: "training_done_date",
          default: "N/A",
        },
        {
          label: "training_name",
          value: "training_name",
          default: "N/A",
        },
        {
          label: "user_name",
          value: "user_name",
          default: "N/A",
        },
      ],
    };
    handleCSVExport(data, opts, "sample");
  };
  const handleSubmitImport = async () => {
    if (!importCompanyName) {
      toast.error("Please Select Company");
    } else if (!csvFile) {
      toast.error("Please Select file");
    } else {
      setUploading(true);
      const formData = new FormData();
      formData.append("file", csvFile);
      formData.append(
        "company_id",
        Object.values(SUPERADMINROLES)?.includes(loginUserInfo?.roleid[0])
          ? importCompanyName?.value
          : importCompanyName
      );
      let url = allApiUrl?.TRAINING_IMPORT_HISTORY;

      apiPostMethod(url, formData)
        .then((data) => {
          if (!isEmpty(data.data)) {
            toast.success(data?.message)
          } else {
            toast.success(data?.message);
          }
          setUserModal(false);
        })
        .catch((err) => {
          toast.error(err?.data?.message[0]);
          setUserModal(false);
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };
  const handleImportCompanyChange = (event, value) => {
    setImportCompanyName(event);
  };
  return (
    <>
      <Helmet>
        <title>
          Training History | {process.env.REACT_APP_APPLICATION_NAME}{" "}
        </title>
      </Helmet>
      <Box>
        <div className="flexTop">
          <PageHeader
            header={"Training History"}
            links={[
              {
                name: "Dashboard",
                path: "/",
              },
            ]}
          />
          <div>
            {PermissionCheck(
              "TrainingsAndCertificates",
              "create",
              loginUserInfo
            ) && (
              <Button
                variant="contained"
                color="inherit"
                startIcon={<AddIcon />}
                onClick={() =>
                  navigate("/training-certificate/add-training-certificate")
                }
                className="blueButton"
              >
                Allocate Certificate
              </Button>
            )}
            {PermissionCheck(
              "TrainingsAndCertificates",
              "create",
              loginUserInfo
            ) && (
              <LoadingButton
                variant="contained"
                color="inherit"
                loading={exportLoader}
                startIcon={<GetAppIcon />}
                onClick={() => setUserModal(true)}
                className="blueButton ms-2"
              >
                Import Training
              </LoadingButton>
            )}
          </div>
        </div>
        <Card className="cardFix">
          <TableToolbar
            title={`Training History`}
            numSelected={selected.length}
            onSearch={(query) => setSearchTerm(query)}
            deleteUrl={allApiUrl.DELETE_MULTIPLE_CERTIFICATE}
            selected={selected}
            setSelected={setSelected}
            apiCall={apiCall}
          />

          <div style={{ padding: "20px 15px 20px 15px" }}>
            <h6 style={{ marginBottom: "15px" }}>Filters:</h6>
            <Grid container columnSpacing={2}>
              <Grid item lg={3}>
                <FormControl sx={{ width: "100%", marginBottom: "5px" }}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    value={status}
                    onChange={handleChangeStatus}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"valid"}>Valid</MenuItem>
                    <MenuItem value={"expired"}>Expired</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {isSuperAdminRole && (
                <Grid item lg={3}>
                  <SelectAsync
                    isClearable
                    onChange={handleCompanyChange}
                    searchType="companyid"
                    selectedValue={companyName}
                    placeholder="Select Company"
                    className="asyncHeight training"
                  />
                </Grid>
              )}
            </Grid>
          </div>
          <Scrollbar>
            <TableContainer sx={{ overflow: "unset" }}>
              <Table>
                <TableHeadRow
                  numSelected={selected.length}
                  rowCount={dataFiltered.length}
                  headCells={headCells}
                  permissionType="TrainingsAndCertificates"
                  listData={dataFiltered}
                  setSelected={setSelected}
                />

                <TableBody>
                  {loader ? (
                    <TableRowsLoader
                      rowsNum={10}
                      columnNum={headCells.length}
                    />
                  ) : isEmpty(dataFiltered) ? (
                    <StyledTableNoData colSpan={headCells.length} />
                  ) : (
                    dataFiltered?.map((row, index) => {
                      return (
                        <CertificateTableRow
                          key={index}
                          dateOfTraining={formatDateFn(row?.training_done_date)}
                          title={row?.certificate_name}
                          userName={row?.user_name}
                          TrainingName={row?.training_name}
                          FormName={row?.form_name}
                          Expiry_date={formatDateFn(row?.created_at)}
                          status={row?.status}
                          total_marks={`${row?.obtained_marks || 0}/${
                            row?.total_marks
                          }`}
                          id={row.id}
                          note={row?.note}
                          dataFiltered={dataFiltered}
                          selected={selected.indexOf(row.id) !== -1}
                          handleClick={(event) => handleClick(event, row.id)}
                          attachment_id={row?.attachment_id}
                          open={open}
                          setOpen={setOpen}
                          apiCall={apiCall}
                          form_id={row?.form_id}
                          validity_period={row?.validity_period}
                          success_entry_form_id={row?.success_entry_form_id}
                          company_name= {row?.company_name}
                          isSuperAdminRole = {isSuperAdminRole}
                        />
                      );
                    })
                  )}
                  {userModal && (
                    <Modal
                      open={userModal}
                      onClose={() => setUserModal(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style} className="confirmModal">
                        <Grid container columnSpacing={2} rowSpacing={3}>
                          <Grid item lg={12}>
                            <h4
                              style={{
                                marginBottom: "20px",
                                fontWeight: "600",
                              }}
                            >
                              Upload Csv File
                            </h4>
                            {isSuperAdminRole && (
                              <Grid item lg={12}>
                                <SelectAsync
                                  onChange={handleImportCompanyChange}
                                  searchType="companyid"
                                  selectedValue={importCompanyName}
                                  placeholder="Select Company"
                                  className="asyncHeight training leftAlign"
                                />
                              </Grid>
                            )}
                            <Grid item lg={12}>
                              <Box className="inputFile">
                                <input
                                  type="file"
                                  id="csvFile"
                                  accept=".csv"
                                  onChange={handleFileChange}
                                  size="10"
                                  style={{
                                    marginTop: "30px",
                                    marginLeft: "-80px",
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "50px",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          <Button
                            className="blueButton"
                            sx={{ p: "8px 25px" }}
                            onClick={handleSampleDataFormat}
                          >
                            Download Sample
                          </Button>
                          <LoadingButton
                            autoFocus
                            disabled={uploading}
                            loading={uploading}
                            endIcon={<UploadIcon />}
                            loadingPosition="end"
                            variant="contained"
                            className="blueButton"
                            onClick={handleSubmitImport}
                          >
                            Upload
                          </LoadingButton>
                          <Button
                            className="blueButton cancel"
                            sx={{ p: "8px 25px" }}
                            onClick={() => setUserModal(false)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Box>
                    </Modal>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePaginationCompo
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            setSelected={setSelected}
          />
        </Card>
      </Box>
    </>
  );
};
