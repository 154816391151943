/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import "./ReactFormBuilderStyle.css";
import * as _ from "lodash";
import EditFieldsModal from "../EditFieldsModal";
import {
  _getWidgets,
  Layout,
  Media,
  Submit,
  FormSettings,
} from "src/shared/constants/widgetJson";
import * as widgetsHtml from "src/shared/constants/widgetsHtml";
import { apiPostMethod, apiGetMethod, apiPutMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import { useLocation, useParams } from "react-router-dom";
import {
  uniqueID,
  deepClone,
  handleOnMouseEnter,
  handleOnMouseLeave,
  copyPasteElement,
  addItemAtIndex,
  updateIdSuffix,
  _setDndWidgetData,
  _dndWidgetData,
} from "src/shared/functions";
import PreviewModal from "../Modals/PreviewModal";
import HeaderCompo from "../HeaderCompo";
import * as React from "react";
import SaveFormModal from "../Modals/SaveFormModal";
import HeaderPreview from "../PreviewFormCompo/HeaderPreview";
import { isEmpty } from "src/shared/functions/isEmpty";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import { CustomAccordion } from "../Common/AccordionCompo";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { useRouter } from "src/routes/hooks";
import sidebarContext from "src/hooks/sidebarContext";
import {
  COMPANYROLES,
  FILTER_FORM_TYPE,
  FORM_CATALOGUE_TABS,
  SUPERADMINROLES,
} from "src/core/constants";
import { toast } from "react-toastify";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { Helmet } from "react-helmet-async";
import {
  _isCompanyAdminRole,
  _isSuperAdminRole,
} from "src/shared/functions/string-conversion";
import {
  _errorMsg,
  _notCompanyUser,
} from "src/shared/functions/string-conversion";
import { JsaCodeListPagination } from "./JsaCodeListPagination";

function ReactFormBuilder() {
  const { form, id } = useParams();
  const router = useRouter();
  const location = useLocation();
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const [selectedWidgets, setSelectedWidgets] = useState([]);
  const [formSubmitSettings, setFormSubmitSettings] = useState(Submit);
  const [form_settings, setFormSettings] = useState(FormSettings);

  const [selectedWidget, setSelectedWidget] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState([0, 0]);
  const [saveAsSubForm, setSaveAsSubForm] = useState(false);
  const [trainingId, setTrainingId] = useState("");
  const [jsaTrainingIds, setJsaTrainingIds] = useState(null);
  const [industryIdPreSelected, setIndustryIdPreSelected] = useState(null);
  const [trainingName, setTrainingName] = useState("");
  const [isProjectForm, setIsProjectForm] = useState("");
  const [saveAsTrainingForm, setSaveAsTrainingForm] = useState(
    FormSettings.isTrainingForm
  );
  const [saveFormStatus, setSaveFormStatus] = useState(false);
  const [openSaveAsModal, setOpenSaveAsModal] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [ChooseFile, setChooseFile] = useState("single");
  const [activeWidgetId, setActiveWidgetId] = useState({
    activeId: "",
    previousActiveId: [],
  });
  const [showIds, setShowIds] = useState(false);
  const [copyWidgetJson, setCopyWidgetJson] = useState({});
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [activeDropBox, setActiveDropBox] = useState([]);
  const [activeDropBoxVer, setActiveDropBoxVer] = useState(-1);
  const [fetchingData, setFetchingData] = useState(!!(id && id.length > 0));
  const [dragElIndexes, setDragElIndexes] = useState([0, 0]);
  const [apiResponseData, setApiResponseData] = useState({});
  const [formTitle, setFormTitle] = useState("");
  const [jsaCodeAttached, setJsaCodeAttached] = useState([]);

  const { state } = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isTraining = queryParams.get("isTraining");
  const currentTab = queryParams.get("currentTab");
  useEffect(() => {
    if (copyWidgetJson?.hasOwnProperty("element")) {
      localStorage.setItem(
        "copyData",
        JSON.stringify(deepClone(copyWidgetJson))
      );
    }
  }, [copyWidgetJson]);
  let dragErrorStatus = false;
  const onChangeFields = (item, e, opt = null) => {};

  function getFormDetails() {
    setFetchingData(true);
    let url = "";
    if (
      _notCompanyUser(loginUserInfo) &&
      _.includes(Object.values(FORM_CATALOGUE_TABS), currentTab)
    ) {
      if (form === "form") {
        url = !isEmpty(id)
          ? `${allApiUrl.VIEW_COMPANY_FORM_LIST}/${id}`
          : `${allApiUrl.VIEW_COMPANY_FORM_LIST}`;
      } else {
        url = !isEmpty(id)
          ? `${allApiUrl.VIEW_COMPANY_JSA_LIST}/${id}`
          : `${allApiUrl.VIEW_COMPANY_JSA_LIST}`;
      }
    } else {
      if (form === "form") {
        url = !isEmpty(id)
          ? `${allApiUrl.JSA_FORMS}/${id}`
          : `${allApiUrl.JSA_FORMS}`;
      } else {
        url = !isEmpty(id)
          ? `${allApiUrl.JSA_CODE}/${id}`
          : `${allApiUrl.JSA_CODE}`;
      }
    }
    apiGetMethod(url)
      .then((response) => {
        response.data.form_settings.isTrainingForm = Boolean(
          response.data.trainings_id
        );
        response.data.form_settings.trainingId =
          response.data.trainings_id || "";

        setApiResponseData(response.data);
        setFormTitle(response.data.title);
        setSelectedWidgets(response.data.structure);
        setFormSubmitSettings(response.data.submit_settings);
        if (response.data.category_id) {
          setSelectedCategoryId(response.data.category_id);
        }
        setFormSettings((prevData) => {
          return {
            ...prevData,
            ...response.data.form_settings,
          };
        });
        setSaveAsTrainingForm(Boolean(response.data.trainings_id));
        setTrainingId(response.data.trainings_id || "");
        setJsaTrainingIds(response.data.jsa_training_ids_label || []);
        const industry_ids_label =
          response.data?.industry_name && response.data?.industry_id
            ? [
                {
                  label: response.data?.industry_name,
                  value: response.data?.industry_id,
                },
              ]
            : [];
        setIndustryIdPreSelected(industry_ids_label);
        setIsProjectForm(response.data?.isProjectForm);
        setTrainingName(response.data?.training_name || "");
        setTimeout(() => {
          setFetchingData(false);
        }, 1000);
      })
      .catch((e) => {
        toast.error(e.data?.message);
        router.replace("/forms");
      });
  }

  useEffect(() => {
    if (id !== undefined) {
      getFormDetails();
    }
    if (form === "subform") {
      setSaveAsSubForm(true);
    }
    if (localStorage.getItem("copyData")) {
      setCopyWidgetJson(JSON.parse(localStorage.getItem("copyData")));
    }

    window.addEventListener("storage", (event) => {
      if (event.key === "copyData") {
        const updatedData = JSON.parse(event.newValue);
        setCopyWidgetJson(updatedData);
      }
    });

    return () => {
      try {
        window.removeEventListener("storage");
      } catch (e) {}
    };
  }, []);

  function handleDragOver(e) {
    e.preventDefault();
  }

  function handleMoveWidgetOnDrag(e, json, pathArray) {
    _setDndWidgetData({ json: json, path: pathArray });
  }
  function removeWidgetAfterDrop(pathArray) {
    if (!dragErrorStatus) {
      if (selectedWidgets[pathArray[0]].childComponent.length > 1) {
        selectedWidgets[pathArray[0]].childComponent.splice(pathArray[1], 1);
      } else {
        selectedWidgets.splice(pathArray[0], 1);
      }
      setSelectedWidgets([...selectedWidgets]);
    } else {
      dragErrorStatus = false;
    }
  }
  function handleOnDrag(e, type, isJSA_CODE = false) {
    if (isJSA_CODE) {
      onSelectJsaCode(type);
    }
    _setDndWidgetData(type);
  }

  function handleOnDrop(e, index, subIndex, isRowDrop = false) {
    e.preventDefault();
    let widgetType = _dndWidgetData();
    if (!isEmpty(widgetType)) {
      let jsonData = {};
      if (typeof widgetType === "string") {
        if (_getWidgets()[widgetType] !== undefined) {
          jsonData.childComponent = [deepClone(_getWidgets()[widgetType])];
        } else if (Layout[widgetType] !== undefined) {
          jsonData.childComponent = [deepClone(Layout[widgetType])];
        } else {
          jsonData.childComponent = [deepClone(Media[widgetType])];
        }
        jsonData.childComponent[0]["id"] = `${
          jsonData.childComponent[0].element
        }_${uniqueID(4)}`;
        jsonData.childComponent[0].width = jsonData.childComponent[0].width
          ? jsonData.childComponent[0].width
          : 6;
        if (
          index !== undefined &&
          subIndex !== undefined &&
          selectedWidgets[index]
        ) {
          if (isRowDrop) {
            selectedWidgets.splice(index, subIndex, jsonData);
            setActiveDropBoxVer(-1);
          } else {
            selectedWidgets[index].childComponent[subIndex].childComponent.push(
              jsonData
            );
          }
        } else {
          selectedWidgets.push(jsonData);
        }
      } else {
        if (widgetType?.hasOwnProperty("json")) {
          selectedWidgets.push({ childComponent: [widgetType.json] });
        } else {
          selectedWidgets.push({ childComponent: [widgetType] });
        }
      }
      setSelectedWidgets([...selectedWidgets]);
    }

    _setDndWidgetData(null);
  }
  function onClickWidgetSection(widgetType) {
    let jsonData = {};
    if (_getWidgets()[widgetType] !== undefined) {
      jsonData.childComponent = [deepClone(_getWidgets()[widgetType])];
    } else if (Layout[widgetType] !== undefined) {
      jsonData.childComponent = [deepClone(Layout[widgetType])];
    } else {
      jsonData.childComponent = [deepClone(Media[widgetType])];
    }
    jsonData.childComponent[0]["id"] = `${
      jsonData.childComponent[0].element
    }_${uniqueID(4)}`;
    jsonData.childComponent[0].width = jsonData.childComponent[0].width
      ? jsonData.childComponent[0].width
      : 6;
    selectedWidgets.push(jsonData);
    setSelectedWidgets([...selectedWidgets]);
  }

  const onSelectJsaCode = (row) => {
    if (!isEmpty(row) && row?.jsa_code_form_id) {
      setJsaCodeAttached((prevState) => {
        return _.uniq([...prevState, row.jsa_code_form_id]);
      });
    }
  };
  function onClickSubFormSection(jsonData) {
    onSelectJsaCode(jsonData);

    selectedWidgets.push({ childComponent: [jsonData] });
    setSelectedWidgets([...selectedWidgets]);
  }
  function sectionHandleOnDrop(e, index) {
    let widgetType = _dndWidgetData();
    let width = 0;
    let usedWidth = 0;
    let totalWidth = 12;
    if (selectedWidgets[index] == undefined) {
      if (widgetType.path[0] < index) {
        index -= 1;
      } else {
        index += 1;
      }
    }
    selectedWidgets[index].childComponent.map((row) => {
      usedWidth += row.width;
    });
    let widthRemaining = totalWidth - usedWidth;
    if (widthRemaining > 6) {
      width = 6;
    } else {
      width = widthRemaining;
    }
    let widgetJson = {};
    if (typeof widgetType == "string") {
      if (_getWidgets()[widgetType] !== undefined) {
        widgetJson = deepClone(_getWidgets()[widgetType]);
      } else if (Layout[widgetType] !== undefined) {
        widgetJson = deepClone(Layout[widgetType]);
      } else {
        widgetJson = deepClone(Media[widgetType]);
      }

      widgetJson["id"] = `${widgetJson.element}_${uniqueID(4)}`;
    } else {
      widgetJson = widgetType;
    }

    if (
      widgetJson?.hasOwnProperty("minRequiredWidth") &&
      widgetJson.minRequiredWidth > width
    ) {
      dragErrorStatus = true;
      toast.error(
        `Not allowed, minimum width required is ${widgetJson.minRequiredWidth}`
      );
    } else {
      selectedWidgets[index].childComponent.push(
        deepClone({ ...widgetJson, ...{ width: width } })
      );
      setSelectedWidgets([...selectedWidgets]);
    }
  }
  function removeWidget(index, refVal) {
    try {
      if (
        !isEmpty(selectedWidgets[index].childComponent[refVal]) &&
        selectedWidgets[index].childComponent[refVal]?.hasOwnProperty(
          "jsa_code_form_id"
        )
      ) {
        const jsa_code_form_id =
          selectedWidgets[index].childComponent[refVal]?.jsa_code_form_id;

        setJsaCodeAttached((prevState) => {
          return _.remove(prevState, jsa_code_form_id);
        });
      }
    } catch (e) {}
    try {
      if (
        selectedWidgets[index].childComponent[refVal].id === selectedWidget.id
      ) {
        setIsOpen(false);
        setSelectedWidget({});
      }
    } catch (e) {}

    if (selectedWidgets[index].childComponent.length > 1) {
      selectedWidgets[index].childComponent.splice(refVal, 1);
    } else {
      selectedWidgets.splice(index, 1);
    }
    setSelectedWidgets([...selectedWidgets]);
  }

  function updateSelectedField(data) {
    if (selectedWidget.element === "Submit") {
      setFormSubmitSettings({ ...data });
    } else if (data?.hasOwnProperty("title")) {
      setFormSettings({ ...data });
      setSaveAsTrainingForm(Boolean(data.isTrainingForm));
      setTrainingId(data.trainingId);
      setJsaTrainingIds(data.jsa_training_ids_label || []);
      const industry_ids_label =
        data?.industry_name && data?.industry_id
          ? [{ label: data?.industry_name, value: data?.industry_id }]
          : [];
      setIndustryIdPreSelected(industry_ids_label);
      setIsProjectForm(data?.isProjectForm);
    } else {
      let dataPath = "";
      selectedIndex.map((value, index) => {
        if (index === 0) {
          dataPath += `[${value}]`;
        } else {
          dataPath += `.childComponent[${value}]`;
        }
      });
      setSelectedWidgets([..._.set(selectedWidgets, dataPath, data)]);
    }
    setSelectedWidget(deepClone(data));
  }

  async function saveFormStructure(
    selectedFormTitle,
    selectedFormType,
    selectedFormCategory,
    trainingIdParam = "",
    selectedFormTrainingName = "",
    selectedJsaTrainingIds = [],
    selectedIndustry = null
  ) {
    setSaveFormStatus(true);
    const isCompanyRole = Object.values(COMPANYROLES)?.includes(
      loginUserInfo?.roleid[0]
    );
    const isSuperAdminRole = Object.values(SUPERADMINROLES)?.includes(
      loginUserInfo?.roleid[0]
    );

    setSaveAsSubForm(
      Boolean(
        selectedFormType === FILTER_FORM_TYPE.JSA_CODE && selectedFormCategory
      )
    );
    if (!isEmpty(form_settings.title.label) || !isEmpty(selectedFormTitle)) {
      setFormSettings((prevState) => {
        return {
          ...prevState,
          isTrainingForm: Boolean(
            selectedFormType === FILTER_FORM_TYPE.TRAINING_FORM
          ),
          trainingId: trainingIdParam,
          title: {
            ...prevState.title,
            label: !isEmpty(selectedFormTitle)
              ? selectedFormTitle
              : prevState.title.label,
          },
        };
      });
      let data = {
        title: selectedFormTitle,
        structure: selectedWidgets,
        submit_settings: formSubmitSettings,
        form_type: selectedFormType,
        form_settings: {
          ...form_settings,
          isTrainingForm: Boolean(
            selectedFormType === FILTER_FORM_TYPE.TRAINING_FORM
          ),
          trainingId: trainingIdParam,
          title: {
            ...form_settings.title,
            label: !isEmpty(selectedFormTitle)
              ? selectedFormTitle
              : form_settings.title.label,
          },
        },
        user_id: loginUserInfo.id,
      };

      if (
        selectedFormType === FILTER_FORM_TYPE.JSA_CODE &&
        selectedFormCategory
      ) {
        data["category_id"] = selectedFormCategory;

        data["trainings_id"] = null;
        data["is_subform"] = true;
        data["jsa_training_ids"] =
          _.map(selectedJsaTrainingIds, "value").join(",") || "";
      } else if (Boolean(selectedFormType === FILTER_FORM_TYPE.TRAINING_FORM)) {
        if (trainingIdParam) {
          data["trainings_id"] = trainingIdParam
            ? trainingIdParam
            : form_settings?.trainingId || "";
        }

        data["category_id"] = null;
        data["is_subform"] = false;
      } else {
        data["category_id"] = null;
        data["is_subform"] = false;
        data["trainings_id"] = null;

        if (isCompanyRole) {
          data["jsa_code_ids"] = jsaCodeAttached.join(",") || "";
        }
        if (isSuperAdminRole && !isEmpty(selectedIndustry)) {
          data["industry_id"] = selectedIndustry?.value || "";
        }
      }

      const methodFn = id ? apiPutMethod : apiPostMethod;

      let url = "";
      if (Object.values(COMPANYROLES)?.includes(loginUserInfo?.roleid[0])) {
        if (
          !(
            selectedFormType === FILTER_FORM_TYPE.JSA_CODE &&
            selectedFormCategory
          )
        ) {
          url = !isEmpty(id)
            ? `${allApiUrl.UPDATE_COMPANY_LIST}/${id}`
            : `${allApiUrl.CREATE_COMPANY_FILTER_FORM}`;
        } else {
          url = !isEmpty(id)
            ? `${allApiUrl.COMPANY_JSA_CODE}/${id}`
            : `${allApiUrl.COMPANY_JSA_CODE}`;
        }
      } else {
        if (
          !(
            selectedFormType === FILTER_FORM_TYPE.JSA_CODE &&
            selectedFormCategory
          )
        ) {
          url = !isEmpty(id)
            ? `${allApiUrl.JSA_FORMS}/${id}`
            : `${allApiUrl.JSA_FORMS}`;
        } else {
          url = !isEmpty(id)
            ? `${allApiUrl.JSA_CODE}/${id}`
            : `${allApiUrl.JSA_CODE}`;
        }
      }

      methodFn(url, data)
        .then((response) => {
          setSaveFormStatus(false);
          toast.success(response?.message);
          getFormDetails()
          if (!id) {
            if (selectedFormType === FILTER_FORM_TYPE.FORM) {
              router.replace(`/forms?currentTab=FORM`);
            } else if (selectedFormType === FILTER_FORM_TYPE.TRAINING_FORM) {
              router.replace(`/forms?currentTab=TRAINING_FORM`);
            } else if (selectedFormType === FILTER_FORM_TYPE.JSA_CODE) {
              router.replace(`/forms?currentTab=JSA_CODE`);
            } else {
              router.replace("/forms");
            }
          }
        })
        .catch((error) => {
          setSaveFormStatus(false);
          toast.error(error.data?.message);
        })
        .finally(() => {
          setSaveFormStatus(false);
          setOpenSaveAsModal(false);

          setSelectedCategoryId(selectedFormCategory);
          setTrainingId(trainingIdParam);

          try {
            setJsaTrainingIds(selectedIndustry);
          } catch (e) {}
          try {
            setIndustryIdPreSelected(setIndustryIdPreSelected);
          } catch (e) {}
          setTrainingName(selectedFormTrainingName);
          setSaveAsSubForm(
            Boolean(selectedFormType === FILTER_FORM_TYPE.JSA_CODE)
          );
          setSaveAsTrainingForm(
            Boolean(selectedFormType === FILTER_FORM_TYPE.TRAINING_FORM)
          );
        });
    } else {
      setSaveFormStatus(false);
      toast.error(
        `Please Set ${
          selectedFormType === FILTER_FORM_TYPE.JSA_CODE && selectedFormCategory
            ? "SubForm"
            : "Form"
        } Title`
      );
    }
  }
  function UpdateSectionUpdate(data, index, sectionIndex) {
    selectedWidgets[index].childComponent[sectionIndex] = data;
    setSelectedWidgets([...selectedWidgets]);
  }
  useEffect(() => {
    if (document.getElementById("formBuilderView")) {
      let allInputs = document
        .getElementById("formBuilderView")
        .getElementsByTagName("input");
      if (allInputs.length > 0) {
        for (let i = 0; i < allInputs.length; i++) {
          allInputs[i].readonly = true;
          allInputs[i].disabled = true;
        }
      }
    }
  }, [selectedWidgets]);

  useEffect(() => {
    if (selectedWidgets && selectedWidget.element === "Content") {
      setShowIds(true);
    } else {
      setShowIds(false);
    }
  }, [selectedWidget]);

  function pasteFunction(index = undefined, position = 1, index2 = undefined) {
    let jsonData = deepClone(copyWidgetJson);
    if (jsonData.element === "Calculation") {
      jsonData = deepClone(updateIdSuffix(copyWidgetJson));
    } else {
      jsonData = deepClone(copyPasteElement(copyWidgetJson));
    }
    const insertData = (data) => {
      let returnData = [];
      const isArray = Array.isArray(data);

      if (position === 1) {
        const newItem = isArray ? data : { childComponent: [data] };
        returnData = addItemAtIndex(selectedWidgets, newItem, index);
      } else if (position === 2) {
        if (isArray) {
          returnData = addItemAtIndex(selectedWidgets, data, index);
        } else {
          selectedWidgets[index].childComponent = addItemAtIndex(
            selectedWidgets[index].childComponent,
            data,
            index2
          );
          returnData = selectedWidgets;
        }
        setActiveDropBox([]);
      } else if (position === 3) {
        if (isArray) {
          returnData = addItemAtIndex(selectedWidgets, data, index + 1);
        } else {
          selectedWidgets[index].childComponent = addItemAtIndex(
            selectedWidgets[index].childComponent,
            data,
            selectedWidgets[index].childComponent.length
          );
          returnData = selectedWidgets;
        }
      } else if (position === 4) {
        const newItem = isArray ? data : { childComponent: [data] };
        returnData = addItemAtIndex(
          selectedWidgets,
          newItem,
          selectedWidgets.length
        );
      }
      return returnData;
    };
    if (jsonData.element === "Copyform") {
      setSelectedWidgets([...insertData(jsonData.jsonData)]);
    } else {
      jsonData.width = jsonData.width ? jsonData.width : 6;
      setSelectedWidgets([...insertData(jsonData)]);
    }
    setActiveDropBoxVer(-1);
  }
  function activeDropBoxHandleOnDrop(e, arrayPath, index, widthRemaining) {
    let widgetType = _dndWidgetData();
    if (widgetType !== undefined || widgetType !== null) {
      let path = "";
      if (arrayPath.length > 0) {
        arrayPath.map((value, index) => {
          if (index == 0) {
            path += `[${value}]`;
          } else if (index > 0) {
            path += `.childComponent[${value}]`;
          }
        });
      }
      let Data = _.get(selectedWidgets, path, []);
      if (widgetType) {
        let jsonData = {};
        if (typeof widgetType === "string") {
          if (_getWidgets()[widgetType] !== undefined) {
            jsonData = deepClone(_getWidgets()[widgetType]);
          } else if (Layout[widgetType] !== undefined) {
            jsonData = deepClone(Layout[widgetType]);
          } else {
            jsonData = deepClone(Media[widgetType]);
          }
          jsonData["id"] = `${jsonData.element}_${uniqueID(4)}`;
          Data.childComponent.splice(index, 0, jsonData);
        } else {
          if (widgetType?.hasOwnProperty("json")) {
            Data.childComponent.splice(index, 0, widgetType.json);
            removeWidget(...widgetType.path);
          } else {
            Data.childComponent.splice(index, 0, widgetType);
          }
        }
      }
      if (widthRemaining > 0) {
        Data.childComponent[index].width = Data.childComponent[index].width =
          widthRemaining;
      } else {
        Data.childComponent[index].width = Data.childComponent[index].width = 2;
        Data.childComponent[index + 1].width =
          Data.childComponent[index + 1].width - 2;
      }
      setSelectedWidgets([...selectedWidgets]);
    }

    _setDndWidgetData(null);
    setActiveDropBox([]);
  }

  const _ShrinkGrow = (
    row,
    index,
    refVal,
    widthRemaining,
    toSide,
    newWidth = 0
  ) => {
    if (toSide === "toRight" && widthRemaining > 0) {
      row.width < 12
        ? (row.width += 1)
        : (row.width = row.width ? row.width : 8);
      selectedWidgets[index].childComponent[refVal] = row;
      setSelectedWidgets([...selectedWidgets]);
    } else if (toSide === "toLeft") {
      row.width > 2
        ? (row.width -= 1)
        : (row.width = row.width ? row.width : 4);
      selectedWidgets[index].childComponent[refVal] = row;
      setSelectedWidgets([...selectedWidgets]);
    } else if (
      toSide === "width" &&
      Number(widthRemaining) + Number(newWidth) - 1 < 12
    ) {
      setActiveDropBox([]);
      let rr = row;
      rr.width = newWidth;
      row.width = newWidth;
      selectedWidgets[index].childComponent[refVal] = row;
      setSelectedWidgets([...selectedWidgets]);
    }
  };

  const mainElementRef = React.createRef();
  const handleMouseDown = (e, toSide, row, index, refVal, widthRemaining) => {
    e.preventDefault();
    _ShrinkGrow(row, index, refVal, widthRemaining, toSide);
  };

  const getMapItems = (item, index, totalItems) => {
    let usedWidth = 0;
    let totalWidth = 12;
    selectedWidgets[index].childComponent.map((row) => {
      usedWidth += row.width;
    });
    let widthRemaining = totalWidth - usedWidth;
    return (
      <div key={`map_items_${index}`}>
        {Boolean(totalItems > 0) && (
          <>
            <div className="row mx-0 mt-1 mb-2">
              <button
                className="addColumnButtonVer"
                onClick={() => {
                  setActiveDropBoxVer(index);
                }}
              >
                <i className="fas fa-plus-circle"></i>
              </button>

              {activeDropBoxVer === index && (
                <div
                  className="col-4 ps-0 mt-3"
                  onDrop={(e) => handleOnDrop(e, index, 0, true)}
                  onDragOver={handleDragOver}
                >
                  <div
                    className="widgedropBox p-4"
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                  >
                    {copyWidgetJson?.hasOwnProperty("element") && (
                      <span className="icons">
                        <a
                          role="button"
                          type="button"
                          onClick={() => {
                            pasteFunction(index, 1);
                          }}
                        >
                          <i className="fas fa-paste"></i> <br />
                          Paste
                        </a>
                      </span>
                    )}
                    <span>Drop Fields Here</span>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        <div className="row" ref={mainElementRef}>
          {item.childComponent.map((row, refVal) => {
            let Tag = widgetsHtml[row.element];
            return (
              <div
                className={`col-lg-${
                  row.width ? row.width : 6
                } position-relative`}
                key={`childCompo3_${row.id}`}
                onMouseLeave={() => {
                  setActiveWidgetId({ activeId: "", previousActiveId: [] });
                }}
                onMouseEnter={(e) => {
                  setActiveWidgetId({ activeId: row.id, previousActiveId: [] });
                }}
                draggable
                onDragStart={(e) => {
                  setDragElIndexes([e.clientX, e.clientY]);
                  if (!["Section", "RepeatingSection"].includes(row.element))
                    handleMoveWidgetOnDrag(e, row, [index, refVal]);
                }}
                onDragEnd={(e) => {
                  const threshold = 20;

                  if (
                    Math.abs(dragElIndexes[0] - e.clientX) > threshold ||
                    Math.abs(dragElIndexes[1] - e.clientY) > threshold
                  ) {
                    if (!["Section", "RepeatingSection"].includes(row.element))
                      setTimeout(() => {
                        removeWidgetAfterDrop([index, refVal]);
                      }, 300);
                  }
                }}
              >
                <button
                  className="addColumnButton"
                  onClick={() => {
                    setActiveDropBox([index, refVal]);
                  }}
                >
                  <i className="fas fa-plus-circle"></i>
                </button>
                <div className="row">
                  {activeDropBox[0] === index &&
                    activeDropBox[1] === refVal && (
                      <div
                        className="col-lg-2"
                        onDrop={(e) =>
                          activeDropBoxHandleOnDrop(
                            e,
                            [index],
                            refVal,
                            widthRemaining
                          )
                        }
                        onDragOver={handleDragOver}
                      >
                        <div
                          className="widgedropBox"
                          onMouseEnter={handleOnMouseEnter}
                          onMouseLeave={handleOnMouseLeave}
                        >
                          {copyWidgetJson?.hasOwnProperty("element") && (
                            <span className="icons">
                              <a
                                role="button"
                                type="button"
                                onClick={() => {
                                  pasteFunction(index, 2, refVal);
                                }}
                              >
                                <i className="fas fa-paste"></i> <br />
                                Paste
                              </a>
                            </span>
                          )}
                          <span>Drop Filed Here</span>
                        </div>
                      </div>
                    )}
                  <div
                    className={
                      activeDropBox[0] === index && activeDropBox[1] === refVal
                        ? "col-lg-10"
                        : "col"
                    }
                  >
                    <div className="form-group  element__wrapper">
                      <div
                        className="editPoint__left"
                        onMouseDown={(e) =>
                          handleMouseDown(
                            e,
                            "toLeft",
                            row,
                            index,
                            refVal,
                            widthRemaining
                          )
                        }
                      ></div>
                      <div
                        className="editPoint__right"
                        onMouseDown={(e) =>
                          handleMouseDown(
                            e,
                            "toRight",
                            row,
                            index,
                            refVal,
                            widthRemaining
                          )
                        }
                      ></div>
                      <span
                        className="icons"
                        style={
                          activeWidgetId.activeId === row.id
                            ? {
                                display: "inline-flex",
                                zIndex: 1,
                              }
                            : {}
                        }
                      >
                        <a
                          role="button"
                          type="button"
                          onClick={() => {
                            setSelectedIndex([index, refVal]);
                            setSelectedWidget(deepClone(row));
                            setIsOpen(true);
                          }}
                        >
                          <i className="fa-regular fa-pen-to-square"></i> <br />{" "}
                          Edit
                        </a>

                        {!row?.hasOwnProperty("widthEditable") && (
                          <>
                            <a
                              role="button"
                              type="button"
                              onClick={() => {
                                _ShrinkGrow(
                                  row,
                                  index,
                                  refVal,
                                  widthRemaining,
                                  "toLeft"
                                );
                              }}
                            >
                              <i className="fa-solid fa-arrow-left"></i> <br />{" "}
                              Shrink
                            </a>
                            <a
                              role="button"
                              disabled={row.width === 12}
                              type="button"
                              onClick={() => {
                                _ShrinkGrow(
                                  row,
                                  index,
                                  refVal,
                                  widthRemaining,
                                  "toRight"
                                );
                              }}
                            >
                              <i className="fa-solid fa-arrow-right"></i> <br />{" "}
                              Grow
                            </a>
                            <a
                              role="button"
                              disabled={row.width === 12}
                              type="button"
                              onClick={() => {
                                setActiveDropBox([]);
                                if (widthRemaining > 0) {
                                  row.width < 12
                                    ? (row.width = row.width + widthRemaining)
                                    : (row.width = row.width ? row.width : 8);
                                  selectedWidgets[index].childComponent[
                                    refVal
                                  ] = row;
                                  setSelectedWidgets([...selectedWidgets]);
                                }
                              }}
                            >
                              <i className="fa-solid fa-arrow-right-arrow-left"></i>{" "}
                              <br /> Justify
                            </a>
                            <a
                              role="button"
                              type="button"
                              onClick={() => [setCopyWidgetJson({ ...row })]}
                            >
                              <i className="fa-solid fa-copy"></i>
                              <br /> Copy
                            </a>
                          </>
                        )}
                        <a
                          role="button"
                          type="button"
                          onClick={() => {
                            removeWidget(index, refVal);
                          }}
                        >
                          <i className="fa-solid fa-trash"></i> <br /> Delete
                        </a>
                      </span>
                      {
                        <span className={`rowId ${showIds ? "show" : ""}`}>
                          {row.id}
                        </span>
                      }
                      <Tag
                        index={index}
                        data={row}
                        onChangeFields={onChangeFields}
                        ChooseFile={ChooseFile}
                        handleOnDrop={handleOnDrop}
                        UpdateSectionUpdate={UpdateSectionUpdate}
                        sectionIndex={refVal}
                        setSelectedWidget={setSelectedWidget}
                        setIsOpen={setIsOpen}
                        setSelectedIndex={(arrayPath) => {
                          arrayPath.unshift(refVal);
                          arrayPath.unshift(index);
                          setSelectedIndex(arrayPath);
                        }}
                        setActiveWidgetId={setActiveWidgetId}
                        activeWidgetId={activeWidgetId}
                        copyWidgetJson={copyWidgetJson}
                        setCopyWidgetJson={setCopyWidgetJson}
                        showIds={showIds}
                        activeDropBoxHandleOnDrop={(
                          e,
                          arrayPath,
                          updateIndex,
                          widthRemaining
                        ) => {
                          arrayPath.unshift(refVal);
                          arrayPath.unshift(index);
                          activeDropBoxHandleOnDrop(
                            e,
                            arrayPath,
                            updateIndex,
                            widthRemaining
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {widthRemaining > 0 && (
            <div
              className={`col-lg-${widthRemaining}`}
              onDrop={(e) => sectionHandleOnDrop(e, index)}
              onDragOver={handleDragOver}
              onClick={() => {
                setIsOpen(false);
                setSelectedWidget({});
              }}
            >
              <div
                className="widgedropBox"
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              >
                {copyWidgetJson?.hasOwnProperty("element") && (
                  <span className="icons">
                    <a
                      role="button"
                      type="button"
                      onClick={() => {
                        pasteFunction(index, 3);
                      }}
                    >
                      <i className="fas fa-paste"></i> <br />
                      Paste
                    </a>
                  </span>
                )}
                <span>Drop Fields Here</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  function SubmitForm({
    showSubmitButton = true,
    hideIcons,
    onClickFormButton = () => {},
  }) {
    const [showIcons, setShowIcons] = useState(false);
    return (
      <div
        className="row"
        onMouseEnter={() => setShowIcons(true)}
        onMouseLeave={() => setShowIcons(false)}
      >
        <div className="col-lg-12">
          <div className="form-group">
            <span
              hidden={hideIcons}
              className={`icons${showIcons ? " show" : ""}`}
            >
              <a
                role="button"
                type="button"
                onClick={() => {
                  setSelectedWidget(deepClone(formSubmitSettings));
                  setIsOpen(true);
                }}
              >
                <i className="fa-regular fa-pen-to-square"></i> <br /> Edit
              </a>
            </span>
            {!!showSubmitButton && (
              <div className="d-flex">
                {formSubmitSettings?.buttons?.map((button, index) => {
                  let showBtn = true;
                  if (
                    button?.eventType === "Draft" &&
                    !location.pathname.includes("/allocated-project")
                  ) {
                    showBtn = false;
                  }
                  return (
                    <div key={`btn____${index}`}>
                      {showBtn && (
                        <LoadingButton
                          loading={button.isLoading}
                          loadingPosition="end"
                          endIcon={
                            button?.eventType === "Draft" ? (
                              <SaveAsIcon />
                            ) : (
                              <SaveIcon />
                            )
                          }
                          variant="contained"
                          key={`btn__${index}`}
                          className={`btn btn-secondary blueButton ${
                            index + 1 === formSubmitSettings?.buttons.length
                              ? ""
                              : "me-3"
                          }`}
                          type={button.actionName}
                          onClick={(e) => {
                            try {
                              onClickFormButton(e, button);
                            } catch (ee) {}
                          }}
                        >
                          <span>{button.buttonText}</span>
                        </LoadingButton>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  const [cloneButton, setCloneButton] = useState(false);

  function cloneToggle() {
    if (window.location.pathname.includes("form-builder/form")) {
      setCloneButton(true);
    }
  }

  useEffect(() => {
    cloneToggle();
  }, []);
  const isNotCompanyUserRole = _notCompanyUser(loginUserInfo);
  const isCompanyRole = _isCompanyAdminRole(loginUserInfo);
  const isSuperAdminRole = _isSuperAdminRole(loginUserInfo);

  function cloneForm() {
    if (form_settings.title.label !== "") {
      let formSetting = JSON.parse(JSON.stringify(form_settings));
      form_settings.title.label = "Cloned " + formSetting.title.label;
      let data = {
        structure: selectedWidgets,
        submit_settings: formSubmitSettings,
        form_settings: form_settings,
        userId: loginUserInfo.id,
      };
      let url;
      if (isSuperAdminRole) {
        url = `${allApiUrl?.CLONE_JSA_FORMS}/${id}`;
      } else if (isCompanyRole) {
        url =
          currentTab === "TEMPLATE"
            ? `${allApiUrl?.COMPANY_ADMIN_CLONE_TEMPLATE}/${id}`
            : `${allApiUrl?.COMPANY_ADMIN_CLONE_JSA_FORM}/${id}`;
      }
      apiPostMethod(saveAsSubForm ? allApiUrl.JSA_CODE : url, data)
        .then((response) => {
          setSaveFormStatus(false);
          toast.success(
            `${saveAsSubForm ? "SubForm" : "Form"} Saved SuccessFully`
          );
          router.replace(`/forms?currentTab=${currentTab}`);
        })
        .catch((err) => {
          toast.error(_errorMsg(err));
        });
    }
  }

  const headerNavItems = cloneButton
    ? [
        { label: "Clone", onClick: () => cloneForm() },
        {
          label: "Copy",
          onClick: () => {
            setCopyWidgetJson(
              deepClone({ element: "Copyform", jsonData: selectedWidgets })
            );
            toast.success("Form Copied");
          },
        },
        {
          label: "Preview",
          onClick: () => {
            setOpenPreviewModal(true);
            localStorage.setItem(
              "previewWidgetsData",
              JSON.stringify(selectedWidgets)
            );
            localStorage.setItem(
              "form_settings",
              JSON.stringify(form_settings)
            );
            localStorage.setItem(
              "formSubmitSettings",
              JSON.stringify(formSubmitSettings)
            );
          },
        },
        { label: "Save", onClick: () => setOpenSaveAsModal(true) },
      ]
    : [
        {
          label: "Preview",
          onClick: () => {
            setOpenPreviewModal(true);
            localStorage.setItem(
              "previewWidgetsData",
              JSON.stringify(selectedWidgets)
            );
            localStorage.setItem(
              "form_settings",
              JSON.stringify(form_settings)
            );
            localStorage.setItem(
              "formSubmitSettings",
              JSON.stringify(formSubmitSettings)
            );
          },
        },
        { label: "Save", onClick: () => setOpenSaveAsModal(true) },
      ];
  const { toogle } = React.useContext(sidebarContext);
  return (
    <>
      <Helmet>
        <title>
          {`Forms Builder | ${process.env.REACT_APP_APPLICATION_NAME}`}{" "}
        </title>
      </Helmet>
      <Box className="widgetSection">
        <HeaderCompo navItems={headerNavItems} />
        {openSaveAsModal && (
          <SaveFormModal
            openSaveAsModal={openSaveAsModal}
            setOpenSaveAsModal={setOpenSaveAsModal}
            saveAsSubForm={saveAsSubForm}
            form={form}
            setSaveAsSubForm={setSaveAsSubForm}
            saveAsTrainingForm={saveAsTrainingForm}
            trainingId={trainingId}
            jsaTrainingIds={jsaTrainingIds}
            industryIdPreSelected={industryIdPreSelected}
            trainingName={trainingName}
            selectedCategoryId={selectedCategoryId}
            saveFormStatus={saveFormStatus}
            saveFormStructure={saveFormStructure}
            formId={id}
            formTitle={formTitle ? formTitle : form_settings.title.label}
            isProjectForm={isProjectForm}
            FORM_TYPE={apiResponseData?.form_type}
          />
        )}
        {openPreviewModal && (
          <PreviewModal
            openPreviewModal={openPreviewModal}
            setOpenPreviewModal={setOpenPreviewModal}
            SubmitForm={SubmitForm}
            setSelectedWidget={setSelectedWidget}
            selectedWidgets={selectedWidgets}
            selectedWidget={selectedWidget}
            setFormSettings={setFormSettings}
            form_settings={form_settings}
            setIsOpen={setIsOpen}
          />
        )}
        <div
          className={`ReactFormBuilder header-margin headerUser ${
            !loginUserInfo?.isUser ? "m-0" : ""
          }`}
          id="ReactFormBuilder"
        >
          <div className={`react-form-widgets`}>
            <div
              className={`sidebar ${
                toogle === true
                  ? "sidebarUser fieldInActive"
                  : "sidebarUser fieldActive"
              }`}
            >
              {!modalIsOpen && (
                <>
                  <CustomAccordion defaultExpanded>
                    <AccordionSummary
                      className="accordionSummary"
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography>Add Fields</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {Object.keys(_getWidgets()).map((row, index) => {
                        if (_getWidgets()[row].listingHidden) {
                          return;
                        }
                        return (
                          <div
                            key={`choice3__${index}`}
                            className="widget"
                            onClick={(e) => onClickWidgetSection(row)}
                            draggable
                            onDragStart={(e) => {
                              handleOnDrag(e, row);
                            }}
                          >
                            {_getWidgets()[row].name}
                          </div>
                        );
                      })}
                    </AccordionDetails>
                  </CustomAccordion>

                  <CustomAccordion defaultExpanded>
                    <AccordionSummary
                      className="accordionSummary"
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography>Layout</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {Object.keys(Layout).map((row, index) => {
                        return (
                          <div
                            key={`choice4__${index}`}
                            className="widget"
                            onClick={(e) => onClickWidgetSection(row)}
                            draggable
                            onDragStart={(e) => {
                              handleOnDrag(e, row);
                            }}
                          >
                            {Layout[row].name}
                          </div>
                        );
                      })}
                    </AccordionDetails>
                  </CustomAccordion>

                  <CustomAccordion defaultExpanded>
                    <AccordionSummary
                      className="accordionSummary"
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography>Media</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {Object.keys(Media).map((row, index) => {
                        return (
                          <div
                            key={`choice5__${index}`}
                            className="widget"
                            onClick={(e) => onClickWidgetSection(row)}
                            draggable
                            onDragStart={(e) => {
                              handleOnDrag(e, row);
                            }}
                          >
                            {Media[row].name}
                          </div>
                        );
                      })}
                    </AccordionDetails>
                  </CustomAccordion>

                  <CustomAccordion>
                    <AccordionSummary
                      className="accordionSummary"
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography>Add JSA Code</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <JsaCodeListPagination
                        onClickSubFormSection={onClickSubFormSection}
                        handleOnDrag={handleOnDrag}
                      />
                    </AccordionDetails>
                  </CustomAccordion>
                </>
              )}
              <EditFieldsModal
                modalStatus={modalIsOpen}
                setModalStatus={setIsOpen}
                data={selectedWidget}
                selectedWidgets={selectedWidgets}
                updateSelectedField={updateSelectedField}
                setSelectedWidget={setSelectedWidget}
                setChooseFile={setChooseFile}
                ChooseFile={ChooseFile}
              />
            </div>
          </div>
          {fetchingData ? (
            <div className="loaderOuter">
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            <div className="widget-dropsecton" id="formBuilderView">
              <div className="outerFromBuilder">
                <HeaderPreview
                  setSelectedWidget={setSelectedWidget}
                  selectedWidgets={selectedWidgets}
                  selectedWidget={selectedWidget}
                  setFormSettings={setFormSettings}
                  form_settings={form_settings}
                  setIsOpen={setIsOpen}
                />
                {selectedWidgets.map((row, index) => {
                  return getMapItems(row, index, selectedWidgets.length);
                })}
                <div
                  draggable
                  className="row"
                  onDrop={handleOnDrop}
                  onDragOver={handleDragOver}
                  onClick={() => {
                    setIsOpen(false);
                    setSelectedWidget({});
                  }}
                >
                  <div className="col-lg-6">
                    <div
                      className="widgedropBox"
                      style={{ minHeight: "150px" }}
                      onMouseEnter={handleOnMouseEnter}
                      onMouseLeave={handleOnMouseLeave}
                    >
                      {copyWidgetJson?.hasOwnProperty("element") && (
                        <span className="icons">
                          <a
                            role="button"
                            type="button"
                            onClick={() => {
                              pasteFunction(undefined, 4);
                            }}
                          >
                            <i className="fas fa-paste"></i> <br />
                            Paste
                          </a>
                        </span>
                      )}
                      <span>Drop Fields Here</span>
                    </div>
                  </div>
                </div>
                <SubmitForm hideIcons={false} onClickFormButton={() => {}} />
              </div>
            </div>
          )}
        </div>
      </Box>
    </>
  );
}

export default ReactFormBuilder;
