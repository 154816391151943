import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { useNavigate } from "react-router-dom";
import Scrollbar from "src/components/scrollbar";
import UserTableRow from "../user-table-row";
import { apiGetMethod } from "src/api/rest";
import {_handleChangePage, PermissionCheck} from "src/utils/common";
import { handleCheckboxClick } from "src/sections/Table";
import { allApiUrl } from "src/api/apiRoute";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import PageHeader from "src/components-form/Common/PageHeader";
import { Helmet } from "react-helmet-async";
import SelectAsync from "src/components-form/shared/SelectAsync";
import {PAGINATION_OBJECT} from "src/core/constants";
import {_setPaginationObjectFn, createQueryString} from "src/shared/functions";

import TableHeadRow, {
  StyledTableCell,
  StyledTableNoData,
  StyledTableRow
} from "src/components/mui-common/table-ui/TableHeadRow";
import TableToolbar from "src/components/mui-common/table-ui/TableToolbar";
import TableRowsLoader from "src/components/mui-common/table-ui/TableRowsLoader";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import {headCellCompany} from "src/components/mui-common/table-ui/headCells";
import {isEmpty} from "src/shared/functions/isEmpty";


export default function UserPage() {
  let navigate = useNavigate();
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );

  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);
  const [searchTerm, setSearchTerm] = useState("");

  const [selected, setSelected] = useState([]);
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(null);
  const [industry, setIndustry] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [status, setStatus] = useState("all");
  const [industryName, setIndustryName] = useState(null);
  const headCells = headCellCompany;

  useEffect(() => {
    if (paginationObject.triggerApi) {
      apiCall();
    }
  }, [paginationObject.triggerApi]);
  useEffect(() => {
    try {
      _handleChangePage({}, 0, setPaginationObject,setSelected)
    } catch (e) {}
    apiIndustryCall();
  }, [searchTerm, status, industryName])

  function apiCall(value) {
    setLoader(true);
    let queryObject = {
      search: searchTerm || "",
      pageNumber: paginationObject.page,
      limit: paginationObject.rowsPerPage,
      industryId: industryName?.value,
      status: status === "all" ? null : status,
    };
    const qryStr = createQueryString(queryObject);


    apiGetMethod(`${allApiUrl?.COMPANY_LISTING}${qryStr}`).then((data) => {
      setDataFiltered(data.data);
      setPaginationObject((prevState) => {
        return _setPaginationObjectFn(prevState, data.meta);
      });
    }).catch((err) => {
      setDataFiltered([...[]]);
      setPaginationObject(PAGINATION_OBJECT);
    }).finally(() => {
      setLoader(false);
    });
  }
  const routeChange = () => {
    let path = `/company/add-company`;
    navigate(path);
  };
  const handleClick = (event, id) => {
    setSelected(handleCheckboxClick(event, id, selected));
  };
  function apiIndustryCall() {
    apiGetMethod(`${allApiUrl?.INDUSTRY_LIST}?pageNumber=${0}&limit=${30}`)
      .then((data) => {
        let array = [];
        data?.data?.map((item) => {
          let obj = {
            id: item.id,
            name: item?.name,
          };
          array.push(obj);
        });
        setIndustry(array);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  const handleIndustryChange = (event, value) => {
    setIndustryName(event);
    setSelected([])
  };
  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
    setSelected([])
  };
  return (
    <>
      <Helmet>
        <title> Companies | {process.env.REACT_APP_APPLICATION_NAME} </title>
      </Helmet>
      <Box>
        <div className="flexTop">
          <PageHeader
            header={"Company"}
            links={[
              {
                name: "Dashboard",
                path: "/",
              },
            ]}
          />
          <div>
            {PermissionCheck("Companies", "create", loginUserInfo) && (
              <Button
                variant="contained"
                color="inherit"
                startIcon={<AddIcon />}
                onClick={routeChange}
                className="blueButton"
              >
                New Company
              </Button>
            )}
          </div>
        </div>
        <Card className="cardFix">
          <TableToolbar
            title={`Company`}
            numSelected={selected.length}
            onSearch={(query) => setSearchTerm(query)}
            deleteUrl={allApiUrl?.DELETE_MULTIPLE_COMPANY}
            selected={selected}
            setSelected={setSelected}
            apiCall={apiCall}
          />

          <div style={{ padding: "20px 15px 20px 15px" }}>
            <h6 style={{marginBottom:"15px"}}>Filters:</h6>
            <Grid container columnSpacing={2}>
              <Grid item lg={3}>
                <FormControl sx={{ width: "100%", marginBottom: "5px" }}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    value={status}
                    onChange={handleChangeStatus}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"Active"}>Active</MenuItem>
                    <MenuItem value={"Inactive"}>InActive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={3}>
                <SelectAsync
                  isClearable
                  onChange={handleIndustryChange}
                  searchType="industry"
                  selectedValue={industryName}
                  placeholder="Select Industry"
                  className="asyncHeight training"
                />
              </Grid>
            </Grid>
          </div>
          <Scrollbar>
            <TableContainer className="tableResponsive">
              <Table>
                <TableHeadRow
                  numSelected={selected.length}
                  rowCount={dataFiltered.length}
                  headCells={headCells}
                  permissionType="Companies"
                  listData={dataFiltered}
                  setSelected={setSelected}
                />

                <TableBody>
                  {loader ? (
                    <TableRowsLoader
                      rowsNum={10}
                      columnNum={headCells.length}
                    />
                  ) : isEmpty(dataFiltered) ? (
                    <StyledTableNoData
                      colSpan={headCells.length}
                    />
                  ) : (
                    dataFiltered.map((row, index) => (
                      <UserTableRow
                        key={index}
                        name={row.name}
                        email={row.email}
                        status={(row.status === "Active")}
                        industry={
                          industry?.find((e) => e.id === row?.industry_id)?.name
                        }
                        phonenumber={row.phonenumber}
                        companyid={row.id}
                        selected={selected.indexOf(row.id) !== -1}
                        handleClick={(event) => handleClick(event, row.id)}
                        billingaddress={row?.billingaddress}
                        industry_id={row?.industry_id}
                        apiCall={apiCall}
                        open={open}
                        setOpen={setOpen}
                      />
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePaginationCompo
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            setSelected={setSelected}
          />
        </Card>
      </Box>
    </>
  );
}
