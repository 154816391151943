import AWS from "aws-sdk";
import { isEmpty } from "./isEmpty";
import * as _ from "lodash";
import {addSpaceBeforeCapitalLetters, formatDateFn, getInputType, removeSpace} from "./string-conversion";
import {COMPANYROLES, DEFAULT_ROW_PER_PAGE, ROLES, SUPERADMINROLES} from "../../core/constants";

export function uniqueID(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function generatePathString(pathArray, keyName = undefined) {
  let path = "";
  if (pathArray.length > 0) {
    pathArray.map((value, index) => {
      if (index == 0) {
        path += `[${value}]`;
      } else if (index > 0 && index !== pathArray.length - 1) {
        path += `${keyName ? ".childComponent" : ""}[${value}]`;
      }
    });
  }
  return path;
}
export function deepClone(data) {
  return JSON.parse(JSON.stringify(data));
}

export async function fetchImage(Key) {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_S3_BUCKET_SECRET_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_S3_BUCKET_ACCESS_KEY_ID,
    region: process.env.REACT_APP_AWS_S3_BUCKET_REGION,
    signatureVersion: "v4",
  });
  const s3 = new AWS.S3();
  const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    Key: Key,
    Expires: 3000,
  };
  return await s3.getSignedUrl("getObject", params);
}

export async function base64Image(url, targetElementId) {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const base64String = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

    if (base64String) {
      // Display the base64 image in an img tag
      const img = document.createElement("img");
      img.src = base64String;
      const targetElement = document.getElementById(targetElementId);
      if (targetElement) {
        targetElement.appendChild(img);
      } else {
        console.error(`Element with id ${targetElementId} not found.`);
      }
    } else {
      console.error("Base64 string is null or empty.");
    }
  } catch (error) {
    console.error("Error converting image to base64:", error);
  }
}

export function getConditionalStringResponse(data, id = null) {
  try {
    let response = data.rules.map((rule) => {
      let { field, operator, value, combinator } = rule;
      if (isEmpty(combinator)) {
        const elField = document
          .getElementById("previewModal")
          .querySelector(`[id='${field}']`);
        if (elField) {
          let Input = elField?.value;
          if (elField && getInputType(elField.id) === "calculation") {
            Input = elField?.value;
          } else if (elField && getInputType(elField.id) === "selectBoxes") {
            try {
              Input =
                elField?.value &&
                JSON.parse(elField?.value) &&
                JSON.parse(elField?.value).length
                  ? JSON.parse(elField?.value)[0].id
                  : "";
            } catch (e) {}
          } else if (
            elField &&
            (elField.type === "checkbox" || elField.type === "radio")
          ) {
            let name = elField.name;
            let ele = document
              .getElementById("previewModal")
              .querySelectorAll(`[name='${name}']`);

            if (elField.type === "radio") {
              try {
                if (
                  elField.value &&
                  JSON.parse(elField.value) &&
                  JSON.parse(elField.value).length
                ) {
                  Input = JSON.parse(elField.value)[0]?.id;
                }
              } catch (e) {}
            } else {
              for (let i = 0; i < ele.length; i++) {
                if (!isEmpty(ele[i].value)) {
                  try {
                    const obj = JSON.parse(ele[i].value);
                    Input = _.sortBy(_.map(obj, "id"));
                    value = _.sortBy(value)
                  } catch (e) {}
                }
              }
            }
          }

          if (operator === "contains") {
            return Input.includes(value);
          } else if (operator === "beginsWith") {
            return Input.startsWith(value);
          } else if (operator === "endsWith") {
            return Input.endsWith(value);
          } else if (operator === "doesNotContain") {
            return !Input.includes(value);
          } else if (operator === "doesNotBeginWith") {
            return !Input.startsWith(value);
          } else if (operator === "doesNotEndWith") {
            return !Input.endsWith(value);
          } else if (operator === "null") {
            return isEmpty(Input);
          } else if (operator === "notNull") {
            return !isEmpty(Input);
          } else if (operator === "in") {
            return Input.includes(value);
          } else if (operator === "notIn") {
            return !Input.includes(value);
          } else if (operator === "between") {
            return (
              greaterThanEqual(Input, value[0]) &&
              lessThanEqual(Input, value[1])
            );
          } else if (operator === "notBetween") {
            return Input < value[0] || Input > value[1];
          } else if (operator === "=") {
            return _.isEqual(Input, value) || Input == value;
          } else if (operator === "!=") {
            return Input != value;
          } else if (operator === "<") {
            return lessThan(Input, value);
          } else if (operator === ">") {
            return greaterThan(Input, value);
          } else if (operator === "<=") {
            return lessThanEqual(Input, value);
          } else if (operator === ">=") {
            return greaterThanEqual(Input, value);
          } else {
            try {
              return eval(`${Input} ${operator} ${value}`);
            } catch (e) {
              return false;
            }
          }
        } else {
          return false;
        }
      } else {
        return getConditionalStringResponse(rule);
      }
    });
    let operator = `${data.combinator === "and" ? "&& " : "|| "} `;
    let string = "";
    response.map((res, index) => {
      string += `${index !== 0 ? operator : ""}${res} `;
    });
    return eval(string);
  } catch (e) {
    return true;
  }
}

export const _checkConditionalShowHideEl = (item) => {
  if (item.conditionalOperation && !isEmpty(item.conditionalOperation.rules.rules)) {
    if (
      !getConditionalStringResponse(item.conditionalOperation.rules, item.id)
    ) {
      return true;
    }
  }
};

export const handleOnMouseEnter = (e) => {
  try {
    if (
      e.target?.childNodes[0].className &&
      e.target?.childNodes[0].className === "icons"
    ) {
      e.target.childNodes[0].style.display = "inline-block";
    }
  } catch (e) {}
};
export const handleOnMouseLeave = (e) => {
  try {
    if (
      e.target?.childNodes.length === 2 &&
      e.target?.childNodes[0].className &&
      e.target?.childNodes[0].className === "icons"
    ) {
      e.target.childNodes[0].style.display = "none";
    }
  } catch (e) {}
};

export const lessThanEqual = (Input, value) => {
  if (typeof Input === "string" && typeof value === "string") {
    const compareStrings = (str1, str2) => {
      str1 = str1.replace(/^0+/, "");
      str2 = str2.replace(/^0+/, "");

      if (str1.length === str2.length) {
        return str1 <= str2;
      } else {
        return str1.length < str2.length;
      }
    };
    return compareStrings(Input, value);
  } else {
    return Input <= value;
  }
};
export const greaterThanEqual = (Input, value) => {
  if (typeof Input === "string" && typeof value === "string") {
    const compareStrings = (str1, str2) => {
      str1 = str1.replace(/^0+/, "");
      str2 = str2.replace(/^0+/, "");

      if (str1.length === str2.length) {
        return str1 >= str2;
      } else {
        return str1.length > str2.length;
      }
    };
    return compareStrings(Input, value);
  } else {
    return Input >= value;
  }
};
const greaterThan = (Input, value) => {
  if (typeof Input === "string" && typeof value === "string") {
    const compareStrings = (str1, str2) => {
      str1 = str1.replace(/^0+/, "");
      str2 = str2.replace(/^0+/, "");

      if (str1.length === str2.length) {
        return str1 > str2;
      } else {
        return str1.length > str2.length;
      }
    };
    return compareStrings(Input, value);
  } else {
    return Input > value;
  }
};
const lessThan = (Input, value) => {
  if (typeof Input === "string" && typeof value === "string") {
    const compareStrings = (str1, str2) => {
      str1 = str1.replace(/^0+/, "");
      str2 = str2.replace(/^0+/, "");

      if (str1.length === str2.length) {
        return str1 < str2;
      } else {
        return str1.length < str2.length;
      }
    };
    return compareStrings(Input, value);
  } else {
    return Input < value;
  }
};

export const setWidgetUserInputValue = (
  form,
  data,
  key,
  val,
  isSplitForm = false,
  obtainedMarks = 0
) => {
  const patchArrayFn = (v) => {
    const patchValFn = (vv) => {
      if (
        _.includes(
          ["Signature", "ContactInformation", "AddressInformation"],
          vv.element
        )
      ) {
        Object.keys(vv.fields).map((fvKey) => {
          const fieldName = `field_name_${vv.id}_${fvKey}`;

          if (key === fieldName) {
            try {
              vv.fields[fvKey].userInputVal = form[vv.id][fvKey]
                ? form[vv.id][fvKey]
                : val;
            } catch (e) {
              try {
                vv.fields[fvKey].userInputVal = form[vv.id][fieldName]
                  ? form[vv.id][fieldName]
                  : val;
              } catch (e) {}
            }
          }
        });
        if (vv.element === "Signature" && !isEmpty(form)) {
          vv.userInputVal = form[vv.id]
            ? form[vv.id]
            : vv.userInputVal
            ? vv.userInputVal
            : "";
        }
      } else if (vv.element === "RatingScale") {
        _.map(vv.fields, (row, rowIndex) => {
          const fieldName = `field_name_${row.id}_${removeSpace(
            row.label
          )}_${rowIndex}`;
          if (key === fieldName) {
            vv.fields[rowIndex].userInputVal = val;
            try {
              if (!_.isNaN(Number(val)) && _.isNumber(Number(val))) {
              }
            } catch (e) {}
          }
        });
      } else if (vv.element === "Paragraph" && !isEmpty(form)) {
        vv.label = form[vv.id] ? form[vv.id] : vv.label;
      } else if (vv.element === "SelectTimePicker" && !isEmpty(form)) {
        const fieldName = `field_name_${vv.id}`;
        if (key === fieldName) {
          vv.userInputVal = form[vv.id] ? form[vv.id] : val;
        }
      } else {
        const fieldName = `field_name_${vv.id}`;
        if (key === fieldName) {
          vv.userInputVal = form[vv.id] ? form[vv.id] : val;
          try {
            const mark =
              vv.element === "DropDown" || vv.element === "CheckBoxes"
                ? Array.isArray(vv.userInputVal)
                  ? vv.userInputVal.reduce((acc, item) => acc + +item.val, 0)
                  : JSON.parse(vv.userInputVal)?.val
                : vv.userInputVal;
            if (
              !_.isNaN(Number(mark)) &&
              _.isNumber(Number(mark)) &&
              _.includes(["DropDown", "CheckBoxes"], vv.element)
            ) {
              obtainedMarks += Number(mark);
              vv.showRetakeField = !(Number(mark) > 0);
            }
          } catch (e) {}
        }
      }
    };
    _.map(v.childComponent, (vv) => {
      if (vv.element === "Section") {
        vv.childComponent.map((row, index) => {
          row.childComponent.map((data, index2) => {
            patchValFn(vv.childComponent[index].childComponent[index2]);
          });
        });
      } else if (vv.element === "RepeatingSection") {
        vv.childComponent.map((row, index) => {
          row.components?.map((compo, index2) => {
            compo.childComponent.map((data, index3) => {
              patchValFn(
                vv.childComponent[index].components[index2].childComponent[
                  index3
                ]
              );
            });
          });
        });
      } else {
        patchValFn(vv);
      }
    });
  };
  if (isSplitForm) {
    _.map(data, (dv) => {
      _.map(dv.structure, (v) => {
        patchArrayFn(v);
      });
    });
  } else {
    _.map(data, (v) => {
      patchArrayFn(v);
    });
  }
  return { obtainedMarks };
};

export const removeKeysFromObject = (obj, keysToRemove) => {
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      removeKeysFromObject(obj[i], keysToRemove);
    }
  } else if (typeof obj === "object" && obj !== null) {
    for (let key in obj) {
      if (obj?.hasOwnProperty(key)) {
        if (keysToRemove.includes(key)) {
          delete obj[key];
        } else {
          removeKeysFromObject(obj[key], keysToRemove);
        }
      }
    }
  }
  return obj;
};

export const createFormUserInputData = (
  form,
  formRef,
  tempSaveObject,
  isSplitForm,
  obtainedMarks = 0
) => {
  const formData = {};
  const formElements = formRef.current?.elements;
  for (let i = 0; i < formElements.length; i++) {
    const element = formElements[i];

    const checkBoxesHiddenFieldRegex =
      /^hidden_field_name_CheckBoxes_([a-zA-Z0-9]+)$/;
    const checkBoxesFieldNameRegex = /^field_name_CheckBoxes_([a-zA-Z0-9]+)$/;

    const ratingScaleHiddenFieldRegex =
      /^hidden_field_name_RatingScale_([a-zA-Z0-9]+)$/;
    const ratingScaleFieldNameRegex = /^field_name_RatingScale_([a-zA-Z0-9]+)$/;

    let elName = element.name;
    if (
      checkBoxesHiddenFieldRegex.test(element.name) ||
      ratingScaleHiddenFieldRegex.test(element.name)
    ) {
      elName = element.id.replace("field_id_", "field_name_");
    }

    if (
      elName &&
      !checkBoxesFieldNameRegex.test(element.name) &&
      !ratingScaleFieldNameRegex.test(element.name)
    ) {
      formData[elName] = element.value;
      const resData = setWidgetUserInputValue(
        form,
        tempSaveObject,
        elName,
        element.value,
        isSplitForm,
        obtainedMarks
      );

      if (
        !_.isNaN(Number(resData.obtainedMarks)) &&
        _.isNumber(Number(resData.obtainedMarks))
      ) {
        obtainedMarks = Number(resData.obtainedMarks);
      }
    }
  }
  return { tempSaveObject, obtainedMarks };
};

export const getS3Fn = () => {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_S3_BUCKET_SECRET_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_S3_BUCKET_ACCESS_KEY_ID,
    region: process.env.REACT_APP_AWS_S3_BUCKET_REGION,
    signatureVersion: "v4",
  });

  return new AWS.S3();
};
export const UploadFilesS3 = async (file) => {
  if (!file.name) {
    return;
  }
  const keyName = `${_.now()}_${file.name}`;
  const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    Key: keyName,
    Body: file,
  };
  const { Key } = await getS3Fn().upload(params).promise();
  return Key;
};

export const copyPasteElement = (structure) => {
  const fieldsToCheck = [
    "Paragraph_",
    "TextInput_",
    "Radio_Option_",
    "CheckBoxes_",
    "CheckBoxes_Option_",
    "DropDown_Option_",
    "SelectDatePicker_",
    "FileUpload_",
    "SelectTimePicker_",
    "RatingScale_",
    "Signature_",
    "Signature_Name_",
    "ContactInformation_",
    "ContactInfo_FirstName_",
    "ContactInfo_MiddleName_",
    "ContactInfo_LastName_",
    "ContactInfo_ContactPhone_",
    "ContactInfo_Address_",
    "ContactInfo_City_",
    "ContactInfo_State_",
    "AddressInformation_",
    "AddressInfo_ProjectName_",
    "AddressInfo_PlantName_",
    "AddressInfo_Address1_",
    "AddressInfo_Address2_",
    "AddressInfo_City_",
    "AddressInfo_State_",
    "AddressInfo_Zip_",
    "AddressInfo_Country_",
    "AddressInfo_ProjectNo_",
    "AddressInfo_PlantNo_",
    "ContactInfo_Zip_",
    "ContactInfo_Email_",
    "Content_",
    "Calculation_",
    "LookupDropDown_",
    "PageBreak_",
    "Section_",
    "RepeatingSection_",
    "Image_",
    "Video_",
    "ImageSlider_",
    "FormSettings_",
    "Title_",
    "SubmitSettings_",
    "DropDown_",
    "Submit_",
  ];

  try {
    let structureString = JSON.stringify(structure);

    let matchingIds = [];
    fieldsToCheck.forEach((prefix) => {
      const regex = new RegExp(`${prefix}([a-zA-Z0-9]+)`, "g");
      let match;
      while ((match = regex.exec(structureString)) !== null) {
        matchingIds.push(match[0]);
      }
    });

    let uniqueMatchingIds = [...new Set(matchingIds)];

    uniqueMatchingIds.forEach((oldId) => {
      const parts = oldId.split("_");
      const idPrefix = parts.slice(0, -1).join("_");
      const idSuffix = parts[parts.length - 1];

      const newIdSuffix = uniqueID(idSuffix.length);

      const newId = `${idPrefix}_${newIdSuffix}`;

      structureString = structureString.split(oldId).join(newId);
    });
    structure = JSON.parse(structureString);
  } catch (e) {}

  return structure;
};
export const updateIdSuffix = (data) => {
  const generateRandomSuffix = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const updateId = (id) => {
    const parts = id.split("_");
    if (parts.length > 1) {
      const originalSuffixLength = parts[parts.length - 1].length;
      parts[parts.length - 1] = generateRandomSuffix(originalSuffixLength);
      return parts.join("_");
    }
    return id;
  };

  const traverse = (obj) => {
    for (const key in obj) {
      if (obj?.hasOwnProperty(key)) {
        if (key === "id" && typeof obj[key] === "string") {
          obj[key] = updateId(obj[key]);
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          traverse(obj[key]);
        }
      }
    }
  };

  // Start traversal
  traverse(data);

  return data;
};

export const addItemAtIndex = (array, newItem, index) => {
  if (index < 0 || index > array.length) {
    return array;
  }
  const newArray = [...array];

  if (Array.isArray(newItem)) {
    newArray.splice(index, 0, ...newItem);
  } else {
    newArray.splice(index, 0, newItem);
  }

  return newArray;
};

export const extractLabelAndUserInputVal = (data) => {
  const result = [];

  const traverse = (obj) => {
    if (Array.isArray(obj)) {
      obj.forEach(item => traverse(item));
    } else if (obj && typeof obj === 'object') {
      if (obj.childComponent && Array.isArray(obj.childComponent)) {
        obj.childComponent.forEach(child => {
          if (
            _.includes(
              ["Signature", "ContactInformation", "AddressInformation", "RatingScale"],
              child.element
            )
          ) {
            Object.keys(child.fields).map((fvKey) => {
              const keyObj = child.fields[fvKey];
              try {
                if (typeof keyObj.userInputVal !== "undefined") {
                  const label = `${addSpaceBeforeCapitalLetters(keyObj?.hasOwnProperty("name") ? keyObj.name : keyObj?.label)}${child.label ? ` (${child.label})` : ``}`;
                  const userInputVal = keyObj.userInputVal;
                  result.push({ label, userInputVal, id: `${(child?.id ? child?.id : removeSpace(child.label))}_${keyObj.id}`, child });
                }
              } catch (e) {
              }
            });
          } else if (!_.includes(
            ["FileUpload", "PageBreak", "Section", "RepeatingSection", "Image", "Video", "ImageSlider", "Paragraph", "Content"],
            child.element
          )) {
            if (child.element === "Calculation") {

            } else {
              if (child.label && typeof child.userInputVal !== 'undefined') {
                let userInputVal = child.userInputVal;
                if (_.includes(["DropDown", "CheckBoxes"], child.element)) {
                  userInputVal = child.userInputVal.map(v => v.label).join(", ")
                } else if (_.includes(["LookupDropDown"], child.element)) {
                  try {
                    userInputVal = child.userInputVal ? JSON.parse(child.userInputVal).map(v => v.label).join(", ") : ""
                  } catch (e) {
                  }
                } else if (_.includes(["SelectDatePicker", "SelectTimePicker"], child.element)) {
                  userInputVal = formatDateFn(child.userInputVal)
                }
                result.push({ label: child.label, userInputVal, id: `${(child?.id ? child?.id : removeSpace(child.label))}_${child.element}`, child });
              }
            }
          }

          traverse(child);
        });
      } else {
        Object.values(obj).forEach(value => traverse(value));
      }
    }
  };

  try {
    traverse(data);
  } catch (e) {
    return data;
  }
  return result;
};

export const _calMaxAndObtainedMarks = (child, max = true) => {
  if (
    !isEmpty(child)
    && _.includes(["DropDown", "CheckBoxes"], child?.element)
    && (max || (!max && !isEmpty(child?.userInputVal)))
  ) {
    let mark = 0;
    if ((child?.element === "CheckBoxes" && child?.typeRadio) || child?.element === "DropDown") {
      const maxBy = max ? (_.maxBy(child?.options, "value")?.value) : child?.userInputVal[0]?.val;
      const m = _.toNumber(maxBy)
      mark = _.isNaN(m) ? 0 : m;
    } else {
      const reduceArray = max ? child?.options : child?.userInputVal;
      const valKey = max ? "value" : "val";
      mark = _.reduce(reduceArray, (sum, n) => {
        return sum + _.toNumber(n[valKey]);
      }, 0)
    }
    return Number(mark)
  }
  return 0;
}

export const calculateTotalMarks = (data, max = true) => {
  let totalMarks = 0;

  const traverse = (obj) => {
    if (Array.isArray(obj)) {
      obj.forEach(item => traverse(item));
    } else if (obj && typeof obj === 'object') {
      if (obj.childComponent && Array.isArray(obj.childComponent)) {
        obj.childComponent.forEach(child => {
          totalMarks += _calMaxAndObtainedMarks(child, max);
          traverse(child);
        });
      } else {
        Object.values(obj).forEach(value => traverse(value));
      }
    }
  };

  try {
    traverse(data);
  } catch (e) {
    return totalMarks;
  }
  return totalMarks;
};
export const getAddUserList = (roleId)=>{
  let obj;
  if (
    Object?.values(SUPERADMINROLES)?.includes(roleId)
  ) {
    obj = [
      { key: "Super Admin", value: ROLES.SUPER_ADMIN },
      { key: "Company Admin", value: ROLES.COMPANY },
      { key: "Backoffice Creator", value: ROLES.BACKOFFICE_FROM_CREATOR },
      { key: "Backoffice User", value: ROLES.BACKOFFICE_USER },
    ];
  } else if (
    Object?.values(COMPANYROLES)?.includes(roleId)
  ) {
    obj = [
      { key: "Company Sub Admin", value: ROLES.COMPANY_SUB_ADMIN },
      { key: "Company Manager", value: ROLES.COMPANY_MANAGER },
      { key: "Company User", value: ROLES.FORM_USER },
    ];
  }
  return obj
}
export const getUserRoleList = (roleId) => {
  let obj;
  if (
    Object.values(SUPERADMINROLES)
      ?.filter((e) => e !== ROLES.SUPER_ADMIN)
      ?.includes(roleId)
  ) {
    obj = [
      { key: "Company Admin", value: ROLES.COMPANY },
      { key: "Company Manager", value: ROLES.COMPANY_MANAGER },
      { key: "Company Sub Admin", value: ROLES.COMPANY_SUB_ADMIN },
      { key: "Backoffice Creator", values: ROLES.BACKOFFICE_FROM_CREATOR },
      { key: "Backoffice User", value: ROLES.BACKOFFICE_USER },
    ];
  } else if (
    Object?.values(SUPERADMINROLES)?.includes(roleId)
  ) {
    obj = [
      { key: "Super Admin", value: ROLES.SUPER_ADMIN },
      { key: "Company Admin", value: ROLES.COMPANY },
      { key: "Company Sub Admin", value: ROLES.COMPANY_SUB_ADMIN },
      { key: "Company Manager", value: ROLES.COMPANY_MANAGER },
      { key: "Company User", value: ROLES.FORM_USER },
      { key: "Backoffice Creator", value: ROLES.BACKOFFICE_FROM_CREATOR },
      { key: "Backoffice User", value: ROLES.BACKOFFICE_USER },
    ];
  } else if (
    Object?.values(COMPANYROLES)?.includes(roleId)
  ) {
    obj = [
      { key: "Company Admin", value: ROLES.COMPANY },
      { key: "Company Sub Admin", value: ROLES.COMPANY_SUB_ADMIN },
      { key: "Company Manager", value: ROLES.COMPANY_MANAGER },
      { key: "Company User", value: ROLES.FORM_USER },
    ];
  }
  return obj
}
export const _setPaginationObjectFn = (prevState, metaData, triggerApi = false) => {
  return {
    ...prevState,
    page: _.toNumber(metaData?.pageNumber) || 0,
    rowsPerPage: _.toNumber(metaData?.limit) || DEFAULT_ROW_PER_PAGE,
    totalItems: _.toNumber(metaData?.totalItems) || 0,
    totalPages: _.toNumber(metaData?.totalPages) || 0,
    triggerApi: triggerApi,
  }
}
export const _handleRedirect = (url, paramObject = {}) => {
  const flattenQueryObject = (obj, prefix = "") =>
    Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? `${prefix}[${k}]` : k;
      if (
        typeof obj[k] === "object" &&
        !Array.isArray(obj[k]) &&
        obj[k] !== null
      ) {
        Object.assign(acc, flattenQueryObject(obj[k], pre));
      } else if (Array.isArray(obj[k])) {
        obj[k].forEach((item, index) => {
          Object.assign(acc, flattenQueryObject(item, `${pre}[${index}]`));
        });
      } else {
        acc[pre] = obj[k];
      }
      return acc;
    }, {});

  const flattendObject = flattenQueryObject(paramObject);
  const params = new URLSearchParams(flattendObject);

  const newUrl = new URL(url, process.env.REACT_APP_STRIPE_SUCCESS_URL);
  // const newUrl = new URL(url);
  newUrl.search = params;

  window.open(newUrl, "_blank");
};

export const createQueryString = (queryObject) => {
  Object.keys(queryObject).forEach((key) =>
    queryObject[key] === undefined ||
    queryObject[key] === null ||
    queryObject[key] === ""
      ? delete queryObject[key]
      : {}
  );
  const queryString = Object.entries(queryObject)
    .map(([k, v]) => {
      return `${k}=${queryObject[k]}`;
    })
    .join("&");
  return !isEmpty(queryString) ? ("?" + queryString) : "";
}

export const _setDndWidgetData = (data) => {
  try {
    localStorage.setItem("dndWidgetData_comply", JSON.stringify(data));
  } catch (e) {
    console.log("_setDndWidgetData Error- ", e)
  }
}
export const _dndWidgetData = () => {
  try {
    const sd = localStorage.getItem("dndWidgetData_comply");
    if (sd) {
      return JSON.parse(sd);
    }
    return null;
  } catch (e) {
    console.log("_setDndWidgetData Error- ", e)
    return null;
  }
}