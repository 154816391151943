import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Scrollbar from "src/components/scrollbar";
import { handleCheckboxClick } from "src/sections/Table";
import { _handleChangePage, PermissionCheck } from "src/utils/common";
import AllocationTableRow from "../allocation-table-row";
import SelectAsync from "src/components-form/shared/SelectAsync";
import { apiGetMethod, apiPostMethod, apiPutMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import PageHeader from "src/components-form/Common/PageHeader";
import {
  COMPANYROLES,
  PAGINATION_OBJECT,
  SUPERADMINROLES,
} from "src/core/constants";
import {
  _isCompanyAdminRole,
  _isSuperAdminRole,
  formatDateFn,
} from "src/shared/functions/string-conversion";
import { Helmet } from "react-helmet-async";
import { headCellTrainingAllocation } from "src/components/mui-common/table-ui/headCells";
import {
  _setPaginationObjectFn,
  createQueryString,
} from "src/shared/functions";
import TableHeadRow, {
  StyledTableNoData,
} from "src/components/mui-common/table-ui/TableHeadRow";
import TableRowsLoader from "src/components/mui-common/table-ui/TableRowsLoader";
import { isEmpty } from "src/shared/functions/isEmpty";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import TableToolbar from "src/components/mui-common/table-ui/TableToolbar";
import { removeItemByKeyValue } from "src/shared/functions/array-conversion";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 475,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export const ViewTrainingAllocation = () => {
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const isCompanyRole = _isCompanyAdminRole(loginUserInfo);
  const isSuperAdminRole = _isSuperAdminRole(loginUserInfo);
  const [selected, setSelected] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [allocation, setAllocation] = useState(false);
  const [editAllocation, setEditAllocation] = useState(false);
  const [userData, setUserData] = useState("");
  const [trainingData, setTrainingData] = useState();
  const [loader, setLoader] = useState(true);
  const [allocationId, setAllocationId] = useState("");
  const [companyName, setCompanyName] = useState(null);
  const [status, setStatus] = useState("all");
  const [form, setForm] = useState({
    training_id: "",
    assigned_to: "",
    company_id: loginUserInfo?.companyid ? loginUserInfo?.companyid : "",
  });
  const [errors, setErrors] = useState({
    assigned_to: "",
    training_id: "",
  });
  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);
  const [searchTerm, setSearchTerm] = useState("");
  const headCells = isCompanyRole
    ? removeItemByKeyValue(headCellTrainingAllocation, ["company_name"])
    : headCellTrainingAllocation;

  useEffect(() => {
    if (paginationObject.triggerApi) {
      apiCall();
    }
  }, [paginationObject.triggerApi]);
  useEffect(() => {
    try {
      _handleChangePage({}, 0, setPaginationObject,setSelected);
    } catch (e) {}
  }, [searchTerm, companyName, status]);

  const handleClick = (event, id) => {
    setSelected(handleCheckboxClick(event, id, selected));
  };
  const handleUserChange = (event, value) => {
    setUserData(event);
    setForm({
      ...form,
      assigned_to: event.value,
      company_id: event.company_id,
    });
    setErrors({
      ...errors,
      assigned_to: null,
    });
  };
  const handleCompanyChange = (event, value) => {
    setCompanyName(event);
    setSelected([]);
  };
  const handleTrainingChange = (event, value) => {
    setTrainingData(event);
    setForm({
      ...form,
      training_id: event.value,
    });
    setErrors({
      ...errors,
      training_id: null,
    });
  };
  const handleSubmit = () => {
    try {
      if (form?.assigned_to == "" || form?.training_id == "") {
        setErrors({
          ...errors,
          assigned_to:
            form?.assigned_to === ""
              ? "Please Select User"
              : errors?.assigned_to,
          training_id:
            form?.training_id === ""
              ? "Please Select Trainings"
              : errors?.training_id,
        });
        return false;
      }
      if (editAllocation === true) {
        apiPutMethod(
          `${allApiUrl?.COMPANY_PUT_TRAINING_ALLOCATION}/${allocationId}`,
          form
        )
          .then((data) => {
            toast.success(data.message);
            setAllocation(false);
            apiCall();
          })
          .catch((err) => {
            toast.error(err.data.message);
          });
      } else {
        apiPostMethod(
          isCompanyRole
            ? allApiUrl?.COMPANY_GET_TRAINING_ALLOCATION
            : allApiUrl?.ADMIN_TRAINING_ALLOCATION,
          form
        )
          .then((data) => {
            toast.success(data.message);
            setAllocation(false);
            apiCall();
          })
          .catch((err) => {
            toast.error(err?.data?.message);
          });
      }
      return true;
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };
  function apiCall(value) {
    setLoader(true);
    let queryObject = {
      search: searchTerm || "",
      pageNumber: paginationObject.page,
      limit: paginationObject.rowsPerPage,
      companyId: companyName?.value,
      training_status: status === "all" ? null : status,
    };
    const qryStr = createQueryString(queryObject);

    apiGetMethod(
      `${
        isCompanyRole
          ? allApiUrl?.COMPANY_GET_TRAINING_ALLOCATION
          : allApiUrl?.GET_TRAINING_ALLOCATION
      }${qryStr}`
    )
      .then((data) => {
        setDataFiltered(data.data);
        setPaginationObject((prevState) => {
          return _setPaginationObjectFn(prevState, data.meta);
        });
      })
      .catch((err) => {
        setDataFiltered([...[]]);
        setPaginationObject(PAGINATION_OBJECT);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  function getApiCall() {
    setLoader(true);
    apiGetMethod(
      `${allApiUrl?.COMPANY_GET_TRAINING_ALLOCATION}/${allocationId}`
    )
      .then((data) => {
        setForm(data.data);
        setUserData({
          value: data?.data?.assigned_to,
          label: data?.data?.user_name,
        });
        setTrainingData({
          value: data?.data?.training_id,
          label: data?.data?.training_name,
        });
        setLoader(false);
      })
      .catch((err) => {
        setLoader(true);
      });
  }
  useEffect(() => {
    if (editAllocation === true) {
      getApiCall();
    } else {
      setUserData();
      setTrainingData();
    }
  }, [editAllocation]);
  const handleChange = (event) => {
    setStatus(event.target.value);
    setSelected([]);
  };
  return (
    <>
      <Helmet>
        <title>
          Training Allocation | {process.env.REACT_APP_APPLICATION_NAME}{" "}
        </title>
      </Helmet>
      <Box>
        <div className="flexTop">
          <PageHeader
            header={"Training Allocation"}
            links={[
              {
                name: "Dashboard",
                path: "/",
              },
            ]}
          />
          {PermissionCheck("TrainingsAllocation", "create", loginUserInfo) && (
            <div>
              <Button
                variant="contained"
                color="inherit"
                startIcon={<AddIcon />}
                onClick={() => {
                  setAllocation(true);
                  setEditAllocation(false);
                }}
                className="blueButton"
              >
                Allocate Training
              </Button>
            </div>
          )}
        </div>
        <Card className="cardFix">
          <TableToolbar
            title={`Training-Allocation`}
            numSelected={selected.length}
            onSearch={(query) => setSearchTerm(query)}
            deleteUrl={allApiUrl?.DELETEMULTIPLE_TRAINING_ALLOCATION}
            selected={selected}
            setSelected={setSelected}
            apiCall={apiCall}
          />

          <div style={{ padding: "20px 15px 20px 15px" }}>
            <h6 className="mb-3">Filters:</h6>
            <Grid container columnSpacing={2}>
              {isSuperAdminRole && (
                <Grid item lg={3}>
                  <SelectAsync
                    isClearable
                    onChange={handleCompanyChange}
                    searchType="companyid"
                    selectedValue={companyName}
                    placeholder="Select Company"
                    className="asyncHeight training"
                  />
                </Grid>
              )}
              <Grid item lg={3}>
                <FormControl sx={{ width: "100%", marginBottom: "5px" }}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    label="Status"
                    onChange={handleChange}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"PENDING"}>Pending</MenuItem>
                    <MenuItem value={"INPROGRESS"}>Fail</MenuItem>
                    <MenuItem value={"COMPLETED"}>Pass</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <Scrollbar>
            <TableContainer sx={{ overflow: "unset" }}>
              <Table>
                <TableHeadRow
                  numSelected={selected.length}
                  rowCount={dataFiltered.length}
                  headCells={headCells}
                  permissionType="Attachments"
                  listData={dataFiltered}
                  setSelected={setSelected}
                />
                <TableBody>
                  {loader ? (
                    <TableRowsLoader
                      rowsNum={10}
                      columnNum={headCells.length}
                    />
                  ) : isEmpty(dataFiltered) ? (
                    <StyledTableNoData colSpan={headCells.length} />
                  ) : (
                    dataFiltered?.map((row, index) => {
                      return (
                        <AllocationTableRow
                          key={index}
                          userName={row?.user_name}
                          TrainingName={row?.training_name || row?.name}
                          lastAttempt={
                            row?.last_attempt
                              ? formatDateFn(row?.last_attempt)
                              : "-"
                          }
                          allocatedDate={formatDateFn(row?.created_at)}
                          company_name = {row?.company_name}
                          isSuperAdminRole = {isSuperAdminRole}
                          status={row.training_status}
                          id={row.id}
                          dataFiltered={dataFiltered}
                          selected={selected.indexOf(row.id) !== -1}
                          handleClick={(event) => handleClick(event, row.id)}
                          setAllocation={setAllocation}
                          setEditAllocation={setEditAllocation}
                          setAllocationId={setAllocationId}
                        />
                      );
                    })
                  )}
                  {allocation && (
                    <Modal
                      open={allocation}
                      onClose={() => setAllocation(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style} className="confirmModal">
                        <Grid container columnSpacing={2} rowSpacing={3}>
                          <Grid item lg={12}>
                            <h4
                              style={{
                                marginBottom: "20px",
                                fontWeight: "600",
                              }}
                            >
                              Allocate Training
                            </h4>

                            <FormControl fullWidth>
                              <InputLabel
                                style={{
                                  backgroundColor: "#fff",
                                  padding: "0 5px",
                                }}
                                shrink={true}
                              >
                                Select User
                              </InputLabel>
                              <SelectAsync
                                onChange={handleUserChange}
                                searchType={
                                  Object?.values(SUPERADMINROLES)?.includes(
                                    loginUserInfo?.roleid[0]
                                  )
                                    ? "user_id"
                                    : "selectCompanyUser"
                                }
                                selectedValue={userData}
                                placeholder="Select User"
                                error={errors?.assigned_to}
                                className="asyncHeight"
                              />
                            </FormControl>
                            {errors?.assigned_to && (
                              <div
                                style={{
                                  color: "#e74c3c",
                                  fontSize: "12px",
                                  paddingLeft: "12px",
                                  paddingTop: "4px",
                                }}
                              >
                                <span>{errors?.assigned_to}</span>
                              </div>
                            )}
                          </Grid>
                          <Grid item lg={12}>
                            <FormControl fullWidth>
                              <InputLabel
                                style={{
                                  backgroundColor: "#fff",
                                  padding: "0 5px",
                                }}
                                shrink={true}
                              >
                                Select Training
                              </InputLabel>
                              <SelectAsync
                                onChange={handleTrainingChange}
                                searchType={
                                  Object?.values(SUPERADMINROLES)?.includes(
                                    loginUserInfo?.roleid[0]
                                  )
                                    ? "trainings_list_acc_company"
                                    : "company_training_id"
                                }
                                selectedValue={trainingData}
                                placeholder="Select Training"
                                error={errors?.training_id}
                                className="asyncHeight"
                                formData={userData?.company_id}
                                isDisabled={
                                  userData == "" || userData == undefined
                                    ? true
                                    : false
                                }
                              />
                            </FormControl>
                            {errors?.training_id && (
                              <div
                                style={{
                                  color: "#e74c3c",
                                  fontSize: "12px",
                                  paddingLeft: "12px",
                                  paddingTop: "4px",
                                }}
                              >
                                <span>{errors?.training_id}</span>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "25px",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          <Button
                            className="blueButton"
                            onClick={handleSubmit}
                            sx={{ p: "8px 25px" }}
                          >
                            Save
                          </Button>
                          <Button
                            onClick={() => {
                              setAllocation(false);
                              setEditAllocation(false);
                            }}
                            className="blueButton cancel"
                            sx={{ p: "8px 25px" }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Box>
                    </Modal>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePaginationCompo
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            setSelected={setSelected}
          />
        </Card>
      </Box>
    </>
  );
};
