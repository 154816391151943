import { Box, TextField, Card, styled, Grid } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiGetMethod, apiPostMethod, apiPutMethod } from "src/api/rest";
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { allApiUrl } from "src/api/apiRoute";
import SelectAsync from "src/components-form/shared/SelectAsync";
import Button from "@mui/material/Button";
import PageHeader from "src/components-form/Common/PageHeader";
import AddIcon from "@mui/icons-material/Add";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Helmet } from "react-helmet-async";

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
}));

export default function AddCategory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    industry_id: "",
    is_active: true,
  });
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required!"),
    description: Yup.string().required("Description is required!"),
    industry_id: Yup.string().required("Industry is required!"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      try {
        setLoading(true);
        let obj = {
          name: values.name,
          description: values.description,
          industry_id: values.industry_id,
          is_active: values?.is_active,
        };
        location?.pathname === "/category/add-category"
          ? apiPostMethod(`${allApiUrl?.ADD_ADMIN_CATEGORY}`, obj)
              .then((data) => {
                toast.success(data?.message);
                navigate("/category");
                setLoading(false);
              })
              .catch((err) => {
                toast.error(err.data.message);

                setLoading(false);
              })
          : apiPutMethod(`${allApiUrl?.UPDATE_ADMIN_CATEGORY}/${id}`, obj)
              .then((data) => {
                toast.success(data?.message);
                navigate("/category");
                setLoading(false);
              })
              .catch((err) => {
                toast.error(err?.data.message);
                setLoading(false);
              });
      } catch (error) {
        toast.error(error.response.data.message);
        setLoading(false);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });
  function apiCategoryIdCall() {
    setLoader(true);
    apiGetMethod(`${allApiUrl?.ADMIN_CATEGORY}/${id}`)
      .then((data) => {
        setInitialValues(data.data[0]);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err?.data?.message);
        console.log("err", err);
      });
  }
  useEffect(() => {
    if (location?.pathname !== "/category/add-category") apiCategoryIdCall();
  }, [location]);
  const handleCancel = () => {
    navigate("/category");
  };
  return (
    <>
      <Helmet>
        <title> JSA Categories | {process.env.REACT_APP_APPLICATION_NAME} </title>
      </Helmet>
      <PageHeader
        header={
          location?.pathname === "/category/add-category"
            ? "Add JSA Category"
            : "Edit JSA Category"
        }
        links={[
          {
            name: "Dashboard",
            path: "/",
          },
          {
            name: "Category",
            path: "/category",
          },
        ]}
      />
      <Card elevation={3} sx={{ pt: "20px", mb: 3 }} className="cardFix">
        <CardHeader className="cardHeadBorder">
          <Title className="cardHead">
            {location?.pathname === "/category/add-category" ? "Add" : "Edit"}{" "}
            New JSA Category
          </Title>
        </CardHeader>
        <Grid container sx={{ display: "block" }}>
          <Grid>
            <Box p={4} height="100%">
              <FormikForm
                formik={formik}
                loader={loader}
                location={location}
                handleCancel={handleCancel}
              />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

function FormikForm({ formik, location, loader, handleCancel }) {
  const {
    values,
    errors,
    setFieldValue,
    submitForm,
    handleSubmit,
    isSubmitting,
    touched,
  } = formik;
  const [industry, setIndustry] = useState([]);
  const [industryError, setIndustryError] = useState(true);
  const handleChange = (event, value) => {
    setIndustry(event);
    setFieldValue("industry_id", event.value);
    setIndustryError(true);
  };
  function getIndustryDetails(id) {
    apiGetMethod(`${allApiUrl?.GET_INDUSTRY_BYID}/${id}`)
      .then((data) => {
        setIndustry({ value: id, label: data?.data[0]?.name });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  useEffect(() => {
    if (values.industry_id !== "") {
      getIndustryDetails(values.industry_id);
    }
  }, [values]);
  return (
    <FormikProvider value={formik}>
      <Form id="formIk" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid item md={6} sm={12}>
            <TextField
              fullWidth
              type="text"
              name="name"
              label="Name"
              variant="outlined"
              className="inputHeight"
              value={values.name}
              onChange={(e) => {
                setFieldValue("name", e.target.value);
              }}
              helperText={touched.name && errors && errors.name}
              error={Boolean(errors.name && touched.name)}
              sx={{ mb: 3 }}
            />
          </Grid>

          <Grid item md={6} sm={12}>
            <FormControl fullWidth>
              {values?.industry_id && (
                <InputLabel
                  style={{ backgroundColor: "#fff", padding: "0 5px" }}
                  shrink={true}
                >
                  Industry
                </InputLabel>
              )}
              <SelectAsync
                onChange={handleChange}
                searchType="industry"
                selectedValue={industry}
                placeholder="Select Industry"
                error={errors?.industry_id && industryError === false}
                className="asyncHeight"
              />
            </FormControl>
            {errors?.industry_id && industryError === false && (
              <div
                style={{
                  color: "#e74c3c",
                  fontSize: "12px",
                  paddingLeft: "12px",
                  paddingTop: "4px",
                }}
              >
                <span>{errors?.industry_id}</span>
              </div>
            )}
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField
            fullWidth
              multiline
              id="outlined-multiline-flexible"
              type="text"
              placeholder="description...."
              name="description"
              label="Description"
              variant="outlined"
              maxRows={8}
              sx={{ mb: 3 }}
              value={values.description}
              onChange={(e) => {
                setFieldValue("description", e.target.value);
              }}
              helperText={
                touched && touched.description && errors && errors.description
              }
              error={Boolean(errors.description && touched.description)}
              className="widthFix"
            />
          </Grid>
          {location.pathname !== "/category/add-category" && (
            <Grid item md={6} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.is_active}
                  label="Status"
                  onChange={(e) => {
                    setFieldValue("is_active", e.target.value);
                  }}
                  style={{
                    mb: 3,
                    height: "46px",
                    border: "1px solid #9194abde",
                  }}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item md={12} sm={12} sx={{ mt: 4 }}>
            <LoadingButton
              className="blueButton"
              type="button"
              color="primary"
              startIcon={location.pathname === "/category/add-category" ? <AddIcon /> : <SaveAsIcon />}
              loading={isSubmitting}
              loadingPosition="start"
              onClick={() => {
                submitForm();
                setIndustryError(false);
              }}
              variant="contained"
              sx={{ mr: "10px" }}
            >
              {location.pathname === "/category/add-category"
                ? " Add JSA Category"
                : "Update"}
            </LoadingButton>
            <Button className="blueButton cancel" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
