export const ROLES = {
  SUPER_ADMIN: 10,
  FORM_USER: 20,
  COMPANY: 30,
  COMPANY_MANAGER: 22,
  BACKOFFICE_FROM_CREATOR: 11,
  COMPANY_SUB_ADMIN: 21,
  BACKOFFICE_USER: 12,
};
export const COMPANYROLES = {
  COMPANY_SUB_ADMIN: 21,
  FORM_USER: 20,
  COMPANY: 30,
  COMPANY_MANAGER: 22,
};

export const SUPERADMINROLES = {
  BACKOFFICE_USER: 12,
  BACKOFFICE_FROM_CREATOR: 11,
  SUPER_ADMIN: 10,
};

export const ROLE_NAMES = {
  ADMIN: "Super Admin",
  USER: "Company User",
  COMPANY: "Company Admin",
  COMPANY_MANAGER: "Company Manager",
  BACKOFFICE_FROM_CREATOR: "Backoffice Form Creator",
  COMPANY_SUB_ADMIN: "Company Sub-Admin",
  BACKOFFICE_USER: "Backoffice Users",
};
export const SUPERROLESNAME = {
  ADMIN: "Super Admin",
  BACKOFFICE_FROM_CREATOR: "Backoffice Form Creator",
  BACKOFFICE_USER: "Backoffice Users",
};
export const COMPANYROLESNAME = {
  USER: "Company User",
  COMPANY: "Company Admin",
  COMPANY_MANAGER: "Company Manager",
  COMPANY_SUB_ADMIN: "Company Sub-Admin",
};
export const TRAINING_TYPES = {
  SYSTEM_GENERATED: "SYSTEM_GENERATED",
  MANUAL: "MANUAL",
};
export const TRAINING_STATUS = {
  PENDING: "PENDING",
  PASSED: "PASSED",
  TEST_NOT_GIVEN: "TEST_NOT_GIVEN",
  FAILED: "FAILED",
  COMPLETED: "COMPLETED"
};
export const DEFAULT_ROW_PER_PAGE = 10;
export const PAGINATION_OBJECT = {
  page: 0,
  rowsPerPage: DEFAULT_ROW_PER_PAGE,
  totalItems: 0,
  totalPages: 0,
  triggerApi: false
}
export const FORM_CATALOGUE_TABS = {
  TEMPLATE: "TEMPLATE",
  FORM: "FORM",
  TRAINING_FORM: "TRAINING_FORM",
  JSA_CODE: "JSA_CODE",
}
export const FILTER_FORM_TYPE = {
  FORM: "JSA_FORM",
  TRAINING_FORM: "TRAINING_FORM",
  JSA_CODE: "JSA_CODE",
}