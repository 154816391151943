import React from "react";
import {pascalCaseToSpaceSeparated} from "../../../shared/functions/string-conversion";
import InputWithInsert from "../../shared/InputWithInsert";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {MuiTelInput} from "mui-tel-input";

export const SetOptionsFields = ({editForm, editElementProp, selectedWidgets}) => {
  return (
    <>
      {editForm.fields &&
        Object.keys(editForm.fields).length > 0 &&
        Object.keys(editForm.fields).map((field) => {
          return (
            <div
              className="form-group row align-items-center"
              key={`item3__${editForm.fields[field].id}`}
            >
              {(editForm.element === "Signature" && editForm.fields[field].name === "Name") ? (
                <InputWithInsert
                  labelTitle={editForm.fields[field].name}
                  selectedWidgets={selectedWidgets}
                  inputValProp={editForm.fields[field].value}
                  showEyeIcon={false}
                  onChange={(val) => {
                    editElementProp(val, [
                      "fields",
                      field,
                      "value",
                    ]);
                  }}
                />
              ) : (
                <>
                  <div className="">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      name={editForm.fields[field].id}
                      value={editForm.fields[field].showField}
                      checked={Boolean(editForm.fields[field].showField)}
                      onChange={(e) => {
                        editElementProp((e.target.checked), [
                          "fields",
                          field,
                          "showField",
                        ])
                      }}
                    />&nbsp;
                    <small>{pascalCaseToSpaceSeparated(editForm.fields[field].name)}:</small>
                  </div>
                  <div className="">
                    {editForm.fields[field].name === "StartDate" ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          value={editForm.fields[field].value ? dayjs(editForm.fields[field].value) : null}
                          slotProps={{textField: {size: 'small', fullWidth: true}}}
                          format={`MM/DD/YYYY`}
                          onChange={(e) => {
                            editElementProp(e.format(), [
                              "fields",
                              field,
                              "value",
                            ]);
                          }}
                        />
                      </LocalizationProvider>
                    ) : (editForm.fields[field].name === "ContactPhone" || editForm.fields[field].name === "EmergencyPhone") ? (
                      <MuiTelInput
                        fullWidth
                        forceCallingCode
                        defaultCountry={"US"}
                        size="small"
                        value={editForm.fields[field].value}
                        onChange={(value, info) => {
                          editElementProp(value, [
                            "fields",
                            field,
                            "value",
                          ]);
                        }}
                      />
                    ) : (
                      <input
                        className="custom-condivol-input form-control"
                        type="text"
                        value={editForm.fields[field].value}
                        onChange={(e) => {
                          editElementProp(e.target.value, [
                            "fields",
                            field,
                            "value",
                          ]);
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          );
        })}
    </>

  )
}