import React, { useEffect, useState } from 'react';
import * as markerjs2 from 'markerjs2';
import AWS from 'aws-sdk';
import { Buffer } from "buffer";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import UploadIcon from '@mui/icons-material/Upload';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from '@mui/material/DialogContent';
import {BootstrapDialog} from "../ui/BootstrapDialog";
import toastMessages from "../../ToastMessage";
import { toast } from 'react-toastify';

const URL_EXPIRATION_SECONDS = 30
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_S3_BUCKET_SECRET_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_S3_BUCKET_ACCESS_KEY_ID,
  region: process.env.REACT_APP_AWS_S3_BUCKET_REGION,
  signatureVersion: 'v4',
});

function ImageEditorModal(
  {
    file,
    setSelectedFile,
    modalIsOpen,
    setIsOpen,
    saveData
  }) {
  let imgRef = React.createRef();
  const [uploading, setUploading] = useState(false);
  const [imageBlob, setImageBlob] = useState("");
  const s3 = new AWS.S3();
  function showMarkerArea() {
    if (imgRef.current !== null) {
      const markerArea = new markerjs2.MarkerArea(imgRef.current);
      markerArea.settings.displayMode = "popup";
      markerArea.addEventListener('render', event => {
        if (imgRef.current) {
          imgRef.current.src = event.dataUrl;
        }
      });
      markerArea.show();
    }
  }
  function closeModal() {
    setIsOpen(false);
    setSelectedFile({});
    setUploading(false)
  }

  async function uploadToS3() {
    try {
      if (imgRef?.current?.src?.length < 10) {
        return;
      }
      setUploading(true)
      const buf = Buffer.from(imgRef.current.src.replace(/^data:image\/\w+;base64,/, ""), 'base64')
      const params = {
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
        Key: `${file.name}`,
        Body: buf,
        Expires: URL_EXPIRATION_SECONDS,
        ContentEncoding: 'base64',
        ContentType: 'image/jpeg'
      };
      const response = await s3.upload(params).promise();
      if (response.Key) {
        setUploading(false)
        saveData(response.Key)
        toast.success(`Uploaded SuccessFully`)
        closeModal();
      } else {
        toast.error(response)
      }
    } catch (err) {
      toast.error(err.message !== undefined ? err.message : err)
    }
  }
  useEffect(() => {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setImageBlob(reader.result);
      };
      reader.onerror = function (error) {
        toast.error(error.message !== undefined ? error.message : error)
      };
    } catch (e) {}
  }, [])

  return (
    <BootstrapDialog
      onClose={closeModal}
      open={modalIsOpen}
      width={`auto`}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">Preview</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div className="previewImg">
          <img
            ref={imgRef}
            src={imageBlob}
            alt="sample"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          autoFocus
          disabled={uploading}
          loading={uploading}
          endIcon={<UploadIcon />}
          loadingPosition="end"
          variant="contained"
          className="my-2 mx-3"
          onClick={uploadToS3}
        >
          <span>Upload</span>
        </LoadingButton>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default ImageEditorModal;