export const headCellsSavedEntries = [
  {
    id: "checkbox",
    numeric: false,
    disablePadding: false,
    label: "",
    align: "left",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Title",
    align: "left",
  },
  {
    id: "user_name",
    numeric: false,
    disablePadding: false,
    label: "User Name",
    align: "left",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Submitted Date",
    align: "left",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    align: "left",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
];
export const headCellsAllocatedTraining = [
  { id: "training_name", label: "Training Name" },
  { id: "cert_name", label: "Certificate Name" },
  { id: "validity_period", label: "Validity Period" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions", align: "center" },
];
export const headCellsAllocatedProjectForm = [
  { id: "project_name", label: "Project Name", isDate: false },
  { id: "form_name", label: "Form Name", isDate: false },
  { id: "allocated_date", label: "Allocated Date", isDate: true },
  { id: "due_date", label: "Due Date", isDate: true },
  { id: "updated_at", label: "Last Updated at", isDate: true },
  { id: "status", label: "Status", isDate: false },
  { id: "actions", label: "Actions", align: "center", isDate: false },
];
export const headCellCategoryCatalogue = [
  { id: "checkbox", label: "", isDate: false },
  { id: "name", label: "Name", isDate: false },
  { id: "description", label: "Description", isDate: false },
  { id: "industry_name", label: "Industry Name", isDate: false },
  { id: "created_at", label: "Created At", isDate: true },
  { id: "status", label: "Status", isDate: false },
  { id: "actions", label: "Actions", isDate: false },
];
export const headCellCompany = [
  { id: "checkbox", label: "" },
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "industry", label: "Industry" },
  { id: "phone number", label: "Phone Number" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions" },
];
export const headCellMyCertificate = [
  { id: "certificate_name", label: "Certificate", isDate: false },
  { id: "user_name", label: "Trainee", isDate: false },
  { id: "training_name", label: "Training", isDate: false },
  { id: "created_at", label: "Created At", isDate: true },
  { id: "validityperiod", label: "Validity Period", isDate: false },
  { id: "status", label: "Status", isDate: false },
  { id: "actions", label: "Action", isDate: false },
];
export const headCellMyTraining = [
  { id: "date_of_training", label: "Date of Training", isDate: true },
  { id: "user_name", label: "User Name", isDate: false },
  { id: "certificate_name", label: "Certificate Name", isDate: false },
  { id: "training_name", label: "Training Name", isDate: false },
  { id: "total_marks", label: "Achieved Marks/Total Marks", isDate: false },
  { id: "expiry_date", label: "Expiry Date", isDate: false },
  { id: "actions", label: "Action", align: "center", isDate: false },
];
export const headCellProjectAll = [
  { id: "checkbox", label: "", isDate: false },
  { id: "created_at", label: "Created Date", isDate: true },
  { id: "project_name", label: "Project Name", isDate: false },
  { id: "client", label: "Client", isDate: false },
  { id: "crew_data", label: "Crew Members", isDate: false },
  { id: "supervisor_name", label: "Supervisor", isDate: false },
  { id: "updated_at", label: "Updated Date", isDate: true },
  { id: "status", label: "Status", isDate: false },
  { id: "actions", label: "Actions", isDate: false },
];
export const headCellUser = [
  { id: "checkbox", label: "" },
  { id: "user_name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "business", label: "Company" },
  { id: "role", label: "Role" },
  { id: "created_at", label: "Created At" },
  { id: "updated_at", label: "Updated At" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions", align: "center" },
];
export const headCellTrainingAllocation = [
  { id: "date", label: "Allocated Date and time" },
  { id: "employee", label: "User Name" },
  { id: "training", label: "Training Name" },
  { id: "company_name", label: "Company Name" },
  { id: "lastAttempt", label: "Last Attempt Date and Time" },
  { id: "training_status", label: "Status" },
];
export const headCellTrainingHistory = [
  { id: "checkbox", label: "" },
  { id: "training_done_date", label: "Date of Training" },
  { id: "user_id", label: "User Name" },
  { id: "company_name", label: "Company Name" },
  { id: "title", label: "Certificate Name" },
  { id: "training_name", label: "Training Name" },
  { id: "status", label: "Expiry Date" },
  { id: "actions", label: "Actions" },
];
export const headCellTrainingsAttempts = [
  { id: "training_done_date", label: "Date and Time of Attempt", isDate: true },
  { id: "user_name", label: "User Name", isDate: false },
  { id: "company_name", label: "Company", isDate: false },
  { id: "training_name", label: "Training Name", isDate: false },
  { id: "total_marks", label: "Total Marks", isDate: false },
  { id: "obtained_marks", label: "Achieved Marks", isDate: false },
  { id: "status", label: "Status", isDate: false },
  { id: "actions", label: "Actions", isDate: false },
];
export const headCellTrainingsCatalogue = [
  { id: "checkbox", label: "" },
  { id: "name", label: "Training Name" },
  { id: "industry_name", label: "Industry" },
  { id: "form_name", label: "Linked Form" },
  { id: "totalmarks", label: "Total Marks" },
  { id: "passingmarks", label: "Passing Marks" },
  { id: "certificatename", label: "Certificate Name" },
  { id: "validityperiod", label: "Validity Period" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions" },
];
export const headCellUserTrainingsCatalogue = [
  { id: "training_name", label: "Training Name" },
  { id: "total_marks", label: "Total Marks" },
  { id: "passing_marks", label: "Passing Marks" },
  { id: "cert_name", label: "Certificate Name" },
  { id: "validity_period", label: "Validity Period" },
  { id: "actions", label: "Actions" },
];
export const headCellIndustry = [
  { id: "checkbox", label: "" },
  { id: "name", label: "Name" },
  { id: "created_at", label: "Created At" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions" },
];
export const headCellProjectFiles = [
  { id: "file", label: "File" },
  { id: "created_at", label: "Created At" },
  { id: "actions", label: "Action", center: "center" },
];
export const headCellProjectArchived = [
  { id: "title", label: "Form Name" },
  { id: "created_at", label: "Allocated Date" },
  { id: "updated_at", label: "Due Date" },
  { id: "training_required", label: "Training Required" },
  { id: "Submitted_by", label: "Submitted By" },
  { id: "actions", label: "Actions", center: "center" },
];
export const headCellProjectForm = [
  { id: "form_name", label: "Form Name" },
  { id: "last_used", label: "Allocated Date" },
  { id: "last_used", label: "Due Date" },
  { id: "updated_at", label: "Last Updated at" },
  { id: "training_required", label: "Training Required" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions", center: "center" },
];
export const headCellProjectActiveForms = [
  { id: "form", label: "Form Name" },
  { id: "user", label: "User Name" },
  { id: "number", label: "Allocated Date" },
  { id: "date", label: "Due Date" },
  { id: "status", label: "Training Required", center: "center" },
  { id: "actions", label: "Actions", center: "center" },
];
export const headCellProjectActiveTraining = [
  { id: "allocatedDate", label: "Allocated Date and time" },
  { id: "training_name", label: "Training Name" },
  { id: "linkedFormName", label: "Linked Form Name" },
  { id: "userName", label: "User Name" },
  { id: "last_attempts", label: "Last Attempt Date and Time" },
  { id: "status", label: "Training Status", center: "center" },
  { id: "actions", label: "Actions", center: "center" },
];
export const headCellLinkedCrew = [
  { id: "title", label: "Form Name" },
  { id: "created_at", label: "Allocated Date" },
  { id: "updated_at", label: "Due Date" },
  { id: "training_required", label: "Training Required" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions", center: "center" },
];
export const headCellLinkedForm = [
  { id: "user_name", label: "User Name" },
  { id: "mobile", label: "Mob. Number" },
  { id: "email", label: "Email" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions", center: "center" },
];
export const headCellsAllForms = [
  {
    id: "checkbox",
    numeric: false,
    disablePadding: false,
    label: "",
    align: "left",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Title",
    align: "left",
  },
  {
    id: "training_name",
    numeric: false,
    disablePadding: false,
    label: "Training Name",
    align: "left",
  },
  {
    id: "industry_name",
    numeric: false,
    disablePadding: false,
    label: "Industry",
    align: "left",
  },
  {
    id: "category_name",
    numeric: false,
    disablePadding: false,
    label: "Category",
    align: "left",
  },
  {
    id: "form_type",
    numeric: false,
    disablePadding: false,
    label: "Form Type",
    align: "left",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    align: "left",
  },
  {
    id: "updated_at",
    numeric: false,
    disablePadding: false,
    label: "Updated At",
    align: "left",
  },
  {
    id: "entry_count",
    numeric: false,
    disablePadding: false,
    label: "Saved Entries",
    align: "left",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    align: "left",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
];
