import axios from "axios";
import { allApiUrl } from "./apiRoute";
import { COMPANYROLES, SUPERADMINROLES } from "src/core/constants";
import { PermissionCheck } from "src/utils/common";

const requestTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const userListData = (
  cancelToken = null,
  token = null,
  loginUserInfo,
  page,
  rowsPerPage
) => {
  let userListData;
  return new Promise((resolve, reject) => {
    const configData = generateConfigData(
      cancelToken,
      loginUserInfo.token !== undefined ? loginUserInfo.token : token
    );
    if (
      loginUserInfo?.roleid
        ? Object?.values(COMPANYROLES)?.includes(loginUserInfo?.roleid[0])
          ? PermissionCheck("CompanyUsers", "read", loginUserInfo)
          : PermissionCheck("Users", "read", loginUserInfo)
        : PermissionCheck("Users", "read", loginUserInfo)
    ) {
      if (loginUserInfo?.companyid === "") {
        userListData = axios.get(
          `${apiEndpoint}${allApiUrl?.USERS_LIST}?pageNumber=${page}&limit=${rowsPerPage}`,
          configData
        );
      } else {
        userListData = axios.get(
          `${apiEndpoint}${allApiUrl?.COMPANY_USER_LIST}?companyId=${loginUserInfo?.companyid}&pageNumber=${page}&limit=${rowsPerPage}`,
          configData
        );
      }
    }

    resolve(userListData);
  });
};
export const formListData = (
  cancelToken = null,
  token = null,
  loginUserInfo,
  page,
  rowsPerPage
) => {
  let formListData;
  return new Promise((resolve, reject) => {
    const configData = generateConfigData(
      cancelToken,
      loginUserInfo.token !== undefined ? loginUserInfo.token : token
    );
    if (PermissionCheck("Forms", "read", loginUserInfo)) {
      if (Object.values(SUPERADMINROLES)?.includes(loginUserInfo?.roleid[0])) {
        formListData = axios.get(
          `${apiEndpoint}${allApiUrl?.JSA_FORMS}?pageNumber=${page}&limit=${rowsPerPage}&form_type=JSA_FORM`,
          configData 
        );
      } else {
        formListData = axios.get(
          `${apiEndpoint}${allApiUrl?.FILTERED_FORMLISTING}?pageNumber=${page}&limit=${rowsPerPage}&form_type=JSA_FORM&industryid=${loginUserInfo?.industryId}`,
          configData
        );
      }
    }

    resolve(formListData);
  });
};
export const apiGetDashboardStats = (
  cancelToken = null,
  token = null,
  loginUserInfo
) => {
  return new Promise((resolve, reject) => {
    const configData = generateConfigData(
      cancelToken,
      loginUserInfo.token !== undefined ? loginUserInfo.token : token
    );
    let dahboardCount;
    if (Object?.values(SUPERADMINROLES)?.includes(loginUserInfo?.roleid[0])) {
      dahboardCount = axios.get(
        `${apiEndpoint}${allApiUrl?.ADMIN_DASHBOARD}`,
        configData
      );
    } else if (
      Object?.values(COMPANYROLES)?.includes(loginUserInfo?.roleid[0])
    ) {
      dahboardCount = axios.get(
        `${apiEndpoint}${allApiUrl?.COMPANY_DASHBOARD}`,
        configData
      );
    }
    resolve(dahboardCount);
  });
};
export const apiGetMethod = (url, cancelToken = null, token = null) => {
  return new Promise((resolve, reject) => {
    const configData = generateConfigData(cancelToken, token);

    axios
      .get(`${apiEndpoint}${url}`, configData)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const apiPostMethod = (
  url,
  data,
  cancelToken = null,
  ct = null,
  token = ""
) => {
  return new Promise((resolve, reject) => {
    const configData = generateConfigData(cancelToken, token);

    axios
      .post(`${apiEndpoint}${url}`, data, configData)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const apiDeleteMethod = (url, cancelToken = null, token = null) => {
  return new Promise((resolve, reject) => {
    const configData = generateConfigData(cancelToken, token);

    axios
      .delete(`${apiEndpoint}${url}`, configData)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const apiPutMethod = (
  url,
  data,
  cancelToken = null,
  ct = null,
  token = ""
) => {
  return new Promise((resolve, reject) => {
    const configData = generateConfigData(cancelToken, token);

    axios
      .put(`${apiEndpoint}${url}`, data, configData)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

const generateConfigData = (cancelToken = null, token = null) => {
  let header = {
    timeZone: requestTimeZone,
  };
  const clsData = JSON.parse(
    localStorage.getItem(
      `persist:${process.env.REACT_APP_STORAGE_PERSIST_CONFIG_KEY}`
    )
  );

  const bearerToken = token ? token : (JSON.parse(clsData?.loginReducer))?.loginUserToken;

  if (bearerToken) {
    header.Authorization = `Bearer ${bearerToken}`;
  }

  let configData = { headers: header };
  if (cancelToken && Object.keys(cancelToken).length) {
    configData.cancelToken = cancelToken;
  }
  return configData;
};
