import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import AccountPopover from "src/layouts/dashboard/common/account-popover";
import { useSelector } from "react-redux";
import Iconify from "../../components/iconify";

const drawerWidth = 240;

function DrawerAppBar(props) {
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );

  const { window, navItems } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <AccountPopover />
      <Divider />
      <List>
        {navItems?.map((item, idx) => (
          <ListItem key={`navItemList_${idx}`} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={(e) => item.onClick(e, item)}
              href={item.href}
            >
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const ButtonsList = ({ navItems }) => {
    return (
      <>
        {navItems?.map((item, idx) => (
          <Button
            variant="contained"
            color="inherit"
            startIcon={item?.icon ? item?.icon : <></>}
            key={`navItemListBtn_${idx}`}
            className={`blueButton editForm ms-1`}
            onClick={(e) => item.onClick(e, item)}
            href={item.href}
          >
            {item.label}
          </Button>
        ))}
      </>
    );
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box
        sx={{
          display: { xs: "none", sm: "block" },
          textAlign: "right",
          marginTop: "0",
        }}
      >
        <ButtonsList navItems={navItems} />
      </Box>
    </>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
  navItems: PropTypes.array,
};

export default DrawerAppBar;
