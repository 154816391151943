import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { allApiUrl } from "src/api/apiRoute";
import { apiGetMethod } from "src/api/rest";
import Loader from "src/components/Loader";
import { ROLE_NAMES } from "src/core/constants";
import { saveLoginUserInfo, saveLoginUserToken } from "src/redux/action/login";
import { useRouter } from "src/routes/hooks";

export const SuccessPaymentPage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const sessionId = queryParams.get("session_id");
  const router = useRouter();
  const [auth, setAuth] = useState({});
  const dispatch = useDispatch();
  const verificationApi = async () => {
    const response = await apiGetMethod(
      `${allApiUrl?.PAYMENT_STATUS}?session_id=${sessionId}`
    )
      .then((data) => {
        const userInfo = data?.data;
        if (
          userInfo &&
          (typeof userInfo.roleid === "string" ||
            typeof userInfo.roleid === "number")
        ) {
          userInfo.roleid = [userInfo.roleid];
        }
        try {
          userInfo.isUser =
            userInfo?.roleDetails?.name.toLowerCase() === ROLE_NAMES.USER;
        } catch (err) {
          toast.error(err?.data?.message);
        }

        setAuth(userInfo);
        dispatch(saveLoginUserInfo(userInfo));
        dispatch(saveLoginUserToken(userInfo.token));
        localStorage.setItem("_Temp_User_Type", userInfo?.roleDetails?.name);
        toast.success(response?.message);
        setTimeout(() => {
          router.replace("/");
        }, 100);
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        router.replace("/login");
      });
  };
  useEffect(() => {
    if (sessionId) {
      verificationApi();
    }
  }, [sessionId]);
  return (
    <div>
      <Loader />
    </div>
  );
};
