import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";

const GuestAuth = ({ children }) => {
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const location = useLocation();
  return loginUserInfo?.roleid ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <>{children}</>
  );
};

export default GuestAuth;
