import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useAuth from "src/hooks/useAuth";
import { useRouter } from "../../routes/hooks";
import axios from "axios";
import { allApiUrl } from "src/api/apiRoute";
import { useState } from "react";
import debounce from "lodash/debounce";
import { FormControl, InputLabel } from "@mui/material";
import { FormHelperText, MenuItem, Select } from "@mui/material";
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";

const  loginSx = {
  color:"#ee7000",
  fontWeight:"100",
  textDecoration:"none",
  "&:hover":{
    color:"#ee7000"
  }
}

export default function ForgotPasswordView() {
  const { forgotPassword } = useAuth();
  const navigate =  useNavigate()
  const [CompanyData, setCompanyData] = useState([]);
  const [count, setCount] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [company, setCompany] = useState();
  const router = useRouter();
  const initialValues = {
    email: "",
    companyid: "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email address")
      .required("Email is required!"),
    companyid:
      CompanyData?.length > 1
        ? Yup.string().required("Company is required")
        : Yup.string(),
  });
  const handleFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await forgotPassword(values.email, companyId);
    } catch (e) {
      toast.error(e.data.message);
    } finally {
      setSubmitting(false);
    }
  };
  let controller = null;
  const getCompanyDetails = async (value) => {
    if (controller) {
      controller.abort();
    }
    controller = new AbortController();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}${allApiUrl?.EMAIL_DETAILS}/${value}`,
        {
          signal: controller.signal,
        }
      );
      setCount(response.data?.totalCount);
      if (response?.data?.data) {
        setCompanyData(response.data.data);
        setCompanyId(response.data.data[0].company_id);
        setDisabled(true);
        if (response?.data?.data?.length > 1) {
          toast.success(
            "Same email is registered with multiple companies, select company and try login again"
          );
        }
      } else {
        setCompanyData();
        setDisabled(true);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        console.error("Fetch error:", error);
      }
    }
  };

  const debouncedGetEmailDetails = debounce(async (value) => {
    setLoading(true);
    setDisabled(true);
    try {
      await getCompanyDetails(value);
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  }, 800);
  const renderForm = (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({
        isSubmitting,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={3} sx={{ my: 3 }}>
            <TextField
              autoComplete={``}
              name="email"
              label="Email address"
              onBlur={handleBlur}
              value={values.email}
              onChange={(e) => {
                setFieldValue("email", e.target.value);
                debouncedGetEmailDetails(e.target.value);
              }}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
            {count > 1 ? (
              <FormControl>
                <InputLabel
                  id="dropdown-label"
                  style={{ backgroundColor: "#fff", padding: "0 5px" }}
                >
                  Select Company
                </InputLabel>
                <Select
                  labelId="dropdown-label"
                  id="dropdown"
                  value={company}
                  onChange={(e) => {
                    setFieldValue("companyid", e?.target.value);
                    setCompanyId(e?.target.value);
                  }}
                >
                  {CompanyData.map((option) => (
                    <MenuItem
                      key={option.company_name}
                      value={option.company_id}
                    >
                      {option.company_name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {touched.companyid && errors.companyid}
                </FormHelperText>
              </FormControl>
            ) : (
              ""
            )}
          </Stack>

          <LoadingButton
            className="blueButton"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="inherit"
            loading={isSubmitting}
          >
            Send Email
          </LoadingButton>

          <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ my: "13px" }}
              >
                  <div
                  variant="subtitle2"
                  onClick={() => navigate("/login")}
                  className="loginfx"
                >
                  Back To Login
                </div>
              </Stack>
        </form>
      )}
    </Formik>
  );

  return (
    <Box height="100%">
      <Grid container height="100%">
        <Grid item lg={6} className="imageSection">
          <span className="dot1">
            <img src="/assets/background/dots.svg" style={{ width: "200px" }} />
          </span>
          <span className="dot2">
            <img src="/assets/background/dots.svg" style={{ width: "200px" }} />
          </span>
          <span className="blob1">
            <img
              src="/assets/background/blob1.svg"
              style={{ width: "400px" }}
            />
          </span>
          <span className="blob2">
            <img
              src="/assets/background/blob1.svg"
              style={{ width: "400px" }}
            />
          </span>
          <img
            src="/assets/img/forgotpass.svg"
            style={{ height: "650px", width: "650px" }}
            alt="login"
          />
        </Grid>
        <Grid item lg={6}>
          <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
            <Card
              sx={{
                p: 5,
                width: 1,
                maxWidth: 500,
              }}
            >
              <Typography variant="h4" sx={{ textAlign: "center" }}>
                Forgot Password
              </Typography>

              {renderForm}
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
