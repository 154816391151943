import PropTypes from "prop-types";

import Box from "@mui/material/Box";

import { useResponsive } from "src/hooks/use-responsive";

import { NAV, HEADER } from "./config-layout";
import { useEffect, useState } from "react";

// ----------------------------------------------------------------------

const SPACING = 8;

export default function Main({ children, sx, ...other }) {
  const lgUp = useResponsive("up", "lg");
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  useEffect(() => {
    setMobileSidebarOpen(
      !(
        Math.max(document.documentElement.clientWidth) > 991 &&
        isMobileSidebarOpen == false
      )
    );
    const colorChange = () =>
      setMobileSidebarOpen(
        !(
          Math.max(document.documentElement.clientWidth) > 991 &&
          isMobileSidebarOpen == false
        )
      );
    window.addEventListener("resize", colorChange);
    return () => window.removeEventListener("resize", colorChange);
  }, []);
  return (
    <Box
      component="main"
      className="mainDashboard"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: "flex",
        flexDirection: "column",
      }}
      {...other}
    >
      {children}
    </Box>
  );
}

Main.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};
