import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { fShortenNumber } from "src/utils/format-number";

// ----------------------------------------------------------------------

export default function AppWidgetSummary({
  title,
  total,
  icon,
  color = "#fff",
  sx,
  ...other
}) {
  return (
    <Card
      component={Stack}
      spacing={3}
      direction="row"
      sx={{
        px: 3,
        py: 5,
        borderRadius: 2,
        ...sx,
      }}
      className="card dashboard"
      {...other}
    >
      {icon && <Box >{icon}</Box>}

      <Stack spacing={0.5} className="marginLeft">
        <Typography variant="h4" className="cardHeading">
          {total == 0 ? 0 : fShortenNumber(total)}
        </Typography>

        <Typography variant="subtitle2" className="cardText" sx={{color: "#000", fontSize: "16px" }}>
          {title}
        </Typography>
      </Stack>
    </Card>
  );
}

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  title: PropTypes.string,
  total: PropTypes.number,
};
