
import { Helmet } from 'react-helmet-async';
import { LoginView } from 'src/sections/login';

export default function LoginPage() {

  return (
    <>
      <Helmet>
        <title>{`Login | ${process.env.REACT_APP_APPLICATION_NAME}`}</title>
      </Helmet>

      <LoginView />
    </>
  );
}
