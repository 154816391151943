import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { useNavigate } from "react-router-dom";
import TableNoData from "../table-no-data";
import UserTableRow from "../user-table-row";
import UserTableHead from "../user-table-head";
import TableEmptyRows from "../table-empty-rows";
import UserTableToolbar from "../user-table-toolbar";
import { emptyRows } from "../utils";
import { apiGetMethod } from "src/api/rest";
import Loader from "src/components/Loader";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import PageHeader from "src/components-form/Common/PageHeader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import Scrollbar from "src/components/scrollbar";
import { Helmet } from "react-helmet-async";

export default function UsersRolesView() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loader, setLoader] = useState(true);
  const [expandedIndex, setExpandedIndex] = useState(0); // State to track expanded accordion
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();

  const styles = {
    tableContainer: {
      maxHeight: 400, 
    },
    tableBodyContainer: {
      maxHeight: 350,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "10px",
      },
    },
    stickyHeader: {
      position: "sticky",
      top: 0,
      backgroundColor: "#fff", 
      zIndex: 1, 
    },
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const [dataFiltered, setDataFiltered] = useState([]);
  useEffect(() => {
    apiCall();
  }, []);

  function apiCall() {
    setLoader(true);
    apiGetMethod("/auth/getRolesList")
      .then((data) => {
        setLoader(false);
        setDataFiltered(data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  }

  const notFound = !dataFiltered.length && !!filterName;
  return (
    <>
      <Helmet>
        <title> User Roles | {process.env.REACT_APP_APPLICATION_NAME} </title>
      </Helmet>
      <Box>
        <PageHeader
          header={"User Roles"}
          links={[
            {
              name: "Dashboard",
              path: "/",
            },
          ]}
        />

        {loader ? (
          <Loader />
        ) : (
          <Card className="cardFix">
            <UserTableToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              title="User Roles"
            />
            {dataFiltered?.map((row, index) => (
              <Accordion
                key={index}
                expanded={expandedIndex === index}
                onChange={() =>
                  setExpandedIndex(expandedIndex === index ? null : index)
                }
                className={expandedIndex === index ? "expandedAccordion" : ""}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div className="accordionHead">
                    <Typography>{row?.name}</Typography>
                    {row?.status}
                    <Button
                      className="blueButton"
                      onClick={() => navigate(`/edit-role/${row?.roleid}`)}
                      sx={{ mr: 1, minWidth: "0px", padding: "7px 8px" }}
                    >
                      <EditIcon sx={{ fontSize: "16px" }} />
                    </Button>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Scrollbar>
                    <TableContainer sx={styles.tableContainer}>
                      <Table>
                        <TableHead sx={styles.stickyHeader}>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell component="th">Create</TableCell>
                            <TableCell component="th">Read</TableCell>
                            <TableCell component="th">Update</TableCell>
                            <TableCell component="th">Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody sx={styles.tableBodyContainer}>
                          <UserTableRow
                            key={index}
                            name={row.name}
                            permissions={row.permissions}
                            status={row.status}
                            roleid={row.roleid}
                            selected={selected.indexOf(row.roleid) !== -1}
                            handleClick={(event) =>
                              handleClick(event, row.roleid)
                            }
                          />
                          {notFound && <TableNoData query={filterName} />}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                </AccordionDetails>
              </Accordion>
            ))}
          </Card>
        )}
      </Box>
    </>
  );
}
