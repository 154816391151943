import { useState, memo, useEffect } from "react";
import "src/components-form/ReactFormBuilder/ReactFormBuilderStyle.css";
import { deepClone } from "../../shared/functions";
 import { isEmpty } from "../../shared/functions/isEmpty";
import { ImageAvatar } from "../Common/ui/ImageAvatar";
import Typography from '@mui/material/Typography';   

function HeaderPreview({
  hideIcons,
  activePage = undefined,
  selectedWidgets,
  setSelectedWidget,
  setFormSettings,
  form_settings,
  setIsOpen,
  showPageNo=true
}) {
  useEffect(() => {
    if (!isEmpty(selectedWidgets)) {
      let tempCount = 1;
      selectedWidgets?.map((row) => {
        if (row.childComponent[0].element === "PageBreak") {
          tempCount += 1;
        }
      });

      let newArray = [];
      if (tempCount > 1 && form_settings.title.pages && form_settings.title.pages.length !== tempCount ) {
        if (tempCount > 1) {
          newArray = form_settings.title.pages == [] ? [...new Array(tempCount)] : form_settings.title.pages;
          newArray = [...new Array(tempCount)]?.map((i, index) => {
            return (newArray[index] !== undefined) ? newArray[index] : { label: `Page ${index + 1}` }
          });
        } else {
          newArray = [];
        }
        form_settings.title.pages = newArray;
        setFormSettings(form_settings);
      }
      if (tempCount === 1 && form_settings.title.pages && form_settings.title.pages.length !== 0) {
        form_settings.title.pages = [];
        setFormSettings(form_settings);
      }
    }
  }, [selectedWidgets]);

  return (
    <HeaderTitleHtml activePage={activePage} form_settings={form_settings} setSelectedWidget={setSelectedWidget} hideIcons={hideIcons} setIsOpen={setIsOpen} showPageNo={showPageNo} />
  );
}
export default memo(HeaderPreview);

export const HeaderTitleHtml = ({
    activePage = 0,
    hideIcons,
    setSelectedWidget = (e) => {},
    form_settings,
    setIsOpen = (e) => {},
    showPageNo = true
}) => {
  const [showIcons, setShowIcons] = useState(false);
  return (
    <>{form_settings?.title &&
      <div
        className="row"
        onMouseEnter={() => setShowIcons(true)}
        onMouseLeave={() => setShowIcons(false)}
      >
        <div className="col-lg-12">
          <div className="form-group">
          <span
            hidden={hideIcons}
            className={`icons${showIcons ? " show" : ""}`}
          >
            <a
              role="button"
              type="button"
              onClick={() => {
                setSelectedWidget(deepClone(form_settings));
                setIsOpen(true);
              }}
            >
              <i className="fa-regular fa-pen-to-square"></i> <br/> Edit
            </a>
          </span>
            <h2
              className="formHeading"
              style={{
                fontWeight: form_settings.title?.bold ? "bold" : "",
                fontStyle: form_settings.title?.italic ? "italic" : "",
                display: "flex",
                alignItems: "center",
              }}
            >
              {!isEmpty(form_settings.logo) && (
                <ImageAvatar data={{src: form_settings.logo}} />
              )}
              <span className={!isEmpty(form_settings.logo) ? "ms-5" : ""}>
                {form_settings.title.label ? form_settings.title.label : "Title"}
              </span>
            </h2>
            {!isEmpty(form_settings.description) && (
              <p>{form_settings.description}</p>
            )}
            {(!isEmpty(form_settings.title.pages)) && (
              <div className="pagesSection my-3">
                {(hideIcons && showPageNo)  && <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Page {activePage + 1}</Typography> }
              </div>
            )}
          </div>
        </div>
      </div>
    }</>

  )
}
