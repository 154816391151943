import {useEffect, useState} from "react";
import {
  addSpaceBeforeCapitalLetters,
  pascalCaseToSpaceSeparated,
  replaceLabelStrings
} from "src/shared/functions/string-conversion";
import LabelCompo from "./LabelCompo";
import * as React from "react";
import {FormControl, FormHelperText, TextField} from "@mui/material";
import validator from "validator";
import {matchIsValidTel, MuiTelInput} from "mui-tel-input";
import {isEmpty} from "src/shared/functions/isEmpty";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import * as _ from "lodash";

export const ContactInformationCompo = ({isReadOnly, data, onChangeFields, formData, form, isSubmitClicked = false, isEntryPage = false, isDraftSaved = false}) => {
  const defaultLabel = "Contact Information";
  const [labelConv, setLabelConv] = useState(
    isReadOnly ? (data.label ? data.label : defaultLabel) : replaceLabelStrings(data?.label, form, defaultLabel)
  );

  const [fields, setFields] = useState(formData);
  const [updatedFields, setUpdatedFields] = useState([]);

  const getFormVal = (key) => {
    const formFieldVal = (key) => {
      try {
        return (formData && formData[key] ? ((key === "StartDate") ? dayjs(formData[key]) : formData[key]) : !isEmpty(data.fields[key]?.value) ? ((key === "StartDate") ? dayjs(data.fields[key]?.value) : data.fields[key]?.value) : "")
      } catch (e) {
        return null;
      }
    }
    try {
      return isEntryPage ? ((isDraftSaved && _.includes(updatedFields, key)) ? formFieldVal(key) : ((key === "StartDate") ? dayjs(data.fields[key]?.userInputVal): data.fields[key]?.userInputVal)) : (isReadOnly ? ((key === "StartDate") ? dayjs(data.fields[key]?.value) : data.fields[key]?.value) : formFieldVal(key))
    } catch (e) {
      return null;
    }
  }
  const [contactPh, setContactPh] = useState(getFormVal("ContactPhone"));
  const [emergencyPhone, setEmergencyPhone] = useState(getFormVal("EmergencyPhone"));

  useEffect(() => {
    if (!isReadOnly) {
      setLabelConv(replaceLabelStrings(data?.label, form, defaultLabel));
    } else {
      setLabelConv(data.label ? data.label : defaultLabel)
    }
  }, [form, data]);
  useEffect(() => {
    if (!isReadOnly) {
      onChangeFields(fields);
    }
  }, [fields]);

  useEffect(() => {
    if (isReadOnly) {
      setContactPh(data.fields["ContactPhone"]?.value)
    }
  }, [data?.fields["ContactPhone"]?.value]);
  useEffect(() => {
    if (isReadOnly) {
      setEmergencyPhone(data.fields["EmergencyPhone"].value)
    }
  }, [data?.fields["EmergencyPhone"]?.value]);


  return (
    <div key={data.id} id={data.id}>
      <LabelCompo data={data} labelConv={labelConv}/>
      <div className="contactInfo">
        <div className="row">
          {Object.keys(data.fields).map((key) => {
            const keyObj = data.fields[key];
            if (!Boolean(keyObj.showField)) { return <></> }
            else if (key === "ContactPhone" || key === "EmergencyPhone") {
              return (
                <div className="col-lg-6" key={`contactInfoFormGroup_${key}`}>
                  <div className="form-group">
                    <label>
                      {pascalCaseToSpaceSeparated(key)}
                      <small className={data.required && keyObj.required ? "asteriskAfter" : ""}>
                        {(data.required && keyObj.required) ? "" : " (Optional)"}
                      </small>
                    </label>
                    <MuiTelInput
                      fullWidth
                      forceCallingCode
                      defaultCountry={"US"}
                      size="small"
                      placeholder={`Enter ${pascalCaseToSpaceSeparated(key)}`}
                      disabled={!isDraftSaved && Boolean(isReadOnly || (isEntryPage && keyObj.userInputVal))}
                      required={Boolean(data.required && keyObj.required)}
                      id={`field_id_${keyObj.id}`}
                      name={`field_name_${data.id}_${key}`}
                      value={key === "ContactPhone" ? contactPh : emergencyPhone}
                      onChange={(value, info) => {
                        if (!isReadOnly) {
                          if (key === "ContactPhone") {
                            setContactPh(value);
                          } else {
                            setEmergencyPhone(value)
                          }
                          setFields(prevState => {
                            return {...prevState, [key]: value}
                          });
                          setUpdatedFields(prevState => {
                            return _.uniq([...prevState, key])
                          })
                        }
                      }}
                      error={Boolean(!isReadOnly && data.required && keyObj.required && isSubmitClicked && (!matchIsValidTel((key === "ContactPhone" ? contactPh : emergencyPhone) || (isEntryPage ? keyObj?.userInputVal : ""))))}
                      helperText={Boolean(!isReadOnly && data.required && keyObj.required && isSubmitClicked && (!(key === "ContactPhone" ? contactPh : emergencyPhone) || !matchIsValidTel(contactPh || (isEntryPage ? keyObj?.userInputVal : "")))) && ((contactPh && !matchIsValidTel(contactPh || (isEntryPage ? keyObj?.userInputVal : ""))) ? "Invalid phone number!" : "This Field is required!")}
                    />
                  </div>
                </div>
              )
            } else if (key === "Zip") {
              return (
                <div className="col-lg-6" key={`contactInfoFormGroup_${key}`}>
                  <label>
                    Zip
                    <small className={data.required && keyObj.required ? "asteriskAfter" : ""}>
                      {(data.required && keyObj.required) ? "" : " (Optional)"}
                    </small>
                  </label>
                  <TextField
                    fullWidth
                    size="small"
                    maxLength="10"
                    placeholder={`Enter Zip Code`}
                    onChange={(e) => {
                      if (!isReadOnly) {
                        let val = e.target.value;
                        const regExp = /[a-zA-Z]/g;
                        if (!regExp.test(val)) {
                          if (val.includes("-")) val = val.replaceAll("-", "");
                          if (val.length > 5)
                            val =
                              val.substr(0, 5) +
                              "-" +
                              val.substr(5, val.length - 5);
                          e.target.value = val;
                          setFields(prevState => {
                            return {...prevState, [key]: val}
                          });
                        } else {
                          e.target.value = fields[key] || "";
                        }
                        setLabelConv(
                          replaceLabelStrings(data?.label, form, defaultLabel)
                        );
                        setUpdatedFields(prevState => {
                          return _.uniq([...prevState, key])
                        })
                      }
                    }}
                    disabled={!isDraftSaved && Boolean(isReadOnly || (isEntryPage && keyObj.userInputVal))}
                    id={`field_id_${keyObj.id}`}
                    name={`field_name_${data.id}_Zip`}
                    value={getFormVal(key)}
                    required={Boolean(data.required && keyObj.required)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={Boolean(!isReadOnly && data.required && keyObj.required && isSubmitClicked && !((fields && fields["Zip"]) || (isEntryPage && keyObj?.userInputVal)))}
                    helperText={Boolean(!isReadOnly && data.required && keyObj.required && isSubmitClicked && !((fields && fields["Zip"]) || (isEntryPage && keyObj?.userInputVal))) && "This Field is required!"}
                  />
                </div>
              )
            } else if (key === "ContactEmail") {
              return (
                <div className="col-lg-6" key={`contactInfoFormGroup_${key}`}>
                  <div className="form-group">
                    <label>
                      Contact Email
                      <small className={data.required && keyObj.required ? "asteriskAfter" : ""}>
                        {(data.required && keyObj.required) ? "" : " (Optional)"}
                      </small>
                    </label>
                    <TextField
                      fullWidth
                      type="email"
                      size="small"
                      placeholder={`Enter ${addSpaceBeforeCapitalLetters(key)}`}
                      required={Boolean(data.required && keyObj.required)}
                      disabled={!isDraftSaved && Boolean(isReadOnly || (isEntryPage && keyObj.userInputVal))}
                      id={`field_id_${keyObj.id}`}
                      name={`field_name_${data.id}_ContactEmail`}
                      value={getFormVal(key)}
                      onChange={(e) => {
                        if (!isReadOnly) {
                          setFields(prevState => {
                            return {...prevState, ContactEmail: e.target.value}
                          });
                          setUpdatedFields(prevState => {
                            return _.uniq([...prevState, key])
                          })
                        }
                      }}
                      error={Boolean(!isReadOnly && data.required && keyObj.required && isSubmitClicked && (!((fields && fields?.ContactEmail) || (isEntryPage && keyObj?.userInputVal)) || (!validator.isEmail(fields?.ContactEmail || (isEntryPage ? keyObj?.userInputVal : "")))))}
                      helperText={Boolean(!isReadOnly && data.required && keyObj.required && isSubmitClicked && (!((fields && fields?.ContactEmail) || (isEntryPage && keyObj?.userInputVal)) || (!validator.isEmail(fields?.ContactEmail || (isEntryPage ? keyObj?.userInputVal : ""))))) && (((fields && fields?.ContactEmail) && (!validator.isEmail(fields?.ContactEmail || (isEntryPage ? keyObj?.userInputVal : "")))) ? "Enter a valid email address!" : "This Field is required!")}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </div>
                </div>
              )
            } else if (key === "StartDate") {
              return (
                <div className="col-lg-6" key={`contactInfoFormGroup_${key}`}>
                  <FormControl
                    sx={{width: "100%"}}
                    required={data.required}
                    error={Boolean(!isReadOnly && data.required && keyObj?.required && isSubmitClicked && !((fields && fields[key]) || (isEntryPage && keyObj?.userInputVal)))}>
                    <label>
                      {addSpaceBeforeCapitalLetters(key)}
                      <small className={data.required && keyObj.required ? "asteriskAfter" : ""}>
                        {(data.required && keyObj.required) ? "" : " (Optional)"}
                      </small>
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        disabled={!isDraftSaved && Boolean(isReadOnly || (isEntryPage && keyObj?.userInputVal))}
                        id={`field_id_${keyObj.id}`}
                        name={`field_name_${data.id}_${key}`}
                        value={getFormVal(key)}
                        required={Boolean(data.required && keyObj.required)}
                        slotProps={{textField: {size: 'small', fullWidth: true, required: data.required}}}
                        format={`MM/DD/YYYY`}
                        onChange={(e) => {
                          if (!isReadOnly) {
                            setFields(prevState => {
                              return {...prevState, [key]: e.format()}
                            });
                            setUpdatedFields(prevState => {
                              return _.uniq([...prevState, key])
                            })
                          }
                        }}
                      />
                    </LocalizationProvider>
                    <FormHelperText>{Boolean(!isReadOnly && data.required && keyObj?.required && isSubmitClicked && !((fields && fields[key]) || (isEntryPage && keyObj?.userInputVal))) && "This Field is required!"}</FormHelperText>
                  </FormControl>
                </div>
              )
            } else {
              return (
                <div className="col-lg-6" key={`contactInfoFormGroup_${key}`}>
                  <div className="form-group">
                    <label>
                      {addSpaceBeforeCapitalLetters(key)}
                      <small className={data.required && keyObj.required ? "asteriskAfter" : ""}>
                        {(data.required && keyObj.required) ? "" : " (Optional)"}
                      </small>
                    </label>
                    <TextField
                      fullWidth
                      size="small"
                      onChange={(e) => {
                        if (!isReadOnly) {
                          setFields(prevState => {
                            return {...prevState, [key]: e.target.value}
                          });
                          setUpdatedFields(prevState => {
                            return _.uniq([...prevState, key])
                          })
                        }
                      }}
                      placeholder={`Enter ${addSpaceBeforeCapitalLetters(key)}`}
                      disabled={!isDraftSaved && Boolean(isReadOnly || (isEntryPage && keyObj?.userInputVal))}
                      id={`field_id_${keyObj.id}`}
                      name={`field_name_${data.id}_${key}`}
                      value={getFormVal(key)}
                      required={Boolean(data.required && keyObj.required)}
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={Boolean(!isReadOnly && data.required && keyObj?.required && isSubmitClicked && !((fields && fields[key]) || (isEntryPage && keyObj?.userInputVal)))}
                      helperText={Boolean(!isReadOnly && data.required && keyObj?.required && isSubmitClicked && !((fields && fields[key]) || (isEntryPage && keyObj?.userInputVal))) && "This Field is required!"}
                    />
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>
    </div>
  );
};