import {
  Box,
  Button,
  Card,
  Chip,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import Scrollbar from "src/components/scrollbar";
import { apiGetMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import PageHeader from "src/components-form/Common/PageHeader";
import { isEmpty } from "src/shared/functions/isEmpty";
import { Helmet } from "react-helmet-async";
import { PAGINATION_OBJECT, ROLES, TRAINING_STATUS } from "src/core/constants";
import { headCellUserTrainingsCatalogue } from "src/components/mui-common/table-ui/headCells";
import {
  _handleRedirect,
  _setPaginationObjectFn,
  createQueryString,
} from "src/shared/functions";
import { formatDateFn } from "src/shared/functions/string-conversion";
import TableHeadRow, {
  StyledTableCell,
  StyledTableNoData,
  StyledTableRow,
} from "src/components/mui-common/table-ui/TableHeadRow";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import TableToolbar from "src/components/mui-common/table-ui/TableToolbar";
import TableRowsLoader from "src/components/mui-common/table-ui/TableRowsLoader";
import { _handleChangePage } from "../../../utils/common";
import { useSelector } from "react-redux";

export const ViewTrainingCatalogue = () => {
  const [selected, setSelected] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [loader, setLoader] = useState(true);
  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);
  const [searchTerm, setSearchTerm] = useState("");
  const headCells = headCellUserTrainingsCatalogue;

  useEffect(() => {
    if (paginationObject.triggerApi) {
      apiCall();
    }
  }, [paginationObject.triggerApi]);
  useEffect(() => {
    try {
      _handleChangePage({}, 0, setPaginationObject,setSelected);
    } catch (e) {}
  }, [searchTerm]);

  function apiCall() {
    setLoader(true);
    let queryObject = {
      search: searchTerm || "",
      pageNumber: paginationObject?.page,
      limit: paginationObject?.rowsPerPage,
    };
    const qryStr = createQueryString(queryObject);

    apiGetMethod(`${allApiUrl?.USER_TRAINING_CATALOGUE}${qryStr}`)
      .then((data) => {
        setDataFiltered(data.data);
        setPaginationObject((prevState) => {
          return _setPaginationObjectFn(prevState, data.meta);
        });
      })
      .catch((err) => {
        setDataFiltered([...[]]);
        setPaginationObject(PAGINATION_OBJECT);
      })
      .finally(() => {
        setLoader(false);
      });
  }
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  return (
    <>
      <Helmet>
        <title>
          Training Catalogue | {process.env.REACT_APP_APPLICATION_NAME}{" "}
        </title>
      </Helmet>
      <Box sx={{ maxWidth: "100%", overflow: "auto" }}>
        <Grid container>
          <Grid item xl={12}>
            <div className="flexTop">
              <PageHeader
                header={"Training Catalogue"}
                links={[
                  {
                    name: "Dashboard",
                    path: "/",
                  },
                ]}
              />
            </div>
            <Card className="cardFix">
              <TableToolbar
                title="Training Catalogue"
                numSelected={selected.length}
                setSelected={setSelected}
                selected={selected}
                apiCall={apiCall}
                onSearch={(query) => setSearchTerm(query)}
              />

              <Scrollbar>
                <TableContainer sx={{ overflow: "unset" }}>
                  <Table>
                    <TableHeadRow
                      numSelected={selected.length}
                      rowCount={dataFiltered.length}
                      headCells={headCells}
                      listData={dataFiltered}
                      setSelected={setSelected}
                    />
                    <TableBody>
                      {loader ? (
                        <TableRowsLoader
                          rowsNum={10}
                          columnNum={headCells.length}
                        />
                      ) : isEmpty(dataFiltered) ? (
                        <StyledTableNoData colSpan={headCells.length} />
                      ) : (
                        dataFiltered?.map((item, index) => {
                          return (
                            <StyledTableRow
                              // hover
                              role="checkbox"
                              tabIndex={-1}
                              key={`styledTableRow_${index}`}
                            >
                              {headCells.map((hCell) => (
                                <StyledTableCell
                                  padding={
                                    hCell.id === "checkbox"
                                      ? "checkbox"
                                      : "normal"
                                  }
                                  className="nowrapFull"
                                  key={`tableCell_${hCell.id}`}
                                >
                                  {hCell.id === "actions" ? (
                                    <>
                                      {item?.status ===
                                      TRAINING_STATUS.PASSED ? (
                                        <Chip
                                          label="COMPLETED"
                                          color="success"
                                        />
                                      ) : (
                                        <Button
                                          onClick={() =>
                                            _handleRedirect(
                                              `/training-catalogue/form-detail/${item?.id}`
                                            )
                                          }
                                          disabled = {loginUserInfo?.roleid[0] !== ROLES.FORM_USER}
                                        >
                                          Start Training
                                        </Button>
                                      )}
                                    </>
                                  ) : hCell?.isDate ? (
                                    formatDateFn(item[hCell.id])
                                  ) : (
                                    item[hCell.id]
                                  )}
                                </StyledTableCell>
                              ))}
                            </StyledTableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePaginationCompo
                paginationObject={paginationObject}
                setPaginationObject={setPaginationObject}
                setSelected={setSelected}
              />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
