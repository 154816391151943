import {Button} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { allApiUrl } from "src/api/apiRoute";
import { apiGetMethod, apiPostMethod } from "src/api/rest";
import CommonHeader from "src/sections/CommonTable";
import { handleCheckboxClick } from "src/sections/Table";
import {_handleChangePage, PermissionCheck} from "src/utils/common";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import {COMPANYROLES, PAGINATION_OBJECT, SUPERADMINROLES} from "src/core/constants";
import PageHeader from "src/components-form/Common/PageHeader";
import { Helmet } from "react-helmet-async";
import {_setPaginationObjectFn, createQueryString} from "src/shared/functions";

const ViewTrainingDirectory = () => {
  const navigate = useNavigate();
  const loginUserInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const isCompanyRole = Object.values(COMPANYROLES)?.includes(loginUserInfo?.roleid[0]);
  const isSuperAdminRole = Object.values(SUPERADMINROLES)?.includes(loginUserInfo?.roleid[0]);

  const _Tabs = isSuperAdminRole ? [
    {tabIndex: 0, id: "TRAININGS", label: "Trainings"},
  ] : [
    {tabIndex: 0, id: "TRAININGS", label: "Trainings"},
    {tabIndex: 1, id: "TEMPLATE", label: "Template"}
  ]

  const [dataFiltered, setDataFiltered] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loader, setLoader] = useState(true);
  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);
  const [industryName, setIndustryName] = useState(null);
  const [currentTab, setCurrentTab] = useState(_Tabs[0]);
  const [statusFilter, setStatusFilter] = useState("all");

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (paginationObject.triggerApi) {
      apiCall();
    }
  }, [paginationObject.triggerApi]);
  useEffect(() => {
    try {
      _handleChangePage({}, 0, setPaginationObject,setSelected)
    } catch (e) {}
  }, [searchTerm, currentTab, statusFilter, industryName])


  function apiCall() {
    setLoader(true);
    let queryObject = {
      search: searchTerm || "",
      industryId: industryName?.value || "",
      pageNumber: paginationObject.page,
      limit: paginationObject.rowsPerPage,
      is_active: statusFilter === "all" ? null : statusFilter,
    };

    const qryStr = createQueryString(queryObject);

    const url = currentTab.id === "TEMPLATE" ? allApiUrl.TRAINING_TEMPLATES :
      (currentTab.id === "TRAININGS" && isCompanyRole) ? allApiUrl?.COMPANY_TRAINING_LIST
        : allApiUrl.TRAINING_LIST;
    apiGetMethod(
      `${url}${qryStr}`
    )
      .then((data) => {
        setDataFiltered(data.data);
        setPaginationObject(prevState => {
          return _setPaginationObjectFn(prevState, data.meta);
        })
      })
      .catch((err) => {
        setDataFiltered([...[]]);
        setPaginationObject(PAGINATION_OBJECT)
      }).finally(() => {
      setLoader(false);
    });
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = dataFiltered?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, id) => {
    setSelected(handleCheckboxClick(event, id, selected));
  };

  const handleDeleteSelected = () => {
    const url = isCompanyRole ? `${allApiUrl?.COMPANY_DELETE_MULTIPLE_TRAINING}` : `${allApiUrl?.MULTIPLE_TRAINING_LIST}`;
    apiPostMethod(url, {
      id: selected,
    }).then((res) => {
      toast.success(res?.message);
      setSelected([...[]]);
      apiCall();
    }).catch((e) => {
      toast.error(e.message);
    });
  };


  return (
    <>
      <Helmet>
        <title>
          Trainings Catalogue | {process.env.REACT_APP_APPLICATION_NAME}{" "}
        </title>
      </Helmet>
      <Box>
        <div className="flexTop">
          <PageHeader
            header={"Trainings Catalogue"}
            links={[
              {
                name: "Dashboard",
                path: "/",
              },
            ]}
          />
          <div>
            {PermissionCheck("Trainings", "create", loginUserInfo) && (
              <Button
                variant="contained"
                color="inherit"
                onClick={() => navigate("/training/add-training")}
                startIcon={<AddIcon />}
                className="blueButton"
              >
                New Training
              </Button>
            )}
          </div>
        </div>
        <CommonHeader
          title={`Trainings Catalogue`}
          numSelected={selected.length}
          setSelected={setSelected}
          onSelectAllClick={handleSelectAllClick}
          rowCount={dataFiltered?.length}
          handleClick={handleClick}
          dataFiltered={dataFiltered}
          selected={selected}
          paginationObject={paginationObject}
          setPaginationObject={setPaginationObject}
          apiCall={apiCall}
          loader={loader}
          industryName={industryName}
          setIndustryName={setIndustryName}
          _Tabs={_Tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          handleDeleteSelected={handleDeleteSelected}
          setSearchTerm={setSearchTerm}
        />
      </Box>
    </>
  );
};

export default ViewTrainingDirectory;
