import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapDialog } from "../ui/BootstrapDialog";
import { Box } from "@mui/material";
import {
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import * as React from "react";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useState } from "react";
import { apiGetMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import FormControl from "@mui/material/FormControl";
import { isEmpty } from "src/shared/functions/isEmpty";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import EditOffIcon from "@mui/icons-material/EditOff";
import EditIcon from "@mui/icons-material/Edit";
import _ from "lodash";
import { useSelector } from "react-redux";
import {
  COMPANYROLES,
  FILTER_FORM_TYPE,
  SUPERADMINROLES,
} from "src/core/constants";
import SelectAsync from "../../shared/SelectAsync";
import SaveIcon from "@mui/icons-material/Save";

function SaveFormModal({
  openSaveAsModal,
  setOpenSaveAsModal,
  saveAsSubForm,
  saveAsTrainingForm,
  trainingId,
  jsaTrainingIds,
  industryIdPreSelected,
  trainingName,
  selectedCategoryId,
  saveFormStatus,
  saveFormStructure,
  formId,
  formTitle,
  isProjectForm,
  FORM_TYPE,
}) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [trainingsList, setTrainingsList] = useState([]);
  const initFormType =
    (saveAsSubForm && !isEmpty(selectedCategoryId)) ||
    FORM_TYPE === FILTER_FORM_TYPE.JSA_CODE
      ? FILTER_FORM_TYPE.JSA_CODE
      : !isEmpty(saveAsTrainingForm && trainingId) ||
        FORM_TYPE === FILTER_FORM_TYPE.TRAINING_FORM
      ? FILTER_FORM_TYPE.TRAINING_FORM
      : FILTER_FORM_TYPE.FORM;
  const [selectedFormType, setSelectedFormType] = useState(initFormType);
  const [selectedFormTitle, setSelectedFormTitle] = useState(formTitle);
  const [selectedFormTraining, setSelectedFormTraining] = useState(trainingId);
  const [selectedJsaTrainingIds, setSelectedJsaTrainingIds] =
    useState(jsaTrainingIds);
  const [selectedIndustry, setSelectedIndustry] = useState(
    industryIdPreSelected
  );
  const [selectedFormTrainingName, setSelectedFormTrainingName] =
    useState(trainingName);
  const [selectedFormCategory, setSelectedFormCategory] =
    useState(selectedCategoryId);
  const [canEditTitle, setCanEditTitle] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    setSelectedFormType(
      !!saveAsTrainingForm ? FILTER_FORM_TYPE.TRAINING_FORM : selectedFormType
    );
  }, [saveAsTrainingForm]);
  useEffect(() => {
    setSelectedFormTraining(trainingId);
  }, [trainingId]);
  useEffect(() => {
    setSelectedFormTrainingName(trainingName);
  }, [trainingName]);
  useEffect(() => {
    setSelectedFormCategory(selectedCategoryId);
  }, [selectedCategoryId]);
  useEffect(() => {
    setSelectedJsaTrainingIds(jsaTrainingIds);
  }, [jsaTrainingIds]);

  useEffect(() => {
    apiCall(page);
  }, [page]);
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );

  const isCompanyRole = Object.values(COMPANYROLES)?.includes(
    loginUserInfo?.roleid[0]
  );
  const isSuperAdminRole = Object.values(SUPERADMINROLES)?.includes(
    loginUserInfo?.roleid[0]
  );

  function apiCall(pagenum) {
    apiGetMethod(`${allApiUrl.FORM_CATEGORIES}?pageNumber=${pagenum}&limit=10`)
      .then((response) => {
        const { data, meta } = response;
        setCategoriesList((prev) => [
          ...prev,
          ...data.map(({ id, name }) => ({ id, name })),
        ]);
        setTotalPages(meta.totalPages);
      })
      .catch(() => {});

    const url = Object.values(SUPERADMINROLES)?.includes(
      loginUserInfo?.roleid[0]
    )
      ? allApiUrl.FILTERED_TRAINING
      : Object.values(COMPANYROLES)?.includes(loginUserInfo?.roleid[0])
      ? allApiUrl.COMPANY_FILTERD_TRAININGLIST
      : null;

    if (url) {
      apiGetMethod(url, null, null)
        .then((res) => {
          let list = _.map(res.data, (v) => {
            if (v?.is_active === true) {
              return {
                id: v?.id,
                name: v?.name,
              };
            }
          });
          list = list.filter((option) => option !== undefined);
          setTrainingsList([
            ...(trainingId !== "" && trainingName !== ""
              ? [{ id: trainingId, name: trainingName }]
              : []),
            ...list,
          ]);
          setSelectedFormTraining(trainingId);
          setSelectedFormTrainingName(trainingName);
        })
        .catch((err) => {});
    }
  }

  const closeModal = () => {
    setOpenSaveAsModal(false);
  };
  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight ===
      event.target.scrollTop + event.target.clientHeight;
    if (bottom && page < totalPages - 1) {
      setPage((prev) => prev + 1);
    }
  };
  const handleCategoryChange = (e) => {
    if (selectedCategoryId === e?.target?.value) {
      setSelectedFormCategory(e.target.value);
      setSelectedJsaTrainingIds([...jsaTrainingIds]);
    } else {
      setSelectedFormCategory(e.target.value);
      setSelectedJsaTrainingIds(null);
    }
  };
  return (
    <Box>
      <BootstrapDialog
        className="saveFormwidth"
        onClose={closeModal}
        open={openSaveAsModal}
        width={`auto`}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {formId ? `Update Form` : `Save Form`}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="saveModalBody">
            <FormControl fullWidth>
              <FormLabel>Form Name</FormLabel>
              <TextField
                fullWidth
                multiline
                type="text"
                size="small"
                disabled={!canEditTitle}
                value={selectedFormTitle}
                onChange={(e) => setSelectedFormTitle(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => {
                          setCanEditTitle((prevState) => {
                            return !prevState;
                          });
                        }}
                      >
                        {canEditTitle ? <EditOffIcon /> : <EditIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputProps: {
                    onWheel: (e) => e.currentTarget.blur(),
                  },
                }}
                error={Boolean(isSubmitted && !selectedFormTitle)}
                helperText={
                  Boolean(isSubmitted && !selectedFormTitle)
                    ? "This field is required!"
                    : ""
                }
              />
            </FormControl>
            <br />
            <br />
            {!isProjectForm && (
              <>
                <FormControl>
                  <FormLabel>Save Form As</FormLabel>
                  <RadioGroup
                    value={selectedFormType}
                    onChange={(val) => {
                      setSelectedFormType(val.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={FILTER_FORM_TYPE.FORM}
                      control={<Radio />}
                      label="Form"
                    />
                    <FormControlLabel
                      value={FILTER_FORM_TYPE.JSA_CODE}
                      control={<Radio />}
                      label="JSA Code"
                    />
                    <FormControlLabel
                      value={FILTER_FORM_TYPE.TRAINING_FORM}
                      control={<Radio />}
                      label="Training Form"
                    />
                  </RadioGroup>
                </FormControl>
                <br />

                {selectedFormType === FILTER_FORM_TYPE.FORM &&
                  isSuperAdminRole && (
                    <FormControl fullWidth>
                      <FormLabel>Select Industry</FormLabel>
                      <SelectAsync
                        searchType="industry"
                        selectedValue={selectedIndustry}
                        placeholder="Select Industry"
                        className="asyncHeight saveForm"
                        onChange={(event) => {
                          setSelectedIndustry(event);
                        }}
                      />
                    </FormControl>
                  )}
                {selectedFormType === FILTER_FORM_TYPE.JSA_CODE && (
                  <>
                    <FormControl fullWidth required>
                      <FormLabel>Select Category</FormLabel>
                      <Select
                        fullWidth
                        size="small"
                        value={selectedFormCategory}
                        placeholder="Select Category"
                        onChange={(e) => {
                          handleCategoryChange(e);
                        }}
                        MenuProps={{
                          PaperProps: {
                            onScroll: handleScroll,
                            style: {
                              maxHeight: 200,
                              overflowY: "auto",
                            },
                          },
                        }}
                      >
                        {categoriesList?.length > 0 ? (
                          categoriesList.map((category, index) => {
                            return (
                              <MenuItem
                                key={`choice1__${index}`}
                                value={category.id}
                              >
                                {category.name}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem>No category Available</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <br />
                    <FormControl fullWidth sx={{ marginTop: "10px" }}>
                      <FormLabel>Select Training</FormLabel>
                      <SelectAsync
                        selectBoxKey={selectedFormCategory}
                        isDisabled={!selectedFormCategory}
                        isMulti={true}
                        onChange={(event) => {
                          setSelectedJsaTrainingIds(event);
                        }}
                        searchType={
                          isSuperAdminRole
                            ? `ADMIN_Filtered_training_form`
                            : `company_filtered_training_form`
                        }
                        dataParam={{
                          isSaveModal: true,
                          categoryId: selectedFormCategory,
                        }}
                        selectedValue={selectedJsaTrainingIds}
                        placeholder="Select Training"
                        className="asyncHeight saveForm"
                      />
                    </FormControl>
                  </>
                )}

                {selectedFormType === FILTER_FORM_TYPE.TRAINING_FORM && (
                  <FormControl
                    fullWidth
                    error={Boolean(isSubmitted && !selectedFormTraining)}
                  >
                    <FormLabel>Select Training</FormLabel>
                    <Select
                      fullWidth
                      size="small"
                      placeholder="Select Training"
                      value={selectedFormTraining}
                      onChange={(event) => {
                        setSelectedFormTraining(event.target.value);
                        setSelectedFormTrainingName(
                          _.find(trainingsList, { id: event.target.value })
                            ?.name
                        );
                      }}
                    >
                      {trainingsList?.length > 0 ? (
                        trainingsList?.map((option, index) => {
                          return (
                            <MenuItem
                              key={`choice0__${index}`}
                              value={option.id}
                            >
                              {option.name}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem>No Training Available</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                )}
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            autoFocus
            disabled={
              !isProjectForm &&
              Boolean(
                saveFormStatus ||
                  (selectedFormType === FILTER_FORM_TYPE.JSA_CODE &&
                    !selectedFormCategory)
              )
            }
            loading={saveFormStatus}
            endIcon={<SaveIcon />}
            loadingPosition="end"
            variant="contained"
            className="my-2 mx-3 blueButton "
            onClick={() => {
              setIsSubmitted(true);
              saveFormStructure(
                selectedFormTitle,
                selectedFormType,
                selectedFormCategory,
                selectedFormTraining,
                selectedFormTrainingName,
                selectedJsaTrainingIds,
                selectedIndustry
              );
            }}
          >
            <span>{formId ? `Update` : `Save`}</span>
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
}

export default SaveFormModal;
