/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as formHtml from "./formHtml";
import { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  _checkConditionalShowHideEl,
  copyPasteElement,
  removeKeysFromObject,
} from "../functions";
import { deepClone } from "../functions";
import * as React from "react";
import { PageBreakCompo } from "src/components-form/Common/PageBreakCompo";
import { TextInputCompo } from "src/components-form/Common/TextInputCompo";
import { RatingScaleCompo } from "src/components-form/Common/RatingScaleCompo";
import { SignatureCompo } from "src/components-form/Common/SignatureCompo";
import { SelectDropdownCompo } from "src/components-form/Common/SelectDropdownCompo";
import { CheckboxCompo } from "src/components-form/Common/CheckboxCompo";
import { CalculationCompo } from "src/components-form/Common/CalculationCompo";
import { ContentCompo } from "src/components-form/Common/ContentCompo";
import { ContactInformationCompo } from "src/components-form/Common/ContactInformationCompo";
import { TimePickerCompo } from "src/components-form/Common/TimePickerCompo";
import { DatePickerCompo } from "src/components-form/Common/DatePickerCompo";
import { ParagraphCompo } from "src/components-form/Common/ParagraphCompo";
import { ImageCompo } from "src/components-form/Common/ImageCompo";
import { VideoCompo } from "src/components-form/Common/VideoCompo";
import { ImageSliderCompo } from "src/components-form/Common/ImageSliderCompo";
import { FileUploadCompo } from "src/components-form/Common/FileUploadCompo";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { AddressInformationCompo } from "src/components-form/Common/AddressInformationCompo";
import LabelCompo from "src/components-form/Common/LabelCompo";
import { LookupDropDownCompo } from "src/components-form/Common/LookupDropDownCompo";

export const TextInput = (props) => {
  return <TextInputCompo isReadOnly={false} {...props} />;
};

export const CheckBoxes = (props) => {
  return <CheckboxCompo isReadOnly={false} {...props} />;
};
export const DropDown = (props) => {
  return <SelectDropdownCompo isReadOnly={false} {...props} />;
};
export const SelectDatePicker = (props) => {
  return <DatePickerCompo isReadOnly={false} {...props} />;
};
export const FileUpload = (props) => {
  return <FileUploadCompo isReadOnly={false} {...props} />;
};
export const Paragraph = (props) => {
  return <ParagraphCompo isReadOnly={false} {...props} />;
};
export const PageBreak = (props) => {
  return <PageBreakCompo {...props} />;
};
export const Section = (props) => {
  const [form, setForm] = useState(props.formData);
  useEffect(() => {
    props.onChangeFields(
      props.data.label ? props.data.label : props.data.id,
      form
    );
    props.setForm(form)
  }, [form]);

  return (
    <div key={props.data.id} id={props.data.id}>
      <h4 className="sectionTitle">
        {props.data.label ? props.data.label : "Title Name"}
      </h4>
      <div className="widget-dropsecton">
        {props.data.childComponent.map((row, index) => {
          return (
            <div className="row" key={`choice__${index}`}>
              {row.childComponent.map((item, refVal) => {
                if (_checkConditionalShowHideEl(item))
                  return (
                    <div
                      className={`col-lg-${item.width ? item.width : 6}`}
                      key={item.id}
                    ></div>
                  );
                let Tag = formHtml[item.element];
                return (
                  <div
                    className={`col-lg-${item.width ? item.width : 6}`}
                    key={item.id}
                  >
                    <div className="form-group">
                      <Tag
                        data={item}
                        onChangeFields={(value) => {
                          props?.setForm &&
                            props?.setForm((prevData) => {
                              return {
                                ...prevData,
                                [item.id]: value,
                              };
                            });
                        }}
                        setForm={setForm}
                        formData={props?.form?.[item.id]}
                        form={props?.form}
                        isTrainingForm={props?.isTrainingForm}
                        isSubmitClicked={props?.isSubmitClicked}
                        isEntryPage={props?.isEntryPage}
                        isRetakePage={props?.isRetakePage}
                        isDraftSaved={props?.isDraftSaved}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const RepeatingSection = (props) => {
  const [form, setForm] = useState([props.form]);
  useEffect(() => {
    props.onChangeFields(
      props.data.label ? props.data.label : props.data.id,
      props.formData
    );
  }, [props.formData]);
  useEffect(() => {
    props.setForm(form);
  }, [form]);

  let DataCopy = {
    components: deepClone(props.data.childComponent),
  };
  const [htmlStructure, setHtmlStructure] = useState(
    props?.isEntryPage ? props.data.childComponent : [DataCopy]
  );

  const HtmlTagLoop = (childComponent = []) => {
    return (
      <>
        {childComponent.map((item, refVal) => {
          if (_checkConditionalShowHideEl(item))
            return (
              <div
                className={`col-lg-${item.width ? item.width : 6}`}
                key={item.id}
              ></div>
            );
          let Tag = formHtml[item.element];
          return (
            <div
              className={`col-lg-${item.width ? item.width : 6}`}
              key={item.id}
            >
              <div className="form-group">
                <Tag
                  {...props}
                  data={item}
                  onChangeFields={(value) => {
                    try {
                      setForm((prevData) => {
                        return {
                          ...prevData,
                          [item.id]: value,
                        };
                      });
                    } catch (e) {}
                  }}
                  formData={form[item.id]}
                  form={form}
                  isTrainingForm={props?.isTrainingForm}
                  isSubmitClicked={props?.isSubmitClicked}
                  isEntryPage={props?.isEntryPage}
                  isRetakePage={props?.isRetakePage}
                  isDraftSaved={props?.isDraftSaved}
                />
              </div>
            </div>
          );
        })}
      </>
    );
  };
  return (
    <div key={props.data.id} id={props.data.id}>
      <h4 className="sectionTitle">
        <LabelCompo data={props.data} defaultLabel={`Title Name`} />
      </h4>
      <div className="widget-dropsecton">
        <Stepper orientation="vertical" nonLinear>
          {htmlStructure.map((html, index) => {
            return (
              <Step key={`stepper_key_${index}`} active expanded>
                <StepLabel>
                  {props.data.itemLabel ? props.data.itemLabel : "Item"}{" "}
                  {index + 1}
                  {htmlStructure.length > 1 &&
                    !props?.isReadOnly &&
                    (!Boolean(props?.isEntryPage) ||
                      Boolean(props?.showSubmitButton)) && (
                      <IconButton
                        aria-label="Delete"
                        onClick={() => {
                          htmlStructure.splice(index, 1);
                          setHtmlStructure([...htmlStructure]);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    )}
                </StepLabel>
                <StepContent>
                  {html?.hasOwnProperty("components")
                    ? html.components?.map((row, index2) => {
                        return (
                          <div className="row" key={`choice__${index2}`}>
                            {HtmlTagLoop(row.childComponent)}
                          </div>
                        );
                      })
                    : HtmlTagLoop(html.childComponent)}
                </StepContent>
              </Step>
            );
          })}
        </Stepper>

        <br />
        {(!Boolean(props?.isEntryPage) || Boolean(props?.showSubmitButton)) && (
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => {
              const newItem = deepClone(
                copyPasteElement(
                  removeKeysFromObject(
                    htmlStructure[htmlStructure.length - 1],
                    ["userInputVal", "showRetakeField"]
                  )
                )
              );
              htmlStructure.push(newItem);
              setHtmlStructure([...htmlStructure]);
              props.onSetCompoNewData(htmlStructure);
            }}
          >
            {props.data.addButtonText ? props.data.addButtonText : "Add Item"}
          </Button>
        )}
      </div>
    </div>
  );
};

export const Image = (props) => {
  return <ImageCompo isReadOnly={false} {...props} />;
};
export const Video = (props) => {
  return <VideoCompo isReadOnly={false} {...props} />;
};
export const SelectTimePicker = (props) => {
  return <TimePickerCompo isReadOnly={false} {...props} />;
};
export const RatingScale = (props) => {
  return <RatingScaleCompo isReadOnly={false} {...props} />;
};
export const Signature = (props) => {
  return <SignatureCompo isReadOnly={false} {...props} />;
};

export const ImageSlider = (props) => {
  return <ImageSliderCompo isReadOnly={false} {...props} />;
};

export const ContactInformation = (props) => {
  return <ContactInformationCompo isReadOnly={false} {...props} />;
};
export const AddressInformation = (props) => {
  return <AddressInformationCompo isReadOnly={false} {...props} />;
};
export const Content = (props) => {
  return <ContentCompo isReadOnly={false} {...props} />;
};
export const Calculation = (props) => {
  return <CalculationCompo isReadOnly={false} {...props} />;
};

export const LookupDropDown = (props) => {
  return <LookupDropDownCompo isReadOnly={false} {...props} />;
};
