import React from "react";
import { getFileExtension } from "src/shared/functions/string-conversion";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Loader from "src/components/Loader";
function MediaPreviewer({ fileName, fileUrl, loading }) {
  const fileType = getFileExtension(fileName);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ marginTop: "10px" }}>
          {fileType === "image" && <img src={fileUrl} alt={`Image`} />}
          {fileType === "video" && (
            <>
              <video controls className="videoWidth">
                <source src={fileUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </>
          )}
          {(fileType === "pdf" ||
            fileType === "document" ||
            fileType === "spreadsheet" ||
            fileType === "presentation") && (
            <Typography variant="p" component="div" sx={{ textAlign: "left" }}>
              <p className="textellipsis">{fileName}</p>

              <Link target="_blank" rel="noreferrer" to={fileUrl}>
                <Button
                  color="primary"
                  variant="contained"
                  endIcon={<DownloadIcon />}
                >
                  Download File
                </Button>
              </Link>
            </Typography>
          )}
        </div>
      )}
    </>
  );
}

export default MediaPreviewer;
