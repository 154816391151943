import {Tab, Tabs} from "@mui/material";
import React from "react";

export const TabsUi = (
  {
    currentTab,
    handleTabChange,
    tabsArray,
    tabsPadding = "10px 0 0 0"
  }) => {
  return (
    <Tabs
      value={currentTab}
      onChange={handleTabChange}
      className="bottomBorder"
      sx={{
        padding: tabsPadding,
        "& .MuiTabs-indicator": {
          backgroundColor: "#ee7000",
          height: "5px",
          borderRadius: "20px",
        },
        "&.Mui-selected": {
          color: "#ee7000",
        },
      }}
    >
      {tabsArray.map((t, idx) => {
        return (
          <Tab
            key={`tab_item_${idx}`}
            sx={{
              "&.Mui-selected": {
                color: "#ee7000",
              },
            }}
            label={t.label}
          />
        )
      })}
    </Tabs>
  )
}