import styled from "@emotion/styled";

export const Text1Line = styled.p`
  color: #a5a9b5;
  font-weight: 500;
  letter-spacing: 0;
  cursor: default;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0;
`;

export const Text2Line = styled.p`
  color: #a5a9b5;
  font-weight: 500;
  letter-spacing: 0;
  cursor: default;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;