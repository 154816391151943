import * as React from "react";
import { useState } from "react";
import { ToolbarUiNew } from "src/components/mui-common/toolbar-ui-new";
import AlertDialog from "src/components-form/shared/AlertDialog";
import {apiPostMethod} from "src/api/rest";
import {toast} from "react-toastify";
import {_errorMsg} from "../../../shared/functions/string-conversion";

export default function TableToolbar(
  {
   title,
   numSelected = 0,
   onSearch = (value) => {},
   showDeleteButtonCheck = true,
   hideSearch = false,
   deleteModalTitle = "All Selected items",
   deleteUrl = "",
   selected,
   setSelected,
   apiCall,
   searchPlaceholder= "Search",
   debounceTime = 500,
   alertContent= null
 }) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteSelected = () => {
    if (deleteUrl) {
      apiPostMethod(deleteUrl, { id: selected })
        .then((res) => {
          toast.success(res?.message);
          setSelected([...[]]);
          if (apiCall !== undefined) {
            apiCall();
          }
        })
        .catch((err) => {
          toast.error(_errorMsg(err));
        });
    }
  };
  return (
    <>
      <AlertDialog
        title={deleteModalTitle}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        handleDelete={() => {
          setOpenDeleteModal(false);
          handleDeleteSelected();
        }}
        content={alertContent}
      />
      <div className="allocation">
        <ToolbarUiNew
          numSelected={numSelected}
          title={title}
          onSearch={onSearch}
          searchPlaceholder={searchPlaceholder}
          showDeleteButton={showDeleteButtonCheck}
          onDeleteClick={() => setOpenDeleteModal(true)}
          debounceTime={debounceTime}
          hideSearch={hideSearch}
        />
      </div>
    </>
  );
}
