import * as _ from "lodash";
import { isEmpty } from "./isEmpty";
import capitalize from "@mui/utils/capitalize";

export const removeIfExists = (array, item) => {
  const index = array.indexOf(item);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
};
export const addUniqueItem = (array, newItem) => {
  if (!array.includes(newItem)) {
    array.push(newItem);
  }
};
export const removeItemByIndex = (array, index) => {
  array.splice(index, 1);
};
export const removeItemByValue = (array, value) => {
  return array.filter((item) => item !== value);
};
export const removeLastItem = (array) => {
  array.pop();
};
export const removeItemByKeyValue = (array, valuesArray,  key = "id") => {
  return array.filter(item => !valuesArray.includes(item[key]));
}

export const createSuggestionList = (selectedWidgets) => {
  const tempSelectedList = [];
  _.forEach(selectedWidgets, (child) => {
    _.forEach(child.childComponent, (childCompo) => {
      if (
        (childCompo.element === "TextInput" && childCompo.type === "number") ||
        childCompo.element === "DropDown" ||
        childCompo.element === "CheckBoxes"
      ) {
        tempSelectedList.push({
          label: childCompo.label ? childCompo.label : childCompo.element,
          id: childCompo.id,
        });
      }
    });
  });

  return makeLabelsUnique(tempSelectedList);
};
export const createInsertFieldsList = (selectedWidgets) => {
  try {
    const elementListFn = (childCompo) => {
      if (
        _.includes(
          [
            "Paragraph",
            "TextInput",
            "DropDown",
            "CheckBoxes",
            "ContactInformation",
            "AddressInformation",
            "SelectDatePicker",
            "SelectTimePicker",
            "Signature",
          ],
          childCompo.element
        )
      ) {
        switch (childCompo.element) {
          case "ContactInformation":
          case "AddressInformation":
          case "Signature": {
            try {
              Object.keys(childCompo.fields).map((k, idx) => {
                tempSelectedList.push({
                  label: `${childCompo.element}.${k}`,
                  id: `${childCompo.id}.${k}`,
                });
              });
            } catch (e) {}
            break;
          }
          default: {
            tempSelectedList.push({
              label: !isEmpty(childCompo.label)
                ? childCompo.label
                : childCompo.element,
              id: childCompo.id,
            });
          }
        }
      }
    }
    const tempSelectedList = [];
    _.forEach(selectedWidgets, (child) => {
      _.forEach(child.childComponent, (childCompo) => {
        if (childCompo.element === "Section") {
          _.forEach(childCompo.childComponent, (childCompo) => {
            _.forEach(childCompo.childComponent, (childCompo) => {
              elementListFn(childCompo);
            })
          });
        } else {
          elementListFn(childCompo);
        }
      });
    });

    return makeLabelsUnique(tempSelectedList);
  } catch (error) {}
};
export const makeLabelsUnique = (arrayOfObjects) => {
  const labelCount = {};
  arrayOfObjects.forEach((obj) => {
    if (labelCount[obj.label] === undefined) {
      labelCount[obj.label] = 1;
    } else {
      labelCount[obj.label]++;
      obj.label = `${obj.label}${labelCount[obj.label]}`;
    }
  });

  return arrayOfObjects;
};
export const getPlaceholder = (data) => {
  const placeholderText = data.input_types.find(v => v.value === data.type).placeholder || data.type
  return data.placeholder ? data.placeholder : `${capitalize(placeholderText)}`;
}
export const customReduce = (arr) => {
  let sum = null;
  for (let i = 0; i < arr.length; i++) {
    if (typeof arr[i] === "string") {
      sum = sum === null ? "" : sum;
      sum += arr[i];
    } else {
      sum = sum === null ? 0 : sum;
      sum += _.toNumber(arr[i]);
    }
  }
  return sum;
}
export const insertAfterKey = (array, newObject, keyName, keyValue) => {
  const index = array.findIndex(item => item[keyName] === keyValue);

  if (index !== -1) {
    return [
      ...array.slice(0, index + 1),
      newObject,
      ...array.slice(index + 1)
    ];
  }

  return array;
};
export const flattenObject = (array) => {
  return array.map(obj => {
    const newObj = {};

    for (const key in obj) {
      if (obj?.hasOwnProperty(key)) {
        if (!isNaN(key)) {
          Object.assign(newObj, obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
    return newObj;
  });
};

