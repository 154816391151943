import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
export default function ({ header, links }) {
  return (
    <div>
      <Typography variant="h4">{header}</Typography>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          {links?.map(({ name, path }, index) => {
            return (
              <Link
                key={index}
                className="breadLink"
                underline="hover"
                color="inherit"
                to={path}
              >
                {name}
              </Link>
            );
          })}
          <Typography color="text.primary">{header}</Typography>
        </Breadcrumbs>
      </div>
    </div>
  );
}
