import { Card, styled } from "@mui/material";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { apiGetMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import { toast } from "react-toastify";
import { PermissionCheck } from "src/utils/common";
import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import moment from "moment";
const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
}));

const SubscriptionPage = () => {
  const [customerName, setCustomerName] = useState("");
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  function apiCall() {
    apiGetMethod(allApiUrl.STRIPE_INTEGRATION)
      .then((data) => {
        setCustomerName(data?.data[0]);
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
  }
  useEffect(() => {
    {
      PermissionCheck("Subscription", "read", loginUserInfo) && apiCall();
    }
  }, []);
  const [redirectURL] = React.useState(
    process.env.REACT_APP_STRIPE_SUCCESS_URL
  );
  const handleUpgrade = async () => {
    try {
      const response = await fetch(
        "https://api.stripe.com/v1/billing_portal/sessions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          },
          body: `customer=${customerName?.customer}&return_url=${redirectURL}`, // Remove extra slash here
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch billing portal session");
      }

      const data = await response.json();
      const url = data.url;
      window.location.href = url;
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <Box sx={{ px: 2.5, pb: 3, mt: 3 }}>
      <Card elevation={3} sx={{ pt: "20px", mb: 3 }} className="cardFix">
        <CardHeader className="cardHeadBorder">
          <Title className="cardHead subscription">Subscription Plan</Title>
        </CardHeader>
        <Box p={4} height="100%">
          <div className="flexTop">
            <div>
              <p
                style={{
                  fontSize: "15px",
                  color: "#6c757d",
                  fontWeight: "700",
                }}
              >
                Current Plan
              </p>
              <h3 style={{ fontWeight: "800" }}>
                {customerName?.plan?.interval === "day"
                  ? "Daily Subscription"
                  : customerName?.plan?.interval === "month"
                  ? "Monthly Subscription"
                  : customerName?.plan?.interval === "year"
                  ? "Yearly Subscription"
                  : ""}
              </h3>
              <p>
                After your free trial ends on{" "}
                {moment.unix(customerName?.current_period_end).format("MMM DD, YYYY")} ,
                this plan will continue automatically.
              </p>
              <span className="nextPayment">
                Next Payment:&nbsp;
                {(customerName?.plan?.amount / 100).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}{" "}
                on {moment.unix(customerName?.current_period_end).format("DD-MM-YYYY")}
              </span>
              <div>
                <span
                  style={{
                    fontWeight: 700,
                    color: "#6c757d",
                    fontSize: "14px",
                    marginRight: "30px",
                  }}
                >
                  <span>Start Date</span>:&nbsp;
                  {moment.unix(customerName?.current_period_start).format("DD-MM-YYYY")}
                </span>
                <span
                  style={{
                    fontWeight: 700,
                    color: "#6c757d",
                    fontSize: "14px",
                  }}
                >
                  <span>End Date</span>:&nbsp;
                  {moment.unix(customerName?.current_period_end).format("DD-MM-YYYY")}
                </span>
              </div>
            </div>
            <div>
              <Button
                target="_blank"
                variant="contained"
                color="inherit"
                className="blueButton"
                onClick={handleUpgrade}
              >
                Manage Plan
              </Button>
            </div>
          </div>
        </Box>
      </Card>
    </Box>
  );
};

export default SubscriptionPage;
