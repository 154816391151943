import Checkbox from "@mui/material/Checkbox";
import {
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import React, { useState } from "react";
import Scrollbar from "src/components/scrollbar";
import { PermissionCheck } from "src/utils/common";
import { apiDeleteMethod, apiPostMethod, apiPutMethod } from "src/api/rest";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "src/components/ConfirmModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { allApiUrl } from "src/api/apiRoute";
import { useSelector } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import { FORM_CATALOGUE_TABS} from "src/core/constants";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { BootstrapTooltipUi } from "src/components/mui-common/BootstrapToolTip";
import SelectAsync from "src/components-form/shared/SelectAsync";
import { TabsUi } from "src/components/mui-common/tabs-ui";
import { removeItemByKeyValue } from "src/shared/functions/array-conversion";
import AlertDialog from "src/components-form/shared/AlertDialog";
import LoadingSwitch from "src/components/mui-common/switch-ui-new";
import { ActionPreview } from "src/components/mui-common/ActionPreview";
import { ToolbarUiNew } from "src/components/mui-common/toolbar-ui-new";
import StartIcon from "@mui/icons-material/Start";
import { isEmpty } from "lodash";
import { _handleRedirect } from "src/shared/functions";
import {Grading} from "@mui/icons-material";
import {_isCompanyAdminRole, _isSuperAdminRole, formatDateFn} from "src/shared/functions/string-conversion";
import {headCellTrainingsCatalogue} from "src/components/mui-common/table-ui/headCells";
import TableHeadRow, {
  StyledTableCell,
  StyledTableNoData,
  StyledTableRow,
} from "src/components/mui-common/table-ui/TableHeadRow";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import TableRowsLoader from "src/components/mui-common/table-ui/TableRowsLoader";


const CommonHeader = ({
  title,
  numSelected,
  setSelected,
  rowCount,
  onSelectAllClick,
  dataFiltered,
  handleClick,
  selected,
  apiCall,
  loader,
  industryName,
  setIndustryName,
  _Tabs,
  currentTab,
  setCurrentTab,
  statusFilter,
  setStatusFilter,
  handleDeleteSelected,
  paginationObject,
  setPaginationObject,
  setSearchTerm,
}) => {
  const navigate = useNavigate();
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const isCompanyRole = _isCompanyAdminRole(loginUserInfo);
  const isSuperAdminRole = _isSuperAdminRole(loginUserInfo);

  const [open, setOpen] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(null);
  const [isLoadingDelete, setIsLoadingDelete] = useState(null);
  const [isLoadingClone, setIsLoadingClone] = useState(null);

  const handleChange = (event, item, idx) => {
    setIsUpdatingStatus(idx);
    let obj;
    if (isCompanyRole) {
      obj = {
        is_active: item.is_active !== true,
      };
    } else {
      obj = {
        name: item?.name,
        industry_id: item?.industry_id,
        totalmarks: parseInt(item.totalmarks),
        passingmarks: parseInt(item.passingmarks),
        certificatename: item.certificatename,
        validityperiod: item.validityperiod,
        is_active: item.is_active !== true,
        form_id: item?.form_id,
      };
    }

    const url = isCompanyRole
      ? `${allApiUrl?.SET_TRAINING_STATUS}/${item.id}`
      : `${allApiUrl?.EDIT_TRAINING}/${item.id}`;
    let method = isCompanyRole ? apiPostMethod : apiPutMethod;
    if (url) {
      apiPutMethod(url, obj)
        .then((data) => {
          toast.success("Status successfully updated.");
          apiCall();
        })
        .catch((err) => {
          toast.error(err?.data.message);
        })
        .finally(() => {
          setIsUpdatingStatus(null);
        });
    } else {
      setIsUpdatingStatus(null);
    }
  };

  const deleteData = () => {
    setIsModalOpen(false);
    const idx = dataFiltered.findIndex((v) => v.id === deleteId);
    setIsLoadingDelete(idx);
    const url = isCompanyRole
      ? `${allApiUrl?.COMPANY_DELETE_TRAINING}/${deleteId}`
      : `${allApiUrl?.DELETE_TRAINING}/${deleteId}`;
    apiDeleteMethod(url)
      .then((data) => {
        toast.success(data?.message);
        apiCall();
      })
      .catch((err) => {
        toast.error(err?.data?.message);
      })
      .finally(() => {
        setIsLoadingDelete(null);
      });
  };

  const handleClone = (item, idx) => {
    setIsLoadingClone(idx);
    const itemId = item?.id; 
    const url =
      isCompanyRole && currentTab.id === "TEMPLATE"
        ? `${allApiUrl?.CLONE_TRAINING_TEMPLATES}/${itemId}`
        : isCompanyRole
        ? `${allApiUrl?.CLONE_COMPANY_TRAINING}/${itemId}`
        : `${allApiUrl?.CLONE_SUPER_ADMIN_CLONE}/${itemId}`;

    apiPostMethod(url, {})
      .then((data) => {
        toast.success(data?.message);
        if (isCompanyRole && currentTab.id === "TEMPLATE") {
          handleTabChange(null, 0);
        } else {
          apiCall();
        }
      })
      .catch((err) => {
        toast.error(err?.data.message);
      })
      .finally(() => {
        setIsLoadingClone(null);
      });
  };
  const handleDelete = (id) => {
    setDeleteId(id);
  };
  const handleIndustryChange = (event, value) => {
    setIndustryName(event);
    setSelected([])
  };

  const handleTabChange = (event, newTab) => {
    const newTabChanged = _Tabs.filter((v) => v.tabIndex === newTab)[0];
    setCurrentTab(newTabChanged);
  };

  const headCellsAll = headCellTrainingsCatalogue;
  const getHeadCellsFn = () => {
    if (currentTab.id === "TRAININGS" && isSuperAdminRole) {
      return headCellsAll;
    } else if (currentTab.id === "TRAININGS" && isCompanyRole) {
      return removeItemByKeyValue(
        headCellsAll,
        PermissionCheck("Trainings", "delete", loginUserInfo)
          ? ["industry_name"]
          : ["checkbox", "industry_name"]
      );
    } else if (currentTab.id === "TEMPLATE") {
      return removeItemByKeyValue(headCellsAll, [
        "checkbox",
        "industry_name",
        "status",
      ]);
    } else {
      return headCellsAll;
    }
  };

  const handleStartTraining = (item) => {
    toast.dismiss();
    if (isEmpty(item?.form_id) && isEmpty(item?.form_is_active)) {
      toast.error("No Linked Form");
    } else if (!isEmpty(item?.form_id) && item?.form_is_active == false) {
      toast.error("Linked Form is not Active");
    } else {
      _handleRedirect(`/training/form-detail/${item?.form_id}`);
    }
  };
  return (
    <>
      <AlertDialog
        title={"Select Form"}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        handleDelete={() => {
          handleDeleteSelected();
          setOpenDeleteModal(false);
        }}
      />
      <Card className="cardFix">
        <div className="allocation">
          <ToolbarUiNew
            numSelected={numSelected}
            title={title}
            onSearch={(query) => setSearchTerm(query)}
            showDeleteButton={true}
            onDeleteClick={() => setOpenDeleteModal(true)}
            key={currentTab?.id}
          />
        </div>
        <div className="mt-0 mb-4">
          <TabsUi
            currentTab={currentTab.tabIndex || 0}
            handleTabChange={handleTabChange}
            tabsArray={_Tabs}
          />
        </div>

        {(currentTab.id === "TRAININGS" || isSuperAdminRole) && (
          <div style={{ padding: "0 15px 20px 15px" }}>
            <h6 className="mb-3">Filters:</h6>
            <Grid container columnSpacing={2}>
              {currentTab.id === "TRAININGS" && (
                <Grid item lg={3}>
                  <FormControl sx={{ width: "100%", marginBottom: "5px" }}>
                    <InputLabel id="select-status">Status</InputLabel>
                    <Select
                      labelId="select-status"
                      id="selectStatus"
                      label="Status"
                      value={statusFilter}
                      onChange={(event) => {setStatusFilter(event.target.value);setSelected([])}}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      <MenuItem value={true}>Active</MenuItem>
                      <MenuItem value={false}>In Active</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {isSuperAdminRole && (
                <Grid item lg={3}>
                  <SelectAsync
                    isClearable
                    onChange={handleIndustryChange}
                    searchType="industry"
                    selectedValue={industryName}
                    placeholder="Select Industry"
                    className="asyncHeight training"
                    dataParam={{ isFilter: true }}
                    selectBoxKey={currentTab}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        )}

        <Scrollbar>
          <TableContainer>
            <Table sx={{ minWidth: 800 }}>
              <TableHeadRow
                numSelected={selected.length}
                rowCount={dataFiltered.length}
                headCells={getHeadCellsFn()}
                listData={dataFiltered}
                setSelected={setSelected}
              />

              <TableBody>
                {loader ? (
                  <TableRowsLoader
                    rowsNum={10}
                    columnNum={getHeadCellsFn().length}
                  />
                ) : isEmpty(dataFiltered) ? (
                  <StyledTableNoData colSpan={getHeadCellsFn().length} />
                ) : (
                  dataFiltered?.map((item, index) => {
                    return (
                      <StyledTableRow
                        role="checkbox"
                        tabIndex={-1}
                        key={`styledTableRow_${index}`}
                      >
                        {getHeadCellsFn().map((headCell) =>
                          <StyledTableCell
                            padding={
                              headCell.id === "checkbox" ? "checkbox" : "normal"
                            }
                            className="nowrapFull"
                            key={`tableCell_${headCell.id}`}
                          >
                            { headCell.id === "checkbox" ? (
                              <Checkbox
                                disableRipple
                                checked={selected.indexOf(item.id) !== -1}
                                onChange={(event) =>
                                  handleClick(event, item.id)
                              }
                              />
                            ) : headCell.id === "status" ? (
                              <FormControlLabel
                                disabled={isUpdatingStatus === index || !PermissionCheck(
                                  "Trainings",
                                  "update",
                                  loginUserInfo
                                )}
                                control={
                                  <LoadingSwitch
                                    loading={isUpdatingStatus === index}
                                    color="success"
                                    checked={Boolean(item?.is_active)}
                                    onChange={(e) =>
                                      handleChange(e, item, index)
                                    }
                                  />
                                }
                                label={item?.is_active ? "Active" : "In Active"}
                              />
                          ) : headCell.id === "actions" ? (
                            <div style={{ whiteSpace: "nowrap" }}>
                                {PermissionCheck(
                                  "Trainings",
                                  "update",
                                  loginUserInfo
                                ) &&
                                  currentTab.id !== "TEMPLATE" && (
                                    <BootstrapTooltipUi
                                      title="Edit"
                                      placement="top"
                                    >
                                      <IconButton
                                        className="outerborder"
                                        aria-label="Edit"
                                        onClick={() => {
                                          navigate(
                                            `/training/update-training/${item.id}`
                                          );
                                        }}
                                      >
                                        <DriveFileRenameOutlineIcon />
                                      </IconButton>
                                    </BootstrapTooltipUi>
                                  )}

                                <BootstrapTooltipUi
                                  title="Clone"
                                  placement="top"
                                >
                                  <IconButton
                                    className="outerborder "
                                    aria-label="Clone"
                                    onClick={() => {
                                      handleClone(item, index);
                                    }}
                                    disabled={isLoadingClone === index}
                                  >
                                    {isLoadingClone === index ? (
                                      <CircularProgress size={20} />
                                    ) : (
                                      <ContentCopyIcon />
                                    )}
                                  </IconButton>
                                </BootstrapTooltipUi>

                                <ActionPreview
                                  permissionCheckType={"Trainings"}
                                  formId={item?.form_id}
                                  loginUserInfo={loginUserInfo}
                                  currentTab={"TRAINING_FORM"}
                                />

                                {PermissionCheck(
                                  "Trainings",
                                  "delete",
                                  loginUserInfo
                                ) &&
                                  currentTab.id !== "TEMPLATE" && (
                                    <BootstrapTooltipUi
                                      title="Delete"
                                      placement="top"
                                    >
                                      <IconButton
                                        className="outerborder"
                                        aria-label="Delete"
                                        onClick={() => {
                                          setIsModalOpen(true);
                                          handleDelete(item.id);
                                        }}
                                        sx={{ color: "error.main" }}
                                        disabled={isLoadingDelete === index}
                                      >
                                        {isLoadingDelete === index ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          <DeleteIcon />
                                        )}
                                      </IconButton>
                                    </BootstrapTooltipUi>
                                  )}
                                {isCompanyRole &&
                                  currentTab.id === "TRAININGS" && (
                                    <>
                                      <BootstrapTooltipUi
                                        title={
                                          (isEmpty(item?.form_id) &&
                                            isEmpty(item?.form_is_active)) ||
                                          (!isEmpty(item?.form_id) &&
                                            item?.form_is_active == false)
                                            ? "No Start Training Available"
                                            : "Start Training"
                                        }
                                        placement="top"
                                      >
                                        <IconButton
                                          className="outerborder "
                                          aria-label="Start Training"
                                          onClick={() => {
                                            handleStartTraining(item);
                                          }}
                                        >
                                          <StartIcon />
                                        </IconButton>
                                      </BootstrapTooltipUi>
                                      <BootstrapTooltipUi
                                      title={!item?.form_id ? "No Saved Entry":"Saved Entries"}
                                      placement="top"
                                    >
                                      <IconButton
                                        className="outerborder "
                                        onClick={() => {
                                          if (item?.form_id) {
                                          _handleRedirect(`/forms/saved-entries/${item?.form_id}`,{currentTab: FORM_CATALOGUE_TABS.TRAINING_FORM})
                                        }}}
                                      >
                                        <Grading />
                                      </IconButton>
                                    </BootstrapTooltipUi>
                                    </>
                                  )}
                              </div>
                          ) : (
                              headCell?.isDate ? formatDateFn(item[headCell.id]) : item[headCell.id]
                          )}
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    );
                  })
                )}
                <ConfirmModal
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  deleteData={deleteData}
                  setOpen={setOpen}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePaginationCompo
          paginationObject={paginationObject}
          setPaginationObject={setPaginationObject}
          setSelected={setSelected}
        />
      </Card>
    </>
  );
};

export default CommonHeader;
