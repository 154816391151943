import React from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, Typography, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const EditModal = ({ isModalOpen, setIsModalOpen, deleteData }) => {
  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="confirmModal">
        <div></div>
        <Typography id="modal-modal-description" style={{ marginTop: "10px" }}>
          Permission Update: Logging Out
        </Typography>
        <Typography>
          For security reasons you must be logged out before updating your
          permissions.
        </Typography>
        <div
          style={{
            display: "flex",
            marginTop: "25px",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Button onClick={() => deleteData()} className="deleteButton">
            Logout
          </Button>
          <Button
            onClick={() => setIsModalOpen(false)}
            className="cancelButton"
          >
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default EditModal;