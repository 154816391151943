import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CancelIcon from "@mui/icons-material/Cancel";
import { BootstrapTooltipUi } from "src/components/mui-common/BootstrapToolTip";
import { apiDeleteMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import { LoadingButton } from "@mui/lab";

export const Attachment = ({
  attachment,
  id,
  setAttachmentsList,
  setFieldValue,
  setAttachmentIds,
}) => {
  const [file, setFile] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentDeleteItem, setCurrentDeleteItem] = useState(null);
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFieldValue("attachment", selectedFiles);

    const fileNames = selectedFiles.map((file) => file.name);
    setFile((prevFiles) => [...prevFiles, ...fileNames]);
    setAttachmentsList((prevList) => [...prevList, ...fileNames]);
  };

  useEffect(() => {
    if (attachment?.length > 0) {
      const fileArray = attachment.map((item) => item.project_attachment_key);
      const idsArray = attachment.map((item) => item.project_attachment_id);
      setFile(fileArray);
      setAttachmentIds(idsArray);
    }
  }, [attachment, setAttachmentIds]);

  const handleOpenDeleteModal = (item, index) => {
    setOpenDeleteModal(true);
    setCurrentDeleteItem({ item, index });
  };

  const handleDeleteConfirmed = async () => {
    const { item, index } = currentDeleteItem;

    if (attachment && attachment[index]) {
      try {
        await apiDeleteMethod(
          `${allApiUrl?.DELETE_ATTACHMENT}/${id}/${attachment[index].project_attachment_id}`
        );
        console.log(
          "Deleted from server:",
          attachment[index].project_attachment_id
        );

        const updatedAttachments = attachment.filter((_, idx) => idx !== index);
        setAttachmentsList(updatedAttachments);

        const updatedFiles = file.filter((_, idx) => idx !== index);
        setFile(updatedFiles);
      } catch (error) {
        console.error("Error deleting the attachment:", error);
      }
    } else {
      const updatedFiles = file.filter((_, idx) => idx !== index);
      setFile(updatedFiles);
      setAttachmentsList(updatedFiles); 
    }
    setOpenDeleteModal(false);
    setCurrentDeleteItem(null);
  };

  return (
    <Grid item md={6} sm={12}>
      <div className="fileuploadWrapper fieldmarginBottom">
        <label htmlFor="file-upload" className="fileuploadPadding">
          <Button
            variant="outlined"
            component="span"
            startIcon={<AttachFileIcon />}
            className="fileButton"
          >
            <p>{"Attachment"}</p>
          </Button>
        </label>
        <input
          accept=".pdf,.jpg,.jpeg,.png"
          id="file-upload"
          type="file"
          multiple
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </div>
      <div className={file?.length > 0 && "dataList"}>
        <ul>
          {file?.length > 0 &&
            file?.map((item, index) => (
              <li key={index}>
                {item}{" "}
                <BootstrapTooltipUi title="Delete" placement="top">
                  <CancelIcon
                    style={{
                      color: "#ee7000",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleOpenDeleteModal(item, index)}
                  />
                </BootstrapTooltipUi>
              </li>
            ))}
        </ul>
      </div>

      {/* Delete Confirmation Modal */}
      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
          }}
        >
          <Typography variant="h6" component="h2">
            Confirm Deletion
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Are you sure you want to delete this attachment?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <LoadingButton
              variant="contained"
              className={`blueButton`}
              type="submit"
              color="inherit"
              sx={{ mr: "10px" }}
              onClick={handleDeleteConfirmed}
            >
              Delete
            </LoadingButton>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={handleDeleteConfirmed}
            >
              Delete
            </Button> */}
            <Button
              className="blueButton cancel"
              variant="outlined"
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
};
