import {
  Box,
  Card,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { allApiUrl } from "src/api/apiRoute";
import { apiGetMethod } from "src/api/rest";
import PageHeader from "src/components-form/Common/PageHeader";
import Scrollbar from "src/components/scrollbar";
import {
  COMPANYROLES,
  PAGINATION_OBJECT,
  SUPERADMINROLES,
} from "src/core/constants";
import {
  _isCompanyAdminRole,
  _isSuperAdminRole,
  formatDateFn,
} from "src/shared/functions/string-conversion";
import { BootstrapTooltipUi } from "src/components/mui-common/BootstrapToolTip";
import { Visibility } from "@mui/icons-material";
import SelectAsync from "src/components-form/shared/SelectAsync";
import IconButton from "@mui/material/IconButton";
import {
  _handleRedirect,
  _setPaginationObjectFn,
  createQueryString,
} from "src/shared/functions";
import { headCellTrainingsAttempts } from "src/components/mui-common/table-ui/headCells";
import TableHeadRow, {
  StyledTableCell,
  StyledTableNoData,
  StyledTableRow,
} from "src/components/mui-common/table-ui/TableHeadRow";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import TableToolbar from "src/components/mui-common/table-ui/TableToolbar";
import TableRowsLoader from "src/components/mui-common/table-ui/TableRowsLoader";
import { isEmpty } from "src/shared/functions/isEmpty";
import {_handleChangePage} from "../../utils/common";

export const TrainingsAttempts = () => {
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const isSuperAdminRole = _isSuperAdminRole(loginUserInfo);
  const isCompanyRole = _isCompanyAdminRole(loginUserInfo);
  const [selected, setSelected] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [loader, setLoader] = useState(true);
  const [companyName, setCompanyName] = useState(null);
  const [status, setStatus] = useState("all");
  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);
  const [searchTerm, setSearchTerm] = useState("");
  const headCells = headCellTrainingsAttempts;

  useEffect(() => {
    if (paginationObject.triggerApi) {
      apiCall();
    }
  }, [paginationObject.triggerApi]);
  useEffect(() => {
    try {
      _handleChangePage({}, 0, setPaginationObject,setSelected)
    } catch (e) {}
  }, [searchTerm, status, companyName])

  function apiCall() {
    setLoader(true);
    let queryObject = {
      search: searchTerm || "",
      companyId: companyName?.value,
      certStatus: status === "all" ? null : status,
      pageNumber: paginationObject?.page,
      limit: paginationObject?.rowsPerPage,
    };
    const qryStr = createQueryString(queryObject);

    apiGetMethod(
      `${
        isCompanyRole
          ? allApiUrl?.COMPANY_TRAINING_ATTEMPTS
          : allApiUrl?.ADMIN_TRAINING_ATTEMPTS
      }${qryStr}`
    )
      .then((data) => {
        setDataFiltered(data.data);
        setPaginationObject((prevState) => {
          return _setPaginationObjectFn(prevState, data.meta);
        });
      })
      .catch((err) => {
        setDataFiltered([...[]]);
        setPaginationObject(PAGINATION_OBJECT);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  const handleCompanyChange = (event, value) => {
    setCompanyName(event);
    setSelected([])
  };
  const handleChange = (event) => {
    setStatus(event.target.value);
    setSelected([])
  };

  return (
    <>
      <Helmet>
        <title>
          Trainings Attempts | {process.env.REACT_APP_APPLICATION_NAME}{" "}
        </title>
      </Helmet>
      <Box>
        <div className="flexTop">
          <PageHeader
            header={"Trainings Attempts"}
            links={[
              {
                name: "Dashboard",
                path: "/",
              },
            ]}
          />
        </div>
        <Card className="cardFix">
          <TableToolbar
            title="Training Attempts"
            numSelected={selected.length}
            setSelected={setSelected}
            selected={selected}
            apiCall={apiCall}
            onSearch={(query) => setSearchTerm(query)}
          />

          <div style={{ padding: "20px 15px 20px 15px" }}>
            <h6 className="mb-3">Filters:</h6>
            <Grid container columnSpacing={2}>
              {isSuperAdminRole && (
                <Grid item lg={3}>
                  <SelectAsync
                    isClearable
                    onChange={handleCompanyChange}
                    searchType="companyid"
                    selectedValue={companyName}
                    placeholder="Select Company"
                    className="asyncHeight training"
                  />
                </Grid>
              )}
              <Grid item lg={3}>
                <FormControl
                  sx={{ width: "100%", marginBottom: "5px" }}
                >
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    label="Status"
                    onChange={handleChange}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"FAILED"}>Fail</MenuItem>
                    <MenuItem value={"PASSED"}>Pass</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <Scrollbar>
            <TableContainer sx={{ overflow: "unset" }}>
              <Table>
                <TableHeadRow
                  numSelected={selected.length}
                  rowCount={dataFiltered.length}
                  headCells={headCells}
                  listData={dataFiltered}
                  setSelected={setSelected}
                />
                <TableBody>
                  {loader ? (
                    <TableRowsLoader
                      rowsNum={10}
                      columnNum={headCells.length}
                    />
                  ) : isEmpty(dataFiltered) ? (
                    <StyledTableNoData colSpan={headCells.length} />
                  ) : (
                    dataFiltered?.map((item, index) => {
                      return (
                        <StyledTableRow
                          role="checkbox"
                          tabIndex={-1}
                          key={`styledTableRow_${index}`}
                        >
                          {headCells.map((hCell) => (
                            <StyledTableCell
                              padding={
                                hCell.id === "checkbox" ? "checkbox" : "normal"
                              }
                              className="nowrapFull"
                              key={`tableCell_${hCell.id}`}
                            >
                              {hCell.id === "status" ? (
                                <>
                                  {item?.cert_status === "FAILED" ? (
                                    <Chip label="FAIL" color="error" />
                                  ) : (
                                    <Chip label="PASS" color="success" />
                                  )}
                                </>
                              ) : hCell.id === "actions" ? (
                                <div className="nowrap16">
                                  <BootstrapTooltipUi
                                    title="View"
                                    placement="top"
                                  >
                                    <IconButton
                                      className="outerborder"
                                      aria-label="View"
                                      onClick={() =>
                                        _handleRedirect(
                                          `/training-attempts/form-details/${item.id}`
                                        )
                                      }
                                    >
                                      <Visibility />
                                    </IconButton>
                                  </BootstrapTooltipUi>
                                </div>
                              ) : hCell?.isDate ? (
                                formatDateFn(item[hCell.id])
                              ) : (
                                item[hCell.id]
                              )}
                            </StyledTableCell>
                          ))}
                        </StyledTableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePaginationCompo
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            setSelected={setSelected}
          />
        </Card>
      </Box>
    </>
  );
};
