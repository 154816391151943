import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { TRAINING_STATUS } from "src/core/constants";
import { isEmpty } from "src/shared/functions/isEmpty";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export const DownloadPdf = ({
  jsonData,
  WidgetsData,
  TagHtmlRow,
  form_settings,
  HeaderTitleHtml,
  setLoader,
}) => {
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    if (isLoader) {
      setTimeout(() => {
        createCanvas(WidgetsData.length).catch(() => {});
      }, 500)
    }
  }, [isLoader]);

  const createCanvas = async (length) => {
    let headerInput = document.getElementById(`previewCanvas_header`);
    let headerCanvas = await html2canvas(headerInput, {
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight,
    });
    let canvasNewData = [...new Array(length)].map((_e, index) => {
      let input = document.getElementById(`previewCanvas_${index}`);
      return html2canvas(input, {
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
      }).then((canvas) => {
        return canvas;
      });
    });
    let canvasPromise = [headerCanvas,...canvasNewData]
    setIsLoader(false);

    if (canvasPromise) {
      Promise.all(canvasPromise).then((res) => {
        printDocument(res);
      });
    }
  };
  const printDocument = (canvasData) => {
    const pdf = new jsPDF();
    let margin = 10;
    let pageWidth = pdf.internal.pageSize.getWidth();
    let pageHeight = pdf.internal.pageSize.getHeight();
    let usedHeight = 10;
    canvasData.map((canvas, indx) => {
      let imgData = canvas.toDataURL("image/png");
      let imgHeight = (canvas.height * pageWidth) / canvas.width;
      let newHeight = imgHeight + usedHeight;
      if (pageHeight < newHeight) {
        pdf.addPage();
        pdf.addImage(
          imgData,
          "PNG",
          margin,
          10,
          pageWidth - 2 * margin,
          imgHeight,
          undefined,
          "FAST"
        );
        usedHeight = 10 + imgHeight;
      } else {
        pdf.addImage(
          imgData,
          "PNG",
          margin,
          usedHeight,
          pageWidth - 2 * margin,
          imgHeight,
          undefined,
          "FAST"
        );
        usedHeight = newHeight;
      }

      if (!(pageHeight < newHeight)) {}
    });
    jsonData?.form_settings?.isTrainingForm != false &&  pdf.addPage();


    const isPassed = (TRAINING_STATUS.PASSED === jsonData?.usertrainingData?.usertraining_status)

    const dialogWidth = 140;
    const dialogHeight = isPassed ? 80 : 60;
    const imgDatas = isPassed ? "/assets/icons/trophy.png" : "/assets/icons/failed.png";
    const textSegments = isPassed ? [
      {
        text: "Congratulations!! You have passed the quiz.",
        fontSize: 16,
        fontType: "bold",
        color: "#212B36;",
      },
      {
        text: "You did it! You just have aced your latest test",
        fontSize: 14,
        fontType: "NunitoSans",
        color: "#777",
      },
      {
        text: ",and we are thrilled to celebrate your achievement with you.",
        fontSize: 14,
        fontType: "NunitoSans",
        color: "#777",
      },
      {
        text: `Certificate: ${jsonData?.trainingData?.training_passing_marks}`,
        fontSize: 19,
        fontType: "bold",
        color: "#212B36;",
      },
      {
        text: `Total Marks: ${jsonData?.trainingData?.training_total_marks}`,
        fontSize: 19,
        fontType: "bold",
        color: "#212B36;",
      },
    ] : [
      {
        text: "Sorry!! You have failed this quiz. ",
        fontSize: 16,
        fontType: "bold",
        color: "#212B36;",
      },
      {
        text: "You can retake the quiz to achieve better results. ",
        fontSize: 14,
        fontType: "NunitoSans",
        color: "#777",
      },
      {
        text: `Total Marks: ${jsonData?.trainingData?.training_total_marks}  Marks Obtained: ${jsonData?.trainingData?.training_passing_marks}`,
        fontSize: 19,
        fontType: "bold",
        color: "#212B36;",
      },
    ];
    const dialogX = (pdf.internal.pageSize.getWidth() - dialogWidth) / 2;
    const dialogY = 40;

    jsonData?.form_settings?.isTrainingForm != false &&   pdf.setDrawColor(0);
    jsonData?.form_settings?.isTrainingForm != false &&  pdf.setFillColor(255, 255, 255);
    jsonData?.form_settings?.isTrainingForm != false &&  pdf.roundedRect(dialogX, dialogY, dialogWidth, dialogHeight, 5, 5, "FD");
    const imgX = dialogX + 60;
    const imgY = dialogY + 2;
    const imgWidths = 15;
    const imgHeights = 15;

    jsonData?.form_settings?.isTrainingForm != false &&  pdf.addImage(imgDatas, "PNG", imgX, imgY, imgWidths, imgHeights);

    let textX =  jsonData?.form_settings?.isTrainingForm != false && pageWidth / 2;
    let textY =  jsonData?.form_settings?.isTrainingForm != false&&  dialogY + 25;
    let lineHeight =  jsonData?.form_settings?.isTrainingForm != false&&  10;

    jsonData?.form_settings?.isTrainingForm != false &&   pdf.setFontSize(12);
    jsonData?.form_settings?.isTrainingForm != false &&  pdf.setTextColor(0);
    jsonData?.form_settings?.isTrainingForm != false &&  textSegments.forEach((segment) => {
      pdf.setFontSize(segment.fontSize);
      pdf.setFont(segment.fontType);
      pdf.setTextColor(segment.color);
      pdf.text(segment.text, textX, textY, { align: "center" });

      textY += lineHeight;
    });

    setTimeout(() => {
      pdf.setProperties({
        title: `${jsonData?.title}_${new Date().getTime()}`
      });
      pdf.output("dataurlnewwindow");
      setLoader(false);
    }, 2000);
  };
  return (
    <div style = {{textAlign:"end", marginBottom:"30px"}}>
      {isLoader && (
        <div id="export_pdf_container">
          {!isEmpty(WidgetsData.length) && (
            <div id="splitformPreview">
              <div id={"previewCanvas_header"}>
                <HeaderTitleHtml
                  activePage={0}
                  form_settings={form_settings}
                  hideIcons={true}
                  showPageNo={Boolean(WidgetsData[0]?.showHidePageCount)}
                />
              </div>

              {WidgetsData.length > 0 && WidgetsData[0].element !== undefined
                ? WidgetsData.map((form, index) => {
                    return (
                      <div id={`previewCanvas_${index}`} key={`previewCanvas_${index}`}>
                        {form.structure?.map((data, index) => {
                          return TagHtmlRow(data, index);
                        })}
                      </div>
                    );
                  })
                : WidgetsData.map((data, index) => {
                    return (
                      <div id={`previewCanvas_${index}`} key={`previewCanvas_${index}`}>
                        {TagHtmlRow(data, index)}
                      </div>
                    );
                  })}
            </div>
          )}
        </div>
      )}
      <Button
        id="removefrompdf"
        className="blueButton"
        variant="contained"
        color="inherit"
        startIcon={<PictureAsPdfIcon />}
        onClick={() => {
          setIsLoader(true);
          setLoader(true);
        }}
      >
        Export Pdf
      </Button>
    </div>
  );
};
