import { isEmpty } from "./isEmpty";
import * as _ from "lodash";
import * as moment from "moment";
import { customReduce } from "./array-conversion";
import { Chip, Stack } from "@mui/material";
import {COMPANYROLES, ROLES, SUPERADMINROLES} from "src/core/constants";

export const convertToUrl = (inputString) => {
  if (/^(https?|ftp):\/\//i.test(inputString)) {
    return inputString; 
  } else {
    return "http://" + inputString;
  }
};

export const getFileExtension = (filename) => {
  const extension = filename?.split(".").pop().toLowerCase();
  const fileTypes = {
    // Image types
    jpg: "image",
    jpeg: "image",
    png: "image",
    gif: "image",
    bmp: "image",
    ico: "image",
    svg: "image",
    tiff: "image",
    webp: "image",
    // Add more image types as needed
    // ...
    // Video types
    mp4: "video",
    avi: "video",
    mov: "video",
    // Document types
    pdf: "pdf",
    doc: "document",
    docx: "document",
    txt: "document",
    rtf: "document",
    // Spreadsheet types
    xls: "spreadsheet",
    xlsx: "spreadsheet",
    csv: "spreadsheet",
    // Presentation types
    ppt: "presentation",
    pptx: "presentation",
    // Add more file types as needed
  };

  return fileTypes[extension] || "unknown";
};

export const removeSpace = (inputString, toLowerCase = true) =>
  !isEmpty(inputString)
    ? toLowerCase ? inputString.replace(/ /g, "_").toLowerCase() : inputString.replace(/ /g, "_")
    : inputString;
export const addSpace = (inputString, toLowerCase = true) =>
  !isEmpty(inputString)
    ? toLowerCase ? inputString.replace(/_/g, " ").toLowerCase() : inputString.replace(/_/g, " ")
    : inputString;
export const pascalCaseToSpaceSeparated = (inputString) =>
  !isEmpty(inputString)
    ? inputString.replace(/([a-z])([A-Z])/g, "$1 $2")
    : inputString;
export const removeAfter = (inputString, separator) =>
  !isEmpty(inputString) ? inputString.split(separator)[0] : inputString;
export const removeHttp = (inputString) =>
  !isEmpty(inputString) ? inputString.replace(/^https?:\/\//, "") : inputString;
export const evaluateExpression = (data, stringValues) => {
  try {
    let expression = convertStringToExpression2(data?.calculation);
    const hasEqualOp = expression.startsWith("=");
    const returnType = data?.type || "number";
    const decimalPlace = data?.decimal || 0;
    if (hasEqualOp) {
      expression = expression.slice(1);
    }

    const replacedExpression = expression.replace(
      /(\$\w+)/g,
      (match, stringId) => {
        const id = stringId.slice(1);
        const value = stringValues[id];
        const finalValue = encodeValueFn(value, id);
        return finalValue ? finalValue : 0; //todo check if 0 is correct
      }
    );

    try {
      const result = eval(replacedExpression);
      return returnType === "percent"
        ? hasEqualOp
          ? `${formatNum(result * 100, decimalPlace, decimalPlace)}%`
          : `${formatNum(result, decimalPlace, decimalPlace)}%`
        : returnType === "currency"
        ? `$${formatNum(result, decimalPlace, 2)}`
        : formatNum(result, decimalPlace, decimalPlace);
    } catch (error) {
      //console.error('Error evaluating expression:', error);
      return null;
    }
  } catch (e) {}
};

export const convertStringToExpression = (inputString) => {
  return !isEmpty(inputString) ? inputString.replace(/\$[^_]*__/g, "$") : "";
};
export const convertStringToExpression2 = (inputString) => {
  if (!isEmpty(inputString)) {
    return inputString.replace(
      /\{\{(.*?)\}\}/g,
      (match, group1) => "$" + group1
    );
  } else {
    return null;
  }
};
export const replaceLabelConstToValues = (text, values) => {
  try {
    let finalValues = {};
    Object.entries(values).forEach(([key, value]) => {
      finalValues = encodeStringFn(finalValues, value, key);
    });

    Object.entries(finalValues).forEach(([key, value]) => {
      const placeholder = `{{${key}}}`;
      const convertedVal = !isEmpty(value)
        ? getInputType(key) === "timePicker" && isDateOrTime(value)
          ? moment(value).format("hh:mm A")
          : getInputType(key) === "datePicker" && isDateOrTime(value)
          ? formatDate(value, "DD/MM/YYYY")
          : typeof value === "object" &&
            _.includes(["checkBoxes", "selectBoxes"], getInputType(key))
          ? customReduce(_.map(value, (o) => o.value))
          : typeof value === "object"
          ? customReduce(value)
          : value
        : "";

      text = text.replace(new RegExp(placeholder, "g"), convertedVal);
    });
    return text.replace(/{{|}}/g, "");
  } catch (e) {
    return text;
  }
};
export const replaceLabelStrings = (
  label,
  stringValues,
  defaultLabel = "Untitled"
) => {
  if (!isEmpty(label)) {
    try {
      return replaceLabelConstToValues(label, stringValues);
    } catch (e) {
      return label;
    }
  } else {
    return defaultLabel;
  }
};
const formatDate = (dateString, format) => {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };
  const formattedDate = date.toLocaleString("en-US", options);

  if (format) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return format
      .replace("YYYY", year)
      .replace("MM", month)
      .replace("DD", day)
      .replace("HH", hours)
      .replace("mm", minutes)
      .replace("ss", seconds);
  }

  return formattedDate;
};
const isDateOrTime = (input) => {
  const parsedDate = moment(input);
  return parsedDate.isValid();
};
export const getInputType = (str) => {
  const timePickerRegex = /^SelectTimePicker_([a-zA-Z0-9]+)$/;
  const datePickerRegex = /^SelectDatePicker_([a-zA-Z0-9]+)$/;
  const checkBoxesRegex = /^CheckBoxes_([a-zA-Z0-9]+)$/;
  const selectBoxesRegex = /^DropDown_([a-zA-Z0-9]+)$/;
  const selectBoxesDropDownRegex = /^field_id_DropDown_([a-zA-Z0-9]+)$/;
  const calculationRegex = /^field_id_Calculation_([a-zA-Z0-9]+)$/;

  if (timePickerRegex.test(str)) {
    return "timePicker";
  } else if (datePickerRegex.test(str)) {
    return "datePicker";
  } else if (checkBoxesRegex.test(str)) {
    return "checkBoxes";
  } else if (selectBoxesRegex.test(str)) {
    return "selectBoxes";
  } else if (selectBoxesDropDownRegex.test(str)) {
    return "selectBoxes";
  } else if (calculationRegex.test(str)) {
    return "calculation";
  } else {
    return typeof str;
  }
};
export const highlightTextWithBackground = (inputText) => {
  const regex = /{{(.*?)}}/g; 
  return inputText.replace(
    regex,
    `<span style="background-color: #dfeaea; color: #55727b; border-color: rgba(0,0,0,0)">$1</span>`
  );
};
export const formatNum = (num = 0, minFD = 0, maxFD = 0) => {
  return num.toLocaleString(undefined, {
    minimumFractionDigits: minFD,
    maximumFractionDigits: maxFD,
  });
};
export const addSpaceBeforeCapitalLetters = (str) => {
  return str.replace(/([A-Z])/g, " $1").trim();
};
export const formatUpperLetterWithSpace = (str) => {
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
  return capitalized.replace(/([A-Z])/g, " $1").trim();
};

export const formatEmptyValue = (v) => v.replace(/<p><br><\/p>/g, "");

const encodeStringFn = (finalValues, value, key) => {
  if (
    value &&
    typeof value === "object" &&
    !_.includes(["checkBoxes", "selectBoxes"], getInputType(key))
  ) {
    Object.entries(value).forEach(([key2, value2]) => {
      finalValues[`${key}.${key2}`] = value2;
    });
  }
  if (
    value &&
    typeof value === "object" &&
    _.includes(["checkBoxes", "selectBoxes"], getInputType(key))
  ) {
    const temVal = _.map(value, (o) => o.val);
    finalValues[key] = customReduce(temVal);
  } else {
    finalValues[key] = value;
  }
  return finalValues;
};
const encodeValueFn = (value, key) => {
  let finalValues = null;
  if (!isEmpty(value)) {
    if (
      typeof value === "object" &&
      !_.includes(["checkBoxes", "selectBoxes"], getInputType(key))
    ) {
    } else if (
      typeof value === "object" &&
      _.includes(["checkBoxes", "selectBoxes"], getInputType(key))
    ) {
      const temVal = _.map(value, (o) => o.val);
      finalValues = `(${customReduce(temVal)})`;
    } else {
      finalValues = `(${_.toNumber(value)})`;
    }
  }
  return finalValues;
};
export const formatDateFn = (dateStr, onlyDate = false) => {
  return !isEmpty(dateStr) ? moment(dateStr).format(onlyDate ? "ll" : "lll") : "";
};
export const calculateExpiryDateNew = (startDate, validityPeriod, chip = false, status = false) => {
  let valid = false;
  const regex = /^\d+\s+(year|years|month|months|week|weeks|day|days|hour|hours|minute|minutes|second|seconds)$/i;
  const isValidityPeriodValid = regex.test(validityPeriod);

  const start = moment(startDate);
  const isStartDateValid = start.isValid();

  if (!isValidityPeriodValid || !isStartDateValid) {
    valid = status ? false : '';
  }

  const [value, unit] = validityPeriod.split(" ");
  const expirationDate = start.add(parseInt(value), unit?.toLowerCase());

  const today = moment().startOf('day');

  if (status) {
    valid = expirationDate.isSameOrAfter(today);
  }

  if(status) {
    return valid
  } else {
    return (
      <div>
        {!chip && (
          <p style={valid === false ? {color: "red"} : {}}>
            {formatDateFn(expirationDate)}
          </p>
        )}
        {chip && (
          <Stack spacing={1} alignItems="left">
            <Stack direction="row" spacing={1}>
              {valid === false ? (
                <Chip label="Expired" color="error"/>
              ) : (
                <Chip label="Valid" color="success"/>
              )}
            </Stack>
          </Stack>
        )}
      </div>
    );
  }
}
export const calculateExpiryDate = (trainingDate, validityPeriod, chip , status = false) => {
  let date = moment(trainingDate);
  const regex = /(\d+)\s*([a-zA-Z]+)/g;
  let match;
  const components = { years: 0, days: 0, hours: 0, minutes: 0, seconds: 0 ,months:0};
  while ((match = regex.exec(validityPeriod)) !== null) {
    const value = parseInt(match[1], 10);
    const unit = match[2].toLowerCase();
    switch (unit) {
      case "month":
        case "months":
          components.months += value;
          break;
      case "year":
      case "years":
        components.years += value;
        break;
      case "day":
      case "days":
        components.days += value;
        break;
      case "hour":
      case "hours":
        components.hours += value;
        break;
      case "minute":
      case "minutes":
        components.minutes += value;
        break;
      case "second":
      case "seconds":
        components.seconds += value;
        break;
      default:
        console.warn(`Unknown unit: ${unit}`);
    }
  }
  const expiryDate = date
    .add(components.years, "years")
    .add(components.months, "months")
    .add(components.days, "days")
    .add(components.hours, "hours")
    .add(components.minutes, "minutes")
    .add(components.seconds, "seconds");
  let today = moment(new Date());
  let valid = false;
  if (today.isBefore(expiryDate)) {
    valid = true;
  }

  if(status){
    return {
      expiryDate,
      valid
    } 
  }else{
    return (
      <div>
        {!chip && (
          <p style={valid === false ? { color: "red" } : {}}>
            {formatDateFn(expiryDate)}
          </p>
        )}
        {chip && (
          <Stack spacing={1} alignItems="left">
            <Stack direction="row" spacing={1}>
              {valid === false ? (
                <Chip label="Expired" color="error" />
              ) : (
                <Chip label="Valid" color="success" />
              )}
            </Stack>
          </Stack>
        )}
      </div>
    );
  }
};

export const _errorMsg = (err) => {
  return err?.message ? err.message
    : err?.data?.message
      ? err?.data?.message
      : "";
}

export const _notCompanyUser = (loginUserInfo) => {
  try {
    return Object.values(COMPANYROLES)
      ?.filter((e) => e !== ROLES.FORM_USER)
      ?.includes(loginUserInfo?.roleid[0])
  } catch (e) {
    return false;
  }
}
export const _isCompanyUser = (loginUserInfo) => {
  try {
    return Object.values(COMPANYROLES)
      ?.filter((e) => e === ROLES.FORM_USER)
      ?.includes(loginUserInfo?.roleid[0])
  } catch (e) {
    return false;
  }
}
export const _isCompanyAdminRole = (loginUserInfo) => {
  try {
    return Object.values(COMPANYROLES)?.includes(
      loginUserInfo?.roleid[0]
    );
  } catch (e) {
    return false;
  }
}
export const _isSuperAdminRole = (loginUserInfo) => {
  try {
    return Object.values(SUPERADMINROLES)?.includes(
      loginUserInfo?.roleid[0]
    );
  } catch (e) {
    return false;
  }
}
