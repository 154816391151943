import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import './responsive.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from 'src/contexts/AuthProvider';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store from "./redux/store";
import ThemeProvider from 'src/theme';
import Interceptor from "./api/interceptor";
/*import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_CURRENT_ENV === 'PRODUCTION') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/comply-logic\.vercel\.app\/api/, /^https:\/\/api-cognito.softuvo\.click/], //"localhost",
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}*/


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  /*<React.StrictMode>*/
    <Provider store={store().store}>
      <PersistGate loading={null} persistor={store().persistor}>
        <HelmetProvider>
          <BrowserRouter>
            <AuthProvider>
              <ThemeProvider>
                <ToastContainer newestOnTop limit={1} />
                <Interceptor />
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </ThemeProvider>
            </AuthProvider>
          </BrowserRouter>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  /*</React.StrictMode>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
