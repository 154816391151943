import {
  Card,
  styled,
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageHeader from "src/components-form/Common/PageHeader";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Form, FormikProvider, useFormik } from "formik";
import SelectAsync from "src/components-form/shared/SelectAsync";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  apiDeleteMethod,
  apiGetMethod,
  apiPostMethod,
  apiPutMethod,
} from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import SaveIcon from "@mui/icons-material/Save";
import { Helmet } from "react-helmet-async";
import { Attachment } from "./Attachment";
import { Crew } from "./Crew";
const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
}));
export const AddProject = () => {
  const location = useLocation();
  const { id } = useParams();
  const [address, setAddress] = useState("");
  const [addClient, setAddClient] = useState(null);
  const [addSupervisor, setAddSupervisor] = useState(null);
  const [showBorder, setShowBorder] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [initialValues, setInitialValues] = useState({
    project_name: "",
    location: "",
    supervisor: "",
    client: "",
    userIds: [],
    project_description: "",
    attachment: [],
    location_lat: "",
    location_lon: "",
  });
  const [showCrew, setShowCrew] = useState([]);
  const [attachmentsList, setAttachmentsList] = useState([]);
  const [attachmentIds, setAttachmentIds] = useState([]);
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    project_name: Yup.string().required("Project Name is required!"),
    location: Yup.string().required("location is required!"),
    supervisor: Yup.string().required("Supervisor is required!"),
    userIds: Yup.array()
      .of(Yup.string().required("Crew is required!"))
      .min(1, "Crew is required!"),
    project_description: Yup.string().required(
      "Project description is required!"
    ),
  });
  const handleDeleteCrew = (deleteCrew) => {
    let obj = {
      deletedIds:deleteCrew
    }
    apiPostMethod(`${allApiUrl?.MULTIPLE_DELETE_CREW_MEMBER}/${id}`, obj)
      .then((data) => {
        toast.success(data?.message);
      })
      .catch((err) => {
        toast.error(err.data?.message);
      });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      const matched = [];
      const nonMatched = [];

      const addCrewIds = showCrew.map((item) => item.value);
      if (location?.pathname !== "/projects/add-projects") {
        values?.crew_data?.forEach((crew) => {
          if (addCrewIds.includes(crew.crew_id)) {
            matched.push(crew);
          } else {
            nonMatched.push(crew);
          }
        });
        if (nonMatched?.length > 0) {
          const deleteCrewIds = nonMatched.map((item) => item.crew_id);
          console.log(deleteCrewIds, "====deleteCrewsIds=====");
          handleDeleteCrew(deleteCrewIds); 
        }
      }
      try {
        const formData = new FormData();
        formData.append("project_name", values?.project_name);
        formData.append("location", values?.location);
        formData.append("supervisor", values?.supervisor);
        formData.append("client", values?.client);
        formData.append("project_description", values?.project_description);
        formData.append("location_lat", values?.location_lat);
        formData.append("location_lon", values?.location_lon);
        values?.attachment?.forEach((item) => {
          formData.append("file", item);
        });
        if (location?.pathname === "/projects/add-projects") {
          formData.append("userIds", values?.userIds);
        } else {
          formData.append("userIds", addCrewIds.join(", "));
        }
        if (location?.pathname === "/projects/add-projects") {
          apiPostMethod(allApiUrl?.CREATE_PROJECT, formData)
            .then((data) => {
              toast.success(data.message);
              navigate("/projects");
            })
            .catch((err) => {
              toast.error(err?.data?.message);
            });
        } else {
          apiPutMethod(`${allApiUrl?.EDIT_PROJECT}/${id}`, formData)
            .then((data) => {
              toast.success(data.message);
              navigate("/projects");
            })
            .catch((err) => {
              toast.error(err?.data?.message);
            });
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });

  function apiCall() {
    apiGetMethod(`${allApiUrl?.GET_PROJECT_LIST}/${id}`)
      .then((data) => {
        setInitialValues(data.data);
        setAddClient({
          company_id: data?.data?.company_id,
          label: data?.data?.client_name,
          value: data?.data?.client,
        });
        setAddSupervisor({
          company_id: data?.data?.company_id,
          label: data?.data?.supervisor_name,
          value: data?.data?.supervisor,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  async function apiCallAttachment() {
    let array = [];
    await apiGetMethod(`${allApiUrl?.GET_PROJECT_ATTACHMENT}/${id}`)
      .then((data) => {
        data?.data?.map((item) => {
          array.push(item?.attachment_key);
        });
        setAttachmentsList(array);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  useEffect(() => {
    if (id) {
      apiCall();
      apiCallAttachment();
    }
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Projects | {process.env.REACT_APP_APPLICATION_NAME} </title>
      </Helmet>
      <div className="flexTop">
        <PageHeader
          header={
            location?.pathname !== "/projects/add-projects"
              ? "Edit Project"
              : "Add Project"
          }
          links={[
            {
              name: "Dashboard",
              path: "/",
            },
            {
              name: "Project",
              path: "/projects",
            },
          ]}
        />
      </div>
      <Card elevation={3} sx={{ pt: "20px", mb: 3 }} className="cardFix">
        <CardHeader className="cardHeadBorder">
          <Title className="cardHead">
            {location?.pathname == "/projects/add-projects" ? "Add" : "Edit"}{" "}
            Project
          </Title>
        </CardHeader>
        <Box p={4} height="100%">
          <FormikForm
            formik={formik}
            address={address}
            setAddress={setAddress}
            location={location}
            setAddClient={setAddClient}
            addClient={addClient}
            setAddSupervisor={setAddSupervisor}
            addSupervisor={addSupervisor}
            navigate={navigate}
            setAttachmentsList={setAttachmentsList}
            attachmentsList={attachmentsList}
            setShowBorder={setShowBorder}
            showBorder={showBorder}
            showCrew={showCrew}
            setShowCrew={setShowCrew}
            id={id}
            deletedIds={deletedIds}
            setDeletedIds={setDeletedIds}
            setAttachmentIds={setAttachmentIds}
          />
        </Box>
      </Card>
    </>
  );
};
function FormikForm({
  formik,
  location,
  address,
  setAddress,
  setAddClient,
  addClient,
  setAddSupervisor,
  addSupervisor,
  navigate,
  attachmentsList,
  setAttachmentsList,
  showBorder,
  setShowBorder,
  showCrew,
  setShowCrew,
  deletedIds,
  setDeletedIds,
  id,
  setAttachmentIds,
}) {
  const {
    values,
    errors,
    setFieldValue,
    submitForm,
    handleSubmit,
    getFieldProps,
    isSubmitting,
    touched,
  } = formik;
  const [addCrew, setAddCrew] = useState([]);
  const handleChangeAddress = (newAddress) => {
    setAddress(newAddress);
    setShowBorder(true);
    setFieldValue("location", newAddress);
  };
  const handleSelectAddress = (newAddress) => {
    setAddress(newAddress);
    setShowBorder(false);
    setFieldValue("location", newAddress);
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setFieldValue("location_lon", latLng?.lng);
        setFieldValue("location_lat", latLng?.lat);
      })
      .catch((error) => console.error("Error", error));
  };
  const handleChangeCrew = (event) => {
    setAddCrew([...event]);
    setFieldValue(
      "userIds",
      event.map((e) => e.value)
    );
  };
  const handleChangeClient = (event) => {
    setAddClient(event);
    setFieldValue("client", event.value);
  };
  const handleChangeSupervisor = (event) => {
    setAddSupervisor(event);
    setFieldValue("supervisor", event.value);
  };
  const handleFileChange = (event) => {
    let array = [];
    const selectedFiles = Array.from(event.target.files);
    setFieldValue("attachment", selectedFiles);
    selectedFiles?.length > 0 &&
      selectedFiles?.map((item) => {
        array.push(item?.name);
      });
    setAttachmentsList(array);
  };
  return (
    <FormikProvider value={formik}>
      <Form id="formIk" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid item md={6} sm={12}>
            <TextField
              fullWidth
              type="text"
              name="project_name"
              label="Project Name"
              value={values?.project_name}
              onChange={(e) => {
                setFieldValue("project_name", e.target.value);
              }}
              variant="outlined"
              sx={{ mb: 3 }}
              className="inputHeight"
              helperText={
                touched && touched.project_name && errors.project_name
              }
              error={Boolean(
                touched && touched?.project_name && errors.project_name
              )}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <PlacesAutocomplete
              value={address || values?.location}
              onChange={handleChangeAddress}
              onSelect={handleSelectAddress}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
                item,
              }) => (
                <div
                  className={`subhurb-hightlight lg:mr-0 md:mr-0 mr-4  fieldmarginBottom ${
                    showBorder ? "dropdownOuter" : ""
                  }`}
                >
                  <input
                    label="Suburb"
                    value={item?.streetAddress}
                    {...getInputProps({
                      placeholder: "Search Location",
                      className:
                        touched && touched?.location && errors?.location
                          ? "location-search-input-error w-full p-3"
                          : "location-search-input w-full p-3",
                    })}
                  />
                  <i
                    className="pi pi-minus lg:p-2 md:p-2 "
                    style={{
                      " color": "rgb(112, 128, 144)",
                      marginTop: "10px",
                      marginLeft: "5px",
                      backgroundColor: "#f9fafa",
                      borderRadius: "10px",
                    }}
                  ></i>

                  <div className="autocomplete-dropdown-container absolute dropdownInner top-100 bg-white z-2 p-0 shadow-1  mb-2 lg:col-6">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active mb-2 p-2 border-bottom-1 text-sm surface-border"
                        : "suggestion-item mb-2 p-2 border-bottom-1 text-sm surface-border";
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                          };
                      return (
                        <div
                          key={suggestion.placeId}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span className="">{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            {touched && touched.location && errors?.location && (
              <div
                style={{
                  color: "#e74c3c",
                  fontSize: "12px",
                  paddingLeft: "12px",
                  paddingTop: "4px",
                }}
              >
                <span>{errors?.location}</span>
              </div>
            )}
          </Grid>
          {location?.pathname === "/projects/add-projects" ? (
            <Grid item md={6} sm={12}>
              <FormControl fullWidth>
                <InputLabel
                  style={{
                    backgroundColor: "#fff",
                    padding: "0 5px",
                  }}
                  shrink={true}
                >
                  Crew
                </InputLabel>
                <SelectAsync
                  searchType="selectCompanyUser"
                  placeholder="Select Crew"
                  className="multiSelectHeight project"
                  onChange={handleChangeCrew}
                  selectedValue={addCrew}
                  isMulti={true}
                  error={touched && touched.userIds && errors?.userIds}
                />
              </FormControl>
              {touched && touched.userIds && errors?.userIds && (
                <div
                  style={{
                    color: "#e74c3c",
                    fontSize: "12px",
                    paddingLeft: "12px",
                    paddingTop: "4px",
                  }}
                >
                  <span>{errors?.userIds}</span>
                </div>
              )}
            </Grid>
          ) : (
            <Crew
              addCrew={values?.crew_data}
              showCrew={showCrew}
              setShowCrew={setShowCrew}
            />
          )}

          <Grid item lg={6} md={6} sm={12}>
            <TextField
              rows={4}
              fullWidth
              type="text"
              name="client"
              label="Client"
              value={values?.client}
              onChange={(e) => {
                setFieldValue("client", e.target.value);
              }}
              variant="outlined"
              sx={{ mb: 3 }}
              className="inputHeight"
              helperText={touched && touched.client && errors.client}
              error={Boolean(touched && touched.client && errors.client)}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} sx={{ mb: 3 }}>
            <FormControl fullWidth>
              <InputLabel
                style={{
                  backgroundColor: "#fff",
                  padding: "0 5px",
                }}
                shrink={true}
              >
                Supervisor
              </InputLabel>
              <SelectAsync
                searchType="selectCompanyUser"
                placeholder="Select Supervisor"
                className="asyncHeight"
                onChange={handleChangeSupervisor}
                selectedValue={addSupervisor}
                error={touched && touched.supervisor && errors?.supervisor}
              />
            </FormControl>
            {touched && touched.supervisor && errors?.supervisor && (
              <div
                style={{
                  color: "#e74c3c",
                  fontSize: "12px",
                  paddingLeft: "12px",
                  paddingTop: "4px",
                }}
              >
                <span>{errors?.supervisor}</span>
              </div>
            )}
          </Grid>
          {location?.pathname === "/projects/add-projects" ? (
            <Grid item md={6} sm={12}>
              <div className="fileuploadWrapper fieldmarginBottom">
                <label htmlFor="file-upload" className="fileuploadPadding">
                  <Button
                    variant="outlined"
                    component="span"
                    startIcon={<AttachFileIcon />}
                    className="fileButton"
                  >
                    <p>
                      {" "}
                      {attachmentsList?.length > 0
                        ? attachmentsList
                        : "Attachment"}
                    </p>
                  </Button>
                </label>
                <input
                  accept=".pdf,.jpg,.jpeg,.png"
                  id="file-upload"
                  type="file"
                  multiple
                  disabled={
                    location?.pathname?.includes("/projects/edit-projects/") &&
                    true
                  }
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
              {touched && touched.attachment && errors?.attachment && (
                <div
                  style={{
                    color: "#e74c3c",
                    fontSize: "12px",
                    paddingLeft: "12px",
                    paddingTop: "4px",
                  }}
                >
                  <span>{errors?.attachment}</span>
                </div>
              )}
            </Grid>
          ) : (
            <Attachment
              attachment={values?.attachment_data}
              id={id}
              setAttachmentsList={setAttachmentsList}
              setFieldValue={setFieldValue}
              attachmentsList={attachmentsList}
              deletedIds={deletedIds}
              setDeletedIds={setDeletedIds}
              setAttachmentIds={setAttachmentIds}
            />
          )}
          <Grid item md={6} sm={12}>
            <TextField
              rows={4}
              fullWidth
              type="text"
              name="project_description"
              label="Project Description"
              value={values?.project_description}
              onChange={(e) => {
                setFieldValue("project_description", e.target.value);
              }}
              multiline
              variant="outlined"
              sx={{ mb: 3 }}
              className="inputHeight textarea"
              helperText={
                touched &&
                touched.project_description &&
                errors.project_description
              }
              error={Boolean(
                touched &&
                  touched.project_description &&
                  errors.project_description
              )}
            />
          </Grid>
          <Grid item md={12} sm={12}>
            <LoadingButton
              loading={isSubmitting}
              loadingPosition="end"
              endIcon={<SaveIcon />}
              variant="contained"
              className={`blueButton`}
              type="submit"
              color="inherit"
              sx={{ mr: "10px" }}
              onClick={() => {
                submitForm();
              }}
            >
              {location.pathname === "/projects/add-projects"
                ? "Add Project"
                : "Update"}
            </LoadingButton>
            <Button
              className="blueButton cancel"
              onClick={() => navigate("/projects")}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
