import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";

export const BootstrapDialog = styled(Dialog)(({ theme, width= "80%", minwidth = "auto" }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      width: "100%",
      minHeight: "30vh",
      minWidth: minwidth,
      maxWidth: width
    },
  },
}));