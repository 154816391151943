import {useEffect} from "react";
import * as _ from "lodash";
import * as React from "react";

export const ContentCompo = ({ isReadOnly = true, data, form }) => {
  useEffect(() => {
    if (!isReadOnly && document.getElementById("formBuilderView")) {
      let allCodeKeys = document
        .getElementById("formBuilderView")
        .getElementsByTagName("code");
      if (allCodeKeys.length > 0) {
        [...new Array(allCodeKeys.length)].map((key, index) => {
          let data = _.get(form, allCodeKeys[index].outerText.split("."));
          if (data.length > 0) {
            allCodeKeys[index].outerText = data;
          }
        });
      }
    }
  }, [form]);
  return (
    <div key={data.id} id={data.id}>
      <p
        style={{
          fontWeight: data.bold ? "bold" : "",
          fontStyle: data.italic ? "italic" : "",
        }}
        dangerouslySetInnerHTML={{
          __html: data.value
            ? data.value
            : "Dynamic Content Here",
        }}
      ></p>
    </div>
  );
};