import {
  Card,
  TableContainer,
  Table,
  TableBody,
  Chip
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { allApiUrl } from "src/api/apiRoute";
import { apiGetMethod } from "src/api/rest";
import Scrollbar from "src/components/scrollbar";
import { formatDateFn } from "src/shared/functions/string-conversion";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapDialog } from "src/components-form/Modals/ui/BootstrapDialog";
import PageHeader from "src/components-form/Common/PageHeader";
import { Helmet } from "react-helmet-async";
import {BootstrapTooltipUi} from "src/components/mui-common/BootstrapToolTip";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import {PAGINATION_OBJECT} from "src/core/constants";
import {headCellMyCertificate} from "src/components/mui-common/table-ui/headCells";
import {_setPaginationObjectFn, createQueryString} from "src/shared/functions";
import TableHeadRow, {
  StyledTableCell,
  StyledTableNoData,
  StyledTableRow
} from "src/components/mui-common/table-ui/TableHeadRow";
import TableToolbar from "src/components/mui-common/table-ui/TableToolbar";
import TableRowsLoader from "src/components/mui-common/table-ui/TableRowsLoader";
import {isEmpty} from "src/shared/functions/isEmpty";
import {_handleChangePage} from "../../utils/common";

const MyCertificate = () => {
  const [selected, setSelected] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [loader, setLoader] = useState(true);
  const [openModal, closeModal] = useState(false);
  const [resultData, setResultData] = useState({});

  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);
  const [searchTerm, setSearchTerm] = useState("");

  const headCells = headCellMyCertificate;

  useEffect(() => {
    if (paginationObject.triggerApi) {
      apiCall();
    }
  }, [paginationObject.triggerApi]);
  useEffect(() => {
    try {
      _handleChangePage({}, 0, setPaginationObject,setSelected)
    } catch (e) {}
  }, [searchTerm])

  function apiCall(value) {
    setLoader(true);
    let queryObject = {
      search: searchTerm || "",
      pageNumber: paginationObject.page,
      limit: paginationObject.rowsPerPage
    };
    const qryStr = createQueryString(queryObject);

    apiGetMethod(`${allApiUrl.MY_CERTIFICATE}${qryStr}`).then((data) => {
      setDataFiltered(data.data);
      setPaginationObject((prevState) => {
        return _setPaginationObjectFn(prevState, data.meta);
      });
    }).catch((err) => {
      setDataFiltered([...[]]);
      setPaginationObject(PAGINATION_OBJECT);
    }).finally(() => {
      setLoader(false);
    });
  }

  return (
    <>
      <Helmet>
        <title>MyCertificate | {process.env.REACT_APP_APPLICATION_NAME} </title>
      </Helmet>
      <Box>
        <div>
          <PageHeader
            header={"My Certificate"}
            links={[
              {
                name: "Dashboard",
                path: "/",
              },
            ]}
          />
        </div>
        <Card className="cardFix">
          <TableToolbar
            title={`Company`}
            numSelected={selected.length}
            onSearch={(query) => setSearchTerm(query)}
            selected={selected}
            setSelected={setSelected}
            apiCall={apiCall}
          />

          <Scrollbar>
            <TableContainer sx={{ overflow: "unset" }}>
              <Table>
                <TableHeadRow
                  numSelected={selected.length}
                  rowCount={dataFiltered.length}
                  headCells={headCells}
                  listData={dataFiltered}
                  setSelected={setSelected}
                />

                <TableBody>
                  {loader ? (
                    <TableRowsLoader
                      rowsNum={10}
                      columnNum={headCells.length}
                    />
                  ) : isEmpty(dataFiltered) ? (
                    <StyledTableNoData
                      colSpan={headCells.length}
                    />
                  ) : (
                    dataFiltered?.map((item, index) => {
                      return (
                        <StyledTableRow
                          role="checkbox"
                          tabIndex={-1}
                          key={`styledTableRow_${index}`}
                        >
                          {headCells.map((hCell) =>
                            <StyledTableCell padding={(hCell.id === "checkbox") ? "checkbox" : "normal"} className="nowrapFull" key={`tableCell_${hCell.id}`}>
                              {(hCell.id === "status") ? (
                                <>
                                  {item?.status === "FAILED" ? (
                                    <Chip label="FAIL" color="error" />
                                  ) : (
                                    <Chip label="PASS" color="success" />
                                  )}
                                </>
                              ) : (hCell.id === "actions") ? (
                                <>
                                  <BootstrapTooltipUi title="Preview" placement="top">
                                    <IconButton
                                      className="outerborder"
                                      aria-label="Preview"
                                      onClick={() => {
                                        closeModal(true);
                                        setResultData(item);
                                      }}
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  </BootstrapTooltipUi>
                                </>
                              ) : (
                                (hCell?.isDate) ? formatDateFn(item[hCell.id]) : item[hCell.id]
                              )}
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <BootstrapDialog
              onClose={closeModal}
              open={openModal}
              width={`auto`}
              className="modalHeight"
            >
              <IconButton
                aria-label="close"
                onClick={() => closeModal(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent dividers>
                <div className="saveModalBody" style={{ textAlign: "center" }}>
                  <Box>
                    <>
                      <img
                        src="/assets/icons/trophy.svg"
                        style={{
                          width: "60px",
                          height: "60px",
                          marginBottom: "20px",
                        }}
                        alt={""}
                      />
                      <h5>
                        Congratulations!! You have passed the quiz.{" "}
                        {resultData?.training_name
                          ? `(${resultData?.training_name})`
                          : ``}
                      </h5>
                      <p style={{ color: "#777" }}>
                        You did it! You just have aced your latest test, and we
                        are thrilled to celebrate your achievement with you.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <p style={{ fontSize: "19px", marginBottom: "0" }}>
                          <strong>Certificate: </strong>
                          {resultData?.certificate_name}
                        </p>
                      </div>
                    </>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <p style={{ fontSize: "19px", marginBottom: "0" }}>
                        <strong>Total Marks: </strong>
                        {resultData?.training_total_marks}
                      </p>
                    </div>
                  </Box>
                </div>
              </DialogContent>
            </BootstrapDialog>
          </Scrollbar>
          <TablePaginationCompo
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            setSelected = {setSelected}
          />
        </Card>
      </Box>
    </>
  );
};
export default MyCertificate;
