import { Card, Box, Button, Typography, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PageHeader from "src/components-form/Common/PageHeader";
import { Tab, Tabs } from "@mui/material";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import PlaceIcon from "@mui/icons-material/Place";
import Person4Icon from "@mui/icons-material/Person4";
import { apiGetMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import StaticTab from "./StaticTab";
import { AssignModal } from "../AssignModal";
import AssignCrewForm from "./AssignCrew";
import AssignTrainingForm from "./AssignTraining";
import { BootstrapTooltipUi } from "src/components/mui-common/BootstrapToolTip";
import { Helmet } from "react-helmet-async";
import {
  _setPaginationObjectFn,
  createQueryString,
} from "src/shared/functions";
import { PAGINATION_OBJECT } from "src/core/constants";
import {
  headCellProjectActiveForms,
  headCellProjectActiveTraining,
  headCellProjectArchived,
  headCellProjectFiles,
  headCellProjectForm,
} from "src/components/mui-common/table-ui/headCells";
import { TabsUi } from "src/components/mui-common/tabs-ui";

export const ProjectAddress = () => {
  const state = useLocation();
  const [formData, setFormData] = useState("");
  const [value, setValue] = useState(0);
  const [assignModal, setAssignModal] = useState(null);
  const [formFiltered, setFormFiltered] = useState([]);
  const [crewsFiltered, setCrewsFiltered] = useState([]);
  const [formFilteredArchived, setFormFilteredArchives] = useState([]);
  const [attachmentsList, setAttachmentsList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [assignTraining, setAssignTraining] = useState(false);
  const [loadingCrew, setLoadingCrew] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingFormArchived, setLoadingFormArchived] = useState(false);
  const [loadingArch, setLoadingArch] = useState(false);
  const [userId, setUserId] = useState([]);
  const [trainingData, setTraningData] = useState({});
  const [disabledTraining, setDisabledTraining] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectDueDate, selectSelectDueDate] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [linkedFormsModalOpen, setLinkedFormsModalOpen] = useState(null);

  const [paginationObjectFile, setPaginationObjectFile] =
    useState(PAGINATION_OBJECT);
  const [paginationObjectArchived, setPaginationObjectArchived] =
    useState(PAGINATION_OBJECT);
  const [paginationObjectForm, setPaginationObjectForm] =
    useState(PAGINATION_OBJECT);

  useEffect(() => {
    if (paginationObjectFile.triggerApi) {
      apiCallAttachment();
    }
  }, [paginationObjectFile.triggerApi]);
  useEffect(() => {
    if (paginationObjectArchived.triggerApi) {
      apiFormArchivedCall();
    }
  }, [paginationObjectArchived.triggerApi]);
  useEffect(() => {
    if (paginationObjectForm.triggerApi) {
      apiFormCall();
    }
  }, [paginationObjectForm.triggerApi]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setLoadingCrew(true);
      setLoadingForm(true);
      setLoadingFormArchived(true);
      apiFormCall();
      apiCrewsCall();
      apiFormArchivedCall();
    } else if (newValue === 1) {
      setLoadingArch(true);
      apiCallAttachment();
    } else if (newValue === 2) {
      setLoadingFormArchived(true);
      apiFormArchivedCall();
    } else if (newValue === 3) {
      setLoadingForm(true);
      apiFormCall();
    }
  };
  const headLabel = [
    { id: "crew", label: "Crew Name" },
    { id: "mobile", label: "Mob.Number" },
    { id: "email", label: "Email" },
    { id: "actions", label: "Actions", center: "center" },
  ];
  const headLabel2 = [
    { id: "allocatedDate", label: "Allocated Date and time" },
    { id: "training_name", label: "Training Name" },
    { id: "linkedFormName", label: "Linked Form Name" },
    { id: "last_attempts", label: "Last Attempt Date and Time" },
    { id: "status", label: "Training Status", center: "center" },
    { id: "actions", label: "Actions", center: "center" },
  ];
  const headLabelFiles = headCellProjectFiles;
  const headLabelArchived = headCellProjectArchived;
  const headLabelForm = headCellProjectForm;
  const headLabelActiveForms = headCellProjectActiveForms;
  const headLabelActiveTraining = headCellProjectActiveTraining;

  const { id } = useParams();

  function apiFormCall() {
    setLoadingForm(true);
    let queryObject = {
      pageNumber: paginationObjectForm.page,
      limit: paginationObjectForm.rowsPerPage,
      project_form_status: statusFilter === "all" ? null : statusFilter,
    };
    const qryStr = createQueryString(queryObject);

    apiGetMethod(`${allApiUrl?.PROJECT_FORM}/${id}${qryStr}`)
      .then((data) => {
        setFormFiltered(data.data);
        setPaginationObjectForm((prevState) => {
          return _setPaginationObjectFn(prevState, data.meta);
        });
      })
      .catch((err) => {
        setFormFiltered([...[]]);
        setPaginationObjectForm(PAGINATION_OBJECT);
      })
      .finally(() => {
        setLoadingForm(false);
      });
  }
  function apiFormArchivedCall() {
    setLoadingFormArchived(true);
    let queryObject = {
      pageNumber: paginationObjectArchived.page,
      limit: paginationObjectArchived.rowsPerPage,
      datafilter: "archived_forms",
      project_form_status: "COMPLETED",
    };
    const qryStr = createQueryString(queryObject);
    apiGetMethod(`${allApiUrl?.PROJECT_FORM}/${id}${qryStr}`)
      .then((res) => {
        setFormFilteredArchives(res?.data);
        setPaginationObjectArchived((prevState) => {
          return _setPaginationObjectFn(prevState, res.meta);
        });
      })
      .catch((err) => {
        setFormFilteredArchives([...[]]);
        setPaginationObjectArchived(PAGINATION_OBJECT);
      })
      .finally(() => {
        setLoadingFormArchived(false);
      });
  }
  function apiCrewsCall() {
    setLoadingCrew(true);
    let queryObject = {
      pageNumber: 0,
      limit: 5,
    };
    const qryStr = createQueryString(queryObject);

    apiGetMethod(
      assignModal === true
        ? `${allApiUrl?.PROJECT_CREWS_MEMBER}/${id}`
        : `${allApiUrl?.PROJECT_CREWS_MEMBER}/${id}${qryStr}`
    )
      .then((res) => {
        if (!isEmpty(res.data)) {
          setCrewsFiltered(res?.data);
          setLoadingCrew(false);
        } else {
          setCrewsFiltered([...[]]);
          setLoadingCrew(false);
        }
      })
      .catch((err) => {
        setLoadingCrew(false);
        toast.error(err?.data?.message);
      });
  }
  function apiCallAttachment() {
    setLoadingArch(true);
    let queryObject = {
      pageNumber: paginationObjectFile.page,
      limit: paginationObjectFile.rowsPerPage,
    };
    const qryStr = createQueryString(queryObject);

    apiGetMethod(`${allApiUrl?.GET_PROJECT_ATTACHMENT}/${id}${qryStr}`)
      .then((data) => {
        setAttachmentsList(data?.data);
        setPaginationObjectFile((prevState) => {
          return _setPaginationObjectFn(prevState, data.meta);
        });
      })
      .catch((err) => {
        setAttachmentsList([...[]]);
        setPaginationObjectFile(PAGINATION_OBJECT);
      })
      .finally(() => {
        setLoadingArch(false);
      });
  }

  useEffect(() => {
    apiFormCall();
  }, [statusFilter]);
  useEffect(() => {
    apiCrewsCall();
    apiFormArchivedCall();
  }, []);
  useEffect(() => {
    if (openModal === false) {
      apiFormCall();
      apiCrewsCall();
      apiFormArchivedCall();
    }
  }, [openModal]);
  useEffect(() => {
    if (assignModal === false) {
      apiCrewsCall();
    }
  }, [assignModal]);

  function apiTrainingCall() {
    setLoader(true);
    apiGetMethod(
      `${allApiUrl?.PROJECT_REQUIRED_TRAINING}/${id}/${formData?.value}`
    )
      .then((res) => {
        if (!isEmpty(res.data)) {
          setTraningData(res?.data);
          setLoader(false);
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        setTraningData({});
        setLoader(false);
      });
  }
  useEffect(() => {
    if (openModal === false) {
      setFormData("");
      setTraningData({});
      setUserId([]);
    }
  }, [openModal]);
  useEffect(() => {
    if (assignTraining === false) {
      setDisabledTraining(false);
    }
  }, [assignTraining]);
  const _Tabs = [
    { tabIndex: 0, label: "Overview" },
    { tabIndex: 1, label: "Files & Documents" },
    { tabIndex: 2, label: "Submitted Forms" },
    { tabIndex: 3, label: "Form" },
  ];
  return (
    <>
      <Helmet>
        <title>{`Projects | ${process.env.REACT_APP_APPLICATION_NAME}`}</title>
      </Helmet>
      <Box>
        <div className="flexTop">
          <PageHeader
            header="Project"
            links={[
              {
                name: "Dashboard",
                path: "/",
              },
              {
                name: "Project",
                path: "/projects",
              },
            ]}
          />
          <div>
            <Button
              variant="contained"
              color="inherit"
              className="blueButton"
              sx={{ marginRight: "10px" }}
              onClick={() => setOpenModal(true)}
            >
              Assign Form
            </Button>
            <Button
              variant="contained"
              color="inherit"
              className="blueButton"
              onClick={() => setAssignModal(true)}
            >
              Add New Crew
            </Button>
          </div>
        </div>
        <Card className="cardFix">
          <Box sx={{ p: "20px" }}>
            <Grid container>
              <Grid item sx={{ marginBottom: "30px" }}>
                <Typography variant="h5" style={{ marginTop: "5px" }}>
                  {state?.state?.address?.project_name}
                </Typography>
              </Grid>
              <Grid item marginLeft={2}></Grid>
              <AvatarGroup max={4}>
                {crewsFiltered?.length > 0 &&
                  crewsFiltered.map((item, index) => (
                    <BootstrapTooltipUi
                      key={index}
                      title={item?.user_name}
                      placement="top"
                    >
                      <Avatar alt={item?.user_name.substring(0, 2)} src="">
                        {item?.user_name.substring(0, 2)}
                      </Avatar>
                    </BootstrapTooltipUi>
                  ))}
              </AvatarGroup>
            </Grid>

            <div>
              <Grid container marginBottom={1}>
                <Grid item>
                  <PlaceIcon
                    style={{
                      marginRight: "10px",
                      fontSize: "20px",
                      color: "#ee7000",
                    }}
                  />
                  <Typography variant="p">
                    {state?.state?.address?.location}
                  </Typography>
                  {state?.state?.address?.is_active == true ? (
                    <span style={{ marginLeft: "20px", color: "green" }}>
                      Active
                    </span>
                  ) : (
                    <span style={{ marginLeft: "20px", color: "red" }}>
                      In Active
                    </span>
                  )}
                </Grid>
              </Grid>
              <Grid container gap={2}>
                {state?.state?.address?.client && (
                  <Grid item>
                    <Person4Icon sx={{ fontSize: "20px", color: "#ee7000" }} />
                    <Typography variant="p" style={{ marginLeft: "10px" }}>
                      Client :-
                    </Typography>
                    <Typography variant="p" style={{ marginLeft: "10px" }}>
                      {state?.state?.address?.client}
                    </Typography>
                  </Grid>
                )}
                {state?.state?.address?.supervisor_name && (
                  <Grid item>
                    <Person4Icon sx={{ fontSize: "20px", color: "#ee7000" }} />
                    <Typography variant="p" style={{ marginLeft: "10px" }}>
                      Supervisor :-
                    </Typography>
                    <Typography variant="p" sx={{ marginLeft: "8px" }}>
                      {state?.state?.address?.supervisor_name}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </div>
          </Box>
          <Divider sx={{ borderColor: "#828282" }} />
          <TabsUi
            currentTab={value}
            handleTabChange={handleChange}
            tabsArray={_Tabs}
            tabsPadding={"30px"}
          />
          <StaticTab
            value={value}
            headLabel={headLabel}
            crewsFiltered={crewsFiltered}
            headLabel2={headLabel2}
            formFiltered={formFiltered}
            headLabelActiveForms={headLabelActiveForms}
            headLabelFiles={headLabelFiles}
            headLabelArchived={headLabelArchived}
            headLabelForm={headLabelForm}
            state={state}
            formFilteredArchived={formFilteredArchived}
            setDisabledTraining={setDisabledTraining}
            paginationObjectFile={paginationObjectFile}
            setPaginationObjectFile={setPaginationObjectFile}
            paginationObjectArchived={paginationObjectArchived}
            setPaginationObjectArchived={setPaginationObjectArchived}
            paginationObjectForm={paginationObjectForm}
            setPaginationObjectForm={setPaginationObjectForm}
            attachmentsList={attachmentsList}
            id={id}
            apiCallAttachment={apiCallAttachment}
            apiCrewsCall={apiCrewsCall}
            apiFormCall={apiFormCall}
            setAssignModal={setAssignModal}
            setAssignTraining={setAssignTraining}
            assignTraining={assignTraining}
            setUserId={setUserId}
            userId={userId}
            loadingCrew={loadingCrew}
            loadingForm={loadingForm}
            loadingFormArchived={loadingFormArchived}
            loadingArch={loadingArch}
            setOpenModal={setOpenModal}
            setFormData={setFormData}
            openModal={openModal}
            assignModal={assignModal}
            selectSelectDueDate={selectSelectDueDate}
            headLabelActiveTraining={headLabelActiveTraining}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            linkedFormsModalOpen={linkedFormsModalOpen}
            setLinkedFormsModalOpen={setLinkedFormsModalOpen}
          />
          {openModal && (
            <AssignModal
              openModal={openModal}
              setOpenModal={setOpenModal}
              status={state?.state?.address?.is_active}
              projectId={id}
              crewsFiltered={crewsFiltered}
              setAssignTraining={setAssignTraining}
              assignTraining={assignTraining}
              setUserId={setUserId}
              userId={userId}
              projectName={state?.state?.address?.project_name}
              trainingData={trainingData}
              apiTrainingCall={apiTrainingCall}
              formData={formData}
              setFormData={setFormData}
              loader={loader}
              setLoader={setLoader}
              disabledTraining={disabledTraining}
              selectDueDate={selectDueDate}
              linkedFormsModalOpen={linkedFormsModalOpen}
              setLinkedFormsModalOpen={setLinkedFormsModalOpen}
            />
          )}
          {assignModal && (
            <AssignCrewForm
              setAssignModal={setAssignModal}
              id={id}
              apiCrewsCall={apiCrewsCall}
              headLabel={headLabel}
              crewsFiltered={crewsFiltered}
            />
          )}
          {assignTraining && (
            <AssignTrainingForm
              assignTraining={assignTraining}
              setAssignTraining={setAssignTraining}
              formData={formData}
              userId={userId}
              trainingData={trainingData}
              crewsFiltered={crewsFiltered}
              setFormData={setFormData}
              projectId={id}
              apiTrainingCall={apiTrainingCall}
              disabledTraining={disabledTraining}
            />
          )}
        </Card>
      </Box>
    </>
  );
};
