import React, { useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import { fetchImage } from "../../../shared/functions";

export const ImageAvatar = ({ data }) => {
  let [imagSrc, setImageSrc] = useState("");
  useEffect(() => {
    if (data.src) {
      fetchImage(data.src).then(async (res) => {
        setImageSrc(
          await fetch(res).then(async (response) => {
            let blob = await response.blob();
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            });
          })
        );
      });
    }
  }, [data.src]);
  return (
    <Avatar
      className="logoFit"
      src={imagSrc}
      sx={{ width: 56, height: 56 }}
    />
  );
};
