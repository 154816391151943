import * as React from "react";
import {useEffect, useState} from "react";
import {_getWidgets} from "src/shared/constants/widgetJson";
import {QueryBuilderDnD} from "@react-querybuilder/dnd";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import {QueryBuilderBootstrap} from "@react-querybuilder/bootstrap";
import {defaultOperators, defaultValidator, QueryBuilder} from "react-querybuilder";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from '@mui/material/DialogContent';
import {BootstrapDialog} from "../ui/BootstrapDialog";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import 'react-querybuilder/dist/query-builder.scss';
import './style.css';
import {isEmpty} from "src/shared/functions/isEmpty";
import {getInputType, removeSpace} from "src/shared/functions/string-conversion";
import * as _ from "lodash";

const initialQuery = { combinator: 'and', rules: [] };

export const ConditionalQueryBuilderModal = ({ ModalOpen, setModalOpen, updateRule, rulesQuery, selectedWidgets }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState(initialQuery);
  useEffect(() => { setIsOpen(ModalOpen) }, [ModalOpen]);
  useEffect(() => {
    setQuery(rulesQuery.rules !== [] ? rulesQuery : initialQuery)
  }, [rulesQuery])

  const [fields, setFields] = useState([]);
  useEffect(() => {
    if (selectedWidgets.length > 0) {
      function queryBuilderLoop(row) {
        let widgetsList = []
        row.map((data) => {
          if (data?.hasOwnProperty("element") && [...Object.keys(_getWidgets())].includes(data.element) && data?.hasOwnProperty("valueEditorType") && data?.hasOwnProperty("inputType")) {
            widgetsList.push(data)
          }
          if (data?.hasOwnProperty("childComponent") && data.childComponent.length > 0) {
            widgetsList.push(...queryBuilderLoop(data.childComponent))
          }
        })
        return widgetsList
      }

      const RatingScaleArray = [];
      queryBuilderLoop(selectedWidgets).map(v => {
        if (v.element === "RatingScale") {
          RatingScaleArray.push(v);
        }
      })

      const notHavingRatingScale = _.remove(queryBuilderLoop(selectedWidgets), v=> v.element !== "RatingScale")

      let response = notHavingRatingScale.map((row) => {
        let Json = {
          name: `field_id_${row.id}`,
          label: !isEmpty(row.label) ? row.label : row.id,
          valueEditorType: (getInputType(row.id) === "checkBoxes" && !row.typeRadio) ? "checkbox" : row.valueEditorType, 
          values: []
        }
        if (row?.hasOwnProperty("options") || row?.hasOwnProperty("questions")) {
          if (_.includes(["select", "radio"], Json.valueEditorType)) {
            Json["values"] = row.options.map((option) => {
              return {
                name: option.id,
                label: option.label ? option.label : option.value,
              }
            });
          } else if (Json.valueEditorType === "checkbox") {
            Json["valueEditorType"] = "multiselect"
            Json["operators"] = defaultOperators.filter((op) => !!_.includes(["=", "!=", "notNull", "null"], op.name))
            Json["values"] = row.options.map((option) => {
              return {
                name: option.id,
                label: !isEmpty(option.label) ? option.label : option.value,
              }
            });
          } else {
            Json["values"] = row.options.map((option) => {
              return {
                name: !isEmpty(option.value) ? option.value : option.key,
                label: !isEmpty(option.key) ? option.key : option.value,
                value: !isEmpty(option.value) ? option.value : option.key
              }
            });
          }
        }
        return Json
      })

      let tempRatingScaleArray = [];
      try {
        RatingScaleArray.map((rating, index) => {
          const optionsArray = _.find(rating.typesOfRating, v => v.type === rating.selectedRatingType)
          rating.fields.map((row, index2) => {
            tempRatingScaleArray.push({
              name: `field_id_${row.id}_${removeSpace(row.label)}`,
              label: !isEmpty(row.label) ? `${rating?.label}-${row.label}` : `${rating?.label}-${row.id}`,
              valueEditorType: "radio",
              values: optionsArray.structure.map((option, idx) => {
                return {
                  name: option,
                  label: option
                }
              })
            });

          })
        })
      } catch (e) {}
      setFields(_.concat(response, tempRatingScaleArray))
    }
  }, [selectedWidgets])


  function closeModal() {
    setIsOpen(false);
    setModalOpen(false)
  }

  function saveRules() {
    if (query?.hasOwnProperty("rules")) {
      updateRule(query, "rules")
      setIsOpen(false);
      setModalOpen(false)
    }
  }

  return (
    <BootstrapDialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={modalIsOpen}
      width={`auto`}
      minwidth={`50%`}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Visible When...
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <QueryBuilderDnD dnd={{...ReactDnD, ...ReactDndHtml5Backend}}>
          <QueryBuilderBootstrap>
            <QueryBuilder
              fields={fields}
              query={query}
              onQueryChange={(q) => setQuery(q)}
              addRuleToNewGroups
              listsAsArrays
              resetOnOperatorChange
              showCombinatorsBetweenRules
              validator={defaultValidator}
              controlClassnames={{queryBuilder: 'queryBuilder-branches'}}
              
            />
          </QueryBuilderBootstrap>
        </QueryBuilderDnD>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          autoFocus
          variant="contained"
          onClick={saveRules}
          className="blueButton"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </BootstrapDialog>
  )
}