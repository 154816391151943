import {useEffect, useState} from "react";
import {addSpaceBeforeCapitalLetters, replaceLabelStrings} from "src/shared/functions/string-conversion";
import LabelCompo from "./LabelCompo";
import * as React from "react";
import {TextField} from "@mui/material";
import {isEmpty} from "src/shared/functions/isEmpty";
import * as _ from "lodash";

export const AddressInformationCompo = ({isReadOnly, data, onChangeFields, formData, form, isSubmitClicked = false, isEntryPage = false, isDraftSaved = false}) => {
  const defaultLabel = "Address Information";
  const [labelConv, setLabelConv] = useState(
    isReadOnly ? (data.label ? data.label : defaultLabel) : replaceLabelStrings(data?.label, form, defaultLabel)
  );

  const [fields, setFields] = useState(formData);

  useEffect(() => {
    if (!isReadOnly) {
      setLabelConv(replaceLabelStrings(data?.label, form, defaultLabel));
    } else {
      setLabelConv(data.label ? data.label : defaultLabel)
    }
  }, [form, data]);
  useEffect(() => {
    if (!isReadOnly) {
      onChangeFields(fields);
    }
  }, [fields]);

  const [updatedFields, setUpdatedFields] = useState([]);


  const getFormVal = (key, keyObj) => {
    const formFieldVal = (key) => {
      try {
        return (fields && fields[key] ? formData[key] : !isEmpty(keyObj?.value) ? keyObj?.value : "")
      } catch (e) {
        return null;
      }
    }
    try {
      return isEntryPage ? ((isDraftSaved && _.includes(updatedFields, key)) ? formFieldVal(key) : keyObj?.userInputVal) : (isReadOnly ? keyObj?.value : formFieldVal(key))
    } catch (e) {
      return null;
    }
  }

  return (
    <div key={data.id} id={data.id}>
      <LabelCompo data={data} labelConv={labelConv}/>
      <div className="contactInfo">
        <div className="row">
          {Object.keys(data.fields).map((key) => {
            const keyObj = data.fields[key];

            if (key === "Zip" && Boolean(keyObj.showField)) {
              return (
                <div className="col-lg-6" key={`addressInfoFormGroup_${key}`}>
                  <label>
                    Zip
                    <small className={data.required && keyObj.required ? "asteriskAfter" : ""}>
                      {(data.required && keyObj.required) ? "" : " (Optional)"}
                    </small>
                  </label>
                  <TextField
                    fullWidth
                    size="small"
                    maxLength="10"
                    placeholder={`Enter Zip Code`}
                    onChange={(e) => {
                      if (!isReadOnly) {
                        let val = e.target.value;
                        const regExp = /[a-zA-Z]/g;
                        if (!regExp.test(val)) {
                          if (val.includes("-")) val = val.replaceAll("-", "");
                          if (val.length > 5)
                            val =
                              val.substr(0, 5) +
                              "-" +
                              val.substr(5, val.length - 5);
                          e.target.value = val;
                          setFields(prevState => {
                            return {...prevState, [key]: val}
                          });
                        } else {
                          e.target.value = fields[key] || "";
                        }
                        setLabelConv(
                          replaceLabelStrings(data?.label, form, defaultLabel)
                        );
                        setUpdatedFields(prevState => {
                          return _.uniq([...prevState, key])
                        })
                      }
                    }}
                    disabled={!isDraftSaved && Boolean(isReadOnly || (isEntryPage && keyObj.userInputVal))}
                    id={`field_id_${keyObj.id}`}
                    name={`field_name_${data.id}_Zip`}
                    value={getFormVal(key, keyObj)}
                    required={Boolean(data.required && keyObj.required)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={Boolean(!isReadOnly && data.required && keyObj.required && isSubmitClicked && !((fields && fields["Zip"]) || (isEntryPage && keyObj?.userInputVal)))}
                    helperText={Boolean(!isReadOnly && data.required && keyObj.required && isSubmitClicked && !((fields && fields["Zip"]) || (isEntryPage && keyObj?.userInputVal))) && "This Field is required!"}
                  />
                </div>
              )
            } else if (Boolean(keyObj.showField)){
              return (
                <div className="col-lg-6" key={`addressInfoFormGroup_${key}`}>
                  <div className="form-group">
                    <label>
                      {addSpaceBeforeCapitalLetters(keyObj.name)}
                      <small className={data.required && keyObj.required ? "asteriskAfter" : ""}>
                        {(data.required && keyObj.required) ? "" : " (Optional)"}
                      </small>
                    </label>
                    <TextField
                      fullWidth
                      size="small"
                      onChange={(e) => {
                        if (!isReadOnly) {
                          setFields(prevState => {
                            return {...prevState, [key]: e.target.value}
                          });
                          setUpdatedFields(prevState => {
                            return _.uniq([...prevState, key])
                          })
                        }
                      }}
                      placeholder={`Enter ${addSpaceBeforeCapitalLetters(key)}`}
                      disabled={!isDraftSaved && Boolean(isReadOnly || (isEntryPage && keyObj?.userInputVal))}
                      id={`field_id_${keyObj.id}`}
                      name={`field_name_${data.id}_${key}`}
                      value={getFormVal(key, keyObj)}
                      required={Boolean(data.required && keyObj.required)}
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={Boolean(!isReadOnly && data.required && keyObj?.required && isSubmitClicked && !((fields && fields[key]) || (isEntryPage && keyObj?.userInputVal)))}
                      helperText={Boolean(!isReadOnly && data.required && keyObj?.required && isSubmitClicked && !((fields && fields[key]) || (isEntryPage && keyObj?.userInputVal))) && "This Field is required!"}
                    />
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>
    </div>
  );
};