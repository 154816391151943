import Drawer from "@mui/material/Drawer";
import {isEmpty} from "src/shared/functions/isEmpty";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ViewEntryCompo from "src/pages/BuildForm/ViewEntryCompo";

const SavedEntriesDrawer = ({openDrawer, setOpenDrawer }) => {
  const handleClose = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer({});
  };
  return (
    <Drawer className="savedEntryDrawer"
      anchor={"right"}
      open={!isEmpty(openDrawer)}
      onClose={handleClose()}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Preview
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <ViewEntryCompo
          isEntryPage
          propsType={openDrawer?.type}
          propsId={openDrawer?.id}
          propsPathname={openDrawer?.pathName}
          propsState={openDrawer?.state}
        />
      </Box>
    </Drawer>
  )
}
export default SavedEntriesDrawer;