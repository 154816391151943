import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Skeleton} from "@mui/material";
export default function TableRowsLoader({ rowsNum, columnNum }) {
  const TableCellSkeletonHtml = () => {
    return (
      <TableCell component="th" scope="row">
        <Skeleton animation="wave" variant="text"/>
      </TableCell>
    )
  }
  return [...Array(rowsNum)].map((row, index) => (
    <TableRow key={`rows_${index}`}>
      {[...Array(columnNum)].map((col, idx) => {
        return (
          <TableCellSkeletonHtml key={`column_${idx}`} />
        )
      })}
    </TableRow>
  ));
};