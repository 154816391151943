import {
  Box,
  TextField,
  Card,
  styled,
  Grid,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiGetMethod, apiPostMethod, apiPutMethod } from "src/api/rest";
import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { allApiUrl } from "src/api/apiRoute";
import Loader from "src/components/Loader";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SelectAsync from "src/components-form/shared/SelectAsync";
import * as Yup from "yup";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import PageHeader from "src/components-form/Common/PageHeader";
import { COMPANYROLES, SUPERADMINROLES } from "src/core/constants";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import dayjs, { Dayjs } from "dayjs";
import {
  calculateExpiryDate,
  calculateExpiryDateNew,
} from "src/shared/functions/string-conversion";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
}));

export default function EditCertificatePage() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [trainingData, setTrainingData] = useState();
  const [formData, setFormData] = useState();
  const [loader, setLoader] = useState(false);
  const [fileName, setFileName] = useState("");
  const [certificateName, setCertificateName] = useState("");
  const [initialValues, setInitialValues] = useState({
    user_id: "",
    trainings_id: "",
    form_id: "",
    note: "",
    attachment_id: "",
    dateofTraining: null,
  });
  const validationSchema = Yup.object().shape({
    user_id: Yup.string().required("User is required!"),
    trainings_id: Yup.string().required("Training is required!"),
    dateofTraining: Yup.date().required("Date of Training is required"),
  });
  function apiCall() {
    apiGetMethod(`${allApiUrl?.GET_CERTIFICATE_BYID}/${id}`)
      .then((data) => {
        setInitialValues(data.data);
        apiTrainingCall(data?.data?.trainings_id);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  function apiTrainingCall(trainingid) {
    apiGetMethod(`${allApiUrl?.COMPANY_TRAINING_LIST}/${trainingid}`)
      .then((data) => {
        let obj = {
          certificate_name: data?.data?.certificatename,
          date: data?.data?.validityperiod,
          label: data?.data?.name,
          value: data?.data?.id,
        };
        setTrainingData(obj);
        setCertificateName(data?.data?.certificatename);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  useEffect(() => {
    if (id) {
      apiCall();
    }
  }, [id]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      try {
        setLoading(true);

        const formData = new FormData();
        formData.append("trainings_id", values?.trainings_id);
        formData.append("note", values?.note);
        formData.append("user_id", values?.user_id);
        formData.append("training_done_date", values?.dateofTraining);
        formData.append(
          "file",
          typeof fileName == "string" ? values?.attachment_id : fileName
        );
        formData.append("status", "PASSED");
        {
          location.pathname === "/training-certificate/add-training-certificate"
            ? apiPostMethod(allApiUrl?.ADD_USER_TRAINING, formData)
                .then((data) => {
                  toast.success("Training added Successfully.");
                  navigate("/training-certificate");
                })
                .catch((err) => {
                  toast.error(err.data.message);
                })
            : apiPutMethod(`${allApiUrl?.EDIT_USER_TRAINING}/${id}`, formData)
                .then((data) => {
                  toast.success("Training edit Successfully.");
                  navigate("/training-certificate");
                })
                .catch((err) => {
                  toast.error(err.data.message);
                });
        }
      } catch (error) {
        toast.error(error.response.data.message);
        setLoading(false);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );

  useEffect(() => {
    if (initialValues?.user_id !== "") {
      apiUserCall();
    }
  }, [initialValues]);

  function apiUserCall() {
    apiGetMethod(
      Object?.values(SUPERADMINROLES)?.includes(loginUserInfo?.roleid[0])
        ? `${allApiUrl?.USER_PROFILE_DETAIL}/${initialValues?.user_id}`
        : `${allApiUrl?.COMPANY_USER_PROFILE_DETAILS}/${initialValues?.user_id}`
    )
      .then((data) => {
        setUserData({
          value: id,
          label: `${data?.data[0]?.user_name}(~${data?.data[0]?.company_name})`,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  const handleCancel = () => {
    navigate("/training-certificate");
  };
  return (
    <>
      <Helmet>
        <title>
          Training History | {process.env.REACT_APP_APPLICATION_NAME}
        </title>
      </Helmet>
      <div className="flexTop">
        <PageHeader
          header={
            location?.pathname ===
            "/training-certificate/add-training-certificate"
              ? "Allocate Certificate"
              : "Edit Certificate"
          }
          links={[
            {
              name: "Dashboard",
              path: "/",
            },
            {
              name: "Trainings History",
              path: "/training-certificate",
            },
          ]}
        />
      </div>
      <Card elevation={3} sx={{ pt: "20px", mb: 3 }} className="cardFix">
        <CardHeader className="cardHeadBorder">
          <Title className="cardHead">
            {location?.pathname ===
            "/training-certificate/add-training-certificate"
              ? "Allocate"
              : "Edit"}{" "}
            Certificate
          </Title>
        </CardHeader>
        {loader &&
        location.pathname ===
          "/training-certificate/add-training-certificate" ? (
          <Loader />
        ) : (
          <Grid container className="allocationTraining">
            <Grid>
              <FormikForm
                formik={formik}
                loading={loading}
                location={location}
                setUserData={setUserData}
                setTrainingData={setTrainingData}
                setFileName={setFileName}
                userData={userData}
                trainingData={trainingData}
                formData={formData}
                fileName={fileName}
                setFormData={setFormData}
                handleCancel={handleCancel}
                loginUserInfo={loginUserInfo}
                setInitialValues={setInitialValues}
                id={id}
                certificateName={certificateName}
                setCertificateName={setCertificateName}
              />
            </Grid>
          </Grid>
        )}
      </Card>
    </>
  );
}

function FormikForm({
  formik,
  loading,
  location,
  setUserData,
  setTrainingData,
  setFileName,
  userData,
  trainingData,
  fileName,
  handleCancel,
  loginUserInfo,
  setInitialValues,
  id,
  certificateName,
  setCertificateName,
}) {
  const { values, errors, setFieldValue, submitForm, handleSubmit, touched } =
    formik;
  const [userError, setUserError] = useState(true);
  const [trainingError, setTrainingError] = useState(true);
  const [formError, setFormError] = useState(true);

  const [trainingId, setTrainingId] = useState("");
  const handleUserChange = (event, value) => {
    setUserData(event);
    setFieldValue("user_id", event.value);
    setUserError(true);
  };

  const handleTrainingChange = (event, value) => {
    setTrainingData(event);
    setCertificateName(event?.certificate_name);
    setFieldValue("trainings_id", event.value);
    setTrainingError(true);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file);
    setFieldValue("attachment_id", URL.createObjectURL(file));
  };
  useEffect(() => {
    if (userData) {
      setTrainingId(userData?.company_id);
    }
  }, [userData]);
  const isPdf = fileName?.name?.includes("pdf");
  const handleDelete = () => {
    setInitialValues({
      attachment_id: "",
    });
  };

  return (
    <FormikProvider value={formik}>
      <Form id="formIk" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item lg={6} md={6} sm={12}>
            <FormControl fullWidth>
              {values?.user_id && (
                <InputLabel
                  style={{ backgroundColor: "#fff", padding: "0 5px" }}
                  shrink={true}
                >
                  Users
                </InputLabel>
              )}
              <SelectAsync
                onChange={handleUserChange}
                searchType={
                  Object?.values(SUPERADMINROLES)?.includes(
                    loginUserInfo?.roleid[0]
                  )
                    ? "user_id"
                    : "selectCompanyUser"
                }
                selectedValue={userData}
                placeholder="Select User"
                error={errors?.user_id && userError === false}
                formData={trainingId}
                className="asyncHeight"
              />
            </FormControl>
            {errors?.user_id && userError === false && (
              <div
                style={{
                  color: "#e74c3c",
                  fontSize: "12px",
                  paddingLeft: "12px",
                  paddingTop: "4px",
                }}
              >
                <span>{errors?.user_id}</span>
              </div>
            )}
          </Grid>

          <Grid item lg={6} md={6} sm={12}>
            <FormControl fullWidth>
              {values?.trainings_id && (
                <InputLabel
                  style={{ backgroundColor: "#fff", padding: "0 5px" }}
                  shrink={true}
                >
                  Trainings
                </InputLabel>
              )}
              <SelectAsync
                onChange={handleTrainingChange}
                searchType={
                  Object?.values(SUPERADMINROLES)?.includes(
                    loginUserInfo?.roleid[0]
                  )
                    ? "admin_trainings_id"
                    : "company_training_id"
                }
                selectedValue={trainingData}
                placeholder="Select Trainings"
                error={errors?.trainings_id && trainingError === false}
                formData={trainingId}
                isDisabled={
                  userData == "" || userData == undefined ? true : false
                }
                className="asyncHeight"
              />
            </FormControl>
            {errors?.trainings_id && trainingError === false && (
              <div
                style={{
                  color: "#e74c3c",
                  fontSize: "12px",
                  paddingLeft: "12px",
                  paddingTop: "4px",
                }}
              >
                <span>{errors?.trainings_id}</span>
              </div>
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            <TextField
              fullWidth
              type="text"
              label="Certificate Name"
              variant="outlined"
              className="inputHeight"
              value={certificateName}
              disabled={true}
            />
          </Grid>
          {trainingData?.form_name && (
            <Grid item lg={6} md={6} sm={12}>
              <TextField
                fullWidth
                id="outlined-textarea"
                label="Forms"
                placeholder="forms"
                multiline
                variant="outlined"
                value={trainingData?.form_name}
                name="forms"
                className="inputTextarea"
                style={{ marginTop: "7px" }}
                disabled
              />
            </Grid>
          )}
          <Grid item lg={6} md={6} sm={12}>
            <TextField
              fullWidth
              id="outlined-textarea"
              label="Notes"
              placeholder="notes"
              multiline
              variant="outlined"
              value={values?.note}
              name="note"
              className="inputTextarea inputHeight"
              onChange={(e) => {
                setFieldValue("note", e.target.value);
              }}
            />
          </Grid>
          <Grid item md={6}>
            <FormControl sx={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="dateWidth">
                  <DesktopDatePicker
                    label="Date of Training"
                    value={values?.dateofTraining || values?.training_done_date ? dayjs(
                      values?.dateofTraining || values?.training_done_date 
                    ):null}
                    onChange={(e) => {
                      setFieldValue("dateofTraining", e);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </LocalizationProvider>
              <FormHelperText style={{ color: "red" }}>
                {touched.dateofTraining && errors.dateofTraining}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl sx={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className="dateWidth">
                  <DesktopDatePicker
                    label="Expiry Date"
                    value={
                      (values?.dateofTraining &&
                        calculateExpiryDate(
                          new Date(values?.dateofTraining),
                          trainingData?.date,
                          "",
                          true
                        ).expiryDate) ||
                      (values?.training_done_date &&
                        calculateExpiryDate(
                          new Date(values?.training_done_date),
                          trainingData?.date,
                          "",
                          true
                        ).expiryDate)
                    }
                    disabled={true}
                    renderInput={(params) => (
                      <TextField {...params} disabled={true} />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item lg={12} md={6} sm={12}>
            <Box className="inputFile">
              {values.attachment_id && (
                <div>
                  {isPdf ? (
                    <div>
                      <img
                        id="preview"
                        src="/assets/icons/pdf.png"
                        style={{ width: "100px", height: "100px" }}
                        alt="PDF Preview"
                      />
                    </div>
                  ) : (
                    <div>
                      <img
                        id="preview"
                        src={values.attachment_id}
                        style={{ width: "100px", height: "100px" }}
                        alt="Image Preview"
                      />
                    </div>
                  )}
                  <DeleteOutlinedIcon
                    style={{ fontSize: "18px", cursor: "pointer" }}
                    onClick={() => setFieldValue("attachment_id", "")}
                  />
                </div>
              )}
              {!values.attachment_id && (
                <input
                  type="file"
                  size="10"
                  style={{ marginTop: "10px" }}
                  onChange={handleFileChange}
                />
              )}
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} sx={{ mt: 3 }}>
            <LoadingButton
              className="blueButton"
              type="button"
              color="primary"
              variant="contained"
              sx={{ mr: "10px" }}
              onClick={() => {
                submitForm();
                setUserError(false);
                setFormError(false);
                setTrainingError(false);
              }}
            >
              {location.pathname ===
              "/training-certificate/add-training-certificate"
                ? " Save"
                : "Update"}
            </LoadingButton>
            <Button className="blueButton cancel" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
