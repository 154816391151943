import {PermissionCheck} from "src/utils/common";
import {BootstrapTooltipUi} from "./BootstrapToolTip";
import {IconButton} from "@mui/material";
import {_handleRedirect} from "src/shared/functions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React from "react";
import {isEmpty} from "src/shared/functions/isEmpty";

export const ActionPreview = (
  {
    permissionCheckType,
    loginUserInfo,
    formId,
    currentTab
  }) => {
  return <>
    {PermissionCheck(
      permissionCheckType,
      "read",
      loginUserInfo
    ) && (
      <BootstrapTooltipUi
        title={isEmpty(formId) ? "No Preview Available": "Preview"}
        placement="top"
      >
        <IconButton
          className="outerborder"
          aria-label="Preview"
          onClick={() => {
            if (!isEmpty(formId)) {
              _handleRedirect(
                `/training/forms/form-detail/${formId}`,
                currentTab ? { currentTab } : {}
              );
            }
          }}
        >
          <VisibilityIcon />
        </IconButton>
      </BootstrapTooltipUi>
    )}
  </>
}