import {
  Card,
  TableContainer,
  Table,
  TableBody,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useEffect, useState } from "react";
import { allApiUrl } from "src/api/apiRoute";
import { apiGetMethod } from "src/api/rest";
import Scrollbar from "src/components/scrollbar";
import { calculateExpiryDate, formatDateFn } from "src/shared/functions/string-conversion";
import {PAGINATION_OBJECT, TRAINING_STATUS} from "src/core/constants";
import Box from "@mui/material/Box";
import PageHeader from "src/components-form/Common/PageHeader";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {BootstrapTooltipUi} from "src/components/mui-common/BootstrapToolTip";
import {_setPaginationObjectFn, createQueryString} from "src/shared/functions";
import { isEmpty } from "src/shared/functions/isEmpty";
import TableHeadRow, {
  StyledTableCell,
  StyledTableNoData,
  StyledTableRow
} from "src/components/mui-common/table-ui/TableHeadRow";
import TableToolbar from "src/components/mui-common/table-ui/TableToolbar";
import TableRowsLoader from "src/components/mui-common/table-ui/TableRowsLoader";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import {headCellMyTraining} from "src/components/mui-common/table-ui/headCells";
import {_handleChangePage} from "../../utils/common";


const MyTraining = () => {
  const [selected, setSelected] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [loader, setLoader] = useState(true);

  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);
  const [searchTerm, setSearchTerm] = useState("");
  const headCells = headCellMyTraining;

  useEffect(() => {
    if (paginationObject.triggerApi) {
      apiCall();
    }
  }, [paginationObject.triggerApi]);
  useEffect(() => {
    try {
      _handleChangePage({}, 0, setPaginationObject,setSelected)
    } catch (e) {}
  }, [searchTerm])

  function apiCall(value) {
    setLoader(true);
    let queryObject = {
      search: searchTerm || "",
      pageNumber: paginationObject.page,
      limit: paginationObject.rowsPerPage,
    };
    const qryStr = createQueryString(queryObject);


    apiGetMethod(`${allApiUrl?.MY_TRAINING}${qryStr}`).then((data) => {
      setDataFiltered(data.data);
      setPaginationObject((prevState) => {
        return _setPaginationObjectFn(prevState, data.meta);
      });
    }).catch((err) => {
      setDataFiltered([...[]]);
      setPaginationObject(PAGINATION_OBJECT);
    }).finally(() => {
      setLoader(false);
    });
  }

  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>MyTraining | {process.env.REACT_APP_APPLICATION_NAME} </title>
      </Helmet>
      <Box>
        <PageHeader
          header={"My Training & Certificate"}
          links={[
            {
              name: "Dashboard",
              path: "/",
            },
          ]}
        />
        <Card className="cardFix">
          <TableToolbar
            title={`Allocated Trainings`}
            numSelected={selected.length}
            onSearch={(query) => setSearchTerm(query)}
            selected={selected}
            setSelected={setSelected}
            apiCall={apiCall}
          />

          <Scrollbar>
            <TableContainer>
              <Table>
                <TableHeadRow
                  numSelected={selected.length}
                  rowCount={dataFiltered.length}
                  headCells={headCells}
                  listData={dataFiltered}
                  setSelected={setSelected}
                />

                <TableBody>
                  {loader ? (
                    <TableRowsLoader
                      rowsNum={10}
                      columnNum={headCells.length}
                    />
                  ) : isEmpty(dataFiltered) ? (
                    <StyledTableNoData
                      colSpan={headCells.length}
                    />
                  ) : (
                    dataFiltered?.map((item, index) => {
                      return (
                        <StyledTableRow
                          role="checkbox"
                          tabIndex={-1}
                          key={`styledTableRow_${index}`}
                        >
                          {headCells.map((hCell) =>
                            <StyledTableCell padding={(hCell.id === "checkbox") ? "checkbox" : "normal"} className="nowrapFull" key={`tableCell_${hCell.id}`}>
                              {(hCell.id === "expiry_date") ? (
                                <>{calculateExpiryDate(item?.date_of_training,item?.validityperiod)}</>
                              ) : (hCell.id === "total_marks") ? (
                                <>{`${item?.obtained_marks || 0}/${item?.training_total_marks || 0}`}</>
                              ) : (hCell.id === "actions") ? (
                                <>
                                  <BootstrapTooltipUi title="Preview" placement="top">
                                    <IconButton
                                      className="outerborder"
                                      aria-label="Preview"
                                      onClick={() =>
                                        navigate(
                                          `/my-training/view-entry/${item?.entry_form_id}`,
                                          {
                                            state: {
                                              isTraining: true,
                                            },
                                          }
                                        )
                                      }
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  </BootstrapTooltipUi>
                                  {item?.status !== TRAINING_STATUS.PASSED ? (
                                    <Button
                                      onClick={() => {
                                        window.open(
                                          `/forms/retake-form/${item?.entry_form_id}`,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      Retake
                                    </Button>
                                  ) : (
                                    <Button disabled>Retake</Button>
                                  )}
                                </>
                              ) : (
                                (hCell?.isDate) ? formatDateFn(item[hCell.id]) : item[hCell.id]
                              )}
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePaginationCompo
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            setSelected={setSelected}
          />
        </Card>
      </Box>
    </>
  );
};
export default MyTraining;
