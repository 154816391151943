import axios from "axios";

const cancelToken = axios.CancelToken;

const apiTermination = (cancelTokensRef) => {
  cancelTokensRef.current.forEach(cancelToken => {
    cancelToken.cancel('Component unmounted');
  });
}
export {apiTermination, cancelToken}
