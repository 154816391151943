import {useEffect, useState} from "react";
import {allApiUrl} from "src/api/apiRoute";
import {_isCompanyAdminRole, _isCompanyUser} from "src/shared/functions/string-conversion";
import {useSelector} from "react-redux";
import {apiGetMethod} from "src/api/rest";
import {_setPaginationObjectFn, createQueryString, deepClone} from "src/shared/functions";
import {Layout} from "src/shared/constants/widgetJson";
import * as React from "react";
import {PAGINATION_OBJECT} from "src/core/constants";
import SelectAsync from "../shared/SelectAsync";
import {SearchDebounce} from "../Common/ui/SearchDebounce";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import {_handleChangePage} from "src/utils/common";

export const JsaCodeListPagination = (
  {
    onClickSubFormSection,
    handleOnDrag
  }) => {
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCategory, setFilterCategory] = useState(null);
  const [selected,setSelected] =  useState([])
  useEffect(() => {
    if (paginationObject.triggerApi) {
      fetchList();
    }
  }, [paginationObject.triggerApi]);
  useEffect(() => {
    try {
      _handleChangePage({}, 0, setPaginationObject,setSelected)
    } catch (e) {}
  }, [searchTerm, filterCategory])

  const fetchList = () => {
    setIsLoading(true);

    let queryObject = {
      search: searchTerm || "",
      categoryId: filterCategory?.value || null,
      pageNumber: paginationObject.page,
      limit: paginationObject.rowsPerPage,
      form_type: "JSA_CODE",
      is_active: true
    };
    const qryStr = createQueryString(queryObject);

    const url = _isCompanyUser(loginUserInfo) || _isCompanyAdminRole(loginUserInfo)
      ? `${allApiUrl.COMPANY_JSA_CODE}${qryStr}`
      : `${allApiUrl.JSA_FORMS}${qryStr}`;

    apiGetMethod(
      url,
    ).then((res) => {;
      let newData = res.data.map((row) => {
        let jsonData = deepClone(Layout["Section"]);
        jsonData.childComponent = row.structure;
        jsonData.label = row.title;
        jsonData.category = row.category_id;
        jsonData.category_name = row.category_name || "";
        jsonData.jsa_code_form_id = row.id;
        return jsonData;
      });
      setRows([...newData]);
      setPaginationObject((prevState) => {
        return _setPaginationObjectFn(prevState, res.meta);
      });
    }).catch(() => {
      setRows([...[]]);
      setPaginationObject(PAGINATION_OBJECT);
    })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div>
      <SelectAsync
        isClearable
        onChange={setFilterCategory}
        searchType="categoryList"
        selectedValue={filterCategory}
        placeholder="Select Category"
        className="asyncHeight mh-40 mt-3"
        dataParam={{isFilter: true}}
      />

      <SearchDebounce
        className="mt-3"
        onSearch={setSearchTerm}
      />
      <div className="mt-3">
        <List>
          {rows.map((item, idx) => (
            <div
              key={`choice7__${idx}`}
              className="widget w-100 mx-0 my-1"
              onClick={(e) => onClickSubFormSection(item)}
              draggable
              onDragStart={(e) => {
                handleOnDrag(e, item, true);
              }}
            >
              {item?.label}
            </div>
          ))}
          {isLoading && (
            <Loading/>
          )}
          {(!isLoading && !rows.length) && (
            <div className="animate-pulse p-2">No Data Found!</div>
          )}
        </List>
        <TablePaginationCompo
          className={"jsa_code_pagination"}
          labelRowsPerPage={""}
          paginationObject={paginationObject}
          setPaginationObject={setPaginationObject}
          setSelected = {setSelected}
        />
      </div>
    </div>
  )
}

export function List({...rest}) {
  return (
    <div
      {...rest}
    />
  );
}

export function Loading() {
  return (
    <div className="animate-pulse p-2">Loading...</div>
  );
}