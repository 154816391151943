
import PropTypes from "prop-types";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";

export default function UserTableRow({
  selected,
  permissions,
}) {
  return (
    <>
      {permissions?.map((entity, index) => {
        if (
          entity?.permission?.create?.is_disabled === false ||
          entity?.permission?.read?.is_disabled === false ||
          entity?.permission?.update?.is_disabled === false ||
          entity?.permission?.delete?.is_disabled === false
        )
          return (
            <TableRow hover tabIndex={-1} role="checkbox" selected={selected} key = {index}>
              <TableCell>{entity?.label}</TableCell>
              <TableCell>
                <Checkbox
                  disabled={entity?.permission?.create?.is_disabled}
                  checked={entity?.permission?.create?.check || false}
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  disabled={entity?.permission?.read?.is_disabled}
                  checked={entity?.permission?.read?.check || false}
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  disabled={entity?.permission?.update?.is_disabled}
                  checked={entity?.permission?.update?.check || false}
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  disabled={entity?.permission?.delete?.is_disabled}
                  checked={entity?.permission?.delete?.check || false}
                />
              </TableCell>
            </TableRow>
          );
      })}
    </>
  );
}

UserTableRow.propTypes = {
  avatarUrl: PropTypes.any,
  handleClick: PropTypes.func,
  name: PropTypes.any,
  permissions: PropTypes.any,
  selected: PropTypes.any,
  status: PropTypes.string,
};
