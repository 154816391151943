import {useEffect, useState} from "react";
import {replaceLabelStrings} from "src/shared/functions/string-conversion";
import LabelCompo from "./LabelCompo";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import * as React from "react";
import {isEmpty} from "src/shared/functions/isEmpty";
import {FormControl, FormHelperText} from "@mui/material";

export const DatePickerCompo = ({ isReadOnly, data, onChangeFields, form, isTrainingForm, isSubmitClicked = false, isEntryPage = false, isDraftSaved = false }) => {
  const [labelConv, setLabelConv] = useState(
    isReadOnly ? data.label : replaceLabelStrings(data?.label, form)
  );
  useEffect(() => {
    setLabelConv(isReadOnly ? data.label : replaceLabelStrings(data?.label, form));
  }, [data.label, form]);

  const [inputDate, setInputDate] = useState(isEntryPage ? data.userInputVal : data.value);
  useEffect(() => {
    if (!isReadOnly) {
      onChangeFields(inputDate);
    }
  }, [inputDate]);
  return (
    <div key={data.id} id={data.id}>
      <FormControl sx={{width: "100%"}} required={data.required} error={Boolean(!isReadOnly && data.required && isSubmitClicked && isEmpty(inputDate))}>
        <LabelCompo data={data} labelConv={labelConv}/>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disabled={!isDraftSaved && Boolean(isReadOnly || !!(isTrainingForm && inputDate) || (isEntryPage && inputDate))}
            id={`field_id_${data.id}`}
            name={`field_name_${data.id}`}
            slotProps={{textField: {size: 'small', fullWidth: true, required: data.required }}}
            defaultValue={inputDate ? dayjs(inputDate) : null}
            format={`MM/DD/YYYY`}
            onChange={(e) => {
              if (!isReadOnly) {
                setInputDate(e.format());
              }
            }}
          />
        </LocalizationProvider>
        <FormHelperText>{Boolean(!isReadOnly && data.required && isSubmitClicked && !inputDate) && "This Field is required!"}</FormHelperText>
      </FormControl>
    </div>
  );
};