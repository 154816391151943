import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {BootstrapDialog} from "../ui/BootstrapDialog";
import * as React from "react";
import {TRAINING_STATUS} from "../../../core/constants";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

function EntryResultModal({
    openModal,
    setOpenModal,
    resultData,
    onCloseResultModal
  }) {

  const closeModal = () => {
    setOpenModal(false)
    onCloseResultModal()
  }
  return (
    <BootstrapDialog
      onClose={closeModal}
      open={openModal}
      width={`auto`}
     className="modalHeight"
    >
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div className="saveModalBody" style={{textAlign: "center"}}>
          <Box>
            {(TRAINING_STATUS.PASSED === resultData?.userTrainingData?.status) ? (<>
              <img src='/assets/icons/trophy.svg' style={{width: "60px", height: "60px", marginBottom: "20px"}} alt={''}/>
              <h5>Congratulations!! You have passed the
                quiz. {resultData?.trainingData?.name ? `(${resultData?.trainingData?.name})` : ``}</h5>
              <p style={{color: '#777'}}>You did it! You just have aced your latest test, and we are thrilled to celebrate your achievement with you.</p>
              <div style={{display: "flex", justifyContent: "center", gap: "10px"}}>
                <p style={{fontSize: "19px", marginBottom: "0"}}>
                  <strong>Certificate: </strong>{resultData?.trainingData?.certificatename}</p>
              </div>
            </>) : (<>
              <img src='/assets/icons/fail.svg' style={{width: "55px", marginBottom: "20px"}} alt={''}/>
              <h5>Sorry!! You have failed this quiz.</h5>
              <p style={{color: '#777'}}>You can retake the quiz to achieve better results.</p>
            </>)}

            <div style={{display: "flex", justifyContent: "center", gap: "10px"}}>
              <p style={{fontSize: "19px", marginBottom: "0"}}><strong>Total Marks: </strong>{resultData?.trainingData?.totalmarks}</p>
              <p style={{fontSize: "19px", marginBottom: "0"}}><strong>Marks Obtained: </strong>{resultData?.obtainedMarks}</p>
            </div>
          </Box>
        </div>
      </DialogContent>
      {(TRAINING_STATUS.PASSED !== resultData?.userTrainingData?.status) && (
        <DialogActions sx={{justifyContent:"center"}}>
          <Button className='blueButton dialog' sx={{padding: "8px 25px", margin:"10px 0"}} autoFocus onClick={() => {
            closeModal();
            window.open(`/forms/retake-form/${resultData?.id}`, "_blank")
          }}>
            Retake
          </Button>
        </DialogActions>
      )}
    </BootstrapDialog>
  );
}

export default EntryResultModal;
