import {
  Box,
  TextField,
  Card,
  styled,
  Grid,
  Autocomplete,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
}));

function ProfilePage() {
    return (
      <>
      <Card elevation={3} sx={{ pt: "20px", mb: 3 }} className="cardFix">
        <CardHeader className="cardHeadBorder">
          <Title className="cardHead">Create Profile</Title>
        </CardHeader>

        <Box p={4} height="100%">
          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item lg={12}>
              <div className="uploadPhoto">
                <CameraAltIcon className="camUpload"/>
                <img src="/assets/images/avatars/avatar_3.jpg" className="userImage"/>
              </div>
            </Grid>
            <Grid item lg={6}>
                <TextField
                fullWidth
                type="text"
                name="fname"
                label="First Name"
                variant="outlined"
                className="inputHeight"
                placeholder="John"
                />
            </Grid>
            <Grid item lg={6}>
                <TextField
                fullWidth
                type="text"
                name="lname"
                label="Last Name"
                variant="outlined"
                className="inputHeight"
                placeholder="John"
                />
            </Grid>
            <Grid item lg={6}>
                <TextField
                fullWidth
                type="email"
                name="email"
                label="Email"
                variant="outlined"
                placeholder="John@example.com"
                className="inputHeight"
                />

            </Grid>
            <Grid item lg={6}>
              <MuiTelInput
                fullWidth
                forceCallingCode
                defaultCountry={"US"}
                size="small"
                placeholder="Phone Number"
                name="phone_number"
                className="phoneHeight"
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                fullWidth
                type="number"
                name="password"
                label="Password"
                variant="outlined"
                className="inputHeight"
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                fullWidth
                type="number"
                name="confirmpassword"
                label="Confirm Password"
                variant="outlined"
                className="inputHeight"
              />
            </Grid>
            <Grid item lg={12} sx={{marginTop:"20xpx"}}>
            <LoadingButton
              className="blueButton"
              type="button"
              color="primary"
              variant="contained"
              sx={{ mr: "10px" }}
            >
              Create
            </LoadingButton>
            <Button className="blueButton cancel">
              Cancel
            </Button>
          </Grid>
          </Grid>
        </Box>

      </Card>
      </>
    );
  }
  export default ProfilePage;