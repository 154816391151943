import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { useNavigate } from "react-router-dom";
import Scrollbar from "src/components/scrollbar";
import UserTableRow from "../user-table-row";
import { apiGetMethod } from "src/api/rest";
import {_handleChangePage, PermissionCheck} from "src/utils/common";
import { handleCheckboxClick } from "src/sections/Table";
import { allApiUrl } from "src/api/apiRoute";
import { useSelector } from "react-redux";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import PageHeader from "src/components-form/Common/PageHeader";
import { Helmet } from "react-helmet-async";
import {PAGINATION_OBJECT} from "src/core/constants";
import {headCellIndustry} from "src/components/mui-common/table-ui/headCells";
import {_setPaginationObjectFn, createQueryString} from "src/shared/functions";
import TableHeadRow, {StyledTableNoData} from "src/components/mui-common/table-ui/TableHeadRow";
import TableRowsLoader from "src/components/mui-common/table-ui/TableRowsLoader";
import {isEmpty} from "src/shared/functions/isEmpty";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import TableToolbar from "src/components/mui-common/table-ui/TableToolbar";
import {removeItemByKeyValue} from "src/shared/functions/array-conversion";

export default function IndustryPage() {
  let navigate = useNavigate();
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );

  const [selected, setSelected] = useState([]);
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(null);
  const [status, setStatus] = useState("all");
  const [dataFiltered, setDataFiltered] = useState([]);

  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);
  const [searchTerm, setSearchTerm] = useState("");

  const headCells = PermissionCheck("Industries", "delete", loginUserInfo) ? headCellIndustry : removeItemByKeyValue(headCellIndustry, ["checkbox"]);

  useEffect(() => {
    if (paginationObject.triggerApi) {
      apiCall();
    }
  }, [paginationObject.triggerApi]);
  useEffect(() => {
    try {
      _handleChangePage({}, 0, setPaginationObject,setSelected)
    } catch (e) {}
  }, [searchTerm, status])

  function apiCall(value) {
    setLoader(true);
    let queryObject = {
      search: searchTerm || "",
      pageNumber: paginationObject?.page,
      limit: paginationObject?.rowsPerPage,
      is_active: status === "all" ? null : status,
    };
    const qryStr = createQueryString(queryObject);

    apiGetMethod(`${allApiUrl?.INDUSTRY_LIST}${qryStr}`)
      .then((data) => {
        setDataFiltered(data.data);
        setPaginationObject((prevState) => {
          return _setPaginationObjectFn(prevState, data.meta);
        });
      })
      .catch((err) => {
        setDataFiltered([...[]]);
        setPaginationObject(PAGINATION_OBJECT);
      }).finally(() => {
      setLoader(false);
    });
  }

  const handleClick = (event, id) => {
    setSelected(handleCheckboxClick(event, id, selected));
  };

  const routeChange = () => {
    let path = `/industry/add-industry`;
    navigate(path);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
    setSelected([])
  };
  return (
    <>
      <Helmet>
        <title> Industries | {process.env.REACT_APP_APPLICATION_NAME} </title>
      </Helmet>
      <Box>
        <div className="flexTop">
          <PageHeader
            header={"Industry"}
            links={[
              {
                name: "Dashboard",
                path: "/",
              },
            ]}
          />
          <div>
            {PermissionCheck("Industries", "create", loginUserInfo) && (
              <Button
                variant="contained"
                color="inherit"
                startIcon={<AddIcon />}
                onClick={routeChange}
                className="blueButton"
              >
                New Industry
              </Button>
            )}
          </div>
        </div>
        <Card className="cardFix">
          <TableToolbar
            title="Industry"
            numSelected={selected.length}
            setSelected={setSelected}
            selected={selected}
            apiCall={apiCall}
            onSearch={(query) => setSearchTerm(query)}
            deleteUrl={allApiUrl?.DELETE_MULTIPLE_INDUSTRY}
            alertContent={`The deletion of an industry would trigger a chain reaction resulting in the removal of all related companies and substantial economic upheaval`}
          />

          <div style={{ padding: "20px 15px 20px 15px" }}>
            <h6 style={{marginBottom:"15px"}}>Filters:</h6>
            <Grid container columnSpacing={2}>
              <Grid item lg={3}>
                <FormControl sx={{ width: "100%", marginBottom: "5px" }}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    value={status}
                    onChange={handleChangeStatus}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>In Active</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <Scrollbar>
            <TableContainer>
              <Table>
                <TableHeadRow
                  numSelected={selected.length}
                  rowCount={dataFiltered.length}
                  headCells={headCells}
                  permissionType="Industries"
                  listData={dataFiltered}
                  setSelected={setSelected}
                />

                <TableBody>
                  {loader ? (
                    <TableRowsLoader
                      rowsNum={10}
                      columnNum={headCells.length}
                    />
                  ) : isEmpty(dataFiltered) ? (
                    <StyledTableNoData colSpan={headCells.length} />
                  ) : (
                    dataFiltered.map((row, index) => (
                      <UserTableRow
                        key={index}
                        name={row.name}
                        status={row.is_active}
                        created_at={row.created_at}
                        industryid={row.id}
                        selected={selected.indexOf(row.id) !== -1}
                        handleClick={(event) => handleClick(event, row.id)}
                        apiCall={apiCall}
                        setOpen={setOpen}
                        open={open}
                      />
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePaginationCompo
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            setSelected={setSelected}
          />
        </Card>
      </Box>
    </>
  );
}
