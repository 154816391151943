import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import LoadingButton from "@mui/lab/LoadingButton";
import * as React from "react";
import { FORM_CATALOGUE_TABS } from "../../core/constants";

function FooterPreview({
  formSubmitSettings,
  showSubmitButton = true,
  trainingParams,
  onClickFormButton = () => {},
  onCloseButton = null,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="form-group cardOuter d-flex justify-content-between">
          {!!showSubmitButton && (
            <div className="d-flex">
              {formSubmitSettings?.buttons?.map((button, index) => {
                let showBtn = true;
                if (
                  button?.eventType === "Draft" &&
                  !location.pathname.includes("/allocated-project-form")
                ) {
                  showBtn = false;
                }
                return (
                  <div key={`choice__${index}`}>
                    {showBtn && (
                      <LoadingButton
                        loading={button.isLoading}
                        id="removefrompdf"
                        loadingPosition="end"
                        endIcon={
                          button?.eventType === "Draft" ? (
                            <SaveAsIcon />
                          ) : (
                            <SaveIcon />
                          )
                        }
                        variant="contained"
                        key={`choice__${index}`}
                        className={`blueButton cancel ${
                          index + 1 === formSubmitSettings?.buttons.length
                            ? ""
                            : "me-3"
                        }`}
                        type={button.actionName}
                        onClick={(e) => {
                          try {
                            onClickFormButton(e, button);
                          } catch (ee) {}
                        }}
                      >
                        <span>{button.buttonText}</span>
                      </LoadingButton>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          <Button
            className="blueButton cancel"
            id="removefrompdf"
            onClick={() => {
              if (onCloseButton) {
                try {
                  onCloseButton();
                } catch (e) {}
              } else {
                location?.pathname?.includes("/allocated-training/retake-form")
                  ? navigate("/allocated-training")
                  : location?.pathname?.includes(
                      "/allocated-project-form/form-detail"
                    )
                  ? navigate("/allocated-project-form")
                  : location?.pathname?.includes(
                      "/training-attempts/form-details"
                    )
                  ? navigate("/training-attempts")
                  : location?.pathname?.includes("/my-training")
                  ? navigate("/my-training")
                  : location?.pathname?.includes("/allocated-training")
                  ? navigate("/allocated-training")
                  : location?.pathname?.includes("/training-catalogue")
                  ? navigate("/training-catalogue")
                  : location?.pathname?.includes("/retake-form")
                  ? navigate("/my-training")
                  : location?.pathname?.includes("/projects")
                  ? navigate("/projects")
                  : location?.pathname?.includes(
                      "/allocated-project-form/view-detail"
                    )
                  ? navigate("/allocated-project-form")
                  : location?.pathname?.includes("/projects/form-detail")
                  ? navigate(-1)
                  : location?.pathname?.includes("/training/view-entry")
                  ? navigate("/training-certificate")
                  : navigate(
                      `/forms?currentTab=${
                        trainingParams
                          ? trainingParams
                          : FORM_CATALOGUE_TABS.FORM
                      }`
                    );
              }
            }}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FooterPreview;
