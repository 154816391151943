const INITIAL_STATE = {
  loginUserInfo: {},
  loginUserToken: '',
}

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LOGIN_USER_INFO':
      return { ...state, loginUserInfo: action.data };
    case 'LOGIN_USER_TOKEN':
      return { ...state, loginUserToken: action.data };
    default:
      return state;
  }
};
export default loginReducer;