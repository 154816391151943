import { FormControlLabel, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { allApiUrl } from "src/api/apiRoute";
import { apiDeleteMethod, apiPutMethod } from "src/api/rest";
import ConfirmModal from "src/components/ConfirmModal";
import { Android12Switch } from "src/components/mui-common/switch-ui";
import { PermissionCheck } from "src/utils/common";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { BootstrapTooltipUi } from "src/components/mui-common/BootstrapToolTip";
import Checkbox from "@mui/material/Checkbox";
import {
  StyledTableCell,
  StyledTableRow,
} from "src/components/mui-common/table-ui/TableHeadRow";
import { Text2Line } from "src/components/TextLine";
import {
  _isCompanyAdminRole,
  _isSuperAdminRole,
} from "src/shared/functions/string-conversion";

export const CategoryTableRow = ({
  id,
  created_at,
  description,
  name,
  industry_name,
  status,
  selected,
  handleClick,
  loginUserInfo,
  industry_id,
  open,
  setOpen,
  apiCall,
}) => {
  const isSuperAdminRole = _isSuperAdminRole(loginUserInfo);
  const isCompanyRole = _isCompanyAdminRole(loginUserInfo);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(status);
  const [checkStatus, setCheckedStatus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const handleChange = (event) => {
    setChecked(event.target.checked);
    setCheckedStatus(true);
  };
  const editUser = async () => {
    navigate(`/category/edit-category/${id}`);
  };
  const deleteData = () => {
    apiDeleteMethod(`${allApiUrl?.DELETE_ADMIN_CATEGORY}/${deleteId}`)
      .then((data) => {
        setIsModalOpen(false);
        setOpen(null);
        toast.success(data?.message);
        apiCall();
      })
      .catch((err) => {
        toast.error(err?.data?.message);
      });
  };
  const editStatus = () => {
    let obj = {
      name: name,
      description: description,
      industry_id: industry_id,
      is_active: checked,
    };
    apiPutMethod(`${allApiUrl?.UPDATE_ADMIN_CATEGORY}/${id}`, obj)
      .then((data) => {
        toast.success("Status successfully updated.");
      })
      .catch((err) => {
        toast.error(err?.data.message);
      });
  };
  useEffect(() => {
    if (checkStatus === true) {
      editStatus();
    }
  }, [checked, checkStatus]);
  return (
    <>
      <StyledTableRow tabIndex={-1} role="checkbox">
        {PermissionCheck("Categories", "delete", loginUserInfo) && (
          <StyledTableCell padding="checkbox">
            <Checkbox disableRipple checked={selected} onChange={handleClick} />
          </StyledTableCell>
        )}
        <StyledTableCell sx={{ fontWeight: "700" }}>{name}</StyledTableCell>
        <StyledTableCell className="nowrap12">
          <Text2Line style={{ color: "#000000" }}>{description}</Text2Line>
        </StyledTableCell>
        {isSuperAdminRole && (
          <StyledTableCell className="nowrap12">
            {industry_name}
          </StyledTableCell>
        )}
        <StyledTableCell className="nowrap12">{created_at}</StyledTableCell>

        <StyledTableCell>
          <FormControlLabel
            control={
              <Android12Switch
                color="success"
                checked={Boolean(checked)}
                onChange={handleChange}
                disabled={!PermissionCheck("Categories", "update", loginUserInfo)}
              />
            }
            label={checked ? "Active" : "In Active"}
          />
        </StyledTableCell>

        {(PermissionCheck("Categories", "update", loginUserInfo) ||
          PermissionCheck("Categories", "delete", loginUserInfo)) && (
          <StyledTableCell className="nowrapFull">
            {PermissionCheck("Categories", "update", loginUserInfo) && (
              <BootstrapTooltipUi title="Edit" placement="top">
                <IconButton
                  className="outerborder"
                  aria-label="Edit"
                  onClick={() => editUser(id)}
                >
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </BootstrapTooltipUi>
            )}
            {PermissionCheck("Categories", "delete", loginUserInfo) && (
              <BootstrapTooltipUi title="Delete" placement="top">
                <IconButton
                  className="outerborder"
                  aria-label="Delete"
                  sx={{ color: "error.main" }}
                  onClick={() => {
                    setDeleteId(id);
                    setIsModalOpen(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </BootstrapTooltipUi>
            )}
          </StyledTableCell>
        )}
      </StyledTableRow>
      <ConfirmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        deleteData={deleteData}
        content="Are you sure you want to delete this item? "
        setOpen={setOpen}
      />
    </>
  );
};
