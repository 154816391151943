import {replaceLabelStrings} from "src/shared/functions/string-conversion";
import LabelCompo from "./LabelCompo";
import * as _ from "lodash";
import * as React from "react";
import {FormControl, FormControlLabel, FormGroup, FormHelperText} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import {useEffect, useState} from "react";
import {isEmpty} from "src/shared/functions/isEmpty";
import {QueCorrectIncorrect} from "./ui/QueCorrectIncorrect";

export const CheckboxCompo = (
  {
    isReadOnly = true,
    data,
    onChangeFields,
    form,
    isTrainingForm = false,
    isSubmitClicked = false,
    isEntryPage = false,
    isRetakePage = false,
    isDraftSaved = false,
  }) => {
  const [fieldChangedCount, setFieldChangeCount] = useState(0)
  const [labelConv, setLabelConv] = useState(
    isReadOnly ? data.label : replaceLabelStrings(data?.label, form)
  );

  const [selectedValues, setSelectedValues] = useState(((isEntryPage && data.userInputVal) ? ((typeof data.userInputVal === "object" && data.userInputVal) ? data.userInputVal : !isEmpty(JSON.parse(data.userInputVal)) ? [JSON.parse(data.userInputVal)] : []) : data.value) || []);

  useEffect(() => {
    if (isReadOnly) {
      setSelectedValues(data.value)
    }
  }, [data.value]);
  useEffect(() => {
    setLabelConv(isReadOnly ? data.label : replaceLabelStrings(data?.label, form));
  }, [data.label, form]);

  useEffect(() => {
    onChangeFields(selectedValues)
  }, [selectedValues]);

  const HtmlTag = !data.typeRadio ? Checkbox : Radio;

  return (
    <div key={data.id} id={data.id}>
      <FormControl required={data.required} error={Boolean(!isReadOnly && data.required && isSubmitClicked && isEmpty(selectedValues))} sx={{width:"100%"}}>
        <LabelCompo data={data} labelConv={labelConv}/>

        <input hidden id={`field_id_${data.id}`} type={!data.typeRadio ? `checkbox`: `radio`} name={`hidden_field_name_${data.id}`} value={JSON.stringify(selectedValues)} readOnly/>
        <FormGroup className={data?.layout === "side_by_side" ? `flex-row`: ""} >
          {data.options.map((opt, idx) => {
            return (
              <div key={`options_form_${opt.id}`} className="">
                <FormControlLabel 
                  className="labelWidth"
                  label={opt.label}
                  control={
                    isReadOnly ?
                      <HtmlTag
                        disabled={!isDraftSaved && isReadOnly}
                        id={`field_id_${data.id}`}
                        name={`field_name_${data.id}`}
                        checked={_.includes(_.map(selectedValues, "id"), opt.id)}
                       
                      /> :
                      <HtmlTag
                        disabled={fieldChangedCount> 0 || ((!isDraftSaved && !data.showRetakeField) && (isReadOnly || !!(data.typeRadio && isTrainingForm && selectedValues.length) || (isEntryPage && !isEmpty(selectedValues))))}
                        id={`field_id_${data.id}`}
                        name={`field_name_${data.id}`}
                        value={data.hasValues ? opt.value : opt.label}
                        checked={_.includes(_.map(selectedValues, "id"), opt.id)}
                        onChange={(e) => {
                          const el = {
                            element: data.element,
                            id: opt.id,
                            val: data.hasValues ? opt.value : opt.label,
                            label: opt.label
                          };

                          if (data.typeRadio) {
                            if (isRetakePage) {
                              setFieldChangeCount(prevState => { return prevState+1 })
                            }
                            setSelectedValues(e.target.checked ? [el] : [])
                          } else {
                            let tempDataVal = [...selectedValues];
                            if (e.target.checked) {
                              tempDataVal.push(el)
                            } else {
                              _.remove(tempDataVal, o => o.id === opt.id)
                            }
                            setSelectedValues(tempDataVal);
                          }
                        }}
                      />
                  }/>
              </div>
            );
          })}
        </FormGroup>
        <FormHelperText>{Boolean(!isReadOnly && data.required && isSubmitClicked && isEmpty(selectedValues)) && "This Field is required!"}</FormHelperText>
        {Boolean(isTrainingForm && data.userInputVal !== undefined && !isReadOnly && !isSubmitClicked) &&
          <QueCorrectIncorrect child={data} />
        }
      </FormControl>
    </div>
  );
};
