import {useEffect, useState} from "react";
import * as React from "react";
import {highlightTextWithBackground} from "src/shared/functions/string-conversion";
import {ParagraphEditorCompo} from "./ParagraphEditorCompo";
import EditNoteIcon from '@mui/icons-material/EditNote';
import IconButton from "@mui/material/IconButton";

export const ParagraphCompo = ({ isReadOnly, data, onChangeFields }) => {
  const [content, setContent] = useState(data.label || "");
  const [showEditor, setShowEditor] = useState(false);

  useEffect(() => {
    setContent(data.label);
  }, [data.label]);

  useEffect(() => {
    onChangeFields(content);
  }, [content]);

  return (
    <div key={data.id} id={data.id}>
      <input hidden id={`field_id_${data.id}`} name={`field_name_${data.id}`} value={content} readOnly/>
      {data?.userContentEditable && (
        <IconButton
          className={"editPreviewForm"}
          onClick={(e) => {
            if (data?.userContentEditable && !isReadOnly) {
              setShowEditor(!showEditor);
            }
          }}
        >
          <EditNoteIcon/>
        </IconButton>
      )}
      {!showEditor ? (
        <p
          style={{
            fontWeight: data.bold ? "bold" : "",
            fontStyle: data.italic ? "italic" : "",
          }}
          dangerouslySetInnerHTML={{
            __html: data.label
              ? highlightTextWithBackground(content)
              : "Content Here",
          }}
        />
      ) : (
        <ParagraphEditorCompo
          label={null}
          valueProp={content}
          onEditorChange={(e) => {
            if (!isReadOnly) {
              setContent(e);
            }
          }}
          dataId={data.id}
        />
      )}
    </div>
  );
};