import LabelCompo from "./LabelCompo";
import * as React from "react";
import { UploadFilesS3 } from "src/shared/functions";
import MediaPreviewer from "./ui/MediaPreview";
import { isEmpty } from "src/shared/functions/isEmpty";
import Loader from "src/components/Loader";
import { Box } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';

export const MultipleFileUploadCompo = ({
  isReadOnly = false,
  data,
  isEntryPage = false,
  labelConv,
  setFileName,
  fileName,
  fileSrc,
  setFileSrc,
  showSubmitButton = true
}) => {
  const [newSrc, setNewSrc] = React.useState([]);
  const [newFileSrc, setNewFileSrc] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    if (newFileSrc.length > 0 && !isEntryPage)
      setFileName(JSON.stringify(newFileSrc));
  }, [newFileSrc]);
  React.useEffect(() => {
    setNewSrc([...fileSrc]);
  }, [fileSrc]);

  return (
    <>
      <div key={data.id} id={data.id} className="imageChanges">
        <LabelCompo data={data} labelConv={labelConv} />
        <input
          hidden
          id={`field_id_${data.id}`}
          name={`field_name_${data.id}`}
          value={fileName}
          readOnly
        />

        <br />
        <Box className="inputFile compo">
          {((!fileSrc && isEntryPage) || !isEntryPage) && (
            <input
              type="file"
              required={data.required}
              accept={data.fileType?.value}
              onChange={(e) => {
                if (!isReadOnly && !isEmpty(e.target.files) && e.target.files) {
                  setLoading(true);
                  let promise = [];
                  Object.keys(e.target.files).map((key) => {
                    promise.push(UploadFilesS3(e.target.files[key]));
                  });
                  Promise.all(promise).then((data) => {
                    if (data?.length > 0) {
                      setNewFileSrc((prevData) => {
                        return [...prevData, ...data];
                      });
                      setLoading(false);
                    }
                  });
                }
              }}
              multiple
            />
          )}
        </Box>
        {loading ? <Loader /> : ""}
        <div className="imageFlex" style={{marginTop:"10px"}}>
          {newSrc &&
            newSrc.length > 0 &&
            newSrc.map((src, index) => {
              return (
                <div key={index}>
                  {(!Boolean(isEntryPage) || Boolean(showSubmitButton)) && <IconButton
                  onClick={() => {
                    let newFiles = newFileSrc.filter(
                      (el, idx) => index !== idx
                    );
                    setNewFileSrc([...newFiles]);
                    setNewSrc([...newFiles]);
                    setFileSrc([...newFiles]);
                  }}
                  >
                    <CancelIcon/>
                  </IconButton>}
                  <MediaPreviewer
                    fileName={JSON.parse(fileName)[index]}
                    fileUrl={src}
                    loading={loading}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};
