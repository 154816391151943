import { FormControl, Grid, InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SelectAsync from "src/components-form/shared/SelectAsync";

export const Crew = ({ addCrew, showCrew, setShowCrew }) => {
  useEffect(() => {
    let obj;
    let array = [];
    if (addCrew?.length > 0) {
      addCrew?.map((item) => {
        obj = {
          value: item?.crew_id,
          label: `${item?.crew_user_name}`,
          crew_member_user_id: item?.crew_user_id,
        };
        array.push(obj);
      });
      setShowCrew(array);
    }
  }, [addCrew]);
  const handleChangeCrew = (event) => {
    setShowCrew([...event]);
  };
  return (
    <Grid item md={6} sm={12}>
      <FormControl fullWidth>
        <InputLabel
          style={{
            backgroundColor: "#fff",
            padding: "0 5px",
          }}
          shrink={true}
        >
          Crew
        </InputLabel>
        <SelectAsync
          searchType="selectCompanyUser"
          placeholder="Select Crew"
          className="multiSelectHeight project"
          onChange={handleChangeCrew}
          selectedValue={showCrew}
          isMulti={true}
        />
      </FormControl>
    </Grid>
  );
};
