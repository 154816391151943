import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  Table,
  TableBody,
  TableContainer,
  Button,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { BootstrapDialog } from "src/components-form/Modals/ui/BootstrapDialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";
import { useState } from "react";
import SelectAsync from "src/components-form/shared/SelectAsync";
import TableHeadRow, {
  StyledTableCell,
  StyledTableNoData,
  StyledTableRow,
} from "src/components/mui-common/table-ui/TableHeadRow";
import {isEmpty} from "src/shared/functions/isEmpty";


const AssignForm = ({
  setCheckAssignTraining,
  selectedDate,
  formData,
  projectTraining,
  userId,
}) => {
  const [selectedTrainings, setSelectedTrainings] = useState([]);
  const closeModal = () => {
    setCheckAssignTraining(null);
  };
  const headLabel = [
    { id: "assign_crew", label: "User Name" },
    { id: "email", label: "Training Required" },
    { id: "done_training", label: "Training Done" },
  ];
  const getTrainingStatus = () => {
    let result = projectTraining?.map((item) => {
      return item?.requiredTrainings?.map((rt) => {
        let assignedTraining = item?.userDoneTrainings?.find(
          (ut) => ut?.trainings_id == rt?.id
        );
        if (assignedTraining) {
          rt.status = "COMPLETED";
        }
        let assignedResult = item?.userAssignedTrainings?.find(
          (uat) => rt?.id == uat?.training_id
        );
        if (assignedResult) {
          rt.status = "PENDING";
        }
        if (!rt?.status) {
          rt.status = "-";
        }
        return rt;
      });
    });
    setSelectedTrainings(result);
  };
  React.useEffect(() => {
    getTrainingStatus();
  }, []);
  return (
    <BootstrapDialog
      className="saveFormwidth"
      onClose={closeModal}
      width={`auto`}
      open={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Assign Form
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel
                style={{ backgroundColor: "#fff", padding: "0 5px" }}
                shrink={true}
              >
                Forms
              </InputLabel>
              <SelectAsync
                isDisabled={true}
                searchType="company_filtered_training_form"
                dataParam={{filtered_data: "project"}}
                selectedValue={formData}
                placeholder="Select Form"
                className="asyncHeight"
              />
            </FormControl>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <FormControl sx={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="dateWidth">
                  <DesktopDatePicker
                    label="Select date"
                    value={selectedDate}
                    disabled={true}
                  />
                </div>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item lg={12}>
            <Card>
              <Box>
                <TableContainer>
                  <Table sx={{minWidth: 800}}>
                    <TableHeadRow headCells={headLabel} />
                    <TableBody>
                      {isEmpty(projectTraining) ? (
                        <StyledTableNoData colSpan={headLabel.length} />
                      ) : (
                        projectTraining?.map((item, index) => {
                          const status = selectedTrainings[index]
                            ?.map(
                              (statu, refIndx) =>
                                statu.name + `(${statu?.status})`
                            )
                            .join(", ");
                          return (
                            <StyledTableRow key={`styledTableRowForm_${index}`}>
                              <StyledTableCell>{item?.user_name}</StyledTableCell>
                              <StyledTableCell>
                                {status}
                              </StyledTableCell>
                              <StyledTableCell>
                                {item?.userDoneTrainings?.length}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className="blueButton cancel"
          sx={{ p: "8px 25px" }}
          onClick={() => setCheckAssignTraining(false)}
        >
          Cancel
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};
export default AssignForm;
