import { useState } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import { toast } from "react-toastify";
import Iconify from "src/components/iconify";
import { Formik } from "formik";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import Grid from '@mui/material/Grid';

const initialValues = {
  password: "",
  confirm_password: "",
};

const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, "Password must be 6 character length")
    .required("Password is required!"),
  confirm_password: Yup.string()
    .required('Confirm Password is required!')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

export default function ResetpasswordView() {
  const { resetPassword } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const urlToken = queryParams.get("token");

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await resetPassword(values.password, urlToken);
    } catch (e) {
      toast.error(e.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  const renderForm = (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({
        isSubmitting,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={3} sx={{ my: 3 }}>
            <TextField
              autoComplete={``}
              name="password"
              label="New Password"
              type={showPassword ? "text" : "password"}
              onBlur={handleBlur}
              value={values.password}
              onChange={handleChange}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              autoComplete={``}
              name="confirm_password"
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              onBlur={handleBlur}
              value={values.confirm_password}
              onChange={handleChange}
              error={
                touched.confirm_password && Boolean(errors.confirm_password)
              }
              helperText={touched.confirm_password && errors.confirm_password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <LoadingButton
          className="blueButton"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="inherit"
            loading={isSubmitting}
          >
            Change Password
          </LoadingButton>
        </form>
      )}
    </Formik>
  );

  return (
    <Box height="100%">
      <Grid container height="100%" className="loginSection">
        <Grid item lg={6} className="imageSection">
          <span className="dot1"><img src="/assets/background/dots.svg" style={{ width:"200px"}}/></span>
          <span className="dot2"><img src="/assets/background/dots.svg" style={{ width:"200px"}}/></span>
          <span className="blob1"><img src="/assets/background/blob1.svg" style={{ width:"400px"}}/></span>
          <span className="blob2"><img src="/assets/background/blob1.svg" style={{ width:"400px"}}/></span>
          <img src="/assets/img/reset3.svg" style={{height:"650px", width:"650px"}}  alt="login"/>
        </Grid>
        <Grid item lg={6}>
          <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
            <Card
              sx={{
                p: 5,
                width: 1,
                maxWidth: 500,
              }}
            >
              <Typography variant="h4" sx={{textAlign: "center"}}>Reset Password</Typography>

              {renderForm}
            </Card>
          </Stack>
        </Grid>
      </Grid>

    </Box>
  );
}
