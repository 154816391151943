import {
  Box,
  Button,
  Chip,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import { BootstrapDialog } from "src/components-form/Modals/ui/BootstrapDialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";
import { isEmpty } from "src/shared/functions/isEmpty";
import Scrollbar from "src/components/scrollbar/scrollbar";
import { useEffect, useState } from "react";
import { apiDeleteMethod, apiGetMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import { formatDateFn } from "src/shared/functions/string-conversion";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { BootstrapTooltipUi } from "src/components/mui-common/BootstrapToolTip";
import SavedEntriesDrawer from "src/components-form/Modals/SavedEntriesDrawer";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import ConfirmModal from "src/components/ConfirmModal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ShareIcon from "@mui/icons-material/Share";
import { PAGINATION_OBJECT } from "src/core/constants";
import {
  headCellLinkedCrew,
  headCellLinkedForm,
} from "src/components/mui-common/table-ui/headCells";
import {
  _setPaginationObjectFn,
  createQueryString,
} from "src/shared/functions";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import TableRowsLoader from "src/components/mui-common/table-ui/TableRowsLoader";
import TableHeadRow, {
  StyledTableCell,
  StyledTableNoData,
  StyledTableRow,
} from "src/components/mui-common/table-ui/TableHeadRow";

const LinkedFormsModal = ({
  linkedFormsModalOpen,
  setLinkedFormsModalOpen,
  setOpenModal,
  setFormData,
  selectSelectDueDate,
  setUserId = [],
}) => {
  const isCrewMember = Boolean(linkedFormsModalOpen?.type === "crewMember");
  const headLabel = isCrewMember ? headCellLinkedCrew : headCellLinkedForm;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [open, setOpen] = useState(null);
  const [deleteData, setDeleteData] = useState({});
  const [loader, setLoader] = useState(true);
  const [openDrawer, setOpenDrawer] = useState({});
  const [copied, setCopied] = useState("");

  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);

  useEffect(() => {
    fetchFormList();
  }, []);
  useEffect(() => {
    if (paginationObject.triggerApi) {
      fetchFormList();
    }
  }, [paginationObject.triggerApi]);

  const fetchFormList = () => {
    setLoader(true);
    let queryObject = {
      pageNumber: paginationObject.page,
      limit: paginationObject.rowsPerPage,
    };
    const qryStr = createQueryString(queryObject);
    const url = isCrewMember
      ? allApiUrl?.PROJECT_USER_FORMS_LIST
      : allApiUrl?.PROJECT_FORM_CREW_DATA;

    apiGetMethod(
      `${url}/${linkedFormsModalOpen?.projectId}/${linkedFormsModalOpen?.type_id}${qryStr}`
    )
      .then((data) => {
        setDataFiltered(data.data);
        setPaginationObject((prevState) => {
          return _setPaginationObjectFn(prevState, data.meta);
        });
      })
      .catch((err) => {
        setDataFiltered([...[]]);
        setPaginationObject(PAGINATION_OBJECT);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const closeModal = () => {
    setLinkedFormsModalOpen(null);
    setUserId([]);
  };
  const handleDeleteForm = async (item) => {
    await apiDeleteMethod(
      `${allApiUrl?.PROJECT_DELETE_CREW_FORM}/${deleteData?.project_form_id}/${deleteData?.allocated_project_form_id}`
    )
      .then((data) => {
        setIsModalOpen(false);
        setOpen(null);
        toast.success(data?.message);
        fetchFormList();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err.data?.message);
      });
  };
  const handleCopy = async (item) => {
    let linkToCopy = `${process.env.REACT_APP_STRIPE_SUCCESS_URL}/allocated-project-form/form-detail/${item?.allocated_project_form_id}?status=${item?.project_form_status}&shareable_auth_token=${item?.shareable_auth_token}`;
    try {
      await navigator.clipboard.writeText(linkToCopy);
      setCopied(item?.allocated_project_form_id);
      setTimeout(() => setCopied(false), 2000); // Reset copied status after 2 seconds
    } catch (err) {
      setCopied("");
      console.error("Failed to copy: ", err);
    }
  };
  return (
    <Box>
      <SavedEntriesDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
      />
      <BootstrapDialog
        className="saveFormwidth"
        onClose={closeModal}
        width={`auto`}
        open={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {isCrewMember ? `Assigned Forms` : `Users List`} |{" "}
          {!isEmpty(linkedFormsModalOpen) && linkedFormsModalOpen?.type_label}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box>
            <Button
              variant="contained"
              color="inherit"
              className="blueButton"
              sx={{ marginRight: "10px" }}
              onClick={() => setOpenModal(true)}
            >
              Assign Form
            </Button>
            <Scrollbar>
              <TableContainer sx={{ overflow: "unset" }}>
                <Table
                  sx={{
                    minWidth: 800,
                    borderCollapse: "separate",
                    borderSpacing: "0px 8px",
                  }}
                >
                  <TableHeadRow headCells={headLabel} />

                  <TableBody>
                    {loader ? (
                      <TableRowsLoader
                        rowsNum={4}
                        columnNum={headLabel.length}
                      />
                    ) : isEmpty(dataFiltered) ? (
                      <StyledTableNoData colSpan={headLabel.length} />
                    ) : (
                      dataFiltered?.map((item, index) => {
                        return (
                          <>
                            <StyledTableRow
                              role="checkbox"
                              tabIndex={-1}
                              key={`styledTableRow_${index}`}
                              className="tableRow"
                            >
                              <StyledTableCell sx={{ fontWeight: "700" }}>
                                {isCrewMember
                                  ? item?.form_name
                                  : item?.user_name}
                              </StyledTableCell>
                              <StyledTableCell>
                                {isCrewMember
                                  ? formatDateFn(
                                      item?.project_form_allocation_date
                                    )
                                  : item?.phone_number}
                              </StyledTableCell>
                              <StyledTableCell>
                                {isCrewMember
                                  ? formatDateFn(item?.due_date)
                                  : item?.user_email}
                              </StyledTableCell>
                              {isCrewMember && (
                                <StyledTableCell>
                                  {item?.requiredTrainingData?.length > 0 ? (
                                    item?.requiredTrainingData?.map((tran) => (
                                      <p>{tran?.name}</p>
                                    ))
                                  ) : (
                                    <p>N/A</p>
                                  )}
                                </StyledTableCell>
                              )}
                              <StyledTableCell>
                                {item?.project_form_status === "PENDING" ? (
                                  <Chip label="PENDING" color="error" />
                                ) : (
                                  <Chip label="SUBMITTED" color="success" />
                                )}
                              </StyledTableCell>
                              <StyledTableCell style={{ whiteSpace: "noWrap" }}>
                                {item?.project_form_status === "COMPLETED" ? (
                                  <BootstrapTooltipUi
                                    title="View"
                                    placement="top"
                                  >
                                    <IconButton
                                      className="outerborder"
                                      aria-label="Delete"
                                      onClick={() =>
                                        setOpenDrawer({
                                          id: item?.allocated_project_form_id,
                                          state: {
                                            project_form_status: "COMPLETED",
                                          },
                                          pathName: "/projects/form-detail",
                                        })
                                      }
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  </BootstrapTooltipUi>
                                ) : (
                                  <BootstrapTooltipUi
                                    title="View"
                                    placement="top"
                                  >
                                    <IconButton
                                      className="outerborder"
                                      aria-label="Delete"
                                      onClick={() => {
                                        setOpenModal(true);
                                        setFormData({
                                          value: item?.form_id,
                                          label: item?.form_name,
                                        });
                                        selectSelectDueDate(
                                          item?.due_date || item?.created_at
                                        );
                                      }}
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  </BootstrapTooltipUi>
                                )}
                                {item?.project_form_status !== "COMPLETED" && (
                                  <BootstrapTooltipUi
                                    title="Delete"
                                    placement="top"
                                  >
                                    <IconButton
                                      className="outerborder"
                                      aria-label="Delete"
                                      onClick={() => {
                                        setIsModalOpen(true);
                                        setDeleteData(item);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </BootstrapTooltipUi>
                                )}
                                <BootstrapTooltipUi
                                  title={
                                    copied === item?.allocated_project_form_id
                                      ? "Copied"
                                      : "Copy link"
                                  }
                                  placement="top"
                                >
                                  <IconButton
                                    className="outerborder "
                                    aria-label="Shareable Link"
                                    onClick={(e) => handleCopy(item)}
                                    variant="text"
                                  >
                                    {copied ===
                                    item?.allocated_project_form_id ? (
                                      <CheckCircleOutlineIcon />
                                    ) : (
                                      <ShareIcon />
                                    )}
                                  </IconButton>
                                </BootstrapTooltipUi>
                              </StyledTableCell>
                            </StyledTableRow>
                            <ConfirmModal
                              isModalOpen={isModalOpen}
                              setIsModalOpen={setIsModalOpen}
                              deleteData={handleDeleteForm}
                              content="Are you sure you want to delete this item? "
                              setOpen={setOpen}
                            />
                          </>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePaginationCompo
              paginationObject={paginationObject}
              setPaginationObject={setPaginationObject}
            />
          </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </Box>
  );
};
export default LinkedFormsModal;
