import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import Iconify from "src/components/iconify";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import useAuth from "src/hooks/useAuth";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { allApiUrl } from "src/api/apiRoute";
import debounce from "lodash/debounce";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
const initialValues = {
  email: "",
  password: "",
  companyid: "",
};
const loginSx = {
  color: "#ee7000",
  textDecoration: "none",
  fontWeight: "100",
  "&:hover": {
    color: "#ee7000",
  },
};
export default function LoginView() {
  useEffect(() => {}, []);
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [company, setCompany] = useState();
  const [CompanyData, setCompanyData] = useState([]);
  const [count, setCount] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must be 6 character length")
      .required("Password is required!"),
    email: Yup.string()
      .email("Invalid Email address")
      .required("Email is required!"),
    companyid:
      CompanyData?.length > 1
        ? Yup.string().required("Company is required")
        : Yup.string(),
  });
  const handleFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      let portal = location?.pathname == "/login" ? null : "Admin";
      await login(values.email, values.password, companyId, portal);
    } catch (e) {
      toast.error(e.data.message);
    } finally {
      setSubmitting(false);
    }
  };
  let controller = null;
  const getCompanyDetails = async (value) => {
    if (controller) {
      controller.abort();
    }
    controller = new AbortController();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}${allApiUrl?.EMAIL_DETAILS}/${value}`,
        {
          signal: controller.signal,
        }
      );
      setCount(response.data?.totalCount);
      if (response?.data?.data) {
        setCompanyData(response.data.data);
        setCompanyId(response.data.data[0].company_id);
        setDisabled(true);
        if (response?.data?.data?.length > 1) {
          toast.success(
            "Same email is registered with multiple companies, select company and try login again"
          );
        }
      } else {
        setCompanyData();
        setDisabled(true);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        console.error("Fetch error:", error);
      }
    }
  };

  const debouncedGetEmailDetails = debounce(async (value) => {
    setLoading(true);
    setDisabled(true);
    try {
      if (location.pathname === "/login") {
        await getCompanyDetails(value);
      }
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  }, 800);
  const renderForm = (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({
        isSubmitting,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              className="inputText"
              autoComplete="off"
              name="email"
              label="Email address"
              value={values.email}
              onChange={(e) => {
                setFieldValue("email", e.target.value);
                debouncedGetEmailDetails(e.target.value);
              }}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email ? errors.email : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="adornBack">
                    {loading && (
                      <IconButton edge="end">
                        <Iconify
                          icon="bi:arrow-clockwise"
                          className="spinnerIcon"
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {location.pathname === "/login" && count > 1 ? (
              <FormControl>
                <InputLabel
                  id="dropdown-label"
                  style={{ backgroundColor: "#fff", padding: "0 5px" }}
                  // shrink={true}
                >
                  Select Company
                </InputLabel>
                <Select
                  labelId="dropdown-label"
                  id="dropdown"
                  value={company}
                  onChange={(e) => {
                    setFieldValue("companyid", e?.target.value);
                    setCompanyId(e?.target.value);
                  }}
                >
                  {CompanyData.map((option) => (
                    <MenuItem
                      key={option.company_name}
                      value={option.company_id}
                    >
                      {option.company_name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {touched.companyid && errors.companyid}
                </FormHelperText>
              </FormControl>
            ) : (
              ""
            )}

            <TextField
              autoComplete="off"
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              onBlur={handleBlur}
              value={values.password}
              onChange={handleChange}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password ? errors.password : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="adornBack">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                        className="eyeIcon"
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ my: "13px" }}
          >
            <div
              variant="subtitle2"
              onClick={() => navigate("/forgot-password")}
              className="loginfx"
            >
              Forgot password?
            </div>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="inherit"
            className=" blueButton"
            style={{ fontSize: "20px", marginTop: "8px" }}
            disabled={disabled || isSubmitting}
            loading={isSubmitting}
            onClick={handleSubmit}
          >
            Login
          </LoadingButton>
          {location?.pathname === "/login" && (
            <>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ my: "13px" }}
              >
                <div
                  variant="subtitle2"
                  onClick={() => navigate("/company/register")}
                  className="loginfx"
                >
                  Register Your Company ? Sign up
                </div>
              </Stack>
            </>
          )}
        </form>
      )}
    </Formik>
  );

  return (
    <Box height="100%">
      <Grid container height="100%" className="loginSection">
        <Grid item lg={6} className="imageSection">
          <span className="dot1">
            <img src="/assets/background/dots.svg" style={{ width: "200px" }} />
          </span>
          <span className="dot2">
            <img src="/assets/background/dots.svg" style={{ width: "200px" }} />
          </span>
          <span className="blob1">
            <img
              src="/assets/background/blob1.svg"
              style={{ width: "400px" }}
            />
          </span>
          <span className="blob2">
            <img
              src="/assets/background/blob1.svg"
              style={{ width: "400px" }}
            />
          </span>
          <img
            src="/assets/img/login3.svg"
            style={{ height: "650px", width: "650px" }}
            alt="login"
          />
        </Grid>
        <Grid item lg={6}>
          <Stack>
            <Card
              className="cardBack"
              sx={{
                p: 5,
                width: 1,
                maxWidth: 500,
              }}
            >
              <Typography
                variant="h4"
                sx={{ textAlign: "center", color: "#000" }}
              >
                Login Into Your Account
              </Typography>
              <Typography variant="body2" sx={{ mt: 2, mb: 5 }}></Typography>
              {renderForm}
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
