import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import { apiDeleteMethod, apiPutMethod } from "src/api/rest";
import ConfirmModal from "src/components/ConfirmModal";
import { PermissionCheck } from "src/utils/common";
import { allApiUrl } from "src/api/apiRoute";
import { useSelector } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { Android12Switch } from "src/components/mui-common/switch-ui";
import {BootstrapTooltipUi} from "src/components/mui-common/BootstrapToolTip";


export default function UserTableRow({
  selected,
  name,
  email,
  phonenumber,
  companyid,
  status,
  handleClick,
  industry,
  billingaddress,
  industry_id,
  apiCall,
  open,setOpen
}) {
  let navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [checked, setChecked] = useState(status);
  const [checkStatus, setCheckedStatus] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    setCheckedStatus(true);
  };

  const deleteData = () => {
    apiDeleteMethod(`${allApiUrl?.DELETE_COMPANY}/${deleteId}`)
      .then((data) => {
        setIsModalOpen(false);
        toast.success(data?.message);
        setOpen(null);
        apiCall()
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err.data?.message);
      });
  };
  const editCompany = async () => {
    navigate(`/company/edit-company/${companyid}`);
  };
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const editStatus = () => {
    let obj = {
      name: name,
      billingaddress: billingaddress,
      email: email,
      phonenumber: phonenumber,
      industry_id: industry_id,
      status: checked == true ? "Active" : "Inactive",
    };

    apiPutMethod(`${allApiUrl?.EDIT_COMPANY}/${companyid}`, obj)
      .then((data) => {
        toast.success("Status successfully updated.");
      })
      .catch((err) => {
        toast.error(err?.data.message);
      });
  };
  useEffect(() => {
    if (checkStatus === true) {
      editStatus();
    }
  }, [checked, checkStatus]);

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        role="checkbox"
        selected={selected}
        className="tableRow"
      >
        {PermissionCheck("Companies", "delete", loginUserInfo) && (
          <TableCell padding="checkbox">
            <Checkbox
              disableRipple
              checked={selected}
              onChange={handleClick}
            />
          </TableCell>
        )}
        <TableCell sx={{fontWeight:"700"}}>
          {name}
        </TableCell>

        <TableCell>{email}</TableCell>
        <TableCell>{industry}</TableCell>
        <TableCell>{phonenumber}</TableCell>

        <TableCell>
          <FormControlLabel
            control={
              <Android12Switch
                color="success"
                checked={Boolean(checked)}
                onChange={handleChange}
              />
            }
            label={checked ? "Active" : "In Active"}
          />
        </TableCell>
        <TableCell>
        <div style={{whiteSpace:"nowrap"}}>
          {PermissionCheck("Companies", "update", loginUserInfo) && (
            <BootstrapTooltipUi title="Edit" placement="top">
             <IconButton className="outerborder"
               aria-label="Edit"
               onClick={() => editCompany(companyid)}
             >
               <DriveFileRenameOutlineIcon />
             </IconButton>
           </BootstrapTooltipUi>
          )}

          {PermissionCheck("Companies", "delete", loginUserInfo) && (
            <BootstrapTooltipUi title="Delete" placement="top">
              <IconButton  className="outerborder"
                aria-label="Delete"
                onClick={() => {
                  setDeleteId(companyid);
                  setIsModalOpen(true);
                }}
                sx={{ color: "error.main" }}
              >
                <DeleteIcon/>
              </IconButton>
          </BootstrapTooltipUi>
          )}
        </div>
        </TableCell>
      </TableRow>
      <ConfirmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        deleteData={deleteData}
        setOpen={setOpen}
      />
    </>
  );
}

UserTableRow.propTypes = {
  avatarUrl: PropTypes.any,
  phonenumber: PropTypes.any,
  handleClick: PropTypes.func,
  companyid: PropTypes.any,
  name: PropTypes.any,
  email: PropTypes.any,
  selected: PropTypes.any,
  status: PropTypes.any,
};
