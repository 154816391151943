import { _handleChangePage, _handleChangeRowsPerPage } from "src/utils/common";
import { TablePagination } from "@mui/material";
import React from "react";

export default function TablePaginationCompo({
  paginationObject,
  setPaginationObject,
  className = "",
  labelRowsPerPage = "Rows per page:",
  setSelected,
}) {
  return (
    <TablePagination
      className={className}
      labelRowsPerPage={labelRowsPerPage}
      variant="outlined"
      rowsPerPageOptions={[10, 20, 50, 100]}
      component="div"
      count={Number(paginationObject?.totalItems)}
      rowsPerPage={paginationObject?.rowsPerPage}
      page={paginationObject?.page}
      onPageChange={(event, newPage) => {
        _handleChangePage(event, newPage, setPaginationObject, setSelected);
      }}
      onRowsPerPageChange={(event) => {
        _handleChangeRowsPerPage(event, setPaginationObject,setSelected);
      }}
    />
  );
}
