import {
  Card,
  Grid,
  Table,
  TableBody,
  TableContainer,
  Chip,
} from "@mui/material";
import { BootstrapDialog } from "src/components-form/Modals/ui/BootstrapDialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import { useState } from "react";
import * as _ from "lodash";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { TrainingStatusTable } from "./trainingTable";
import { apiGetMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import { useParams } from "react-router-dom";
import TableHeadRow, {
  StyledTableCell,
  StyledTableNoData,
  StyledTableRow,
} from "src/components/mui-common/table-ui/TableHeadRow";

const AssignTrainingForm = ({
  setAssignTraining,
  crewsFiltered,
  formData,
  userId,
  trainingData,
  setFormData,
  apiTrainingCall,
}) => {
  const { trainingStatus, handleAssignTraining, trainingLoader } =
    TrainingStatusTable();
  const [dataFiltered, setDataFiltered] = useState([]);
  const { id } = useParams();
  const closeModal = () => {
    setAssignTraining(false);
  };
  let crewMemberDetails = {};
  try {
    crewMemberDetails = _.filter(
      crewsFiltered,
      (v) => v.crew_member_user_id === userId
    )[0];
  } catch (e) {}
  React.useEffect(() => {
    trainingStatus(trainingData, userId);
    apiCall();
  }, [userId, trainingData]);
  const handleFormChange = (event) => {
    setFormData(event);
  };
  React.useEffect(() => {
    if (formData) apiTrainingCall();
  }, [formData]);
  function apiCall() {
    const url = `${allApiUrl?.CREW_MEMBER_TRAINING_ASSIGNED}/${id}/${userId}`;
    apiGetMethod(`${allApiUrl?.CREW_MEMBER_TRAINING_ASSIGNED}/${id}/${userId}`)
      .then((data) => {
        setDataFiltered(data.data);
      })
      .catch((err) => {
        setDataFiltered([...[]]);
      });
  }
  const headCells = [
    {
      label: "Training Name",
      id: "training_name",
    },
    {
      label: "Status",
      id: "status",
    },
    {
      label: "Action",
      id: "action",
    },
  ];
  return (
    <BootstrapDialog
      className="saveFormwidth assignTraining"
      onClose={closeModal}
      width={`auto`}
      open={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Assign Training | {crewMemberDetails?.user_name}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid
          container
          sx={{ marginTop: "10px" }}
          columnSpacing={2}
          rowSpacing={3}
        >
          <Grid item md={12}>
            <Card>
              <TableContainer>
                <Table sx={{ minWidth: 200 }}>
                  <TableHeadRow headCells={headCells} />

                  <TableBody>
                    {dataFiltered?.length > 0 ? (
                      dataFiltered?.map((item, idx) => {
                        return (
                          <StyledTableRow
                            role="checkbox"
                            tabIndex={-1}
                            key={`styledTableRowTraining_${idx}`}
                          >
                            <StyledTableCell>{item?.name}</StyledTableCell>
                            <StyledTableCell>
                              {item?.allocatedtrainingdata[0]
                                ?.training_status === "PENDING" ? (
                                <Chip label="PENDING" color="error" />
                              ) : item?.allocatedtrainingdata[0]
                                  ?.training_status === "COMPLETED" ? (
                                <Chip label="COMPLETED" color="success" />
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              {!item?.allocatedtrainingdata?.length > 0 && (
                                <LoadingButton
                                  loading={trainingLoader}
                                  loadingPosition="end"
                                  className="blueButton"
                                  endIcon={<SaveIcon />}
                                  variant="contained"
                                  onClick={() => {
                                    handleAssignTraining(userId, item);
                                    setAssignTraining(false);
                                  }}
                                  sx={{
                                    p: "8px 25px",
                                    borderRadius: "4px",
                                    fontSize: "15px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <span>Assign Training</span>
                                </LoadingButton>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    ) : (
                      <StyledTableNoData colSpan={headCells.length} />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </BootstrapDialog>
  );
};
export default AssignTrainingForm;
