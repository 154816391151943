import { uniqueID } from "../functions";
const _getWidgets = () => {
  return {
    Paragraph: {
      width: 6,
      minRequiredWidth: 2,
      name: "Paragraph",
      value: null,
      label: "",
      hideLabel: false,
      labelActualValue: "",
      element: "Paragraph",
      userContentEditable: false,
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
    },
    TextInput: {
      width: 4,
      minRequiredWidth: 2,
      name: "Text Box",
      value: "",
      label: "",
      hideLabel: false,
      labelActualValue: "",
      setInputValueRange: false,
      element: "TextInput",
      showDescription: false,
      required: true,
      placeholder: "",
      type: "text",
      min_value: "",
      max_value: "",
      input_types: [
        {
          id: "Radio_Option_" + uniqueID(6),
          text: "Text Single-Line",
          value: "text",
          placeholder: "TextBox (Single Line)"
        },
        {
          id: "Radio_Option_" + uniqueID(6),
          text: "Text Multi-Line",
          value: "textarea",
          placeholder: "TextBox (Multiple Lines)"
        },
        {
          id: "Radio_Option_" + uniqueID(6),
          text: "Password",
          value: "password",
          placeholder: "Password",
        },
        { id: "Radio_Option_" + uniqueID(6), text: "Number", value: "number", placeholder: "Number" },
        { id: "Radio_Option_" + uniqueID(6), text: "Email", value: "email", placeholder: "Email" },
        { id: "Radio_Option_" + uniqueID(6), text: "Phone", value: "tel", placeholder: "Phone" },
      ],
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      valueEditorType: "text",
      inputType: "text",
    },
    CheckBoxes: {
      width: 4,
      minRequiredWidth: 2,
      name: "Choice",
      value: [],
      label: "",
      hideLabel: false,
      labelActualValue: "",
      element: "CheckBoxes",
      showDescription: false,
      required: true,
      hasValues: false,
      layout: "one_column", // one_column, two_column, side_by_side
      layoutOptions: ["one_column", "side_by_side"],
      options: [
        {
          id: "CheckBoxes_Option_" + uniqueID(4),
          label: "First",
          value: 0,
          checked: false,
        },
        {
          id: "CheckBoxes_Option_" + uniqueID(4),
          label: "Second",
          value: 0,
          checked: false,
        },
        {
          id: "CheckBoxes_Option_" + uniqueID(4),
          label: "Third",
          value: 0,
          checked: false,
        },
      ],
      typeRadio: false,
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      valueEditorType: "radio",
      inputType: "radio",
    },
    DropDown: {
      width: 4,
      minRequiredWidth: 2,
      name: "Select Drop Down",
      value: "",
      label: "",
      hideLabel: false,
      labelActualValue: "",
      element: "DropDown",
      showDescription: false,
      required: true,
      hasValues: false,
      allowFillIn: false,
      optionType: "static",
      options: [
        { id: "DropDown_Option_" + uniqueID(4), label: "First", value: "0", checked: false, disabled: false },
        { id: "DropDown_Option_" + uniqueID(4), label: "Second", value: "0", checked: false, disabled: false },
        { id: "DropDown_Option_" + uniqueID(4), label: "Third", value: "0", checked: false, disabled: false },
      ],
      defaultValue: "",
      typeRadio: true,
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      valueEditorType: "select",
      inputType: "select",
    },
    SelectDatePicker: {
      width: 4,
      minRequiredWidth: 2,
      name: "Date Picker",
      value: "",
      label: "",
      hideLabel: false,
      labelActualValue: "",
      element: "SelectDatePicker",
      showDescription: false,
      required: true,
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      inputType: "date",
    },
    FileUpload: {
      width: 6,
      minRequiredWidth: 4,
      name: "Upload File",
      value: "",
      label: "",
      hideLabel: false,
      labelActualValue: "",
      min_value: undefined,
      max_value: 100,
      element: "FileUpload",
      showDescription: false,
      required: true,
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      valueEditorType: "text",
      inputType: "text",
      chooseFile:"single"
    },
    SelectTimePicker: {
      width: 4,
      minRequiredWidth: 2,
      name: "Time Picker",
      value: null,
      label: "",
      hideLabel: false,
      labelActualValue: "",
      element: "SelectTimePicker",
      showDescription: false,
      required: true,
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      inputType: "time",
    },
    RatingScale: {
      width: 6,
      minRequiredWidth: 6,
      name: "Rating Scale",
      value: null,
      label: "",
      hideLabel: false,
      labelActualValue: "",
      element: "RatingScale",
      showDescription: false,
      fields: [
        { id: "RatingScale_" + uniqueID(4), label: "Question 1", value: "" },
        { id: "RatingScale_" + uniqueID(4), label: "Question 2", value: "" },
        { id: "RatingScale_" + uniqueID(4), label: "Question 3", value: "" },
      ],
      typesOfRating: [
        {
          type: "Satisfied/Unsatisfied",
          structure: [
            "Very Unsatisfied",
            "Unsatisfied",
            "Neutral",
            "Satisfied",
            "Very Satisfied",
          ],
        },
        {
          type: "Agree/Disagree",
          structure: [
            "Strongly Disagree",
            "Disagree",
            "Neutral",
            "Agree",
            "Strongly Agree",
          ],
        },
        {
          type: "Will/Won't",
          structure: [
            "Definitely Won`t",
            "Probably Won`t",
            "Not Sure",
            "Probably Will",
            "Definitely Will",
          ],
        },
        {
          type: "Good/Poor",
          structure: ["Very Poor", "Poor", "Average", "Good", "Very Good"],
        },
        { type: "Scale of 1 to 5", structure: ["1", "2", "3", "4", "5"] },
        {
          type: "Custom",
          structure: [
            "Very Unsatisfied",
            "Unsatisfied",
            "Neutral",
            "Satisfied",
            "Very Satisfied",
          ],
        },
      ],
      selectedRatingType: "Satisfied/Unsatisfied",
      required: true,
      defaultRadioValue: 0,
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      valueEditorType: "multiselect",
      inputType: "multiselect",
    },
    Signature: {
      width: 4,
      minRequiredWidth: 4,
      name: "Signature",
      value: null,
      label: "",
      hideLabel: false,
      labelActualValue: "",
      element: "Signature",
      showDescription: false,
      required: true,
      fields: {
        Name: { id: "Signature_Name_" + uniqueID(4), name: "Name", value: "" },
      },
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      // valueEditorType: "text",
      inputType: "text",
    },
    ContactInformation: {
      width: 6,
      minRequiredWidth: 4,
      name: "Contact Information",
      value: null,
      label: "",
      hideLabel: false,
      labelActualValue: "",
      element: "ContactInformation",
      showDescription: false,
      required: true,
      fields: {
        CompanyName: { id: "ContactInfo_CompanyName_" + uniqueID(4), required: true, name: "CompanyName", value: "", showField: true },
        SubcontractorName: { id: "ContactInfo_SubcontractorName_" + uniqueID(4), required: true, name: "SubcontractorName", value: "", showField: true },
        FirstName: { id: "ContactInfo_FirstName_" + uniqueID(4), required: true, name: "FirstName", value: "", showField: true },
        MiddleName: { id: "ContactInfo_MiddleName_" + uniqueID(4), required: false, name: "MiddleName", value: "", showField: true },
        LastName: { id: "ContactInfo_LastName_" + uniqueID(4), required: true, name: "LastName", value: "", showField: true },
        ContactPhone: { id: "ContactInfo_ContactPhone_" + uniqueID(4), required: true, name: "ContactPhone", value: "", showField: true },
        ContactEmail: { id: "ContactInfo_Email_" + uniqueID(4), required: true, name: "ContactEmail", value: "", showField: true },
        Address1: { id: "ContactInfo_Address1_" + uniqueID(4), required: true, name: "Address1", value: "", showField: true },
        Address2: { id: "ContactInfo_Address2_" + uniqueID(4), required: true, name: "Address2", value: "", showField: true },
        City: { id: "ContactInfo_City_" + uniqueID(4), required: true, name: "City", value: "", showField: true },
        State: { id: "ContactInfo_State_" + uniqueID(4), required: true, name: "State", value: "", showField: true },
        Zip: { id: "ContactInfo_Zip_" + uniqueID(4), required: true, name: "Zip", value: "", showField: true },
        Country: { id: "ContactInfo_Country_" + uniqueID(4), required: true, name: "Country", value: "", showField: true },
        PositionTitle: { id: "ContactInfo_PositionTitle_" + uniqueID(4), required: true, name: "PositionTitle", value: "", showField: true },
        EmployeeNo: { id: "ContactInfo_EmployeeNo_" + uniqueID(4), required: true, name: "EmployeeNo", value: "", showField: true },
        StartDate: { id: "ContactInfo_StartDate_" + uniqueID(4), required: true, name: "StartDate", value: "", showField: true },
        PayRate: { id: "ContactInfo_PayRate_" + uniqueID(4), required: true, name: "PayRate", value: "", showField: true },
        PayFrequency: { id: "ContactInfo_PayFrequency_" + uniqueID(4), required: true, name: "PayFrequency", value: "", showField: true },
        EmergencyContactName: { id: "ContactInfo_EmergencyContactName_" + uniqueID(4), required: true, name: "EmergencyContactName", value: "", showField: true },
        EmergencyPhone: { id: "ContactInfo_EmergencyPhone_" + uniqueID(4), required: true, name: "EmergencyPhone", value: "", showField: true },
        EmergencyRelationship: { id: "ContactInfo_EmergencyRelationship_" + uniqueID(4), required: true, name: "EmergencyRelationship", value: "", showField: true },
      },
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      // valueEditorType: "text",
      inputType: "text",
    },
    AddressInformation: {
      width: 6,
      minRequiredWidth: 4,
      name: "Address",
      value: null,
      label: "",
      hideLabel: false,
      labelActualValue: "",
      element: "AddressInformation",
      showDescription: false,
      required: true,
      fields: {
        ProjectName: { id: "AddressInfo_ProjectName_" + uniqueID(4), required: true, name: "ProjectName", value: "", showField: true },
        PlantName: { id: "AddressInfo_PlantName_" + uniqueID(4), required: true, name: "PlantName", value: "", showField: true },
        Address1: { id: "AddressInfo_Address1_" + uniqueID(4), required: true, name: "AddressLine1", value: "", showField: true },
        Address2: { id: "AddressInfo_Address2_" + uniqueID(4), required: false, name: "AddressLine2", value: "", showField: true },
        City: { id: "AddressInfo_City_" + uniqueID(4), required: true, name: "City", value: "", showField: true },
        State: { id: "AddressInfo_State_" + uniqueID(4), required: true, name: "State", value: "", showField: true },
        Zip: { id: "AddressInfo_Zip_" + uniqueID(4), required: true, name: "Zip", value: "", showField: true },
        Country: { id: "AddressInfo_Country_" + uniqueID(4), required: true, name: "Country", value: "", showField: true },
        ProjectNo: { id: "AddressInfo_ProjectNo_" + uniqueID(4), required: true, name: "ProjectNo", value: "", showField: true },
        PlantNo: { id: "AddressInfo_PlantNo_" + uniqueID(4), required: true, name: "PlantNo", value: "", showField: true }
      },
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      inputType: "text",
    },
    Content: {
      width: 6,
      minRequiredWidth: 2,
      name: "Content",
      value: "",
      element: "Content",
      showDescription: false,
      required: true,
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
    },

    Calculation: {
      width: 4,
      minRequiredWidth: 2,
      name: "Calculation",
      value: "",
      label: "",
      hideLabel: false,
      labelActualValue: "",
      element: "Calculation",
      showDescription: false,
      required: true,
      type: "number",
      input_types: [
        {
          id: "Radio_Option_" + uniqueID(6),
          text: "Number",
          value: "number",
        },
        { id: "Radio_Option_" + uniqueID(6), text: "Percent", value: "percent" },
        {
          id: "Radio_Option_" + uniqueID(6),
          text: "Currency",
          value: "currency",
        },
      ],
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      limitQuantity: {
        type: "never",
        rules: { combinator: "and", rules: [] },
      },
      customError: {
        type: "never",
        rules: { combinator: "and", rules: [] },
      },
      calculation: "",
      helptext: "",
      decimal: 2,
      valueEditorType: "text",
      inputType: "text",
    },
    LookupDropDown: {
      width: 4,
      minRequiredWidth: 2,
      name: "Lookup DropDown",
      value: "",
      label: "",
      hideLabel: false,
      labelActualValue: "",
      element: "LookupDropDown",
      showDescription: false,
      required: true,
      hasValues: false,
      allowFillIn: false,
      optionType: "user",
      options: [],
      defaultValue: "",
      typeRadio: true,
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      valueEditorType: "select",
      inputType: "select",
    },
  }
}
const Layout = {
  PageBreak: {
    name: "Page Break",
    pageNumber: 0,
    buttons: [
      {
        label: "Next",
        hidden: false,
        type: "Next",
        conditionalOperation: {
          type: "always",
          rules: { combinator: "and", rules: [] },
        },
      },
      {
        label: "Back",
        hidden: true,
        type: "Previous",
        conditionalOperation: {
          type: "always",
          rules: { combinator: "and", rules: [] },
        },
      }
    ],
    element: "PageBreak",
    width: 12,
    minRequiredWidth: 12,
    widthEditable: false,
    showHidePageCount: false,
  },
  Section: {
    name: "Section",
    element: "Section",
    label: "",
    hideLabel: false,
    width: 12,
    minRequiredWidth: 6,
    childComponent: [],
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
  },
  RepeatingSection: {
    name: "Repeating",
    element: "RepeatingSection",
    label: "",
    width: 12,
    minRequiredWidth: 6,
    childComponent: [],
    itemLabel: "",
    addButtonText: "Add Item",
    numberofItems: { min: 5, max: 10 },
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
  },
};
const Media = {
  Image: {
    name: "Image",
    element: "Image",
    label: "",
    required: true,
    width: 6,
    minRequiredWidth: 2,
    src: "",
    accept: "image/png, image/gif, image/jpeg",
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
    properties: {
      width: "200px",
      height: "200px",
      center: "off"
    }
  },
  Video: {
    name: "Video",
    element: "Video",
    width: 6,
    minRequiredWidth: 4,
    label: "",
    required: true,
    src: "",
    image_width: "560",
    image_height: "315",
    accept: "video/mp4, video/x-m4v, video/*",
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
    properties: {
      width: "200px",
      height: "200px",
      center: "off"
    }
  },
  ImageSlider: {
    name: "Image Slider",
    element: "ImageSlider",
    label: "",
    required: true,
    width: 6,
    minRequiredWidth: 6,
    images: [
      {
        id: "ImageSlider_" + uniqueID(4),
        src: "",
        value: "",
        altName: "Legend",
      },
    ],
    accept: "image/png, image/gif, image/jpeg",
    conditionalOperation: {
      type: "always",
      rules: { combinator: "and", rules: [] },
    },
    properties: {
      width: "200px",
      height: "200px",
      center: "off"
    }
  },
};
const FormSettings = {
  id: `FormSettings_${uniqueID(4)}`,
  title: {
    id: `Title_${uniqueID(4)}`,
    label: "",
    pages: [],
  },
  isTrainingForm: false,
  description: "",
  formActionSettings: [
    {
      type: "submit",
      entry_endpoint: "",
    },
    {
      type: "update",
      entry_endpoint: "",
    },
    {
      type: "delete",
      entry_endpoint: "",
    },
  ],
  extra_setting: {},
};

const Submit = {
  id: `SubmitSettings_${uniqueID(4)}`,
  name: "Submit",
  element: "Submit",
  showDescription: false,
  buttons: [
    {
      buttonText: "Draft Save",
      label: "Draft Save",
      eventType: "Draft",
      actionName: "button",
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      confirmationType: "message",
      confirmationRedirection: "",
      confirmationMessage:
        "Form saved as draft.",
      changeStatusTo: null,
    }, {
      buttonText: "Submit",
      label: "submit",
      eventType: "Save",
      actionName: "submit",
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      confirmationType: "message",
      confirmationRedirection: "",
      confirmationMessage:
        "Thank you for filling out the form. Your response has been recorded.",
      changeStatusTo: null,
    }
  ],
  statusTypes: [
    {
      label: "Incompleted",
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      color: "#000000",
    },
    {
      label: "Submitted",
      conditionalOperation: {
        type: "always",
        rules: { combinator: "and", rules: [] },
      },
      color: "#FFFFFF",
    },
  ],
  width: 12,
  fixed: true,
  listingHidden: true,
  conditionalOperation: {
    type: "always",
    rules: { combinator: "and", rules: [] },
  },
  extra_setting: {},
};

const AddButtonJson = {
  buttonText: "Action",
  label: "Action",
  eventType: "action",
  actionName: "Action",
  conditionalOperation: {
    type: "always",
    rules: { combinator: "and", rules: [] },
  },
  confirmationType: "message",
  confirmationRedirection: "",
  confirmationMessage:
    "Thank you for filling out the form. Your response has been recorded.",
  changeStatusTo: null,
};
const addStatusJson = {
  label: "Status",
  conditionalOperation: {
    type: "always",
    rules: { combinator: "and", rules: [] },
  },
  color: "#FFFFFF",
};

export {
  _getWidgets,
  Layout,
  Media,
  Submit,
  AddButtonJson,
  addStatusJson,
  FormSettings,
};
