import PreviewFormCompo from "../../PreviewFormCompo";

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {BootstrapDialog} from "../ui/BootstrapDialog";

function PreviewModal({
  openPreviewModal,
  setOpenPreviewModal,
  SubmitForm,
  selectedWidgets,
  setSelectedWidget,
  setFormSettings,
  form_settings,
  setIsOpen,
}) {
  const closeModal = () => {
    setOpenPreviewModal(false);
    localStorage.removeItem("previewWidgetsData");
    localStorage.removeItem("form_settings");
    localStorage.removeItem("formSubmitSettings");
  }
  return (
    <BootstrapDialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={openPreviewModal}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Preview
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <PreviewFormCompo
          SubmitForm={SubmitForm}
          selectedWidgets={selectedWidgets}
          setSelectedWidget={setSelectedWidget}
          formSettingsProp={form_settings}
          setFormSettingsProp={setFormSettings}
          setIsOpen={setIsOpen}
          showSubmitButton={false}
        />
      </DialogContent>
    </BootstrapDialog>
  );
}
export default PreviewModal;
