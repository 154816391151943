import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { allApiUrl } from "src/api/apiRoute";
import { apiPostMethod } from "src/api/rest";
import { isEmpty } from "src/shared/functions/isEmpty";

export const TrainingStatusTable = () => {
  const [selectedTrainings, setSelectedTrainings] = useState([]);
  const [trainingreqData, setTrainingReqData] = useState([]);
  const [assignLoader, setAssignLoader] = useState(false);
  const [disableTraining, setDisableTraining] = useState(true);
  const [trainingLoader, setTrainingLoader] = useState(false);
  const [checkTrainingstatus, setCheckTrainingStatus] = useState(false);
  const trainingStatus = (trainingData, userId) => {
    let result =
      !isEmpty(trainingData) &&
      JSON.parse(JSON.stringify(trainingData?.trainingsData))?.map((td) => {
        let usersResult = trainingData?.userTrainings?.find(
          (ut) => ut?.user_id == userId && td.id == ut.trainings_id
        );
        if (usersResult) {
          td.isExisting = true;
        } else {
          td.isExisting = false;
        }
        trainingData.projectUsers.map((pu) => {
          let doneTraining = pu.userDoneTrainings.find(
            (udt) => udt?.user_id == userId && udt.trainings_id == td.id
          );
          if (doneTraining) {
            td.disabled = true;
            td.userDone = true;
          }
          let doneAssignedTraining = pu.userAssignedTrainings.find(
            (uat) => uat?.assigned_to == userId && uat.training_id == td.id
          );
          if (doneAssignedTraining) {
            td.disabled = true;
          }
        });
        if (td.isExisting || td.disabled || td.userDone) {
          td.showCheckbox = false;
        } else {
          td.showCheckbox = true;
        }
        return td;
      });
    setTrainingReqData(JSON.parse(JSON.stringify(result)));
  };
  const headLabeleRequired = [
    { id: "", label: "" },
    { id: "required", label: "Training Status" },
  ];
  const handleSelectAllClickTraining = (event) => {
    setDisableTraining(false);
    if (event.target.checked) {
      const newSelected = trainingreqData?.map((n, index) => {
        return { index, trainingId: n.id };
      });
      setSelectedTrainings(newSelected);
      return;
    }
    setSelectedTrainings([]);
  };
  const handleChangeTrainingRequired = (index, trainingId) => () => {
    setDisableTraining(false);
    const isSelected = selectedTrainings.some(
      (sel) => sel.trainingId === trainingId
    );

    if (!isSelected) {
      setSelectedTrainings([...selectedTrainings, { index, trainingId }]);
    } else {
      setSelectedTrainings(
        selectedTrainings.filter((sel) => sel.trainingId !== trainingId)
      );
    }
  };
  useEffect(() => {
    if (selectedTrainings?.length == 0) {
      setDisableTraining(true);
    }
  }, [selectedTrainings]);
  const handleAssignTraining = (userId, trainingid) => {
    console.log(userId, trainingid, "=====userId====");
    setTrainingLoader(true);
    let array = [];
    // if (!selectedTrainings?.length) {
    //   toast.error("Please Select any Training");
    //   setTrainingLoader(false);
    // } else {
    // selectedTrainings?.length > 0 &&
    //   selectedTrainings?.map((item) => {
    //     array.push(item?.trainingId);
    //   });
    array.push(trainingid?.id);
    let obj = {
      training_ids: array,
      assigned_to: userId,
    };
    apiPostMethod(allApiUrl?.MULTIPLE_TRAINING_ASSIGNED, obj)
      .then((data) => {
        toast.success(data.message);
        setTrainingLoader(false);
      })
      .catch((error) => {
        setTrainingLoader(false);
        toast.error(error?.data?.message);
      });
    // }
  };
  return {
    trainingStatus,
    trainingreqData,
    headLabeleRequired,
    selectedTrainings,
    handleChangeTrainingRequired,
    handleAssignTraining,
    assignLoader,
    disableTraining,
    trainingLoader,
    handleSelectAllClickTraining,
    checkTrainingstatus,
  };
};
