import * as React from "react";
import JsaTable, { JSA_TABLE_TYPE } from "src/components-form/Tables/JsaTable";
import HeaderCompo from "src/components-form/HeaderCompo";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PageHeader from "src/components-form/Common/PageHeader";
import { Helmet } from "react-helmet-async";
import AddIcon from '@mui/icons-material/Add';
import { PermissionCheck } from "src/utils/common";

export default function LandingPage() {
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const navigate = useNavigate();
  const [isTraining, setIsTraining] = React.useState(undefined);

  return (
    <>
      <Helmet>
        <title>{`Forms Catalogue | ${process.env.REACT_APP_APPLICATION_NAME}`}</title>
      </Helmet>
      <Box>
        <div className="flexTop">
          <PageHeader
            header={"Forms Catalogue"}
            links={[
              {
                name: "Dashboard",
                path: "/",
              },
            ]}
          />
          <div>
            {PermissionCheck("Forms","create",loginUserInfo) &&
            <HeaderCompo
              navItems={[
                {
                  label: "Create New Form",
                  icon: <AddIcon />,
                  onClick: (e) => {
                    navigate("/forms/form-builder");
                  },
                  href: "",
                },
              ]}
            />}
          </div>
        </div>
        <JsaTable
          type={JSA_TABLE_TYPE.JSA_FORM}
          industryid={loginUserInfo?.industryId}
          isTraining={isTraining}
          setIsTraining={setIsTraining}
        />
      </Box>
    </>
  );
}
