import {useEffect, useState} from "react";
import * as React from "react";
import {fetchImage} from "../../shared/functions";
import LabelCompo from "./LabelCompo";
import {replaceLabelStrings} from "../../shared/functions/string-conversion";

export const VideoCompo = ({ isReadOnly, data, form, isSubmitClicked = false }) => {
  const labelConv = isReadOnly ? data.label : replaceLabelStrings(data?.label, form);
  let [Src, setSrc] = useState("");
  useEffect(() => {
    if (data.src) {
      fetchImage(data.src).then((res) => {
        setSrc(res);
      });
    }
  }, [data.src]);
  return (
    <div key={data.id} id={data.id}>
      <LabelCompo data={data} labelConv={labelConv}/>
      {data.src && Src !== "" ? (
        <video width="100%" height={(data.width * 315) / 6} controls autoPlay>
          <source src={Src} type="video/mp4"/>
          <source src={Src} type="video/ogg"/>
          Your browser does not support the video tag.
        </video>
      ) : (
        <span className="defaultIcon">
          <img src={`/assets/img/videoIcon.svg`} alt={`Video`}/>
        </span>
      )}
    </div>
  );
};