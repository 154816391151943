import React, { useEffect, useRef } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  apiTermination,
  cancelToken,
} from "src/shared/functions/apiTermination";
import { allApiUrl } from "src/api/apiRoute";
import { apiGetMethod } from "src/api/rest";
import { isEmpty } from "../../shared/functions/isEmpty";
import { ROLES } from "src/core/constants";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { components } from "react-select";
import * as _ from "lodash";
import toInt from "validator/es/lib/toInt";

const SelectAsync = ({
  searchType,
  selectedValue = null,
  onChange,
  placeholder,
  isMulti = false,
  dataParam,
  isClearable = false,
  isDisabled,
  className = "userSelect",
  name = "",
  required = false,
  error,
  formData,
  projectId,
  selectBoxKey = null,
}) => {
  const source = cancelToken.source();
  const cancelTokensRef = useRef([]);
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shareable_auth_token = queryParams.get("shareable_auth_token") || "";

  const urls = {
    industry: allApiUrl.INDUSTRY_LIST,
    companyid: allApiUrl?.COMPANY_LISTING,
    categoryList: allApiUrl?.FORM_CATEGORIES,
    user_id: allApiUrl?.USERS_LIST,
    trainings_id: allApiUrl?.TRAINING_LIST,
    trainings_list_acc_company: `${allApiUrl?.TRAINING_LIST}?companyId=${formData}`,
    form_id: allApiUrl?.GET_USER_FORM,
    filtered_form: allApiUrl?.FILTERED_FORM,
    filtered_company_form: allApiUrl?.FILTERED_COMPANY_FORM,
    signupcompanyid: allApiUrl?.PUBLIC_COMPANY_SIGNUP,
    selectCompanyUser: `${allApiUrl?.COMPANY_USER_LIST}?companyId=${loginUserInfo?.companyid}`,
    selectCompanyid: `${allApiUrl?.COMPANY_USER_LIST}?companyId=${loginUserInfo?.companyid}&roleId=${ROLES.FORM_USER}`,
    selectSupervisor: `${allApiUrl?.COMPANY_USER_LIST}?companyId=${loginUserInfo?.companyid}&roleId=${ROLES.COMPANY}`,
    admin_trainings_id: `${allApiUrl?.SUPER_ADMIN_TRAINING_LIST}?companyId=${formData}`,
    company_training_id: allApiUrl?.COMPANY_TRAINING_LIST,
    company_filtered_training_form: `${allApiUrl.FILTERED_FORMLISTING}?${
      dataParam?.filtered_data && dataParam?.filtered_data === "project"
        ? "form_type=JSA_FORM"
        : "form_type=TRAINING_FORM"
    }`,
    ADMIN_Filtered_training_form:
      dataParam?.categoryId && !isEmpty(dataParam?.categoryId)
        ? `${allApiUrl.JSA_FORMS}?form_type=TRAINING_FORM&categoryId=${dataParam?.categoryId}`
        : `${allApiUrl.JSA_FORMS}?form_type=TRAINING_FORM`,
    COMPANY_JSA_CODE: allApiUrl?.COMPANY_JSA_CODE,
    project_JSA_FORM: `${allApiUrl?.ASSIGN_PROJECT_JSA_FORM}/${projectId}`,
    userListPublic: loginUserInfo?.token
      ? `${allApiUrl?.PRIVATE_TRAININGS_USER_LIST}`
      : `${allApiUrl?.PUBLIC_TRAININGS_USER_LIST}?shareable_auth_token=${shareable_auth_token}`,
    projectListPublic: loginUserInfo?.token
      ? `${allApiUrl?.PRIVATE_PROJECT_PUBLIC_PROJECT_LIST}`
      : `${allApiUrl?.PUBLIC_PROJECT_PUBLIC_PROJECT_LIST}?shareable_auth_token=${shareable_auth_token}`,
    ProjectUserList: allApiUrl?.PROJECT_USER_LISTING,
    project_submited_form: `${allApiUrl?.PROJECT_FORM}/${projectId}?project_form_status=PENDING`,
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: "46px",
      borderRadius: "8px",
      borderColor: error && error ? "#ee7000" : provided.borderColor,
      color: error && error ? "#ee7000" : provided.color,
    }),
    menu: (provided, state) => ({
      ...provided,
      position: "absolute",
      backgroundColor: "#fff",
      zIndex: "99999",
      marginTop: "0px",
      boxShadow:
        "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
    }),
    listbox: (provided, state) => ({
      ...provided,
      maxHeight: "150px",
      overflowY: "auto",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: error && error ? "#e74c3c" : provided.color,
    }),
  };

  useEffect(() => {
    return () => {
      apiTermination(cancelTokensRef);
    };
  }, []);

  const getListOptions = (resData) => {
    let options = [];
    let hasMore = resData.meta.hasMore;
    switch (searchType) {
      case "industry": {
        if (dataParam?.isFilter && toInt(resData.meta.pageNumber) === 0) {
          resData.data = [
            { is_active: true, id: "UNASSIGNED", name: "Unassigned" },
            ...resData.data,
          ];
        }
        options = [...resData.data].map((o, idx) => {
          if (o?.is_active === true) {
            return {
              value: o?.id,
              label: o?.name,
            };
          }
        });
        options = options.filter((option) => option !== undefined);
        break;
      }
      case "categoryList": {
        if (dataParam?.isFilter && toInt(resData.meta.pageNumber) === 0) {
          resData.data = [
            { is_active: true, id: "UNASSIGNED", name: "Unassigned" },
            ...resData.data,
          ];
        }
        options = [...resData.data].map((o, idx) => {
          if (o?.is_active === true) {
            return {
              value: o?.id,
              label: o?.name,
            };
          }
        });
        options = options.filter((option) => option !== undefined);
        break;
      }
      case "ProjectUserList": {
        if (dataParam?.isFilter && toInt(resData.meta.pageNumber) === 0) {
          resData.data = [
            { is_active: true, id: "UNASSIGNED", project_name: "Unassigned" },
            ...resData.data,
          ];
        }
        options = [...resData.data].map((o, idx) => {
          if (o?.is_active === true) {
            return {
              value: o?.id,
              label: o?.project_name,
            };
          }
        });
        options = options.filter((option) => option !== undefined);
        break;
      }
      case "companyid": {
        options = [...resData.data].map((o, idx) => {
          if (o?.status === "Active") {
            return {
              value: o?.id,
              label: o?.name,
            };
          }
        });
        options = options.filter((option) => option !== undefined);
        break;
      }
      case "signupcompanyid": {
        options = [...resData.data].map((o, idx) => {
          if (o?.is_verified === true) {
            return {
              value: o?.id,
              label: o?.name,
            };
          }
        });

        options = options.filter((option) => option !== undefined);
        break;
      }
      case "selectCompanyid": {
        options = [...resData.data].map((o, idx) => {
          return {
            value: o?.id,
            label: `${o?.user_name} (${o?.company_name})`,
            company_id: o?.companyid,
            phone_number: o?.phone_number,
            email: o?.email,
            crew_member_user_id: o?.crew_member_user_id,
          };
        });

        options = options.filter((option) => option !== undefined);
        break;
      }
      case "selectCompanyUser": {
        options = [...resData.data].map((o, idx) => {
          return {
            value: o?.id,
            label: `${o?.user_name} (${o?.company_name})`,
            company_id: o?.companyid,
          };
        });

        options = options.filter((option) => option !== undefined);
        break;
      }
      case "selectSupervisor": {
        options = [...resData.data].map((o, idx) => {
          return {
            value: o?.id,
            label: `${o?.user_name} (${o?.company_name})`,
            company_id: o?.companyid,
          };
        });

        options = options.filter((option) => option !== undefined);
        break;
      }
      case "user_id": {
        options = [...resData.data].map((o, idx) => {
          if (o?.is_active === true && o?.roleid === ROLES.FORM_USER) {
            return {
              value: o?.id,
              label: `${o?.user_name} (${o?.company_name})`,
              company_id: o?.companyid,
            };
          }
        });
        options = options.filter((option) => option !== undefined);
        break;
      }
      case "trainings_id": {
        options = [...resData.data].map((o, idx) => {
          if (o?.is_active === true) {
            return {
              value: o?.id,
              label: o?.name,
              date: o?.validityperiod,
            };
          }
        });
        options = options.filter((option) => option !== undefined);
        break;
      }
      case "admin_trainings_id": {
        options = [...resData.data].map((o, idx) => {
          if (o?.is_active === true) {
            return {
              value: o?.id,
              label: o?.name,
              date: o?.validityperiod,
              form_name: o?.form_name,
              certificate_name: o?.certificatename,
            };
          }
        });
        options = options.filter((option) => option !== undefined);
        break;
      }
      case "company_training_id": {
        options = [...resData.data].map((o, idx) => {
          if (o?.is_active === true && o?.form_id !== null) {
            return {
              value: o?.id,
              label: o?.name,
              date: o?.validityperiod,
              certificate_name: o?.certificatename,
            };
          }
        });
        options = options.filter((option) => option !== undefined);
        break;
      }
      case "form_id": {
        options = [...resData.data].map((o, idx) => {
          return {
            value: o?.id,
            label: o?.title,
          };
        });
        break;
      }
      case "company_filtered_training_form": {
        options = [...resData.data].map((o, idx) => {
          return {
            value: o?.trainings_id || o?.id,
            label: dataParam?.isSaveModal ? o?.training_name : o?.title,
          };
        });
        break;
      }
      case "ADMIN_Filtered_training_form": {
        options = [...resData.data].map((o, idx) => {
          return {
            value: o?.trainings_id,
            label: dataParam?.isSaveModal ? o?.training_name : o?.title,
          };
        });
        break;
      }
      case "COMPANY_JSA_CODE": {
        options = [...resData.data].map((o, idx) => {
          return {
            value: o?.id,
            label: o?.title,
          };
        });
        break;
      }
      case "project_JSA_FORM": {
        options = [...resData.data].map((o, idx) => {
          return {
            value: o?.id,
            label: o?.title,
          };
        });
        break;
      }
      case "project_submited_form": {
        options = [...resData.data].map((o, idx) => {
          return {
            value: o?.id,
            label: o?.form_name,
            due_date: o?.due_date,
          };
        });
        break;
      }
      case "filtered_form": {
        options = [...resData.data].map((o, idx) => {
          return {
            value: o?.id,
            label: o?.title,
            o,
          };
        });
        options = options.filter((option) => option !== undefined);
        break;
      }
      case "filtered_company_form": {
        options = [...resData.data].map((o, idx) => {
          return {
            value: o?.id,
            label: o?.title,
            o,
          };
        });
        options = options.filter((option) => option !== undefined);
        break;
      }
      case "userListPublic": {
        options = [...resData.data].map((o, idx) => {
          return {
            value: o?.id,
            label: o?.user_name,
          };
        });
        options = options.filter((option) => option !== undefined);
        break;
      }
      case "projectListPublic": {
        options = [...resData.data].map((o, idx) => {
          return {
            value: o?.id,
            label: o?.project_name,
          };
        });
        options = options.filter((option) => option !== undefined);
        break;
      }
      default: {
        //
        options = [...resData.data].map((o, idx) => {
          return {
            value: o?.id,
            label: o?.name,
            ...o,
          };
        });
        break;
      }
    }
    return { options, hasMore };
  };

  const loadOptions = async (searchText, prevOptions, { page }) => {
    const limit = 10;
    let qryStr =
      (urls[searchType].includes("?") ? "&" : "?") + `pageNumber=${page}`;
    qryStr = qryStr + `&limit=${limit}`;

    if (searchText) {
      qryStr = qryStr + `&search=${searchText}`;
    }

    cancelTokensRef.current.push(source);
    try {
      const res = await apiGetMethod(urls[searchType] + qryStr, source.token);
      if (res && !isEmpty(res.data)) {
        return {
          ...getListOptions(res),
          additional: {
            page: page + 1,
          },
        };
      }
    } catch (e) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page + 1,
        },
      };
    }
    return {
      options: [],
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  };

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div
          style={{
            fontStyle: _.includes(["UNASSIGNED"], props.data.value)
              ? "italic"
              : "normal",
          }}
        >
          {props.data.label}
        </div>
      </components.Option>
    );
  };
  return (
    <>
      <AsyncPaginate
        key={selectBoxKey ? selectBoxKey : searchType}
        debounceTimeout={500}
        name={name}
        value={selectedValue}
        loadOptions={loadOptions}
        onChange={onChange}
        isMulti={isMulti}
        placeholder={placeholder}
        isClearable={isClearable}
        isDisabled={isDisabled}
        className={`select-async ${className}`}
        closeMenuOnSelect={!isMulti}
        required={required}
        additional={{
          page: 0,
        }}
        styles={customStyles}
        defaultMenuIsOpen={false}
        components={{ Option: CustomOption }}
        // menuPlacement="top"
      />
    </>
  );
};
export default SelectAsync;
