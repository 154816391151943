import * as React from "react";
import Box from "@mui/material/Box";
import HeaderCompo from "src/components-form/HeaderCompo";
import { useLocation, useParams } from "react-router-dom";
import SavedEntriesTable from "src/components-form/Tables/SavedEntriesTable";
import {Helmet} from "react-helmet-async";

export default function SavedFormPage() {
  const { id } = useParams();
  const { state } = useLocation();
  return (
    <Box sx={{ width: "100%" }}>
      <Helmet>
        <title>{`Saved Entries | ${process.env.REACT_APP_APPLICATION_NAME}`}</title>
      </Helmet>
      <HeaderCompo />
      <SavedEntriesTable formId={id} state={state} />
    </Box>
  );
}
