import React, {useEffect, useState} from "react";

export const SetAllowFillIn = ({editForm, editElementProp}) => {
  const [oldAllowFillIn, setOldAllowFillIn] = useState(editForm.allowFillIn);
  useEffect(() => {
    if (oldAllowFillIn !== editForm.allowFillIn) {
      editElementProp("", ["value"])
      setOldAllowFillIn(editForm.allowFillIn)
    }
  }, [editForm.allowFillIn]);

  return (
    <div className="form-group">
      <input
        id="AllowFillIn"
        className="custom-control-input"
        type="checkbox"
        checked={!!editForm.allowFillIn}
        onChange={(e) => {
          editElementProp(!!e.target.checked, ["allowFillIn"])
        }}
      />
      <label
        className="custom-control-label"
        htmlFor="allowFillIn"
      >
        &nbsp;Allow Fill In
      </label>
    </div>
  )
}