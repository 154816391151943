import React from "react";
import axios from "axios";
import useAuth from "../hooks/useAuth";
import { toast } from "react-toastify";
function Interceptor() {
  const {logout} = useAuth();

  axios.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  const interceptor = axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error && error.response && error.response.status !== 403) {
        return Promise.reject(error);
      }
      axios.interceptors.response.eject(interceptor);

      if (error?.response?.status === 403) {
        try {
          toast.dismiss();
          toast.error(
            error.response.data.message ? error.response.data.message : "Session token expired..! Please log in once more."
          );
        } catch (e) {}
        logout();
      }
    }
  );

  return <React.Fragment />;
}

export default Interceptor;
