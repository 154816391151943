import ReactQuill from "react-quill";
import React from "react";
export const ParagraphEditorCompo = ({
  label,
  valueProp,
  onEditorChange,
  dataId,
}) => {
  return (
    <>
      {label && (
        <label>
          <b>{label}</b>
        </label>
      )}
      <ReactQuill
        id={`field_id_${dataId}`}
        name={`field_name_${dataId}`}
        value={valueProp}
        onChange={onEditorChange}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline"],
            [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" },
            ],
            [
              { list: "ordered" },
              { list: "bullet" },
              { list: "check" },
              { indent: "-1" },
              { indent: "+1" },
            ],
          ],
          clipboard: { matchVisual: false },
        }}
        theme="snow"
      />
    </>
  );
};
