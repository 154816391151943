import React, { memo } from "react";
import Table from "@mui/material/Table";
import Card from "@mui/material/Card";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useEffect, useState } from "react";
import AlertDialog from "../shared/AlertDialog";
import {
  apiDeleteMethod,
  apiGetMethod,
  apiPostMethod,
  apiPutMethod,
} from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { handleCheckboxClick, isSelected } from "./functions";
import { headCellsAllForms } from "src/components/mui-common/table-ui/headCells";
import TableRowsLoader from "src/components/mui-common/table-ui/TableRowsLoader";
import TableHeadRow, {
  StyledTableCell,
  StyledTableNoData,
  StyledTableRow,
} from "src/components/mui-common/table-ui/TableHeadRow";
import { isEmpty } from "src/shared/functions/isEmpty";
import Checkbox from "@mui/material/Checkbox";
import { useRouter } from "src/routes/hooks";
import { _handleChangePage, PermissionCheck } from "src/utils/common";
import { useSelector } from "react-redux";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FILTER_FORM_TYPE,
  FORM_CATALOGUE_TABS,
  PAGINATION_OBJECT,
} from "src/core/constants";
import {
  _errorMsg,
  _isCompanyAdminRole,
  _isSuperAdminRole,
  _notCompanyUser,
  addSpace,
  formatDateFn,
  removeSpace,
} from "src/shared/functions/string-conversion";
import { toast } from "react-toastify";
import { BootstrapTooltipUi } from "src/components/mui-common/BootstrapToolTip";
import { removeItemByKeyValue } from "src/shared/functions/array-conversion";
import SelectAsync from "../shared/SelectAsync";
import { TabsUi } from "src/components/mui-common/tabs-ui";
import Scrollbar from "src/components/scrollbar";
import LoadingSwitch from "src/components/mui-common/switch-ui-new";
import {
  _handleRedirect,
  _setPaginationObjectFn,
  createQueryString,
} from "src/shared/functions";
import { ActionPreview } from "src/components/mui-common/ActionPreview";
import ShareIcon from "@mui/icons-material/Share";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import TableToolbar from "src/components/mui-common/table-ui/TableToolbar";

export const JSA_TABLE_TYPE = {
  JSA_FORM: "JSA_FORM",
  JSA_CODES: "JSA_CODES",
  SAVED_FORM: "SAVED_FORM",
};

function JsaTable({
  type = JSA_TABLE_TYPE.JSA_FORM,
  industryid,
  isTraining,
  setIsTraining,
}) {
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const isNotCompanyUserRole = _notCompanyUser(loginUserInfo);
  const isCompanyRole = _isCompanyAdminRole(loginUserInfo);
  const isSuperAdminRole = _isSuperAdminRole(loginUserInfo);

  const _Tabs = isSuperAdminRole
    ? [
        { tabIndex: 0, label: "Form", tabType: "FORM" },
        { tabIndex: 1, label: "Training Form", tabType: "TRAINING_FORM" },
        { tabIndex: 2, label: "JSA Code", tabType: "JSA_CODE" },
      ]
    : [
        { tabIndex: 0, label: "Form", tabType: "FORM" },
        { tabIndex: 1, label: "Training Form", tabType: "TRAINING_FORM" },
        { tabIndex: 2, label: "JSA Code", tabType: "JSA_CODE" },
        { tabIndex: 3, label: "Template", tabType: "TEMPLATE" },
      ];

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const router = useRouter();

  const [jsaType, setJsaType] = useState(type);
  const [selected, setSelected] = React.useState([]);

  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);

  const [value, setValue] = useState(0);
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState({ id: null, title: "" });
  const [categoryName, setCategoryName] = useState(null);
  const [industryName, setIndustryName] = useState(null);
  const [formTypeFilter, setFormTypeFilter] = useState("JSA_FORM");
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(null);
  const [isLoadingDelete, setIsLoadingDelete] = useState(null);
  const [isLoadingClone, setIsLoadingClone] = useState(null);
  const [isLoadingCopy, setIsLoadingCopy] = useState(null);
  const [status, setStatus] = useState("all");
  const [currentTab, setCurrentTab] = useState("FORM");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (paginationObject.triggerApi) {
      fetchList();
    }
  }, [paginationObject.triggerApi]);
  useEffect(() => {
    try {
      _handleChangePage({}, 0, setPaginationObject,setSelected);
    } catch (e) {}
  }, [
    searchTerm,
    isTraining,
    industryid,
    jsaType,
    industryName,
    formTypeFilter,
    status,
    categoryName,
    currentTab,
  ]);

  const handleClick = (event, id) => {
    setSelected(handleCheckboxClick(event, id, selected));
  };

  function fetchList() {
    setIsLoading(true);
    let queryObject = {
      search: searchTerm || "",
      industryId: industryName?.value || null,
      categoryId: categoryName?.value || null,
      pageNumber: paginationObject.page,
      limit: paginationObject.rowsPerPage,
      form_type:
        currentTab === "FORM"
          ? "JSA_FORM"
          : currentTab === "TRAINING_FORM"
          ? "TRAINING_FORM"
          : currentTab === "JSA_CODE"
          ? "JSA_CODE"
          : null,
      template_type: currentTab === "TEMPLATE" ? formTypeFilter : null,
      is_active: currentTab === "TEMPLATE" || status === "all" ? null : status,
      industryid: industryid ? industryid : null,
    };

    const qryStr = createQueryString(queryObject);
    let url = "";
    if (isCompanyRole && currentTab === "TEMPLATE") {
      url =
        jsaType === JSA_TABLE_TYPE.JSA_FORM &&
        `${allApiUrl.COMPANY_ADMIN_TEMPLATE}${qryStr}`;
    } else if (
      (isNotCompanyUserRole && isTraining !== undefined) ||
      (isCompanyRole && currentTab !== "TEMPLATE")
    ) {
      url =
        jsaType === JSA_TABLE_TYPE.JSA_FORM
          ? `${allApiUrl.FILTERED_FORMLISTING}${qryStr}`
          : jsaType === JSA_TABLE_TYPE.JSA_CODES
          ? `${allApiUrl.COMPANY_JSA_CODE}${qryStr}`
          : ``;
    } else {
      url = `${allApiUrl.JSA_FORMS}${qryStr}`;
    }

    if (url) {
      apiGetMethod(url, null, loginUserInfo.token)
        .then((data) => {
          const tempRows = [];
          data.data.map((v) => {
            const title = v.title || v.form_settings.title.label;
            const updated_at = formatDateFn(v.updated_at);
            const created_at = formatDateFn(v.created_at);
            const entry_count = Number(v.entry_count);
            const category_name = v?.category_name || "";
            const training_status = v?.form_settings?.isTrainingForm;
            const is_active = v?.is_active;
            const trainings_id = v?.trainings_id;
            const training_name = v?.training_name || "";
            const industry_name = v?.industry_name;
            const shareable_auth_token = v?.shareable_auth_token;
            const form_type =
              v?.form_type === "JSA_FORM"
                ? "FORM"
                : addSpace(v?.form_type).toUpperCase();

            tempRows.push({
              id: v.id,
              title,
              category_name,
              created_at,
              updated_at,
              entry_count,
              training_status,
              is_active,
              trainings_id,
              industry_name,
              form_type,
              training_name,
              shareable_auth_token,
            });
          });
          setRows([...tempRows]);
          setPaginationObject((prevState) => {
            return _setPaginationObjectFn(prevState, data.meta);
          });
        })
        .catch(() => {
          setRows([...[]]);
          setPaginationObject(PAGINATION_OBJECT);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  function removeForm(id) {
    const idx = rows.findIndex((v) => v.id === id);
    setIsLoadingDelete(idx);
    const url =
      jsaType === JSA_TABLE_TYPE.JSA_FORM && isCompanyRole
        ? `${allApiUrl?.DELETE_COMPANY_FORM}/${id}`
        : jsaType === JSA_TABLE_TYPE.JSA_FORM && isSuperAdminRole
        ? `${allApiUrl.JSA_FORMS}/${id}`
        : jsaType === JSA_TABLE_TYPE.JSA_CODES && isSuperAdminRole
        ? `${allApiUrl.JSA_CODE}/${id}`
        : jsaType === JSA_TABLE_TYPE.JSA_CODES && isCompanyRole
        ? `${allApiUrl.COMPANY_JSA_CODE}/${id}`
        : ``;
    if (url) {
      apiDeleteMethod(url)
        .then((response) => {
          setSelected([...[]]);
          fetchList();
          toast.success(response.message);
          setIsLoadingDelete(null);
        })
        .catch((e) => {
          toast.error(_errorMsg(e));
          setIsLoadingDelete(null);
        });
    }
  }
  function createFormClone(form, formType, idx) {
    setIsLoadingClone(idx);
    let url;
    if (isSuperAdminRole) {
      url = `${allApiUrl?.CLONE_JSA_FORMS}/${form?.id}`;
    } else if (isCompanyRole) {
      url =
        currentTab === "TEMPLATE"
          ? `${allApiUrl?.COMPANY_ADMIN_CLONE_TEMPLATE}/${form?.id}`
          : `${allApiUrl?.COMPANY_ADMIN_CLONE_JSA_FORM}/${form?.id}`;
    }

    apiPostMethod(url, {})
      .then((res) => {
        onChangeTab(removeSpace(formType, false));
        fetchList();
        toast.success(res.message);
        if (res && res.id) {
          router.replace(`/forms/form-builder/form/${res.id}`);
        }
      })
      .catch((e) => {
        toast.error(_errorMsg(e));
      })
      .finally(() => {
        setIsLoadingClone(null);
      });
  }

  const handlePublishedChange = (event, item, idx) => {
    setIsUpdatingStatus(idx);
    let obj = {
      is_active: event.target.checked,
    };

    apiPutMethod(
      isSuperAdminRole
        ? `${allApiUrl?.SUPER_ADMIN_FoRM_PUBLISHED}/${item.id}`
        : `${allApiUrl.UPDATE_PUBLISHED_ACTION}/${item.id}`,
      obj
    )
      .then((res) => {
        fetchList();
        toast.success("Form status updated.");
      })
      .catch((e) => {
        toast.error(_errorMsg(e));
      })
      .finally(() => {
        setIsUpdatingStatus(null);
      });
  };
  const onChangeTab = (type = "FORM") => {
    setIsLoading(true);
    const tabIndex = _Tabs.filter((v) => v?.tabType === type)[0]?.tabIndex;
    setValue(tabIndex);
    if (currentTab !== type) {
      setSearchTerm("");
    }
    setSelected([]);
    setCurrentTab(type);
    switch (type) {
      case "JSA_CODE": {
        setJsaType(JSA_TABLE_TYPE.JSA_CODES);
        setIsTraining(undefined);
        break;
      }
      case "ACTIVE_FORM": {
        setIsTraining("active");
        setJsaType(JSA_TABLE_TYPE.JSA_FORM);
        break;
      }
      case "TRAINING_FORM": {
        setIsTraining(true);
        setJsaType(JSA_TABLE_TYPE.JSA_FORM);
        break;
      }
      case "FORM": {
        setIsTraining(false);
        setJsaType(JSA_TABLE_TYPE.JSA_FORM);
        break;
      }
      default: {
        setIsTraining(undefined);
        setJsaType(JSA_TABLE_TYPE.JSA_FORM);
        break;
      }
    }
  };

  const getHeadCellsFn = () => {
    switch (currentTab) {
      case "TEMPLATE": {
        const valuesArray =
          isCompanyRole && formTypeFilter === "JSA_CODE"
            ? [
                "checkbox",
                "training_name",
                "industry_name",
                "entry_count",
                "status",
              ]
            : [
                "checkbox",
                "training_name",
                "industry_name",
                "category_name",
                "entry_count",
                "status",
              ];
        return removeItemByKeyValue(headCellsAllForms, valuesArray);
      }
      case "TRAINING_FORM": {
        return isSuperAdminRole
          ? removeItemByKeyValue(headCellsAllForms, [
              "form_type",
              "category_name",
              "entry_count",
            ])
          : removeItemByKeyValue(
              headCellsAllForms,
              PermissionCheck("Forms", "delete", loginUserInfo)
                ? ["industry_name", "form_type", "category_name"]
                : ["checkbox", "industry_name", "form_type", "category_name"]
            );
      }
      case "JSA_CODE": {
        return isSuperAdminRole
          ? removeItemByKeyValue(headCellsAllForms, [
              "training_name",
              "form_type",
              "entry_count",
            ])
          : removeItemByKeyValue(
              headCellsAllForms,
              PermissionCheck("Forms", "delete", loginUserInfo)
                ? ["training_name", "industry_name", "form_type", "entry_count"]
                : [
                    "training_name",
                    "checkbox",
                    "industry_name",
                    "form_type",
                    "entry_count",
                  ]
            );
      }
      default: {
        return isSuperAdminRole
          ? removeItemByKeyValue(headCellsAllForms, [
              "training_name",
              "category_name",
              "form_type",
              "entry_count",
            ])
          : removeItemByKeyValue(
              headCellsAllForms,
              PermissionCheck("Forms", "delete", loginUserInfo)
                ? [
                    "training_name",
                    "industry_name",
                    "category_name",
                    "form_type",
                  ]
                : [
                    "checkbox",
                    "training_name",
                    "industry_name",
                    "category_name",
                    "form_type",
                  ]
            );
      }
    }
  };

  const handleIndustryChange = (event, value) => {
    setIndustryName(event);
    setSelected([]);
  };
  const handleCategoryName = (event, value) => {
    setCategoryName(event);
    setSelected([]);
  };
  const handleChangeFilterType = (event) => {
    setFormTypeFilter(event.target.value);
    setSelected([]);
    if (event?.target?.value === "JSA_FORM") {
      setIsTraining(false);
    } else if (event?.target?.value === "TRAINING_FORM") {
      setIsTraining(true);
    } else {
      setIsTraining(undefined);
    }
  };
  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
    setSelected([]);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("currentTab");
    if (tab) {
      onChangeTab(tab);
    }
  }, [location]);

  const handleTabChange = (event, newTab) => {
    const tabType = _Tabs.filter((v) => v.tabIndex === newTab)[0].tabType;
    const params = new URLSearchParams(location.search);
    if (tabType !== currentTab) {
      setFormTypeFilter("JSA_FORM");
      setIndustryName(null);
      setStatus("all");
    }
    params.set("currentTab", tabType);
    navigate({ search: params.toString() });
    onChangeTab(tabType);
  };

  const handleCopyLink = async (event, row, idx) => {
    if (isEmpty(row.is_active)) {
      toast.dismiss();
      toast.error("Form is not active!");
    } else if (isEmpty(row.shareable_auth_token)) {
      toast.dismiss();
      toast.error("Shareable link not generated!");
    } else {
      setIsLoadingCopy(idx);
      let linkToCopy = `${process.env.REACT_APP_STRIPE_SUCCESS_URL}/forms/form-detail-shareable/${row?.id}?shareable_auth_token=${row?.shareable_auth_token}`;
      try {
        await navigator.clipboard.writeText(linkToCopy);

        setTimeout(() => {
          setIsLoadingCopy(null);
        }, 2000);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    }
  };
  return (
    <>
      <AlertDialog
        title={selectedForm.title}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        handleDelete={() => {
          removeForm(selectedForm.id);
          setOpenDeleteModal(false);
        }}
      />
      <Card className="cardFix">
        <TableToolbar
          title={
            jsaType === JSA_TABLE_TYPE.JSA_FORM
              ? `Forms Catalogue`
              : jsaType === JSA_TABLE_TYPE.JSA_CODES
              ? `JSA Codes`
              : ``
          }
          numSelected={selected.length}
          onSearch={(query) => setSearchTerm(query)}
          deleteUrl={
            jsaType === JSA_TABLE_TYPE.JSA_FORM && isSuperAdminRole
              ? allApiUrl.REMOVE_MULTI_JSA_FORMS
              : jsaType === JSA_TABLE_TYPE.JSA_FORM && isCompanyRole
              ? allApiUrl.REMOVE_COMPANY_MULTI_JSA_FORM
              : jsaType === JSA_TABLE_TYPE.JSA_CODES
              ? allApiUrl.REMOVE_MULTI_JSA_CODE
              : ""
          }
          selected={selected}
          setSelected={setSelected}
          apiCall={fetchList}
          showDeleteButtonCheck={currentTab !== "TEMPLATE"}
          key={currentTab}
        />

        <div className="mt-2 mb-4">
          <TabsUi
            currentTab={value}
            handleTabChange={handleTabChange}
            tabsArray={_Tabs}
          />
        </div>
        <div style={{ padding: "0 15px 20px 15px" }}>
          <h6 className="mb-3">Filters:</h6>
          <Grid container columnSpacing={2}>
            {isSuperAdminRole && (
              <Grid item lg={3}>
                <SelectAsync
                  isClearable
                  onChange={handleIndustryChange}
                  searchType="industry"
                  selectedValue={industryName}
                  placeholder="Select Industry"
                  className="asyncHeight training"
                  dataParam={{ isFilter: true }}
                  selectBoxKey={currentTab}
                />
              </Grid>
            )}
            {currentTab === FORM_CATALOGUE_TABS.TEMPLATE && (
              <Grid item lg={3}>
                <FormControl sx={{ width: "100%", marginBottom: "5px" }}>
                  <InputLabel id="select-form-type">Form Type</InputLabel>
                  <Select
                    labelId="select-form-type"
                    id="select-formType"
                    label="Form Type"
                    value={formTypeFilter}
                    onChange={(event) => handleChangeFilterType(event)}
                  >
                    {Object.keys(FILTER_FORM_TYPE).map((k) => {
                      const val = FILTER_FORM_TYPE[k];
                      return (
                        <MenuItem key={`menu_items_${k}`} value={val}>
                          {addSpace(k, false)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {(jsaType === JSA_TABLE_TYPE.JSA_CODES ||
              currentTab === FORM_CATALOGUE_TABS.JSA_CODE ||
              (currentTab === FORM_CATALOGUE_TABS.TEMPLATE &&
                formTypeFilter === FILTER_FORM_TYPE.JSA_CODE)) && (
              <Grid item lg={3}>
                <SelectAsync
                  isClearable
                  onChange={handleCategoryName}
                  searchType="categoryList"
                  selectedValue={categoryName}
                  placeholder="Select Category"
                  className="asyncHeight training"
                  dataParam={{ isFilter: true }}
                  selectBoxKey={currentTab}
                />
              </Grid>
            )}

            {currentTab !== FORM_CATALOGUE_TABS.TEMPLATE && (
              <Grid item lg={3}>
                <FormControl sx={{ width: "100%", marginBottom: "5px" }}>
                  <InputLabel id="select-form-type">Status</InputLabel>
                  <Select
                    labelId="select-form-type"
                    id="select-formType"
                    label="Status"
                    value={status}
                    onChange={(event) => handleChangeStatus(event)}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>In Active</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </div>
        <Scrollbar>
          <TableContainer className="tableResponsive">
            <Table
              sx={{
                minWidth: 750,
              }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableHeadRow
                numSelected={selected.length}
                rowCount={rows.length}
                headCells={getHeadCellsFn()}
                permissionType="Forms"
                listData={rows}
                setSelected={setSelected}
              />

              <TableBody>
                {isLoading ? (
                  <TableRowsLoader
                    rowsNum={10}
                    columnNum={getHeadCellsFn().length}
                  />
                ) : isEmpty(rows) ? (
                  <StyledTableNoData colSpan={getHeadCellsFn().length} />
                ) : (
                  <>
                    {rows.map((row, index) => {
                      const isItemSelected = isSelected(row.id, selected);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <StyledTableRow
                          className="tableRow"
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {getHeadCellsFn().map((hCell) =>
                            hCell.id === "checkbox" ? (
                              <StyledTableCell
                                padding="checkbox"
                                key={`styledTableCell_0_${hCell.id}`}
                              >
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                  onClick={(event) =>
                                    handleClick(event, row.id)
                                  }
                                />
                              </StyledTableCell>
                            ) : hCell.id === "entry_count" ? (
                              <StyledTableCell
                                key={`styledTableCell_0_${hCell.id}`}
                              >
                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    _handleRedirect(
                                      `/forms/saved-entries/${row.id}`,
                                      currentTab ? { currentTab } : {}
                                    );
                                  }}
                                  style={{ color: "#4492ff" }}
                                >
                                  {row.entry_count || 0}
                                </div>
                              </StyledTableCell>
                            ) : hCell.id === "status" ? (
                              <StyledTableCell
                                key={`styledTableCell_0_${hCell.id}`}
                              >
                                <FormControlLabel
                                  disabled={isUpdatingStatus === index}
                                  control={
                                    <LoadingSwitch
                                      loading={isUpdatingStatus === index}
                                      color="success"
                                      checked={Boolean(row?.is_active)}
                                      onChange={(e) =>
                                        handlePublishedChange(e, row, index)
                                      }
                                    />
                                  }
                                  label={
                                    row?.is_active ? "Active" : "In Active"
                                  }
                                />
                              </StyledTableCell>
                            ) : hCell.id === "actions" ? (
                              <StyledTableCell
                                key={`styledTableCell_0_${hCell.id}`}
                                sx={{ whiteSpace: "nowrap" }}
                              >
                                {isCompanyRole &&
                                  (currentTab === FORM_CATALOGUE_TABS.FORM ||
                                    (currentTab ===
                                      FORM_CATALOGUE_TABS.TEMPLATE &&
                                      formTypeFilter ===
                                        FILTER_FORM_TYPE.FORM)) && (
                                    <BootstrapTooltipUi
                                      title={
                                        isLoadingCopy === index
                                          ? "Copied"
                                          : "Copy link"
                                      }
                                      placement="top"
                                    >
                                      <IconButton
                                        className="outerborder "
                                        aria-label="Shareable Link"
                                        disabled={isLoadingCopy === index}
                                        onClick={(e) =>
                                          handleCopyLink(e, row, index)
                                        }
                                      >
                                        {isLoadingCopy === index ? (
                                          <CheckCircleOutlineIcon />
                                        ) : (
                                          <ShareIcon />
                                        )}
                                      </IconButton>
                                    </BootstrapTooltipUi>
                                  )}
                                {PermissionCheck(
                                  "Forms",
                                  "create",
                                  loginUserInfo
                                ) && (
                                  <BootstrapTooltipUi
                                    title="Clone"
                                    placement="top"
                                  >
                                    <IconButton
                                      className="outerborder "
                                      aria-label="Clone"
                                      onClick={() => {
                                        createFormClone(
                                          row,
                                          row?.form_type,
                                          index
                                        );
                                      }}
                                      disabled={isLoadingClone === index}
                                    >
                                      {isLoadingClone === index ? (
                                        <CircularProgress size={20} />
                                      ) : (
                                        <ContentCopyIcon />
                                      )}
                                    </IconButton>
                                  </BootstrapTooltipUi>
                                )}

                                {PermissionCheck(
                                  "Forms",
                                  "update",
                                  loginUserInfo
                                ) &&
                                  currentTab !== "TEMPLATE" && (
                                    <BootstrapTooltipUi
                                      title="Edit"
                                      placement="top"
                                    >
                                      <IconButton
                                        className="outerborder"
                                        aria-label="Edit"
                                        onClick={() => {
                                          _handleRedirect(
                                            `/forms/form-builder/${
                                              jsaType ===
                                              JSA_TABLE_TYPE.JSA_FORM
                                                ? "form"
                                                : "subform"
                                            }/${row.id}`,
                                            currentTab ? { currentTab } : {}
                                          );
                                        }}
                                      >
                                        <DriveFileRenameOutlineIcon />
                                      </IconButton>
                                    </BootstrapTooltipUi>
                                  )}
                                <ActionPreview
                                  permissionCheckType={"Forms"}
                                  formId={row.id}
                                  currentTab={currentTab}
                                  loginUserInfo={loginUserInfo}
                                />
                                {PermissionCheck(
                                  "Forms",
                                  "delete",
                                  loginUserInfo
                                ) &&
                                  currentTab !== "TEMPLATE" && (
                                    <BootstrapTooltipUi
                                      title="Delete"
                                      placement="top"
                                    >
                                      <IconButton
                                        className="outerborder"
                                        aria-label="Delete"
                                        onClick={() => {
                                          setSelectedForm({
                                            id: row.id,
                                            title: row.title,
                                          });
                                          setOpenDeleteModal(true);
                                        }}
                                        sx={{ color: "error.main" }}
                                        disabled={isLoadingDelete === index}
                                      >
                                        {isLoadingDelete === index ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          <DeleteIcon />
                                        )}
                                      </IconButton>
                                    </BootstrapTooltipUi>
                                  )}
                              </StyledTableCell>
                            ) : (
                              <StyledTableCell
                                key={`styledTableCell_0_${hCell.id}`}
                                align="left"
                                className="noWrap"
                              >
                                {row[hCell.id]}
                              </StyledTableCell>
                            )
                          )}
                        </StyledTableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePaginationCompo
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            setSelected={setSelected}
          />
        </Scrollbar>
      </Card>
    </>
  );
}

export default memo(JsaTable);
