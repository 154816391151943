import {useEffect, useState} from "react";
import {replaceLabelStrings} from "src/shared/functions/string-conversion";
import LabelCompo from "./LabelCompo";
import {Autocomplete, FormControl, FormHelperText, MenuItem, Select, TextField} from "@mui/material";
import * as React from "react";
import {isEmpty} from "src/shared/functions/isEmpty";
import {uniqueID} from "src/shared/functions";
import {QueCorrectIncorrect} from "./ui/QueCorrectIncorrect";

export const SelectDropdownCompo = (
  {
    isReadOnly = true,
    data,
    onChangeFields,
    form,
    isTrainingForm = false,
    isSubmitClicked = false,
    isEntryPage = false,
    isRetakePage = false,
    isDraftSaved = false
  }) => {

  const [fieldChangedCount, setFieldChangeCount] = useState(0)
  const [labelConv, setLabelConv] = useState(
    isReadOnly ? data.label : replaceLabelStrings(data?.label, form)
  );

  const [selectedValues, setSelectedValues] = useState(((isEntryPage && data.userInputVal) ? (typeof data.userInputVal === "object" ? data.userInputVal : !isEmpty(JSON.parse(data.userInputVal)) ? [JSON.parse(data.userInputVal)] : []) : data.value) || []);
  useEffect(() => {
    if (isReadOnly) {
      setSelectedValues(data.value)
    }
  }, [data.value]);
  useEffect(() => {
    setLabelConv(isReadOnly ? data.label : replaceLabelStrings(data?.label, form));
  }, [data.label, form]);

  useEffect(() => {
    onChangeFields(selectedValues)
  }, [selectedValues]);

  return (
    <div key={data.id} id={data.id}>

      <input hidden id={`field_id_${data.id}`} value={JSON.stringify(selectedValues)} readOnly/>
      <FormControl required={data.required} fullWidth error={Boolean(!isReadOnly && data.required && isSubmitClicked && !(selectedValues && selectedValues.length))}>
        <LabelCompo data={data} labelConv={labelConv}/>
        {!Boolean(data?.allowFillIn) ? <Select
          fullWidth
          displayEmpty
          disabled={fieldChangedCount> 0 || ((!isDraftSaved && !data.showRetakeField) && (isReadOnly || Boolean(isTrainingForm && selectedValues.length) || (isEntryPage && !isEmpty(selectedValues))))}
          name={`field_name_${data.id}`}
          size="small"
          value={(selectedValues && selectedValues.length) ? JSON.stringify(selectedValues[0]) : ""}
          onChange={(e) => {
            if (!isReadOnly) {
              if (isRetakePage) {
                setFieldChangeCount(prevState => { return prevState+1 })
              }
              setSelectedValues(e.target.value ? [JSON.parse(e.target.value)] : [])
            }
          }}
          >
          {data.options?.map((val, idx) => {
            const el = JSON.stringify({
              element: data.element,
              id: val.id,
              val: (data.hasValues ? val.value : val.label),
              label: val.label
            });
            return <MenuItem key={`select_item__${idx}`} value={el}>
              {val.disabled ? <em>{val?.label}</em> : val?.label}
            </MenuItem>
          })}
        </Select>
          :
        <Autocomplete
          fullWidth
          displayEmpty
          disabled={((!isDraftSaved && !data.showRetakeField) && (isReadOnly))}
          name={`field_name_${data.id}`}
          size="small"
          options={data.options.map((val, idx) => { return {
            element: data.element,
            id: val.id,
            val: (data.hasValues ? val.value : val.label),
            label: val.label,
            disabled: val.disabled
          }})}
          getOptionLabel={(option) => option.label || ""}
          filterOptions={(option, params) => option }
          value={(selectedValues && selectedValues.length) ? selectedValues[0] : []}
          onChange={(e, value) => {
            if (!isReadOnly) {
             setSelectedValues(value ? [value] : [])
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: "select",
              }}
              onChange={(e) => {
                const newData = {
                  element: "DropDown",
                  id: `DropDown_Option_${uniqueID(4)}`,
                  val: e?.target?.value,
                  label: e?.target?.value,
                  disabled: false
                }
                if (!isReadOnly) {
                  setSelectedValues([newData])
                }
              }}
            />
          )}
        />}

        <FormHelperText>{Boolean(!isReadOnly && data.required && isSubmitClicked && !(selectedValues && selectedValues.length)) && "This Field is required!"}</FormHelperText>
        {Boolean(isTrainingForm && data.userInputVal !== undefined && !fieldChangedCount && !isReadOnly) &&
          <QueCorrectIncorrect child={data} />
        }
      </FormControl>
    </div>
  );
};