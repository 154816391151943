import { useState } from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { apiDeleteMethod, apiPostMethod } from "src/api/rest";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "src/components/ConfirmModal";
import { PermissionCheck } from "src/utils/common";
import { allApiUrl } from "src/api/apiRoute";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { BootstrapTooltipUi } from "src/components/mui-common/BootstrapToolTip";
import { calculateExpiryDate } from "src/shared/functions/string-conversion";
import { Visibility } from "@mui/icons-material";
import { isEmpty } from "src/shared/functions/isEmpty";
import {
  StyledTableCell,
  StyledTableRow,
} from "src/components/mui-common/table-ui/TableHeadRow";

export default function CertificateTableRow({
  selected,
  title,
  userName,
  TrainingName,
  dateOfTraining,
  id,
  handleClick,
  setOpen,
  apiCall,
  validity_period,
  success_entry_form_id,
  company_name,
  isSuperAdminRole,
}) {
  let navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const deleteData = () => {
    apiDeleteMethod(`${allApiUrl?.DELETE_TRAINING_BYID}/${deleteId}`)
      .then((data) => {
        setIsModalOpen(false);
        setOpen(null);
        toast.success(data?.message);
        apiCall();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.data?.message);
      });
    // }
  };
  const editUser = async () => {
    navigate(`/training-certificate/edit-training-certificate/${id}`);
  };
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );

  return (
    <>
      <StyledTableRow
        tabIndex={-1}
        role="checkbox"
        selected={selected}
        className="tableRow trainingCertificate "
      >
        {PermissionCheck(
          "TrainingsAndCertificates",
          "delete",
          loginUserInfo
        ) && (
          <StyledTableCell padding="checkbox">
            <Checkbox disableRipple checked={selected} onChange={handleClick} />
          </StyledTableCell>
        )}
        <StyledTableCell className="noWrap">{dateOfTraining}</StyledTableCell>
        <StyledTableCell className="noWrap">{userName}</StyledTableCell>
        {isSuperAdminRole && (
          <StyledTableCell className="noWrap">{company_name}</StyledTableCell>
        )}
        <StyledTableCell sx={{ fontWeight: "700" }} className="nowrap16">
          {title}
        </StyledTableCell>
        <StyledTableCell className="noWrap">{TrainingName}</StyledTableCell>
        <StyledTableCell>
          {calculateExpiryDate(dateOfTraining, validity_period)}
        </StyledTableCell>
        <StyledTableCell className="nowrapFull">
          {(PermissionCheck(
            "TrainingsAndCertificates",
            "update",
            loginUserInfo
          ) ||
            PermissionCheck(
              "TrainingsAndCertificates",
              "delete",
              loginUserInfo
            )) && (
            <div className="nowrap16">
              <BootstrapTooltipUi
                title={
                  isEmpty(success_entry_form_id)
                    ? "No Preview Available"
                    : "View"
                }
                placement="top"
              >
                <IconButton
                  className="outerborder mb-2"
                  aria-label="View"
                  onClick={() => {
                    if (!isEmpty(success_entry_form_id)) {
                      window.open(
                        `/training/view-entry/${success_entry_form_id}`
                      );
                    }
                  }}
                >
                  <Visibility />
                </IconButton>
              </BootstrapTooltipUi>
              {PermissionCheck(
                "TrainingsAndCertificates",
                "update",
                loginUserInfo
              ) && (
                <BootstrapTooltipUi title="Edit" placement="top">
                  <IconButton
                    className="outerborder mb-2"
                    aria-label="Edit"
                    onClick={() => editUser(id)}
                  >
                    <DriveFileRenameOutlineIcon />
                  </IconButton>
                </BootstrapTooltipUi>
              )}

              {PermissionCheck(
                "TrainingsAndCertificates",
                "delete",
                loginUserInfo
              ) && (
                <BootstrapTooltipUi title="Delete" placement="top">
                  <IconButton
                    className="outerborder mb-2"
                    aria-label="Delete"
                    sx={{ color: "error.main" }}
                    onClick={() => {
                      setDeleteId(id);
                      setIsModalOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </BootstrapTooltipUi>
              )}
            </div>
          )}
        </StyledTableCell>
      </StyledTableRow>
      <ConfirmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        deleteData={deleteData}
        setOpen={setOpen}
      />
    </>
  );
}

CertificateTableRow.propTypes = {
  avatarUrl: PropTypes.any,
  email: PropTypes.any,
  handleClick: PropTypes.func,
  id: PropTypes.any,
  name: PropTypes.any,
  role: PropTypes.any,
  selected: PropTypes.any,
  status: PropTypes.string,
};
