import {
  Card,
  Grid,
  Table,
  TableBody,
  TableContainer,
  IconButton,
  Chip,
  Stack,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useState } from "react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Scrollbar from "src/components/scrollbar";
import Box from "@mui/material/Box";
import { apiDeleteMethod, apiPostMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import { toast } from "react-toastify";
import ConfirmModal from "src/components/ConfirmModal";
import { useNavigate } from "react-router-dom";
import Loader from "src/components/Loader";
import { Grading } from "@mui/icons-material";
import LinkedFormsModal from "./LinkedFormsModal";
import { formatDateFn } from "src/shared/functions/string-conversion";
import { BootstrapTooltipUi } from "src/components/mui-common/BootstrapToolTip";
import { TRAINING_STATUS } from "src/core/constants";
import SavedEntriesDrawer from "src/components-form/Modals/SavedEntriesDrawer";
import { ToolbarUiNew } from "src/components/mui-common/toolbar-ui-new";
import TableRowsLoader from "src/components/mui-common/table-ui/TableRowsLoader";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import { isEmpty } from "src/shared/functions/isEmpty";
import TableHeadRow, {
  StyledTableCell,
  StyledTableNoData,
  StyledTableRow,
} from "src/components/mui-common/table-ui/TableHeadRow";
import { _handleRedirect } from "../../../shared/functions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  width: "60%",
  height: "60%",
  p: 4,
};
const StaticTab = ({
  value,
  headLabel,
  crewsFiltered,
  headLabel2,
  headLabelActiveTraining,
  formFiltered,
  headLabelActiveForms,
  headLabelFiles,
  headLabelArchived,
  headLabelForm,
  state,
  formFilteredArchived,
  paginationObjectForm,
  setPaginationObjectForm,
  paginationObjectArchived,
  setPaginationObjectArchived,
  attachmentsList,
  id,
  apiCallAttachment,
  apiCrewsCall,
  apiFormCall,
  setAssignTraining,
  setUserId,
  userId,
  paginationObjectFile,
  setPaginationObjectFile,
  loadingCrew,
  loadingForm,
  loadingFormArchived,
  loadingArch,
  setOpenModal,
  setDisabledTraining,
  setFormData,
  selectSelectDueDate,
  openModal,
  assignModal,
  statusFilter,
  setStatusFilter,
  linkedFormsModalOpen,
  setLinkedFormsModalOpen,
}) => {
  const [weatherData, setWeatherData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formIsModalOpen, setFormIsModelOpen] = useState(false);
  const [open, setOpen] = useState(null);
  const [imagSrc, setImageSrc] = useState("");
  const [imageModel, setImageModel] = useState(false);
  const [imagLoader, setImagLoader] = useState(false);

  const [spinnerLoader, setSpinnerLoader] = useState(null);
  const [openDrawer, setOpenDrawer] = useState({});
  const [formOpenDrawer, setFormOpenDrawer] = useState({});
  const [isActiveForms, setIsActiveForms] = useState(false);
  const [deleteCrewId, setDeleteCrewId] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch weather data here
        const response = await fetch(
          `${allApiUrl?.WEATHER_API}?lat=${state?.state?.address?.location_lat}&lon=${state?.state?.address?.location_lon}&exclude=minutely&appid=${process.env.REACT_APP_WEATHER_KEY}&units=metric`
        );

        if (!response.ok) {
          throw new Error("Weather data not available");
        }

        const data = await response.json();
        setWeatherData(data);
      } catch (error) {
        console.error("Error fetching weather data:", error);
      }
    };

    fetchData();
  }, []);
  const celsiusToFahrenheit = (celsius) => {
    return (celsius * 9) / 5 + 32;
  };
  const getCurrentDay = () => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date();
    const dayOfWeek = daysOfWeek[date.getDay()];
    return dayOfWeek;
  };
  const handleFileChange = (event) => {
    setImagLoader(true);
    const selectedFiles = Array.from(event.target.files);
    const formData = new FormData();
    selectedFiles?.map((item) => {
      formData.append("file", item);
    });
    apiPostMethod(`${allApiUrl?.POST_ATTACHMENT_PROJECT}/${id}`, formData)
      .then((data) => {
        toast.success(data?.message);
        apiCallAttachment();
        setImagLoader(false);
      })
      .catch((err) => {
        toast.error(err?.data?.message);
      });
  };
  const deleteData = (attachId) => {
    apiDeleteMethod(`${allApiUrl?.DELETE_ATTACHMENT}/${id}/${attachId}`)
      .then((data) => {
        toast.success(data?.message);
        apiCallAttachment();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err.data?.message);
      });
  };
  const handleDeleteCrew = () => {
    apiDeleteMethod(`${allApiUrl?.DELETE_CREW_MEMBER}/${id}/${deleteCrewId}`)
      .then((data) => {
        setIsModalOpen(false);
        toast.success(data?.message);
        setOpen(null);
        apiCrewsCall();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err.data?.message);
      });
  };
  const handleDeleteForm = (deleteForm) => {
    apiDeleteMethod(`${allApiUrl?.DELETE_PROJECT_FORM}/${id}/${deleteForm}`)
      .then((data) => {
        setFormIsModelOpen(false);
        setIsActiveForms(false);
        toast.success(data?.message);
        setOpen(null);
        apiFormCall();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err.data?.message);
      });
  };
  const handleDownload = (e, url) => {
    let obj = {
      key: url,
    };
    apiPostMethod(allApiUrl?.GET_FILE, obj).then((data) => {
      fetch(data?.data?.url, {
        method: "GET",
        headers: {},
      })
        .then((response) => {
          response.arrayBuffer().then(function (buffer) {
            const _url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = _url;
            link.setAttribute("download", `${url.split("/").pop()}`); //or any other extension
            // document.body.appendChild(link);
            link.click();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  const handleProjectClone = (formId) => {
    apiPostMethod(`${allApiUrl?.CLONE_PROJECT_FORM}/${id}/${formId}`)
      .then((data) => {
        toast.success(data.message);
        apiCallAttachment();
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
  };

  const handleViewImage = (e, url) => {
    setSpinnerLoader(url);
    let obj = {
      key: url,
    };
    apiPostMethod(allApiUrl?.GET_FILE, obj).then((data) => {
      fetch(data?.data?.url, {
        method: "GET",
        headers: {},
      })
        .then((response) => {
          setImageSrc(response?.url);
          setSpinnerLoader(null);
          if (response?.url?.includes("pdf")) {
            _handleRedirect(response?.url);
          } else {
            setImageModel(true);
          }
        })
        .catch((err) => {
          setSpinnerLoader(null);
          console.log(err);
        });
    });
  };
  return (
    <>
      {linkedFormsModalOpen && (
        <LinkedFormsModal
          linkedFormsModalOpen={linkedFormsModalOpen}
          setLinkedFormsModalOpen={setLinkedFormsModalOpen}
          setOpenModal={setOpenModal}
          setFormData={setFormData}
          selectSelectDueDate={selectSelectDueDate}
          setUserId={setUserId}
        />
      )}
      {value === 0 && (
        <div>
          <Grid container spacing={2} sx={{ padding: "0 30px 30px 30px" }}>
            <Grid item md={6}>
              <Box>
                <Card
                  className="cardFix"
                  sx={{
                    padding: "20px",
                    marginTop: 0,
                    height: "304px",
                    overflowY: "auto",
                  }}
                >
                  {state?.state?.address?.project_description}
                </Card>
              </Box>
            </Grid>
            <Grid item md={6}>
              {!weatherData ? (
                <Card
                  className="cardFix"
                  sx={{ padding: "20px", marginTop: 0 }}
                >
                  Weather details currently unavailable.
                </Card>
              ) : (
                <Card
                  className="cardFix"
                  sx={{ padding: "20px", marginTop: 0 }}
                >
                  <Grid container rowSpacing={0} columnSpacing={2}>
                    <Grid item md={6}>
                      <p>{weatherData?.weather[0]?.main}</p>
                      <img
                        src={`http://openweathermap.org/img/wn/${weatherData?.weather[0]?.icon}@2x.png`}
                        alt=""
                        width={200}
                        height={200}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <h6 style={{ fontWeight: "700" }}>{weatherData?.name}</h6>
                      <p>{getCurrentDay()}</p>
                      <h4 style={{ fontWeight: "700" }}>
                        {weatherData?.main?.temp} °C{" "}
                      </h4>
                      <h6 style={{ fontWeight: "600" }}>
                        {celsiusToFahrenheit(weatherData?.main?.temp).toFixed(
                          2
                        )}{" "}
                        °F
                      </h6>
                    </Grid>
                  </Grid>
                  <span>{weatherData?.weather[0]?.description}</span>
                </Card>
              )}
            </Grid>
            <Grid item md={6}>
              <Card className="cardFix">
                <ToolbarUiNew title={`Crew Members`} hideSearch={true} />
                <TableContainer
                  sx={{
                    overflowX: "auto",
                    height: "400px",
                    textWrap: "nowrap",
                  }}
                >
                  <Table stickyHeader>
                    <TableHeadRow headCells={headLabel} />
                    <TableBody>
                      {loadingCrew ? (
                        <TableRowsLoader
                          rowsNum={4}
                          columnNum={headLabel.length}
                        />
                      ) : isEmpty(crewsFiltered) ? (
                        <StyledTableNoData colSpan={headLabel.length} />
                      ) : (
                        crewsFiltered?.map((item, index) => {
                          return (
                            <StyledTableRow
                              role="checkbox"
                              tabIndex={-1}
                              key={`styledTableRowCrew_${index}`}
                              className={
                                item?.crew_member_user_id === userId
                                  ? "trainingActive"
                                  : ""
                              }
                            >
                              <StyledTableCell>
                                {item?.user_name}
                              </StyledTableCell>
                              <StyledTableCell>
                                {item?.phone_number}
                              </StyledTableCell>
                              <StyledTableCell>
                                {item.user_email}
                              </StyledTableCell>
                              <StyledTableCell className="nowrapFull">
                                <BootstrapTooltipUi
                                  title="Assigned Forms"
                                  placement="top"
                                >
                                  <IconButton
                                    className="outerborder"
                                    aria-label="Assigned Forms"
                                    onClick={() => {
                                      setLinkedFormsModalOpen({
                                        type: "crewMember",
                                        projectId:
                                          item?.project_id ||
                                          state?.state?.address?.id,
                                        type_id: item.crew_member_user_id,
                                        type_label: item.user_name,
                                      });
                                      setUserId(item.crew_member_user_id);
                                    }}
                                  >
                                    <Grading />
                                  </IconButton>
                                </BootstrapTooltipUi>
                                <BootstrapTooltipUi
                                  title="Assign Training"
                                  placement="top"
                                >
                                  <IconButton
                                    className="outerborder"
                                    aria-label="view"
                                    onClick={() => {
                                      setAssignTraining(true);
                                      setUserId(item?.crew_member_user_id);
                                      setDisabledTraining(true);
                                    }}
                                  >
                                    <ArrowForwardIcon />
                                  </IconButton>
                                </BootstrapTooltipUi>
                                <BootstrapTooltipUi
                                  title="Remove from Project"
                                  placement="top"
                                >
                                  <IconButton
                                    className="outerborder"
                                    aria-label="Delete"
                                    onClick={() => {
                                      setIsModalOpen(true);
                                      setDeleteCrewId(item.id);
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </BootstrapTooltipUi>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                      )}
                    </TableBody>
                    <ConfirmModal
                      isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen}
                      deleteData={() => handleDeleteCrew()}
                      content="Are you sure you want to delete this item? "
                      setOpen={setOpen}
                    />
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
            <Grid item md={6}>
              <Card className="cardFix">
                <SavedEntriesDrawer
                  openDrawer={formOpenDrawer}
                  setOpenDrawer={setFormOpenDrawer}
                />
                <ToolbarUiNew title={`Active Forms`} hideSearch={true} />
                <Scrollbar>
                  <TableContainer
                    sx={{
                      overflowX: "auto",
                      height: "400px",
                      textWrap: "nowrap",
                    }}
                  >
                    <Table stickyHeader>
                      <TableHeadRow headCells={headLabelActiveForms} />
                      <TableBody>
                        {loadingForm ? (
                          <TableRowsLoader
                            rowsNum={4}
                            columnNum={headLabelActiveForms.length}
                          />
                        ) : isEmpty(formFiltered) ? (
                          <StyledTableNoData
                            colSpan={headLabelActiveForms.length}
                          />
                        ) : (
                          formFiltered?.map((item, index) => {
                            return (
                              item?.project_form_status !==
                                TRAINING_STATUS.COMPLETED && (
                                <StyledTableRow
                                  // hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={`styledTableRowActive_${index}`}
                                >
                                  <StyledTableCell>
                                    {item?.form_name}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {item?.added_by_user}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {formatDateFn(item?.updated_at)}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {formatDateFn(item?.due_date)}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {item?.requiredTrainingData?.length > 0 ? (
                                      item?.requiredTrainingData?.map(
                                        (tran) => (
                                          <p className="mb-0">{tran?.name}</p>
                                        )
                                      )
                                    ) : (
                                      <p>N/A</p>
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      whiteSpace: "noWrap",
                                    }}
                                  >
                                    <BootstrapTooltipUi
                                      title="Edit"
                                      placement="top"
                                    >
                                      <IconButton
                                        className="outerborder"
                                        aria-label="Delete"
                                        onClick={() => {
                                          setOpenModal(true);
                                          setFormData({
                                            value: item?.form_id,
                                            label: item?.form_name,
                                          });
                                          selectSelectDueDate(item?.due_date);
                                        }}
                                      >
                                        <DriveFileRenameOutlineIcon />
                                      </IconButton>
                                    </BootstrapTooltipUi>
                                    <BootstrapTooltipUi
                                      title="Delete"
                                      placement="top"
                                    >
                                      <IconButton
                                        className="outerborder"
                                        aria-label="Delete"
                                        onClick={() => setIsActiveForms(true)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </BootstrapTooltipUi>
                                  </StyledTableCell>
                                  <ConfirmModal
                                    isModalOpen={isActiveForms}
                                    setIsModalOpen={setIsActiveForms}
                                    deleteData={() =>
                                      handleDeleteForm(item?.id)
                                    }
                                    content="Are you sure you want to delete this item? "
                                    setOpen={setOpen}
                                  />
                                </StyledTableRow>
                              )
                            );
                          })
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </Card>
            </Grid>
          </Grid>
        </div>
      )}
      {value === 1 && (
        <Grid container spacing={2} sx={{ padding: "0 30px 30px 30px" }}>
          <Grid item md={12}>
            <Card className="cardFix" sx={{ marginTop: 0 }}>
              {imagLoader && <Loader />}
              <Scrollbar>
                <TableContainer sx={{ overflow: "unset" }}>
                  <Table sx={{ minWidth: 800 }}>
                    <TableHeadRow headCells={headLabelFiles} />
                    <TableBody>
                      {loadingArch ? (
                        <TableRowsLoader
                          rowsNum={5}
                          columnNum={headLabelFiles.length}
                        />
                      ) : isEmpty(attachmentsList) ? (
                        <StyledTableNoData colSpan={headLabelFiles.length} />
                      ) : (
                        attachmentsList?.map((item, index) => {
                          return (
                            <StyledTableRow
                              // hover
                              role="checkbox"
                              tabIndex={-1}
                              key={`styledTableRow_${index}`}
                            >
                              <StyledTableCell>
                                <Grid container>
                                  <Grid item>
                                    {item?.attachment_key?.name?.includes(
                                      "pdf"
                                    ) ? (
                                      <img
                                        src="/assets/icons/pdf.png"
                                        height={30}
                                      />
                                    ) : (
                                      <img
                                        src="/assets/icons/image1.png"
                                        height={20}
                                        style={{ marginRight: "10px" }}
                                      />
                                    )}
                                    <span>{item?.attachment_key}</span>
                                  </Grid>
                                </Grid>
                              </StyledTableCell>
                              <StyledTableCell>
                                {formatDateFn(item?.created_at)}
                              </StyledTableCell>
                              <StyledTableCell className="nowrapFull">
                                {" "}
                                <BootstrapTooltipUi
                                  title="Download"
                                  placement="top"
                                >
                                  <IconButton
                                    className="outerborder"
                                    aria-label="Delete"
                                    onClick={(e) =>
                                      handleDownload(e, item?.attachment_key)
                                    }
                                  >
                                    <DownloadIcon />
                                  </IconButton>
                                </BootstrapTooltipUi>
                                {spinnerLoader === item?.attachment_key ? (
                                  <BootstrapTooltipUi
                                    title="View"
                                    placement="top"
                                  >
                                    <IconButton
                                      className="outerborder"
                                      aria-label="Delete"
                                    >
                                      <CircularProgress size={20} />
                                    </IconButton>
                                  </BootstrapTooltipUi>
                                ) : (
                                  <BootstrapTooltipUi
                                    title="View"
                                    placement="top"
                                  >
                                    <IconButton
                                      className="outerborder"
                                      aria-label="Delete"
                                      onClick={(e) =>
                                        handleViewImage(e, item?.attachment_key)
                                      }
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  </BootstrapTooltipUi>
                                )}
                                <BootstrapTooltipUi
                                  title="Delete"
                                  placement="top"
                                >
                                  <IconButton
                                    className="outerborder"
                                    aria-label="Delete"
                                    onClick={() => deleteData(item?.id)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </BootstrapTooltipUi>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePaginationCompo
                  paginationObject={paginationObjectFile}
                  setPaginationObject={setPaginationObjectFile}
                />
              </Scrollbar>
            </Card>
          </Grid>
          <Grid item lg={12} md={12}>
            <Box className="drop-file-input">
              <div className="drop-file-input__label">
                <img src="/assets/img/uploadFile.svg" alt="" />
                <input
                  type="file"
                  size="10"
                  multiple
                  style={{ marginTop: "10px" }}
                  onChange={handleFileChange}
                />
                <h4> Click to Upload or drag and drop</h4>
                <p>
                  We Support JPG, PNG file. Make sure that your file is no more
                  than 500 kb.
                </p>
              </div>
            </Box>
          </Grid>
          <Modal
            open={imageModel}
            onClose={() => setImageModel(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="confirmModal">
              <IconButton
                className="closeIcon"
                onClick={() => setImageModel(false)}
              >
                <CancelIcon />
              </IconButton>
              <img
                src={imagSrc}
                style={{ width: "100%", height: "100%" }}
                alt={`upload file`}
              />
            </Box>
          </Modal>
        </Grid>
      )}
      {value === 2 && (
        <Grid container spacing={2} sx={{ padding: "0 30px 30px 30px" }}>
          <Grid item md={12}>
            <Card className="cardFix" sx={{ marginTop: "0px" }}>
              <SavedEntriesDrawer
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
              />
              <Scrollbar>
                <TableContainer sx={{ overflow: "unset" }}>
                  <Table sx={{ minWidth: 800 }}>
                    <TableHeadRow headCells={headLabelArchived} />
                    <TableBody>
                      {loadingFormArchived ? (
                        <TableRowsLoader
                          rowsNum={5}
                          columnNum={headLabelArchived.length}
                        />
                      ) : isEmpty(formFilteredArchived) ? (
                        <StyledTableNoData colSpan={headLabelArchived.length} />
                      ) : (
                        formFilteredArchived?.map((item, index) => {
                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell>
                                {item?.form_name}
                              </StyledTableCell>
                              <StyledTableCell>
                                {formatDateFn(item?.updated_at)}
                              </StyledTableCell>
                              <StyledTableCell>
                                {formatDateFn(item?.due_date)}
                              </StyledTableCell>
                              <StyledTableCell>
                                {item?.requiredTrainingData?.length > 0 ? (
                                  item?.requiredTrainingData?.map((tran) => (
                                    <p>{tran?.name}</p>
                                  ))
                                ) : (
                                  <p>N/A</p>
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                {item?.submitted_by}
                              </StyledTableCell>
                              <StyledTableCell>
                                <BootstrapTooltipUi
                                  title="View"
                                  placement="top"
                                >
                                  <IconButton
                                    className="outerborder"
                                    aria-label="Delete"
                                    onClick={() =>
                                      setOpenDrawer({
                                        id: item?.id,
                                        state: {
                                          project_form_submitted_status:
                                            "COMPLETED",
                                        },
                                        pathName: "/projects/form-detail",
                                      })
                                    }
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </BootstrapTooltipUi>
                                <BootstrapTooltipUi
                                  title="Edit"
                                  placement="top"
                                >
                                  <IconButton
                                    className="outerborder"
                                    aria-label="edit"
                                    onClick={() =>
                                      window.open(
                                        `/projects/form-edit-detail/${
                                          item?.project_form_status ===
                                          "PENDING"
                                            ? item?.id
                                            : item.id
                                        }?project_form_status=${
                                          item?.project_form_status ===
                                          "PENDING"
                                            ? "PENDING"
                                            : "COMPLETED"
                                        }`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    <DriveFileRenameOutlineIcon />
                                  </IconButton>
                                </BootstrapTooltipUi>
                                <BootstrapTooltipUi
                                  title="Delete"
                                  placement="top"
                                >
                                  <IconButton
                                    className="outerborder"
                                    aria-label="Delete"
                                    onClick={() => setFormIsModelOpen(true)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </BootstrapTooltipUi>
                              </StyledTableCell>
                              <ConfirmModal
                                isModalOpen={formIsModalOpen}
                                setIsModalOpen={setFormIsModelOpen}
                                deleteData={() => handleDeleteForm(item?.id)}
                                content="Are you sure you want to delete this item? "
                                setOpen={setOpen}
                              />
                            </StyledTableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePaginationCompo
                paginationObject={paginationObjectArchived}
                setPaginationObject={setPaginationObjectArchived}
              />
            </Card>
          </Grid>
        </Grid>
      )}
      {value === 3 && (
        <Grid container spacing={2} sx={{ padding: "0 30px 30px 30px" }}>
          <Grid item md={12}>
            <Card className="cardFix" sx={{ marginTop: "0px" }}>
              <SavedEntriesDrawer
                openDrawer={formOpenDrawer}
                setOpenDrawer={setFormOpenDrawer}
              />
              <div style={{ padding: " 20px 15px" }}>
                <h6 className="mb-3">Filters:</h6>
                <Grid container columnSpacing={2}>
                  <Grid item lg={3}>
                    <FormControl sx={{ width: "100%", marginBottom: "5px" }}>
                      <InputLabel id="select-status">Status</InputLabel>
                      <Select
                        labelId="select-status"
                        id="selectStatus"
                        label="Status"
                        value={statusFilter}
                        onChange={(event) =>
                          setStatusFilter(event.target.value)
                        }
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={"COMPLETED"}>Completed</MenuItem>
                        <MenuItem value={"PENDING"}>Pending</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              <Scrollbar>
                <TableContainer sx={{ overflow: "unset" }}>
                  <Table sx={{ minWidth: 800 }}>
                    <TableHeadRow headCells={headLabelForm} />

                    <TableBody>
                      {loadingForm ? (
                        <TableRowsLoader
                          rowsNum={10}
                          columnNum={headLabelForm.length}
                        />
                      ) : isEmpty(formFiltered) ? (
                        <StyledTableNoData colSpan={headLabelForm.length} />
                      ) : (
                        formFiltered?.map((item, index) => {
                          return (
                            <StyledTableRow
                              role="checkbox"
                              tabIndex={-1}
                              key={`styledTableRowForm_${index}`}
                            >
                              <StyledTableCell>
                                {item?.form_name}
                              </StyledTableCell>
                              <StyledTableCell>
                                {formatDateFn(item?.updated_at)}{" "}
                              </StyledTableCell>
                              <StyledTableCell>
                                {formatDateFn(item?.due_date)}
                              </StyledTableCell>
                              <StyledTableCell>
                                {formatDateFn(item?.updated_at)}
                              </StyledTableCell>
                              <StyledTableCell>
                                {item?.requiredTrainingData?.length > 0 ? (
                                  item?.requiredTrainingData?.map((tran) => (
                                    <p className="mb-0">{tran?.name}</p>
                                  ))
                                ) : (
                                  <p>N/A</p>
                                )}
                              </StyledTableCell>
                              <StyledTableCell style={{ textAlign: "left" }}>
                                <Stack spacing={1} alignItems="left">
                                  <Stack direction="row" spacing={1}>
                                    {item?.project_form_status ===
                                    "INPROGRESS" ? (
                                      <Chip
                                        label="INPROGRESS"
                                        color="primary"
                                      />
                                    ) : item?.project_form_status ===
                                      "PENDING" ? (
                                      <Chip label="PENDING" color="error" />
                                    ) : (
                                      <Chip label="COMPLETED" color="success" />
                                    )}
                                  </Stack>
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell>
                                {item?.project_form_status === "COMPLETED" && (
                                  <BootstrapTooltipUi
                                    title="View"
                                    placement="top"
                                  >
                                    <IconButton
                                      className="outerborder"
                                      aria-label="Delete"
                                      onClick={() =>
                                        setFormOpenDrawer({
                                          id: item?.id,
                                          state: {
                                            project_form_submitted_status:
                                              "COMPLETED",
                                          },
                                          pathName: "/projects/form-detail",
                                        })
                                      }
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  </BootstrapTooltipUi>
                                )}
                                {item?.project_form_status === "COMPLETED" ? (
                                  <BootstrapTooltipUi
                                    title="Edit"
                                    placement="top"
                                  >
                                    <IconButton
                                      className="outerborder"
                                      aria-label="Delete"
                                      onClick={() => {
                                        window.open(
                                          `/projects/form-edit-detail/${item?.id}?project_form_status=COMPLETED`,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      <DriveFileRenameOutlineIcon />
                                    </IconButton>
                                  </BootstrapTooltipUi>
                                ) : (
                                  <BootstrapTooltipUi
                                    title="Edit"
                                    placement="top"
                                  >
                                    <IconButton
                                      className="outerborder"
                                      aria-label="Edit"
                                      onClick={() => {
                                        setOpenModal(true);
                                        setFormData({
                                          value: item?.form_id,
                                          label: item?.form_name,
                                        });
                                        selectSelectDueDate(
                                          item?.due_date || item?.created_at
                                        );
                                      }}
                                    >
                                      <DriveFileRenameOutlineIcon />
                                    </IconButton>
                                  </BootstrapTooltipUi>
                                )}
                                {item?.project_form_status !==
                                  TRAINING_STATUS.COMPLETED && (
                                  <BootstrapTooltipUi
                                    title="Delete"
                                    placement="top"
                                  >
                                    <IconButton
                                      className="outerborder"
                                      aria-label="Delete"
                                      onClick={() => setFormIsModelOpen(true)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </BootstrapTooltipUi>
                                )}
                              </StyledTableCell>
                              {/* )} */}
                              <ConfirmModal
                                isModalOpen={formIsModalOpen}
                                setIsModalOpen={setFormIsModelOpen}
                                deleteData={() => handleDeleteForm(item?.id)}
                                content="Are you sure you want to delete this item? "
                                setOpen={setOpen}
                              />
                            </StyledTableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePaginationCompo
                paginationObject={paginationObjectForm}
                setPaginationObject={setPaginationObjectForm}
              />
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default StaticTab;
