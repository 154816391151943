import * as formHtml from "src/shared/constants/formHtml";
import {
  _checkConditionalShowHideEl,
  calculateTotalMarks,
  createFormUserInputData,
  deepClone,
} from "src/shared/functions";
import React, { useEffect, useRef, useState } from "react";
import { isEmpty } from "src/shared/functions/isEmpty";
import HeaderPreview, { HeaderTitleHtml } from "./HeaderPreview";
import FooterPreview from "./FooterPreview";
import { PageBreakButtonsHtml } from "../Common/PageBreakCompo";
import { apiPostMethod, apiPutMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRouter } from "src/routes/hooks";
import EntryResultModal from "../Modals/EntryResultModal";
import { toast } from "react-toastify";
import { DownloadPdf } from "./DownloadPdf";
import { PageLoader } from "src/components/PageLoader";
import * as _ from "lodash";

const PreviewFormCompo = ({
  isJsonData = false,
  isEntryPage = false,
  isRetakePage = false,
  jsonData = null,
  SubmitForm = null,
  selectedWidgets = null,
  setSelectedWidget = (e) => {},
  formSettingsProp = null,
  setFormSettingsProp = (e) => {},
  setIsOpen = false,
  showSubmitButton = true,
  trainingParams,
}) => {
  const { id } = useParams();
  const router = useRouter();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token") || "";
  if (location.pathname.includes("/allocated-project-form")) {
    isEntryPage = true;
  }
  const shareable_auth_token = queryParams.get("shareable_auth_token") || "";
  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [splitForm, setSplitForm] = useState([]);
  const [previewWidgetsData, setPreviewWidgetsData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [form_settings, setFormSettings] = useState({});
  const [formSubmitSettings, setFormSubmitSettings] = useState({});
  const [openResultModal, setOpenResultModal] = useState(false);
  const [resultData, setResultData] = useState({});

  const [obtainedMarksProp, setObtainedMarksProp] = useState(0);
  const [WidgetsData] = useState(
    isJsonData
      ? jsonData.structure
      : JSON.parse(localStorage.getItem("previewWidgetsData"))
  );
  const [settings] = useState(
    isJsonData
      ? jsonData.form_settings
      : JSON.parse(localStorage.getItem("form_settings"))
  );
  const [submit_settings] = useState(
    isJsonData
      ? jsonData.submit_settings
      : JSON.parse(localStorage.getItem("formSubmitSettings"))
  );
  useEffect(() => {
    setFormSettings(settings);
    setFormSubmitSettings(submit_settings);
    if (!isEmpty(WidgetsData)) {
      if (!isEmpty(settings.title.pages)) {
        if (isEntryPage || isRetakePage) {
          setSplitForm(WidgetsData);
        } else {
          let Obj = [];
          let paginateForm = [];
          WidgetsData.map((json, index) => {
            json?.childComponent?.map((data, refVal) => {
              if (data.element === "PageBreak") {
                data.structure = deepClone(Obj);
                Obj = [...[]];
                paginateForm.push(data);
              } else {
                if (refVal === 0) {
                  Obj.push(json);
                }
                if (index === WidgetsData.length - 1) {
                  let submitData = { structure: deepClone(Obj) };
                  paginateForm.push(submitData);
                }
              }
            });
          });
          setSplitForm(paginateForm);
        }
      } else {
        setPreviewWidgetsData(WidgetsData);
      }
    }
  }, [WidgetsData, settings]);

  useEffect(() => {
    if (!isEmpty(previewWidgetsData)) {
      let newFormData = {};
      const dataSection = (data) => {
        if (data?.hasOwnProperty("fields")) {
          let structure = {};
          Object.keys(data.fields).map((field) => {
            structure[field] = data.fields[field].value;
          });
          newFormData[data.id] = { ...structure };
        } else {
          newFormData[data.id] = "";
        }
      };
      previewWidgetsData.map((json) => {
        json.childComponent.map((data) => {
          if (data.element === "Section") {
            data.childComponent.map((data) => {
              data.childComponent.map((data) => {
                dataSection(data);
              });
            });
          } else {
            dataSection(data);
          }
        });
      });
      setForm({ ...newFormData });
    }
  }, [previewWidgetsData]);

  const onClickFormButton = (e, button) => {
    if (button && button.actionName === "submit") {
      setIsSubmitClicked(true);
    } else if (button?.eventType === "Draft") {
      handleSubmitForm(e, true);
    }
  };
  const formRef = useRef();
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const handleSubmitForm = (e, isSaveDraft = false) => {
    let tokenData =
      shareable_auth_token?.length > 0 ? shareable_auth_token : token;
    e.preventDefault();
    if (!isSaveDraft) {
      setIsSubmitClicked(true);
    }
    const setIsLoadingButton = (eventType = "Save", isLoading = true) => {
      return formSubmitSettings.buttons.map((event) => {
        if (event.eventType === eventType) {
          return { ...event, isLoading };
        }
        return event;
      });
    };
    let tempSaveObject = [];
    const isFormValid = formRef.current?.checkValidity();
    setTimeout(() => {
      const hasNoError = !formRef.current?.querySelector(".Mui-error");
      if ((isFormValid && hasNoError) || isSaveDraft) {
        if (!isEmpty(WidgetsData)) {
          if (!isEmpty(settings.title.pages)) {
            tempSaveObject = splitForm;
          } else {
            tempSaveObject = previewWidgetsData;
          }
          let { obtainedMarks } = createFormUserInputData(
            form,
            formRef,
            tempSaveObject,
            !isEmpty(settings.title.pages),
            obtainedMarksProp
          );
          obtainedMarks = obtainedMarks ? obtainedMarks : 0;
          const updatedButtons = setIsLoadingButton(
            isSaveDraft ? "Draft" : "Save",
            true
          );
          setFormSubmitSettings((prevState) => {
            return {
              ...prevState,
              buttons: updatedButtons,
            };
          });

          let data = {
            structure: tempSaveObject,
            submit_settings: formSubmitSettings,
            form_settings,
            obtainedMarks,
            isSubmit: !isSaveDraft,
          };
          if (!isRetakePage) {
            data.formId = location?.pathname?.includes("/allocated-training/form-detail")
              ? jsonData?.id
              : id;
          }
          const apiMethod = !isRetakePage ? apiPostMethod : apiPutMethod;
          if (
            shareable_auth_token?.length > 0 &&
            location?.pathname?.includes("/allocated-training/form-detail")
          ) {
            const url = `${allApiUrl?.PUBLIC_SUBMIT_TRAINING_ALLOCATED}/${id}?shareable_auth_token=${shareable_auth_token}`;
            apiMethod(url, data, null, null, tokenData)
              .then((res) => {
                if (res && res.data && data.form_settings.isTrainingForm) {
                  setOpenResultModal(true);
                  res.data.obtainedMarks = data.obtainedMarks;
                  setResultData(res.data);
                } else {
                  toast.success(res.message);
                }
              })
              .catch((err) => {
                const msg = err?.message
                  ? err.message
                  : err?.data?.message
                  ? err?.data?.message
                  : "";
                toast.error(msg);
              })
              .finally(() => {
                const updatedButtons = setIsLoadingButton(
                  isSaveDraft ? "Draft" : "Save",
                  false
                );
                setFormSubmitSettings((prevState) => {
                  return {
                    ...prevState,
                    buttons: updatedButtons,
                  };
                });
              });
          } else if (
            shareable_auth_token?.length > 0 &&
            location?.pathname?.includes("/allocated-project-form")
          ) {
            const url = `${allApiUrl?.PUBLIC_SUBMIT_PROJECT_ALLOCATED}/${id}?shareable_auth_token=${shareable_auth_token}`;
            apiMethod(url, data, null, null, tokenData)
              .then((res) => {
                if (res && res.data && data.form_settings.isTrainingForm) {
                  setOpenResultModal(true);
                  res.data.obtainedMarks = data.obtainedMarks;
                  setResultData(res.data);
                } else {
                  toast.success(res.message);
                }
              })
              .catch((err) => {
                const msg = err?.message
                  ? err.message
                  : err?.data?.message
                  ? err?.data?.message
                  : "";
                toast.error(msg);
              })
              .finally(() => {
                const updatedButtons = setIsLoadingButton(
                  isSaveDraft ? "Draft" : "Save",
                  false
                );
                setFormSubmitSettings((prevState) => {
                  return {
                    ...prevState,
                    buttons: updatedButtons,
                  };
                });
              });
          } else if (
            location?.pathname?.includes("/forms/form-detail-shareable")
          ) {
            const url = `${allApiUrl?.PUBLIC_SUBMIT_JSA_FORM}/${id}?shareable_auth_token=${shareable_auth_token}`;
            apiMethod(url, data, null, null, token)
              .then((res) => {
                if (res && res.data && data.form_settings.isTrainingForm) {
                  setOpenResultModal(true);
                  res.data.obtainedMarks = data.obtainedMarks;
                  setResultData(res.data);
                } else {
                  toast.success(res.message);
                }
              })
              .catch((err) => {
                const msg = err?.message
                  ? err.message
                  : err?.data?.message
                  ? err?.data?.message
                  : "";
                toast.error(msg);
              })
              .finally(() => {
                const updatedButtons = setIsLoadingButton(
                  isSaveDraft ? "Draft" : "Save",
                  false
                );
                setFormSubmitSettings((prevState) => {
                  return {
                    ...prevState,
                    buttons: updatedButtons,
                  };
                });
              });
          } else if (
            location?.pathname?.includes("/projects/form-edit-detail/")
          ) {
            const url = `${allApiUrl?.SUBMIT_COMPANY_ADMIN_ALLOCATED_FORM}/${id}`;
            apiMethod(url, data, null, null, token)
              .then((res) => {
                if (res && res.data && data.form_settings.isTrainingForm) {
                  setOpenResultModal(true);
                  res.data.obtainedMarks = data.obtainedMarks;
                  setResultData(res.data);
                } else {
                  toast.success(res.message);
                  router.replace(`/projects`);
                }
              })
              .catch((err) => {
                const msg = err?.message
                  ? err.message
                  : err?.data?.message
                  ? err?.data?.message
                  : "";
                toast.error(msg);
              })
              .finally(() => {
                const updatedButtons = setIsLoadingButton(
                  isSaveDraft ? "Draft" : "Save",
                  false
                );
                setFormSubmitSettings((prevState) => {
                  return {
                    ...prevState,
                    buttons: updatedButtons,
                  };
                });
              });
          } else if (
            location?.pathname?.includes(
              "/allocated-project-form/form-detail"
            ) 
          ) {
            const url = `${allApiUrl?.SUBMIT_PROJECT_ALLOCATED_FORM}/${jsonData?.allocated_project_form_id}/${jsonData?.project_form_id}`;
            apiMethod(url, data, null, null, token)
              .then((res) => {
                if (res && res.data && data.form_settings.isTrainingForm) {
                  setOpenResultModal(true);
                  res.data.obtainedMarks = data.obtainedMarks;
                  setResultData(res.data);
                } else {
                  toast.success(res.message);
                }
              })
              .catch((err) => {
                const msg = err?.message
                  ? err.message
                  : err?.data?.message
                  ? err?.data?.message
                  : "";
                toast.error(msg);
              })
              .finally(() => {
                const updatedButtons = setIsLoadingButton(
                  isSaveDraft ? "Draft" : "Save",
                  false
                );
                setFormSubmitSettings((prevState) => {
                  return {
                    ...prevState,
                    buttons: updatedButtons,
                  };
                });
              });
          } else {
            let urlData =
              shareable_auth_token?.length > 0
                ? `${allApiUrl?.PUBLIC_SUBMIT_TRAINING_ALLOCATED}/${id}?shareable_token=${shareable_auth_token}`
                : `${allApiUrl?.SUBMIT_TRANINING_ALLOCATED}/${id}`;
            const url =
              location?.pathname?.includes("/allocated-training/form-detail") ||
              location?.pathname?.includes("/training-certificate/view-detail")
                ? urlData
                : !isRetakePage
                ? allApiUrl.ENTRY_FORM
                : `${allApiUrl.ENTRY_FORM}/${id}`;
            apiMethod(url, data, null, null, tokenData)
              .then((res) => {
                if (res && res.data && data.form_settings.isTrainingForm) {
                  setOpenResultModal(true);
                  res.data.obtainedMarks = data.obtainedMarks;
                  setResultData(res.data);
                } else {
                  toast.success(res.message);
                  if (location?.pathname?.includes("/allocated-training/form-detail") || location?.pathname?.includes("/allocated-training/retake-form")) {
                    router.replace(`/allocated-training`);
                  }
                  if (location?.pathname?.includes("/training-catalogue")) {
                    router.replace(
                      id ? `/training-catalogue/saved-entries/${id}` : `/forms`
                    );
                  }else if(isRetakePage && location?.pathname?.includes("/allocated-training/retake-form")){
                    router.replace("/allocated-training")
                  } 
                  else if (isRetakePage) {
                    router.replace(`/my-training`);
                  } else {
                    if (id) {
                      navigate(`/forms/saved-entries/${id}`, {
                        state: { delete: true },
                      });
                    } else {
                      navigate(`/forms`);
                    }
                  }
                }
              })
              .catch((err) => {
                const msg = err?.message
                  ? err.message
                  : err?.data?.message
                  ? err?.data?.message
                  : "";
                toast.error(msg);
              })
              .finally(() => {
                const updatedButtons = setIsLoadingButton(
                  isSaveDraft ? "Draft" : "Save",
                  false
                );
                setFormSubmitSettings((prevState) => {
                  return {
                    ...prevState,
                    buttons: updatedButtons,
                  };
                });
              });
          }
        }
      } else {
        toast.error("Please fill all the required fields.");
      }
    }, 500);
  };

  const onCloseResultModal = () => {
    setOpenResultModal(false);
    if (location?.pathname?.includes("allocated-training")) {
      router.replace("/allocated-training");
    } else if (location?.pathname?.includes("/allocated-project-form")) {
      router.replace("/allocated-project-form");
    } else if (
      isEntryPage ||
      location?.pathname?.includes("/training-catalogue")
    ) {
      router.replace(`/training-catalogue/saved-entries/${id}`);
    }else if(isRetakePage && location?.pathname?.includes("/allocated-training/retake-form")){
      router.replace("/allocated-training")
    }
    else if (isRetakePage || location?.pathname?.includes("/retake-form")) {
      router.replace(`/my-training`, { state: { delete: false } });
    } else {
      router.replace(`/forms/saved-entries/${id}`, {
        state: { delete: false },
      });
    }
  };

  const TagHtmlRow = (data, index, activePageIndex = 0) => {
    return (
      <div className="row" key={`formCompoChild__${index}`}>
        {data.childComponent.map((row, index2) => {
          if (_checkConditionalShowHideEl(row))
            return (
              <div
                className={`hidden-div col-lg-${row.width ? row.width : 6}`}
                key={`choice2__${row.id}_${index}`}
              ></div>
            );
          let Tag = formHtml[row.element];
          return (
            <div
              className={`col-lg-${row.width ? row.width : 6}`}
              key={`choice2__${row.id}_${index}`}
            >
              <div className="form-group">
                <Tag
                  data={row}
                  onChangeFields={(value) => {
                    setForm((prevData) => {
                      return {
                        ...prevData,
                        [row.id]: value,
                      };
                    });
                  }}
                  formData={form?.[row.id] || {}}
                  form={form}
                  setForm={setForm}
                  isTrainingForm={form_settings?.isTrainingForm}
                  isSubmitClicked={isSubmitClicked}
                  isEntryPage={isEntryPage || isRetakePage}
                  isRetakePage={isRetakePage}
                  isDraftSaved={_.some(
                    [
                      "/projects/form-edit-detail",
                      "/allocated-project-form",
                      "/forms/retake-form",
                      "/allocated-training/retake-form"
                    ],
                    (path) => location.pathname.startsWith(path)
                  )}
                  showSubmitButton={showSubmitButton}
                  onSetCompoNewData={(val) => {
                    try {
                      if (!isEmpty(val)) {
                        if (!isEmpty(previewWidgetsData)) {
                          previewWidgetsData[index].childComponent[
                            index2
                          ].childComponent = val;
                          setPreviewWidgetsData(deepClone(previewWidgetsData));
                        } else if (!isEmpty(splitForm.length)) {
                          splitForm[activePageIndex].structure[
                            index
                          ].childComponent[index2].childComponent = val;
                          setSplitForm(deepClone(splitForm));
                        }
                      }
                    } catch (e) {}
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const [totalMarks] = useState(calculateTotalMarks(WidgetsData));
  const [obtainedMarks] = useState(calculateTotalMarks(WidgetsData, false));

  const showExportButton =
    location?.pathname?.includes("/training-catalogue/saved-entries") ||
    location?.pathname?.includes("/forms/view-entry") ||
    location?.pathname?.includes("/projects/form-detail") ||
    location?.pathname?.includes("/forms/saved-entries") ||
    location?.pathname?.includes("/training-attempts/form-details") ||
    location?.pathname?.includes("/training/view-entry");
  return (
    <div className="saveModalBody previewModalBody">
      <div className="entryLoader">{loader && <PageLoader />}</div>

      {!showSubmitButton &&
        (form_settings?.isTrainingForm || jsonData?.usertrainings_id) && (
          <div className={showExportButton ? "float-start" : ""}>
            <h6 className="mb-0">Total Marks: {totalMarks || 0}</h6>
            <h6>Achieved Marks: {obtainedMarks || 0}</h6>
          </div>
        )}

      {showExportButton && (
        <DownloadPdf
          jsonData={jsonData}
          setFormSettings={setFormSettings}
          settings={settings}
          setFormSubmitSettings={setFormSubmitSettings}
          submit_settings={submit_settings}
          WidgetsData={splitForm.length ? splitForm : WidgetsData}
          TagHtmlRow={TagHtmlRow}
          HeaderTitleHtml={HeaderTitleHtml}
          form_settings={form_settings}
          setLoader={setLoader}
        />
      )}
      <div className="previewInnerBody p-3">
        <div className="px-2" id="previewModal">
          {openResultModal && (
            <EntryResultModal
              openModal={openResultModal}
              setOpenModal={setOpenResultModal}
              resultData={resultData}
              onCloseResultModal={onCloseResultModal}
            />
          )}
          {isJsonData ? (
            <HeaderTitleHtml
              activePage={activePageIndex}
              form_settings={form_settings}
              hideIcons={true}
              showPageNo={Boolean(
                splitForm[activePageIndex]?.showHidePageCount
              )}
            />
          ) : (
            <HeaderPreview
              hideIcons={true}
              activePage={activePageIndex}
              setSelectedWidget={setSelectedWidget}
              selectedWidgets={selectedWidgets}
              setFormSettings={setFormSettingsProp}
              form_settings={formSettingsProp}
              setIsOpen={setIsOpen}
              showPageNo={Boolean(
                splitForm[activePageIndex]?.showHidePageCount
              )}
            />
          )}

          <form ref={formRef} onSubmit={handleSubmitForm} noValidate>
            {!isEmpty(previewWidgetsData) && (
              <>
                {previewWidgetsData.map((data, index) => {
                  return TagHtmlRow(data, index);
                })}
                {isJsonData ? (
                  <FooterPreview
                    showSubmitButton={showSubmitButton}
                    formSubmitSettings={formSubmitSettings}
                    trainingParams={trainingParams}
                    onClickFormButton={onClickFormButton}
                  />
                ) : (
                  <SubmitForm
                    showSubmitButton={showSubmitButton}
                    hideIcons={true}
                    onClickFormButton={onClickFormButton}
                  />
                )}
              </>
            )}
            {!isEmpty(splitForm.length) && (
              <>
                {splitForm[activePageIndex].structure?.map((data, index) => {
                  return TagHtmlRow(data, index, activePageIndex);
                })}
                {activePageIndex === splitForm.length - 1 ? (
                  isJsonData ? (
                    <FooterPreview
                      showSubmitButton={showSubmitButton}
                      formSubmitSettings={formSubmitSettings}
                      trainingParams={trainingParams}
                      onClickFormButton={onClickFormButton}
                    />
                  ) : (
                    <SubmitForm
                      showSubmitButton={showSubmitButton}
                      hideIcons={true}
                      onClickFormButton={onClickFormButton}
                    />
                  )
                ) : (
                  <div className="pagebreak text-start my-3">
                    <PageBreakButtonsHtml
                      buttonsArray={splitForm[activePageIndex].buttons}
                      readOnly={false}
                      setActivePageIndex={setActivePageIndex}
                      activePageIndex={activePageIndex}
                      formRef={formRef}
                      setIsSubmitClicked={setIsSubmitClicked}
                      splitForm={splitForm}
                      form={form}
                      obtainedMarksProp={obtainedMarksProp}
                      setObtainedMarksProp={setObtainedMarksProp}
                    />
                    <div className="pagesplit-top"></div>
                  </div>
                )}
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
export default PreviewFormCompo;
