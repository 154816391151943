import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import DashboardLayout from "src/layouts/dashboard";
import { useSelector } from "react-redux";
import { ROLES } from "src/core/constants";

const Layout = () => {
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  return (
    <>
      {loginUserInfo?.id &&
      loginUserInfo?.roleid?.find((role) =>
        Object.values(ROLES)?.includes(role)
      ) ? (
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default Layout;
