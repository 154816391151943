import {useEffect, useState} from "react";
import {replaceLabelStrings} from "src/shared/functions/string-conversion";
import LabelCompo from "./LabelCompo";
import {FormControl, FormHelperText} from "@mui/material";
import * as React from "react";
import {isEmpty} from "src/shared/functions/isEmpty";
import SelectAsync from "../shared/SelectAsync";

export const LookupDropDownCompo = ({ isReadOnly = true, data, onChangeFields, form, isTrainingForm = false, isSubmitClicked = false, isEntryPage = false, isRetakePage = false, isDraftSaved = false }) => {
  const [fieldChangedCount, setFieldChangeCount] = useState(0)
  const [labelConv, setLabelConv] = useState(
    isReadOnly ? data.label : replaceLabelStrings(data?.label, form)
  );

  const [selectedValues, setSelectedValues] = useState(((isEntryPage && data.userInputVal) ? (typeof data.userInputVal === "object" ? data.userInputVal : !isEmpty(JSON.parse(data.userInputVal)) ? JSON.parse(data.userInputVal) : []) : data.value) || []);
  useEffect(() => {
    if (isReadOnly) {
      setSelectedValues(data.value)
    }
  }, [data.value]);
  useEffect(() => {
    setLabelConv(isReadOnly ? data.label : replaceLabelStrings(data?.label, form));
  }, [data.label, form]);

  useEffect(() => {
    onChangeFields(selectedValues)
  }, [selectedValues]);

  return (
    <div key={data.id} id={data.id}>

      <input hidden name={`field_name_${data.id}`} id={`field_id_${data.id}`} value={JSON.stringify(selectedValues)} readOnly/>
      <FormControl required={data.required} fullWidth error={Boolean(!isReadOnly && data.required && isSubmitClicked && !(selectedValues && selectedValues.length))}>
        <LabelCompo data={data} labelConv={labelConv}/>
        <SelectAsync
          fullWidth
          searchType={data?.optionType === "user" ? "userListPublic" : "projectListPublic"}
          isDisabled={fieldChangedCount> 0 || ((!isDraftSaved && !data.showRetakeField) && (isReadOnly || Boolean(isTrainingForm && selectedValues.length) || (isEntryPage && !isEmpty(selectedValues))))}
          selectedValue={selectedValues}
          onChange={(e) => {
            if (!isReadOnly) {
              if (isRetakePage) {
                setFieldChangeCount(prevState => { return prevState+1 })
              }
              setSelectedValues(e ? [e] : [])
            }
          }}
          />
        <FormHelperText>{Boolean(!isReadOnly && data.required && isSubmitClicked && !(selectedValues && selectedValues.length)) && "This Field is required!"}</FormHelperText>
      </FormControl>
    </div>
  );
};