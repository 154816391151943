import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { SignupView } from "src/sections/signup";
import CompanyRegisterView from "src/sections/signup/company-signup";

export default function LoginPage() {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <title>{`Sign up | ${process.env.REACT_APP_APPLICATION_NAME}`} </title>
      </Helmet>
      {location?.pathname == "/register" ? (
        <SignupView />
      ) : (
        <CompanyRegisterView />
      )}
    </>
  );
}
