import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import { apiDeleteMethod, apiPutMethod } from "src/api/rest";
import { PermissionCheck } from "src/utils/common";
import ConfirmModal from "src/components/ConfirmModal";
import { useSelector } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import { allApiUrl } from "src/api/apiRoute";

import { formatDateFn } from "src/shared/functions/string-conversion";
import { Android12Switch } from "src/components/mui-common/switch-ui";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import {BootstrapTooltipUi} from "src/components/mui-common/BootstrapToolTip";
import {
  StyledTableCell,
  StyledTableRow,
} from "src/components/mui-common/table-ui/TableHeadRow";

export default function UserTableRow({
  selected,
  name,
  created_at,
  industryid,
  status,
  handleClick,
  apiCall,
  open,
  setOpen
}) {
  let navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checked, setChecked] = useState(status);
  const [checkStatus, setCheckedStatus] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    setCheckedStatus(true);
  };
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const deleteData = () => {
    apiDeleteMethod(`/industry/delete-industry/${industryid}`)
      .then((data) => {
        setIsModalOpen(false);
        toast.success(data?.message);
        setOpen(null);
        apiCall();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.data?.message);
      });
  };
  const editIndustry = async () => {
    navigate(`/industry/edit-industry/${industryid}`);
  };
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const editStatus = () => {
    let obj = {
      is_active: checked,
    };

    apiPutMethod(`${allApiUrl?.EDIT_INDUSTRY}/${industryid}`, obj)
      .then((data) => {
        toast.success("Status successfully updated.");
      })
      .catch((err) => {
        toast.error(err?.data.message);
      });
  };
  useEffect(() => {
    if (checkStatus === true) {
      editStatus();
    }
  }, [checked, checkStatus]);

  return (
    <>
      <StyledTableRow
        tabIndex={-1}
        role="checkbox"
        className="tableRow"
      >
        {PermissionCheck("Industries", "delete", loginUserInfo) && (
          <StyledTableCell padding="checkbox">
            <Checkbox
              disableRipple
              checked={selected}
              onChange={handleClick}
            />
          </StyledTableCell>
        )}

        <StyledTableCell sx={{fontWeight:"700"}}>
          {name}
        </StyledTableCell>

        <StyledTableCell>{created_at ? formatDateFn(created_at) : "-"}</StyledTableCell>
        <StyledTableCell>
          <FormControlLabel
            control={
              <Android12Switch
                color="success"
                checked={Boolean(checked)}
                onChange={handleChange}
              />
            }
            label={checked ? "Active" : "In Active"}
          />
        </StyledTableCell>
        <StyledTableCell>

        {PermissionCheck("Industries", "update", loginUserInfo) && (
            <BootstrapTooltipUi title="Edit" placement="top">
              <IconButton className="outerborder"
                aria-label="Edit"
                onClick={() => editIndustry(industryid)}
              >
                <DriveFileRenameOutlineIcon />
              </IconButton>
            </BootstrapTooltipUi>
        )}

        {PermissionCheck("Industries", "delete", loginUserInfo) && (
          <BootstrapTooltipUi title="Delete" placement="top">
            <IconButton  className="outerborder"
              aria-label="Delete"
              onClick={() => setIsModalOpen(true)}
            sx={{ color: "error.main" }}
            >
              <DeleteIcon/>
            </IconButton>
          </BootstrapTooltipUi>
        )}


            
        </StyledTableCell>
      </StyledTableRow>
      <ConfirmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        deleteData={deleteData}
        setOpen={setOpen}
        content="The deletion of an industry would trigger a chain reaction, resulting in the removal of all related companies and substantial economic upheaval."
      />
    </>
  );
}

UserTableRow.propTypes = {
  handleClick: PropTypes.func,
  industryid: PropTypes.any,
  name: PropTypes.any,
  created_at: PropTypes.any,
  selected: PropTypes.any,
  status: PropTypes.any,
};
