import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import AlertDialog from "../shared/AlertDialog";
import { apiDeleteMethod, apiGetMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import { handleCheckboxClick, isSelected } from "./functions";
import { isEmpty } from "src/shared/functions/isEmpty";
import Checkbox from "@mui/material/Checkbox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLocation } from "react-router-dom";
import {Chip} from "@mui/material";
import {_notCompanyUser, addSpace, formatDateFn} from "src/shared/functions/string-conversion";
import capitalize from "@mui/utils/capitalize";
import { useSelector } from "react-redux";
import {
  FORM_CATALOGUE_TABS,
  PAGINATION_OBJECT,
  TRAINING_STATUS
} from "src/core/constants";
import { toast } from "react-toastify";
import SavedEntriesDrawer from "../Modals/SavedEntriesDrawer";
import {_setPaginationObjectFn, createQueryString, extractLabelAndUserInputVal} from "src/shared/functions";
import PageHeader from "src/components-form/Common/PageHeader";
import Card from "@mui/material/Card";
import {BootstrapTooltipUi} from "src/components/mui-common/BootstrapToolTip";
import * as _ from "lodash";
import {LoadingButton} from "@mui/lab";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {removeItemByKeyValue} from "src/shared/functions/array-conversion";
import {_handleChangePage, handleCSVExport} from "src/utils/common";
import Scrollbar from "src/components/scrollbar";
import TableHeadRow, {
  StyledTableCell,
  StyledTableNoData,
  StyledTableRow
} from "src/components/mui-common/table-ui/TableHeadRow";
import TableToolbar from "src/components/mui-common/table-ui/TableToolbar";
import TableRowsLoader from "src/components/mui-common/table-ui/TableRowsLoader";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import {headCellsSavedEntries} from "src/components/mui-common/table-ui/headCells";

export default function SavedEntriesTable({ formId = "", state }) {
  const location = useLocation();
  const loginUserInfo = useSelector((state) => state.loginReducer.loginUserInfo);

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token") || "";

  const [selected, setSelected] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState({ id: null, title: "" });
  const [openDrawer, setOpenDrawer] = useState({});
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [exportLoader, setExportLoader] = useState(false);

  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentTab, setCurrentTab] = useState(FORM_CATALOGUE_TABS.FORM);

  useEffect(() => {
    if (paginationObject.triggerApi) {
      fetchList();
    }
  }, [paginationObject.triggerApi]);
  useEffect(() => {
    try {
      _handleChangePage({}, 0, setPaginationObject,setSelected)
    } catch (e) {}
  }, [searchTerm])


  const handleClick = (event, id) => {
    setSelected(handleCheckboxClick(event, id, selected));
  };

  function fetchList() {
    setIsLoading(true);
    const queryObject = {
      search: searchTerm || "",
      formId: formId,
      pageNumber: paginationObject.page,
      limit: paginationObject.rowsPerPage
    }
    const qryStr = createQueryString(queryObject);

    let url;
    if (_notCompanyUser(loginUserInfo)) {
      url = `${allApiUrl.ENTRY_FORM}${qryStr}`;
    } else {
      url = `${allApiUrl.USER_ENTRY_FORM}${qryStr}`;
    }
    if (url) {
      apiGetMethod(url, null, token)
        .then((res) => {
          const tempRows = [];
          let tempDC = [];
          res.data.map((v, idx) => {
            const title = v.title || v.form_settings.title.label;
            const user_name = v?.user_name;
            const status = !isEmpty(v.usertrainingData)
              ? capitalize(addSpace(v.usertrainingData?.usertraining_status))
              : "N/A";

            const updatedAt = formatDateFn(v.updated_at);
            const createdAt = formatDateFn(v.created_at);
            const otherData = extractLabelAndUserInputVal(v.structure);
            const obj = {
              id: v.id,
              title,
              status,
              created_at: createdAt,
              updated_at: updatedAt,
              user_name,
            };

            const dc = otherData.map(v => {
              obj[v.id] = v;
              return {
                id: v.id,
                numeric: false,
                disablePadding: false,
                label: v.label,
                align: "left",
              }
            })
            tempRows.push(obj);

            tempDC = _.concat(tempDC, dc);
          });
          setDynamicColumns(_.uniqBy(tempDC, "id"));
          setRows([...tempRows]);

          setPaginationObject(prevState => {
            return _setPaginationObjectFn(prevState, res.meta);
          })

        })
        .catch(() => {
          setDynamicColumns([...[]]);
          setRows([...[]]);
          setPaginationObject(PAGINATION_OBJECT)
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }
  function removeForm(id) {
    apiDeleteMethod(`${allApiUrl.ENTRY_FORM}/${id}`, null, token)
      .then((response) => {
        setSelected([...[]]);
        fetchList();
        toast.success(response.message);
      })
      .catch((e) => {
        toast.error(e.data.message);
      });
  }
 

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("currentTab");
    if (tab) {
      setCurrentTab(tab);
    }
  }, [location]);

  const getCells = (dynamicColumns) => {
    const tempHeadCells = currentTab === FORM_CATALOGUE_TABS.FORM ?
      removeItemByKeyValue(headCellsSavedEntries, ["status", "user_name"]) : headCellsSavedEntries;

    return _notCompanyUser(loginUserInfo) ? [
      ...tempHeadCells,
      ...dynamicColumns
    ] : [
      ...removeItemByKeyValue(tempHeadCells, ["user_name"]),
      ...dynamicColumns
    ]
  }

  const handleCSVExportClick = () => {
    setExportLoader(true);

    const tempColumns = [...getCells(dynamicColumns).filter(v => (!_.includes(["checkbox", "actions"], v.id)))];
    const opts = {
      fields: tempColumns.map(v => {
        return {
          label: v.label,
          value: v.id,
          default: "N/A",
        }
      })
    };

    const tempRows = [...rows];
    const dataToExport = tempRows?.map((item) => {
      Object.keys(item).map((key) => {
        if (typeof item[key] === "object") {
          item[key] = item[key]?.userInputVal;
        }
      });

      return {
        ...item,
        status: (item.status.toUpperCase() === TRAINING_STATUS.FAILED ? "FAIL" : "PASS"),
        createdAt: formatDateFn(item.created_at),
        updatedAt: formatDateFn(item.updated_at)
      };
    });

    handleCSVExport(dataToExport, opts, "SavedEntriesList");
    setExportLoader(false);
  };

  return (
    <>
      <div className="flexTop">
        <PageHeader
          header={`Saved Entries`}
          links={[
            {
              name: "Dashboard",
              path: "/",
            },
            {
              name: "Forms Catalogue",
              path: "/forms",
            },
          ]}
        />
        <div>
          <LoadingButton
            variant="contained"
            color="inherit"
            loading={exportLoader}
            startIcon={<FileUploadIcon />}
            onClick={handleCSVExportClick}
            className="blueButton ms-2"
          >
            Export Entries
          </LoadingButton>
        </div>
      </div>
      <AlertDialog
        title={selectedForm.title}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        handleDelete={() => {
          removeForm(selectedForm.id);
          setOpenDeleteModal(false);
        }}
      />
      <SavedEntriesDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
      />
      <Card className="cardFix">

        <TableToolbar
          title={`Saved Entries`}
          numSelected={selected.length}
          onSearch={(query) => setSearchTerm(query)}
          deleteUrl={allApiUrl.REMOVE_MULTI_ENTRY_FORM}
          selected={selected}
          setSelected={setSelected}
          apiCall={fetchList}
        />
        <Scrollbar className="cardFix entries">
          <TableContainer className="tableResponsive">
            <Table
              sx={{minWidth: 750}}
              size="medium"
            >
              <TableHeadRow
                numSelected={selected.length}
                rowCount={rows.length}
                headCells={getCells(dynamicColumns)}
                permissionType="Forms"
                listData={rows}
                setSelected={setSelected}
              />
              <TableBody>
                {isLoading ? (
                  <TableRowsLoader
                    rowsNum={10}
                    columnNum={getCells(dynamicColumns).length}
                  />
                ) : isEmpty(rows) ? (
                  <StyledTableNoData
                    colSpan={getCells(dynamicColumns).length}
                  />
                ) : (
                  <>
                    {rows.map((row, index) => {
                      const isItemSelected = isSelected(row.id, selected);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <StyledTableRow
                          // hover
                          role="checkbox"
                          tabIndex={-1}
                          key={`styledTableRow_${index}`}
                        >
                          {getCells(dynamicColumns).map((hCell) =>
                            <StyledTableCell padding={(hCell.id === "checkbox") ? "checkbox" : "normal"} key={`tableCell_${hCell.id}`}>
                              {(hCell.id === "checkbox") ? (
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                  onClick={(event) =>
                                    handleClick(event, row.id)
                                  }
                                />
                              ) : (hCell.id === "status") ? (
                                <>
                                  {row?.status.toUpperCase() === TRAINING_STATUS.FAILED ? (
                                    <Chip label="FAIL" color="error"/>
                                  ) : (
                                    <Chip label="PASS" color="success"/>
                                  )}
                                </>
                              ) : (hCell.id === "actions") ? (
                                <><BootstrapTooltipUi title="Preview" placement="bottom">
                                  <IconButton
                                    aria-label="Preview"
                                    className="outerborder mb-1"
                                    onClick={() => {
                                      (state != null && state?.delete !== true) ||
                                      _notCompanyUser(loginUserInfo)
                                        ? setOpenDrawer({
                                          id: row.id,
                                          type: "SavedEntries",
                                          state,
                                          pathName: "/forms/view-entry"
                                        })
                                        : setOpenDrawer({
                                          id: row.id,
                                          type: "SavedEntries",
                                          state,
                                          pathName: "/training-catalogue/view-entry"
                                        })
                                    }}
                                  >
                                    <VisibilityIcon/>
                                  </IconButton>
                                </BootstrapTooltipUi>
                                  {((state != null && state?.delete !== true) || _notCompanyUser(loginUserInfo)) && (
                                    <BootstrapTooltipUi title="Delete" placement="bottom">
                                      <IconButton
                                        className="outerborder mb-1"
                                        aria-label="Delete"
                                        onClick={() => {
                                          setSelectedForm({
                                            id: row.id,
                                            title: row.title,
                                          });
                                          setOpenDeleteModal(true);
                                        }}
                                      >
                                        <DeleteIcon/>
                                      </IconButton>
                                    </BootstrapTooltipUi>
                                  )}
                                </>
                              ) : (!isEmpty(row[hCell.id]) && typeof row[hCell.id] === "object") ? (
                                <div
                                  dangerouslySetInnerHTML={{__html: row[hCell.id]?.userInputVal}}
                                />
                              ) : (
                                <>{row[hCell.id]}</>
                              )}
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePaginationCompo
            paginationObject={paginationObject}
            setSelected = {setSelected}
            setPaginationObject={setPaginationObject}
          />
        </Scrollbar>
      </Card>
    </>
  );
}
