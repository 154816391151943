export const allApiUrl = {
  USER_LOG_IN: "/auth/login",
  JSA_FORMS: "/userForm/jsa-forms",
  JSA_CODE: "/userForm/jsa-code",
  COMPANY_JSA_CODE: "/form/company-jsa-code",
  ENTRY_FORM: "/userForm/entry-form",
  USER_ENTRY_FORM: "/form/training-catalogue-entry-forms",
  FORM_CATEGORIES: "/category/category-list",
  REMOVE_MULTI_JSA_FORMS: "/userForm/multi-jsa-forms",
  REMOVE_COMPANY_MULTI_JSA_FORM: "/form/company-multi-jsa-forms",
  REMOVE_MULTI_JSA_CODE: "/userForm/multi-jsa-code",
  REMOVE_MULTI_ENTRY_FORM: "/userForm/multi-entry-form",
  CLONE_JSA_FORMS: "/userForm/clone-form",
  // CLONE_JSA_CODE: "/userForm/clone-jsa-code",
  ADMIN_REGISTER: "/auth/superadmin-user-signup",
  COMPANY_REGISTER: "/auth/company-user-signup",
  USER_FORGOT_PASSWORD: "/auth/forgot-password",
  USER_RESET_PASSWORD: "/auth/reset-password",
  USERS_LIST: "/auth/users-list",
  COMPANY_USER_LIST: "/auth/company-users-list",
  USER_PROFILE_DETAIL: "/auth/user-profile",
  COMPANY_USER_PROFILE_DETAILS: "/auth/company-user-profile",
  USER_PROFILE_EDIT: "/auth/edit-user-profile",
  USER_PROFILE_DELETE: "/auth/deleteUser",
  USER_ROLE_LIST: "/auth/login",
  // TRAINING_LIST_ONLY_NAME: "/trainings/trainingListOnlyName",
  TRAINING_LIST: "/trainings/trainingList",
  COMPANY_TRAINING_LIST: "/trainings/company-training-list",
  MULTIPLE_TRAINING_LIST: "/trainings/deleteMultiTraining",
  DELETE_TRAINING: "/trainings/deleteTraining",
  INDUSTRY_LIST: "/industry/industry-list",
  ADD_TRAINING: "/trainings/addNewTraining",
  GET_TRAINING: "/trainings/getTrainingById",
  EDIT_TRAINING: "/trainings/editTraining",
  DELETE_MULTIPLE_FORM: "/form/deleteMultiUserForms",
  COMPANY_LISTING: "/company/companyListing",
  DELETE_MULTIPLE_COMPANY: "/company/deleteMultiCompany",
  ADD_COMPANY: "/company/companySignup",
  EDIT_COMPANY: "/company/editCompanyProfile",
  GET_COMPANY_BYID: "/company/companyProfile",
  DELETE_COMPANY: "/company/delteCompany",
  DELETE_MULTIPLE_INDUSTRY: "/industry/deleteMultiIndustry",
  ADD_INDUSTRY: "/industry/add-industry",
  GET_INDUSTRY_BYID: "/industry/industry-details",
  EDIT_INDUSTRY: "/industry/edit-industry-details",
  USER_LIST: "/auth/users-list",
  DELETE_MULTIPLE_USER: "/auth/deleteMultipleUsers",
  GET_ROLE_LIST: "/auth/getRolesList",
  TRANING_CERTIFICATE_LIST: "/trainings/userTrainingList",
  ADD_USER_TRAINING: "/trainings/addUserTraining",
  EDIT_USER_TRAINING: "/trainings/editUserTraining",
  DELETE_TRAINING_BYID: "/trainings/deleteUserTraining",
  DELETE_MULTIPLE_CERTIFICATE: "/trainings/deleteMultiUserTraining",
  GET_CERTIFICATE_BYID: "/trainings/getUserTrainingById",
  GET_USER_FORM: "/form/userForms",
  // ADD_TRAINING_ALLOCATION: "/trainings/addTrainingAllocation",
  COMPANY_GET_TRAINING_ALLOCATION: "/trainings/company-trainingallocation",
  COMPANY_PUT_TRAINING_ALLOCATION:
    "/trainings/manage-company-allocated-training",
  // GETBYID_TRAINING_ALLOCATION: "/trainings/getTrainingAdminById",
  // EDIT_TRAINING_ALLOCATION: "/trainings/editTrainingAllocation",
  // DELETE_TRAINING_ALLOCATION: "trainings/deleteTrainingAllocation",
  DELETEMULTIPLE_TRAINING_ALLOCATION: "trainings/deleteMultiTrainingAllocation",
  EMAIL_DETAILS: "/auth/email-details",
  ADMIN_DASHBOARD: "/auth/admin-dashboard",
  COMPANY_DASHBOARD: "/auth/company-dashboard",
  USER_DASHBOARD: "/auth/company-user-dashboard",
  MY_TRAINING: "/trainings/getMyTrainings",
  CHANGE_PASSWORD: "/auth/change-password",
  MY_CERTIFICATE: "/trainings/getMyCertficates",
  UPDATE_USER_PROFILE: "/auth/updateProfile",
  GET_USER_PROFILE: "/auth/getProfileData",
  FILTERED_FORM: "/userForm/filtered-formlist",
  FILTERED_COMPANY_FORM: "/form/company-filtered-formlist",
  FILTERED_TRAINING: "/trainings/filtered-traininiglist",
  FILTERED_FORMLISTING: "/form/get-company-formslist",
  COMPANY_CLONE_JSA_FORM: "/form/clone-company-form",
  COMPANY_ADMIN_CLONE_TEMPLATE: "/form/clone-template-company-form",
  COMPANY_ADMIN_CLONE_JSA_FORM: "/form/clone-company-form",
  VIEW_COMPANY_FORM_LIST: "/form/get-company-formlist",
  VIEW_COMPANY_JSA_LIST: "/form/company-jsa-code",
  UPDATE_COMPANY_LIST: "/form/update-company-form",
  UPDATE_PUBLISHED_ACTION: "/form/set-company-form-status",
  CREATE_COMPANY_FILTER_FORM: "/form/create-company-forms",
  PUBLIC_COMPANY_SIGNUP: "/company/public-company-listing",
  USER_TRAINING_CATALOGUE: "/form/get-training-catalogue-list",
  COMPANY_TRAINING_CERTIFICATE: "/trainings/company-user-traininglist",
  COMPANY_CATALOGUE_START: "/form/get-training-catalogue",
  ADMIN_CATEGORY: "/category/category-list",
  DELETE_ADMIN_CATEGORY: "/category/delete-category",
  ADD_ADMIN_CATEGORY: "/category/add-category",
  UPDATE_ADMIN_CATEGORY: "/category/update-category",
  COMPANY_ADD_TRAINING: "/trainings/add-company-training",
  COMPANY_EDIT_TRAINING: "/trainings/edit-company-training",
  COMPANY_DELETE_TRAINING: "/trainings/delete-company-training",
  COMPANY_DELETE_MULTIPLE_TRAINING: "/trainings/delete-company-multi-training",
  CLONE_COMPANY_TRAINING: "/trainings/clone-company-training",
  CLONE_SUPER_ADMIN_CLONE: "/trainings/clone-training",
  PAYMENT_STATUS: "/payment/session_status",
  COMPANY_FILTERD_TRAININGLIST: "/trainings/company-filtered-traininiglist",
  DELETE_COMPANY_FORM: "/form/company-jsa-forms",
  SUPER_ADMIN_TRAINING_LIST: "/trainings/all-company-training-list",
  SUPER_ADMIN_EDIT_REGISTER: "/auth/edit-user-profile",
  COMPANY_EDIT_REGISTER: "/auth/edit-company-user-profile",
  STRIPE_INTEGRATION: "/payment/stripe-subscription-details",
  GET_TRAINING_ALLOCATED: "/trainings/company-allocated-trainings",
  TRAINING_ALLOCATED_START: "/trainings/company-allocated-trainings",
  SUBMIT_TRANINING_ALLOCATED: "/trainings/submit-company-allocated-training",
  PUBLIC_SUBMIT_TRAINING_ALLOCATED:
    "/trainings/public-submit-allocated-training",
  GET_FILE: "/trainings/get-file-signed-url",
  GET_PROJECT_LIST: "/project/project-list",
  CREATE_PROJECT: "/project/create-project",
  ASSIGN_PROJECT_FORM: "/project/assign-project-form",
  PROJECT_FORM: "/project/project-forms",
  PROJECT_CREWS_MEMBER: "/project/project-crew-members",
  PROJECT_REQUIRED_TRAINING: "/project/project-required-trainings",
  COMPANY_PROJECT_REQUIRED_TRAINING:
    "/project/company-user-project-required-trainings",
  DELETE_PROJECT: "/project/delete-project",
  GET_PROJECT_ATTACHMENT: "/project/project-attachments",
  EDIT_PROJECT: "/project/edit-project",
  POST_ATTACHMENT_PROJECT: "/project/upload-attachments",
  DELETE_ATTACHMENT: "/project/delete-project-attachment",
  DELETE_CREW_MEMBER: "/project/delete-project-crew-member",
  MULTIPLE_DELETE_CREW_MEMBER:"/project/multi-delete-project-crew-member",
  DELETE_PROJECT_FORM: "/project/delete-project-form",
  WEATHER_API: "https://api.openweathermap.org/data/2.5/weather",
  CLONE_PROJECT_FORM: "/project/clone-project-form",
  ADD_CREW: "/project/add-crew-member",
  MULTIPLE_TRAINING_ASSIGNED: "/trainings/multi-company-trainingallocation",
  PROJECT_FORM_ALLOCATION: "/project/project-form-allocation",
  ALLOCATED_PROJECT_FORM: "/project/allocated-project-forms",
  USER_ALLOCATER_PROJECT_FORM: "/project/allocated-project-form",
  SUBMIT_PROJECT_ALLOCATED_FORM: "/project/submit-allocated-project",
  SUBMIT_COMPANY_ADMIN_ALLOCATED_FORM:
    "/project/company-admin-submit-allocated-project",
  VIEW_PROJECT_FORM_ENTRY: "/project/allocated-project-form-entry",
  PROJECT_USER_FORMS_LIST: "/project/project-user-forms",
  PROJECT_FORM_CREW_DATA: "/project/project-form-crew-data",
  PROJECT_USER_FORM_ENTRY: "/project/project-user-form-entry",
  PUBLIC_ALLOCATED_TRAINING: "/trainings/public-allocated-trainings",
  ADMIN_TRAINING_ALLOCATION: "/trainings/addTrainingAllocation",
  GET_TRAINING_ALLOCATION: "/trainings/getTrainingAllocationList",
  ASSIGN_PROJECT_JSA_FORM: "/project/project-assignable-jsa-form",
  PUBLIC_PROJECT_ALLOCATED_GET: "/project/public-allocated-project-form",
  PUBLIC_SUBMIT_PROJECT_ALLOCATED:
    "/project/public-submit-allocated-project-form",
  PROJECT_FORM_ENTRY: "/project/project-form-entry",
  MULTIPLE_DELETE_CATEGORY: "/category/multi-delete-category",
  PRIVATE_TRAININGS_USER_LIST: "/trainings/user-list",
  PUBLIC_TRAININGS_USER_LIST: "/trainings/public-user-list",
  PRIVATE_PROJECT_PUBLIC_PROJECT_LIST: "/project/project-list-data",
  PUBLIC_PROJECT_PUBLIC_PROJECT_LIST: "/project/public-project-list",
  IMPORT_USER: "/auth/import-user-csv",
  IMPORT_COMPANY_USER: "/auth/company-import-user-csv",
  PROJECT_DELETE_CREW_FORM: "/project/allocated-project-form",
  COMPANY_TRAINING_ATTEMPTS: "/trainings/company-training-attempts",
  ADMIN_TRAINING_ATTEMPTS: "/trainings/admin-training-attempts",
  TRAINING_CATELOGUE_START_TRAINING:
    "/trainings/manage-company-allocated-training",
  SUPER_ADMIN_FoRM_PUBLISHED: "/form/set-admin-form-status",
  TRAINING_TEMPLATES: "/trainings/training-templates",
  CLONE_TRAINING_TEMPLATES: "/trainings/clone-training-template",
  COMPANY_ADMIN_TEMPLATE: "/form/form-templates",
  PUBLIC_JSA_FORM: "/form/public-jsa-form",
  PUBLIC_SUBMIT_JSA_FORM: "/form/public-submit-jsa-form",
  SET_TRAINING_STATUS: "/trainings/set-training-status",
  CREW_MEMBER_TRAINING_ASSIGNED: "/project/assigned-project-trainings",
  COMPANY_USER_DELETE_PROJECT_ALLOCATION:
    "/project/company-user-allocated-project-form",
  EXPORT_USER_LIST: "/auth/export-users-list",
  PROJECT_USER_LISTING: "/project/user-project-list",
  COMPANY_EXPORT_USER_LIST: "/auth/export-company-users-list",
  TRAINING_IMPORT_HISTORY:"/trainings/import-training-certificates-csv",
  CREW_PROJECT_CREW_ID:"/project/assign-project-form-to-user"
};
