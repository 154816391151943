import "./BuildForm.css";
import { useLocation, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { apiGetMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import { useState } from "react";
import { isEmpty } from "src/shared/functions/isEmpty";
import { Skeleton } from "@mui/material";
import PreviewFormCompo from "src/components-form/PreviewFormCompo";
import Box from "@mui/material/Box";
import { COMPANYROLES, ROLES, TRAINING_STATUS } from "src/core/constants";
import { useSelector } from "react-redux";

function ViewEntry({ isEntryPage = false, isRetakePage = false }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token") || "";
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const { state } = useLocation();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [jsonData, setJsonData] = useState({});

  useEffect(() => {
    getFormDetails();
  }, []);
  function getFormDetails() {
    setIsLoading(true);
    if (Object?.values(COMPANYROLES)?.includes(loginUserInfo?.roleid[0])) {
      const url = `${allApiUrl.ENTRY_FORM}/${id}`;
      apiGetMethod(url, null, token)
        .then((response) => {
          setJsonData(response.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      const url =
        isEntryPage || isRetakePage
          ? `${allApiUrl.ENTRY_FORM}/${id}`
          : `${allApiUrl.JSA_FORMS}/${id}`;
      apiGetMethod(url, null, token)
        .then((response) => {
          response.data.form_settings.isTrainingForm = Boolean(
            response.data.trainings_id
          );
          response.data.form_settings.trainingId =
            response.data.trainings_id || "";

          setJsonData(response.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%" }}>
        <div className="deployedFormOuter">
          <div className="container-fluid">
            <div className="deployedForm">
              {isLoading ? (
                <>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={120}
                  />
                  <br />
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={120}
                  />
                  <br />
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={120}
                  />
                  <br />
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={120}
                  />
                </>
              ) : (
                <>
                  {!isEmpty(jsonData) && (
                    <PreviewFormCompo
                      isJsonData
                      isEntryPage={Boolean(
                        isEntryPage ||
                          location.pathname.includes("/view-entry/")
                      )}
                      isRetakePage={Boolean(
                        isRetakePage ||
                          location.pathname.includes("/retake-form/")
                      )}
                      trainingParams={state?.isTraining}
                      jsonData={jsonData}
                      showSubmitButton={Boolean(
                        Object?.values(COMPANYROLES)?.includes(
                          loginUserInfo?.roleid[0]
                        ) &&
                          (isEmpty(jsonData?.usertrainingData) ||
                            (!isEmpty(jsonData?.usertrainingData) &&
                              jsonData?.usertrainingData
                                ?.usertraining_status !==
                                TRAINING_STATUS.PASSED &&
                              jsonData?.usertrainingData
                                ?.usertraining_status !==
                                TRAINING_STATUS.FAILED))
                      )}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
}

export default ViewEntry;
