import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Box,
  Card,
  styled,
  Grid,
  TableBody,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiGetMethod,  apiPutMethod } from "src/api/rest";
import {  useNavigate, useParams } from "react-router-dom";
import * as React from "react";
import useAuth from "src/hooks/useAuth";
import EditModal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import { saveLoginUserInfo } from "src/redux/action/login";
import PageHeader from "src/components-form/Common/PageHeader";
import { Helmet } from "react-helmet-async";

// ------------------------------------------------------------------------------------------------

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
}));

export default function EditRole() {
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const [dataFiltered, setDataFiltered] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isModal, setIsModal] = useState(false);
  const [roleName, setRoleName] = useState("");
  const handleChange = (index, permission) => {
    setDataFiltered((prevData) => {
      prevData[index].permission[permission].check =
        !prevData[index].permission[permission]?.check;
      return [...prevData];
    });
  };
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );


  const handleSubmit = (event) => {
    setLoading(true);
    let object = {
      permissions: dataFiltered,
    };
    apiPutMethod(`/auth/editRole/${id}`, object)
      .then((data) => {
        toast.success("Edit Successfull");
        if (loginUserInfo?.roleid[0] == id) {
          loginUserInfo.roleDetails.permissions = data?.data?.permissions;
          dispatch(saveLoginUserInfo(loginUserInfo));
        }

        setLoading(false);
        navigate("/user-roles");
      })
      .catch((err) => {
        toast.error(err);

        setLoading(false);
      });
  };

  useEffect(() => {
    apiCall();
  }, []);
  function apiCall() {
    apiGetMethod(`/auth/getRoleDetails/${id}`)
      .then((data) => {
        setRoleName(data.data[0].name);
        setDataFiltered(data.data[0].permissions);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  return (
    <>
      <Helmet>
      <title>
         User Roles | {process.env.REACT_APP_APPLICATION_NAME}{" "}
        </title>
      </Helmet>
      <div className="flexTop">
        <PageHeader
          header={"Edit Role"}
          links={[
            {
              name: "Dashboard",
              path: "/",
            },
            {
              name: "User Roles",
              path: "/user-roles",
            },
          ]}
        />
      </div>
      <Card elevation={3} sx={{ pt: "20px", mb: 3 }} className="cardFix">
        <CardHeader className="cardHeadBorder">
          <Title className="cardHead">{roleName}</Title>
        </CardHeader>
        <Grid container>
          <Grid width="100%">
            <Box p={4} height="100%">
              <Table className="permissionTable">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Create</TableCell>
                    <TableCell>Read</TableCell>
                    <TableCell>Update</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                {dataFiltered?.length > 0 &&
                  dataFiltered?.map((item, index) => {
                    if (
                      dataFiltered[index].permission.create?.is_disabled ===
                        false ||
                      dataFiltered[index].permission.read?.is_disabled ===
                        false ||
                      dataFiltered[index].permission.update?.is_disabled ===
                        false ||
                      dataFiltered[index].permission.delete?.is_disabled ===
                        false
                    )
                    return (
                      <TableBody key={index}>
                        <TableRow>
                          <TableCell>{item?.label}</TableCell>
                          {["create", "read", "update", "delete"]?.map(
                            (prem) => (
                              <TableCell key={prem}>
                                <Checkbox
                                  disabled={
                                    dataFiltered[index].permission[prem]
                                      ?.is_disabled
                                  }
                                  checked={
                                    dataFiltered[index].permission[prem]?.check
                                  }
                                  onChange={() => handleChange(index, prem)}
                                />
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      </TableBody>
                    );
                  })}
              </Table>
            </Box>
          </Grid>
        </Grid>
        <Grid pl={4}>
          <LoadingButton
            className="blueButton"
            type="button"
            color="primary"
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            sx={{ mb: 2 }}
          >
            Update
          </LoadingButton>
        </Grid>
        {isModal && (
          <EditModal
            isModalOpen={isModal}
            setIsModalOpen={setIsModal}
            deleteData={handleSubmit}
          />
        )}
      </Card>
    </>
  );
}
