import { IndustryView } from "src/sections/industry/view";

// ----------------------------------------------------------------------

export default function IndustryPage() {
  return (
    <>
      <IndustryView />
    </>
  );
}
