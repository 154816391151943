import "./BuildForm.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { apiGetMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import { useState } from "react";
import { isEmpty } from "src/shared/functions/isEmpty";
import { Skeleton } from "@mui/material";
import PreviewFormCompo from "src/components-form/PreviewFormCompo";
import Box from "@mui/material/Box";
import { COMPANYROLES, ROLES, TRAINING_STATUS } from "src/core/constants";
import { useSelector } from "react-redux";

function ViewEntryCompo({
  isEntryPage = false,
  isRetakePage = false,
  propsId = null,
  propsType = "BuildFormViewEntry",
  propsState = null,
  propsPathname = "",
}) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token") || "";
  const shareable_auth_token = queryParams.get("shareable_auth_token") || "";
  const copyLink = queryParams.get("copyLink") || "";
  const isTraining = queryParams.get("isTraining");
  const project_form_status = queryParams.get("project_form_status");
  const status = queryParams.get("status");
  const currentTab = queryParams.get("currentTab");
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  let { state } = useLocation();
  if (propsState) {
    state = propsState;
  }
  if (propsPathname) {
    location.pathname = propsPathname;
  }

  let { id } = useParams();
  if (propsId) {
    id = propsId;
  }
  const [isLoading, setIsLoading] = useState(false);
  const [jsonData, setJsonData] = useState({});
  useEffect(() => {
    getFormDetails();
  }, [shareable_auth_token]);
  function getFormDetails() {
    setIsLoading(true);
    let url = "";
    if (
      shareable_auth_token?.length > 0 &&
      location.pathname.includes("/allocated-training")
    ) {
      url = `${allApiUrl.PUBLIC_ALLOCATED_TRAINING}/${id}?shareable_auth_token=${shareable_auth_token}`;
    } else if (location.pathname.includes("/forms/form-detail-shareable")) {
      url = `${allApiUrl.PUBLIC_JSA_FORM}/${id}?shareable_auth_token=${shareable_auth_token}`;
    } else if (
      !isEmpty(shareable_auth_token?.length) &&
      location.pathname.includes("/allocated-project-form")
    ) {
      url = `${allApiUrl.PUBLIC_PROJECT_ALLOCATED_GET}/${id}?shareable_auth_token=${shareable_auth_token}`;
    } else {
      if (propsType === "BuildFormViewEntry") {
        if (location.pathname.includes("/allocated-project-form/view-detail")) {
          url = `${allApiUrl.VIEW_PROJECT_FORM_ENTRY}/${id}`;
        } else if (
          location.pathname.includes("/allocated-project-form/form-detail")
        ) {
          url = `${allApiUrl.USER_ALLOCATER_PROJECT_FORM}/${id}`;
        } else if (isTraining === true) {
          url = `${allApiUrl.VIEW_COMPANY_FORM_LIST}/${id}`;
        } else if (
          state?.project_form_submitted_status == "PENDING" ||
          project_form_status == "PENDING"
        ) {
          url = `${allApiUrl.PROJECT_FORM_ENTRY}/${id}`;
        } else if (
          state?.project_form_submitted_status == "COMPLETED" ||
          project_form_status == "COMPLETED"
        ) {
          url = `${allApiUrl.PROJECT_FORM_ENTRY}/${id}`;
        } else if (
          Object?.values(COMPANYROLES)
            ?.filter((e) => e === ROLES.COMPANY)
            ?.includes(loginUserInfo?.roleid[0]) &&
          (currentTab == "FORM" ||
            currentTab === "TRAINING_FORM" ||
            currentTab === "JSA_CODE")
        ) {
          url = `${allApiUrl.VIEW_COMPANY_FORM_LIST}/${id}`;
        } else if (location.pathname.includes("/projects/form-detail")) {
          url = state?.crewMemberId
            ? `${allApiUrl.PROJECT_USER_FORM_ENTRY}/${id}/${state?.crewMemberId}`
            : `${allApiUrl.PROJECT_USER_FORM_ENTRY}/${id}`;
        } else if (
          location.pathname.includes("/allocated-training/form-detail") &&
          propsState === null
        ) {
          url =
            shareable_auth_token?.length > 0
              ? `${allApiUrl.PUBLIC_ALLOCATED_TRAINING}/${id}`
              : `${allApiUrl.TRAINING_ALLOCATED_START}/${id}`;
        } else if (
          (Object?.values(COMPANYROLES)
            ?.filter((e) => e === ROLES.FORM_USER)
            ?.includes(loginUserInfo?.roleid[0]) &&
            propsState === null &&
            !isRetakePage) ||
          location?.pathname?.includes("/training/form-detail")
        ) {
          url = `${allApiUrl.COMPANY_CATALOGUE_START}/${id}`;
        } else if (
          Object?.values(COMPANYROLES)
            ?.filter((e) => e === ROLES.FORM_USER)
            ?.includes(loginUserInfo?.roleid[0])
        ) {
          url = `${allApiUrl.ENTRY_FORM}/${id}`;
        } else {
          url =
            isEntryPage || isRetakePage
              ? `${allApiUrl.ENTRY_FORM}/${id}`
              : `${allApiUrl.JSA_FORMS}/${id}`;
        }
      } else {
        if (Object?.values(COMPANYROLES)?.includes(loginUserInfo?.roleid[0])) {
          url = `${allApiUrl.ENTRY_FORM}/${id}`;
        } else {
          url =
            isEntryPage || isRetakePage
              ? `${allApiUrl.ENTRY_FORM}/${id}`
              : `${allApiUrl.JSA_FORMS}/${id}`;
        }
      }
    }

    if (url) {
      let tokenData =
        shareable_auth_token?.length > 0 ? shareable_auth_token : token;
      apiGetMethod(url, null, tokenData)
        .then((response) => {
          if (
            location.pathname.includes("/allocated-project-form/view-detail") ||
            project_form_status === "COMPLETED" ||
            project_form_status === "PENDING" ||
            propsPathname === "/projects/form-detail"
          ) {
            response.data = response.data[0];
          }
          try {
            response.data.form_settings.isTrainingForm = Boolean(
              response.data.trainings_id
            );
            response.data.form_settings.trainingId =
              response.data.trainings_id || "";
          } catch (e) {}

          setJsonData(response.data);
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }
  const isPublicForm = location.pathname.includes("/forms/form-detail");

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%" }}>
        <div className="deployedFormOuter">
          <div className="container-fluid">
            <div className="deployedForm">
              {isLoading ? (
                <>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={120}
                  />
                  <br />
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={120}
                  />
                  <br />
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={120}
                  />
                  <br />
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={120}
                  />
                </>
              ) : (
                <>
                  {!isEmpty(jsonData) && (
                    <PreviewFormCompo
                      isJsonData
                      isEntryPage={Boolean(
                        isEntryPage ||
                          location.pathname.includes("/view-entry/") ||
                          location.pathname.includes("/projects/form-detail") ||
                          location.pathname.includes(
                            "/projects/form-edit-detail"
                          )
                      )}
                      isRetakePage={Boolean(
                        isRetakePage ||
                          location.pathname.includes("/retake-form/")
                      )}
                      trainingParams={currentTab}
                      jsonData={jsonData}
                      showSubmitButton={
                        location?.pathname?.includes("/training/form-detail") ||
                        location?.pathname?.includes(
                          "/projects/form-edit-detail"
                        ) ||
                        location?.pathname?.includes(
                          "/training-catalogue/form-detail"
                        ) ||
                        location?.pathname?.includes(
                          "/allocated-training/form-detail"
                        ) ||
                        (location?.pathname?.includes(
                          "/allocated-project-form/form-detail"
                        ) &&
                          copyLink !== "COMPLETED") ||
                        location?.pathname?.includes("/forms/retake-form") ||
                        location?.pathname?.includes(
                          "/allocated-training/retake-form"
                        ) ||
                        (isPublicForm &&
                          Boolean(
                            (propsType === "BuildFormViewEntry" &&
                              (isRetakePage ||
                                (loginUserInfo?.roleid?.[0] !==
                                  ROLES.SUPER_ADMIN &&
                                  loginUserInfo?.roleid?.[0] !==
                                    ROLES.COMPANY &&
                                  (isEmpty(jsonData?.usertrainingData) ||
                                    (!isEmpty(jsonData?.usertrainingData) &&
                                      jsonData?.usertrainingData
                                        ?.usertraining_status !==
                                        TRAINING_STATUS.PASSED &&
                                      jsonData?.usertrainingData
                                        ?.usertraining_status !==
                                        TRAINING_STATUS.FAILED))))) ||
                              (propsType !== "BuildFormViewEntry" &&
                                Object?.values(COMPANYROLES)?.includes(
                                  loginUserInfo?.roleid?.[0]
                                ) &&
                                (isEmpty(jsonData?.usertrainingData) ||
                                  (!isEmpty(jsonData?.usertrainingData) &&
                                    jsonData?.usertrainingData
                                      ?.usertraining_status !==
                                      TRAINING_STATUS.PASSED &&
                                    jsonData?.usertrainingData
                                      ?.usertraining_status !==
                                      TRAINING_STATUS.FAILED)))
                          ))
                      }
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
}

export default ViewEntryCompo;
