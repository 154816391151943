import { Helmet } from 'react-helmet-async';
import { ResetpasswordView } from 'src/sections/reset-password';

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title>{`Reset password | ${process.env.REACT_APP_APPLICATION_NAME}`} </title>
      </Helmet>

      <ResetpasswordView />
    </>
  );
}
