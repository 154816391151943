import React, {useEffect, useState} from "react";

export const ShowHidePageCountUi = ({editForm, editElementProp}) => {
  const [oldShowHidePageCount, setOldShowHidePageCount] = useState(editForm.showHidePageCount);
  useEffect(() => {
    if (oldShowHidePageCount !== editForm.showHidePageCount) {
      setOldShowHidePageCount(editForm.showHidePageCount)
    }
  }, [editForm.showHidePageCount]);

  return (
    <div className="form-group">
      <input
        id="showHidePageCount"
        className="custom-control-input"
        type="checkbox"
        checked={!!editForm.showHidePageCount}
        onChange={(e) => {
          editElementProp(!!e.target.checked, ["showHidePageCount"])
        }}
      />
      <label
        className="custom-control-label"
        htmlFor="showHidePageCount"
      >
        &nbsp;Show Page Count
      </label>
    </div>
  )
}