import LabelCompo from "./LabelCompo";
import {removeSpace, replaceLabelStrings} from "src/shared/functions/string-conversion";
import * as React from "react";
import {useEffect, useState} from "react";
import {Radio} from "@mui/material";

export const RatingScaleCompo = ({isReadOnly = false, data, onChangeFields, formData = null, form, isSubmitClicked = false, isEntryPage = false, isDraftSaved = false }) => {

  const [labelConv, setLabelConv] = useState(
    isReadOnly ? data.label : replaceLabelStrings(data?.label, form)
  );

  useEffect(() => {
    setLabelConv(isReadOnly ? data.label : replaceLabelStrings(data?.label, form));
  }, [data.label, form]);

  let ratingType = data.typesOfRating.find(
    (type) => type.type === data.selectedRatingType
  );

  const [ratings, setRatings] = useState([]);

  useEffect(() => {
    if (ratings.length) {
      let tempNewRatings = {}
      data.fields.map((v, idx) => {
        tempNewRatings[`field_id_${v.id}_${removeSpace(v.label)}`] = ratings[idx];
      })
      onChangeFields(tempNewRatings);
    }
  }, [ratings]);

  const handleRatingChange = (updatedRatings) => {
    setRatings(updatedRatings);
    if (!isReadOnly) {
      setLabelConv(
        replaceLabelStrings(data?.label, form)
      );
    }
  };

  return (
    <div key={data.id} id={data.id}>
      <LabelCompo data={data} labelConv={labelConv}/>
      <br/>
      <div className="questionSection overflow-scroll">
        <table className="table">
          <thead>
          <tr>
            <td></td>
            {ratingType.structure.map((row, index) => {
              return (
                <td className="text-center" key={`choice__${index}${removeSpace(row)}`}>
                  {row}
                </td>
              );
            })}
          </tr>
          </thead>
          <tbody>
            <RatingScaleHtml labelConv={labelConv} data={data} isReadOnly={isReadOnly} rows={data.fields} ratingType={ratingType} onChange={handleRatingChange} isEntryPage={isEntryPage} isDraftSaved={isDraftSaved} />
          </tbody>
        </table>
      </div>
    </div>
  )
}

const RatingScaleHtml = ({ labelConv, data, isReadOnly, rows, ratingType, onChange, isEntryPage = false, isDraftSaved = false }) => {
  const [ratings, setRatings] = useState([]);

  const handleRatingChange = (rowIndex, ratingIndex) => {
    const updatedRatings = [...ratings];
    updatedRatings[rowIndex] = ratingIndex + 1;
    setRatings(updatedRatings);
    onChange(updatedRatings);
  };

  useEffect(() => {
    const tempRatings = [...ratings];
    rows.map((row, rowIndex) => {
      ratingType?.structure.map((option, ratingIndex) => {
        if ((isEntryPage && row.userInputVal === option) || (!ratings[rowIndex] && ratingIndex + 1 === data.defaultRadioValue)) {
          tempRatings[rowIndex] = ratingIndex + 1
        }
      })
    })
    setRatings(tempRatings)
  }, []);
  return (
    <>
      {rows.map((row, rowIndex) => (
        <tr key={`choice__${rowIndex}${removeSpace(row.label)}`}>
          <td>
            {row.label}
            <input hidden type="text" id={`field_id_${row.id}_${removeSpace(row.label)}`}
                   name={`field_name_${row.id}_${removeSpace(row.label)}_${rowIndex}`}
                   value={((isEntryPage && row.userInputVal) ? row.userInputVal : ratingType?.structure[ratings[rowIndex] - 1])} readOnly/>
          </td>

          {ratingType?.structure.map((option, ratingIndex) => (
            <td className="text-center" key={`choice__${ratingIndex}`}>
                {isReadOnly ? (
                  <Radio
                    id={`field_id_${data.id}`}
                    checked={ratingIndex + 1 === data.defaultRadioValue}
                    name={`field_name_${removeSpace(labelConv)}_${removeSpace(row.label)}_${rowIndex}`}
                  />
                ) : (
                  <Radio
                    id={`field_id_${data.id}`}
                    disabled={!isDraftSaved && Boolean(isEntryPage && row.userInputVal)}
                    name={`field_name_${removeSpace(labelConv)}_${removeSpace(row.label)}_${rowIndex}`}
                    value={option}
                    checked={(ratings[rowIndex] === ratingIndex + 1)}
                    onChange={() => handleRatingChange(rowIndex, ratingIndex)}
                  />
                )
                }
              </td>
            )
          )}
        </tr>
      ))}
    </>
  );
};