import {Carousel} from "react-responsive-carousel";
import * as React from "react";
import {useEffect, useState} from "react";
import {fetchImage} from "../../shared/functions";
import {replaceLabelStrings} from "../../shared/functions/string-conversion";
import LabelCompo from "./LabelCompo";

export const ImageSliderCompo = ({isReadOnly, data, form}) => {
  const labelConv = isReadOnly ? data.label : replaceLabelStrings(data?.label, form);
  return (
    <div key={data.id} id={data.id}>
      <LabelCompo data={data} labelConv={labelConv}/>
      {(data.images && data.images.length && data.images[0]?.src) ? (
        <Carousel interval="5" verticalSwipe="standard">
          {data.images.map((row, index) => {
            return (
              <div key={`choice__${index}`}>
                <CrouselImage row={row}/>
                <p className="legend">{row.altName}</p>
              </div>
            );
          })}
        </Carousel>
      ) : (
        <span className="defaultIcon carouselIcon">
          <img src={`/assets/img/carouselIcon.svg`} alt={``}/>
        </span>
      )}
    </div>
  )
};

function CrouselImage({ row }) {
  let [imagSrc, setImageSrc] = useState("");
  useEffect(() => {
    if (row.src) {
      fetchImage(row.src).then((res) => {
        setImageSrc(res);
      });
    }
  }, [row.src]);
  return <img key={row.id} src={imagSrc} alt={`Image`} />;
}