import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import { apiDeleteMethod, apiPutMethod } from "src/api/rest";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "src/components/ConfirmModal";
import { PermissionCheck } from "src/utils/common";
import { allApiUrl } from "src/api/apiRoute";
import FormControlLabel from "@mui/material/FormControlLabel";
import { SUPERADMINROLES } from "src/core/constants";
import { toast } from "react-toastify";
import { Android12Switch } from "src/components/mui-common/switch-ui";
import {_isCompanyAdminRole, _isSuperAdminRole, formatDateFn} from "src/shared/functions/string-conversion";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { BootstrapTooltipUi } from "src/components/mui-common/BootstrapToolTip";

export default function UserTableRow({
  selected,
  name,
  email,
  id,
  status,
  handleClick,
  phoneNumber,
  companyid,
  roleid,
  companyidNum,
  created_at,
  updated_at,
  apiCall,
  open,
  setOpen,
  rolename,
  loginUserInfo
}) {
  let navigate = useNavigate();
  const isCompanyAdminRole = _isCompanyAdminRole(loginUserInfo);
  const isSuperAdminRole = _isSuperAdminRole(loginUserInfo);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [checked, setChecked] = useState(status);
  const [checkStatus, setCheckedStatus] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    setCheckedStatus(true);
  };

  const deleteData = () => {
    apiDeleteMethod(`${allApiUrl?.USER_PROFILE_DELETE}/${deleteId}`)
      .then((data) => {
        setIsModalOpen(false);
        toast.success(data?.message);
        setOpen(null);
        apiCall();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err.data?.message);
      });
  };
  const editUser = async () => {
    navigate(`/user/edit-user/${id}`);
  };
  const editStatus = () => {
    let obj = {
      user_name: name,
      email: email,
      phone_number: phoneNumber,
      is_active: checked,
    };
    obj["roleid"] = roleid;
    if (!Object.values(SUPERADMINROLES)?.includes(roleid)) {
      obj["company_id"] = companyidNum;
    }
    apiPutMethod(
      isSuperAdminRole
        ? `${allApiUrl?.SUPER_ADMIN_EDIT_REGISTER}/${id}`
        : `${allApiUrl?.COMPANY_EDIT_REGISTER}/${id}`,
      obj
    )
      .then((data) => {
        toast.success("Status successfully updated.");
      })
      .catch((err) => {
        toast.error(err?.data.message);
      });
  };
  useEffect(() => {
    if (checkStatus === true) {
      editStatus();
    }
  }, [checked, checkStatus]);

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        role="checkbox"
        selected={selected}
        className="tableRow userTable"
      >
        {(loginUserInfo?.roleid
          ? isCompanyAdminRole
            ? PermissionCheck("CompanyUsers", "delete", loginUserInfo)
            : PermissionCheck("Users", "delete", loginUserInfo)
          : PermissionCheck("Users", "delete", loginUserInfo)) && (
          <TableCell padding="checkbox">
            <Checkbox disableRipple checked={selected} onChange={handleClick} />
          </TableCell>
        )}

        <TableCell sx={{ fontWeight: "700" }}>{name}</TableCell>

        <TableCell>{email}</TableCell>
        {isSuperAdminRole && (
          <TableCell className="noWrap">
            {companyid ? companyid : "--"}
          </TableCell>
        )}
        <TableCell className="noWrap">{rolename}</TableCell>
        <TableCell className="nowrap16">{formatDateFn(created_at)}</TableCell>
        <TableCell className="nowrap16">{formatDateFn(updated_at)}</TableCell>
        <TableCell>
          <FormControlLabel
            control={
              <Android12Switch
                color="success"
                checked={Boolean(checked)}
                onChange={handleChange}
              />
            }
            label={checked ? "Active" : "In Active"}
          />
        </TableCell>
        {((loginUserInfo?.roleid
          ? isCompanyAdminRole
            ? PermissionCheck("CompanyUsers", "update", loginUserInfo)
            : PermissionCheck("Users", "update", loginUserInfo)
          : PermissionCheck("Users", "update", loginUserInfo)) ||
          (loginUserInfo?.roleid
            ? isCompanyAdminRole
              ? PermissionCheck("CompanyUsers", "delete", loginUserInfo)
              : PermissionCheck("Users", "delete", loginUserInfo)
            : PermissionCheck("Users", "delete", loginUserInfo))) && (
          <TableCell>
            <div style={{ whiteSpace: "nowrap" }}>
              {(loginUserInfo?.roleid
                ? isCompanyAdminRole
                  ? PermissionCheck("CompanyUsers", "update", loginUserInfo)
                  : PermissionCheck("Users", "update", loginUserInfo)
                : PermissionCheck("Users", "update", loginUserInfo)) && (
                <BootstrapTooltipUi title="Edit" placement="top">
                  <IconButton
                    className="outerborder"
                    aria-label="Edit"
                    onClick={() => editUser(id)}
                  >
                    <DriveFileRenameOutlineIcon />
                  </IconButton>
                </BootstrapTooltipUi>
              )}
              {(loginUserInfo?.roleid
                ? isCompanyAdminRole
                  ? PermissionCheck("CompanyUsers", "delete", loginUserInfo)
                  : PermissionCheck("Users", "delete", loginUserInfo)
                : PermissionCheck("Users", "delete", loginUserInfo)) && (
                <BootstrapTooltipUi title="Delete" placement="top">
                  <IconButton
                    className="outerborder"
                    aria-label="Delete"
                    onClick={() => {
                      setDeleteId(id);
                      setIsModalOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </BootstrapTooltipUi>
              )}
            </div>
          </TableCell>
        )}
      </TableRow>
      <ConfirmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        deleteData={deleteData}
        content="Are you sure you want to delete this item? "
        setOpen={setOpen}
      />
    </>
  );
}

UserTableRow.propTypes = {
  avatarUrl: PropTypes.any,
  email: PropTypes.any,
  handleClick: PropTypes.func,
  id: PropTypes.any,
  name: PropTypes.any,
  role: PropTypes.any,
  selected: PropTypes.any,
  status: PropTypes.any,
};
