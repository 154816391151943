import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Scrollbar from "src/components/scrollbar";
import UserTableRow from "../user-table-row";
import { apiGetMethod, apiPostMethod } from "src/api/rest";
import { useNavigate } from "react-router-dom";
import {
  PermissionCheck,
  handleCSVExport,
  handleImportCsv,
  _handleChangePage,
} from "src/utils/common";
import { handleCheckboxClick } from "src/sections/Table";
import { allApiUrl } from "src/api/apiRoute";
import { useSelector } from "react-redux";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import { PAGINATION_OBJECT, SUPERADMINROLES } from "src/core/constants";
import AddIcon from "@mui/icons-material/Add";
import {
  _isCompanyAdminRole,
  _isSuperAdminRole,
  formatDateFn,
} from "src/shared/functions/string-conversion";
import PageHeader from "src/components-form/Common/PageHeader";
import { Helmet } from "react-helmet-async";
import SelectAsync from "src/components-form/shared/SelectAsync";
import GetAppIcon from "@mui/icons-material/GetApp";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import {
  _setPaginationObjectFn,
  createQueryString,
  getUserRoleList,
} from "src/shared/functions";
import UploadIcon from "@mui/icons-material/Upload";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import { headCellUser } from "src/components/mui-common/table-ui/headCells";
import TableHeadRow, {
  StyledTableNoData,
} from "src/components/mui-common/table-ui/TableHeadRow";
import { removeItemByKeyValue } from "src/shared/functions/array-conversion";
import TableRowsLoader from "src/components/mui-common/table-ui/TableRowsLoader";
import { isEmpty } from "src/shared/functions/isEmpty";
import TableToolbar from "src/components/mui-common/table-ui/TableToolbar";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function UserPage() {
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const isCompanyAdminRole = _isCompanyAdminRole(loginUserInfo);
  const isSuperAdminRole = _isSuperAdminRole(loginUserInfo);

  const [selected, setSelected] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [loader, setLoader] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(null);
  const [options, setOptions] = useState([]);
  const [role, setRole] = useState("all");
  const [status, setStatus] = useState("all");
  const [companyName, setCompanyName] = useState(null);
  const [exportLoader, setExportLoader] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [importData, setImportData] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [importCompanyName, setImportCompanyName] = useState(
    loginUserInfo?.companyid !== "" ? loginUserInfo?.companyid : null
  );
  const [uploading, setUploading] = useState(false);
  let navigate = useNavigate();
  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);
  const [searchTerm, setSearchTerm] = useState("");
  const headCells = isCompanyAdminRole
    ? removeItemByKeyValue(headCellUser, ["business"])
    : headCellUser;

  useEffect(() => {
    if (paginationObject.triggerApi) {
      apiCall();
    }
  }, [paginationObject.triggerApi]);
  useEffect(() => {
    try {
      _handleChangePage({}, 0, setPaginationObject,setSelected);
      
    } catch (e) {}
  }, [searchTerm, role, status, companyName]);

  const handleClick = (event, id) => {
    setSelected(handleCheckboxClick(event, id, selected));
  };
  const requiredColumns = ["user_name", "email", "phone_number", "role"];
  function apiCall() {
    setLoader(true);
    let queryObject = {
      search: searchTerm || "",
      pageNumber: paginationObject.page,
      limit: paginationObject.rowsPerPage,
      companyId: loginUserInfo?.companyid || companyName?.value,
      roleId: role === "all" ? null : role,
      isActive: status === "all" ? null : status,
    };
    const qryStr = createQueryString(queryObject);

    apiGetMethod(
      `${
        loginUserInfo?.companyid === ""
          ? allApiUrl?.USERS_LIST
          : allApiUrl?.COMPANY_USER_LIST
      }${qryStr}`
    )
      .then((data) => {
        setDataFiltered(data.data);
        setPaginationObject((prevState) => {
          return _setPaginationObjectFn(prevState, data.meta);
        });
      })
      .catch((err) => {
        setDataFiltered([...[]]);
        setPaginationObject(PAGINATION_OBJECT);
      })
      .finally(() => {
        setLoader(false);
      });
  }
  const routeChange = () => {
    let path = `/user/add-user`;
    navigate(path);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
    setSelected([]);
  };
  useEffect(() => {
    apiCallRoles();
  }, []);
  async function apiCallRoles() {
    const results = [];
    let obj = getUserRoleList(loginUserInfo?.roleid[0]);
    results.push(...obj);
    setOptions([{ key: "All", value: "all" }, ...results]);
  }

  const handleRoleChange = (event) => {
    setRole(event.target.value);
    setSelected([]);
  };
  const handleCompanyChange = (event, value) => {
    setCompanyName(event);
    setSelected([]);
  };
  const handleImportCompanyChange = (event, value) => {
    setImportCompanyName(event);
  };
  const handleCSVExportClick = async () => {
    setExportLoader(true);
    let queryObject = {
      search: searchTerm || "",
      companyId: loginUserInfo?.companyid || companyName?.value,
      roleId: role === "all" ? null : role,
      isActive: status === "all" ? null : status,
    };
    const qryStr = createQueryString(queryObject);

    await apiGetMethod(`${isSuperAdminRole? allApiUrl?.EXPORT_USER_LIST: allApiUrl?.COMPANY_EXPORT_USER_LIST}${qryStr}`)
      .then((data) => {
        let newData = JSON.parse(data.body);
        window.open(newData.downloadUrl, "Download");
      })
      .catch((err) => {
        toast.error(err?.data?.message);
      });
    setExportLoader(false);
  };
  const handleSampleDataFormat = () => {
    const data = [
      {
        user_name: "abc",
        email: "xyz@mail.com",
        rolename: "Company Admin",
        phone_number: "1234567890",
      },
      {
        user_name: "abc",
        email: "xyz@mail.com",
        rolename: "Company User",
        phone_number: "1234567890",
      },
    ];
    const opts = {
      fields: [
        {
          label: "user_name",
          value: "user_name",
          default: "N/A",
        },
        {
          label: "email",
          value: "email",
          default: "N/A",
        },
        {
          label: "role",
          value: "rolename",
          default: "N/A",
        },
        {
          label: "phone_number",
          value: "phone_number",
          default: "N/A",
        },
      ],
    };
    handleCSVExport(data, opts, "sample");
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setCsvFile(file);
    handleImportCsv(event, requiredColumns);
  };
  const handleSubmitImport = async () => {
    if (!importCompanyName) {
      toast.error("Please Select Company");
    } else if (!csvFile) {
      toast.error("Please Select file");
    } else {
      setUploading(true);
      const formData = new FormData();
      formData.append("file", csvFile);
      formData.append(
        "company_id",
        Object.values(SUPERADMINROLES)?.includes(loginUserInfo?.roleid[0])
          ? importCompanyName?.value
          : importCompanyName
      );
      let url = Object.values(SUPERADMINROLES)?.includes(
        loginUserInfo?.roleid[0]
      )
        ? allApiUrl?.IMPORT_USER
        : allApiUrl?.IMPORT_COMPANY_USER;
      apiPostMethod(url, formData)
        .then((data) => {
          if (!isEmpty(data.data)) {
            setModalOpen(true);
            setImportData(data?.data);
          } else {
            toast.success(data.message);
          }
          setUserModal(false);
        })
        .catch((err) => {
          toast.error(err?.data?.message[0]);
          setUserModal(false);
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };
  return (
    <>
      <Helmet>
        <title> Users | {process.env.REACT_APP_APPLICATION_NAME} </title>
      </Helmet>
      <Box>
        <div className="flexTop">
          <PageHeader
            header={"Users"}
            links={[
              {
                name: "Dashboard",
                path: "/",
              },
            ]}
          />
          <div className="">
            {(loginUserInfo?.roleid
              ? isCompanyAdminRole
                ? PermissionCheck("CompanyUsers", "create", loginUserInfo)
                : PermissionCheck("Users", "create", loginUserInfo)
              : PermissionCheck("Users", "create", loginUserInfo)) && (
              <>
                <Button
                  variant="contained"
                  color="inherit"
                  startIcon={<AddIcon />}
                  onClick={routeChange}
                  className="blueButton ms-2"
                >
                  New User
                </Button>
              </>
            )}
            {(loginUserInfo?.roleid
              ? isCompanyAdminRole
                ? PermissionCheck("CompanyUsers", "create", loginUserInfo)
                : PermissionCheck("Users", "create", loginUserInfo)
              : PermissionCheck("Users", "create", loginUserInfo)) && (
              <LoadingButton
                variant="contained"
                color="inherit"
                loading={exportLoader}
                startIcon={<GetAppIcon />}
                onClick={() => setUserModal(true)}
                className="blueButton ms-2"
              >
                Import Users
              </LoadingButton>
            )}
            {(loginUserInfo?.roleid
              ? isCompanyAdminRole
                ? PermissionCheck("CompanyUsers", "create", loginUserInfo)
                : PermissionCheck("Users", "create", loginUserInfo)
              : PermissionCheck("Users", "create", loginUserInfo)) && (
              <LoadingButton
                variant="contained"
                color="inherit"
                loading={exportLoader}
                startIcon={<FileUploadIcon />}
                onClick={handleCSVExportClick}
                className="blueButton ms-2"
              >
                Export Users
              </LoadingButton>
            )}
          </div>
        </div>
        <Card className="cardFix">
          <div className="allocation">
            <TableToolbar
              title={`Users`}
              numSelected={selected.length}
              onSearch={(query) => {
                setSearchTerm(query);
              }}
              deleteUrl={allApiUrl.DELETE_MULTIPLE_USER}
              selected={selected}
              setSelected={setSelected}
              apiCall={apiCall}
            />
          </div>
          <div style={{ padding: "20px 15px 20px 15px" }}>
            <h6 style={{ marginBottom: "15px" }}>Filters:</h6>
            <Grid container columnSpacing={2}>
              <Grid item lg={3} md={3} sm={3}>
                <FormControl sx={{ width: "100%", marginBottom: "5px" }}>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{ background: "#fff" }}
                  >
                    User Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    value={role}
                    onChange={handleRoleChange}
                  >
                    {options?.length > 0 &&
                      options?.map((item, idx) => (
                        <MenuItem
                          key={`menuitemkey_${idx}`}
                          value={item?.value}
                        >
                          {item?.key}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item lg={3} md={3} sm={3}>
                <FormControl sx={{ width: "100%", marginBottom: "5px" }}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    value={status}
                    onChange={handleChangeStatus}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>In Active</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {isSuperAdminRole && (
                <Grid item lg={3}>
                  <SelectAsync
                    isClearable
                    onChange={handleCompanyChange}
                    searchType="companyid"
                    selectedValue={companyName}
                    placeholder="All Company"
                    className="asyncHeight training"
                  />
                </Grid>
              )}
            </Grid>
          </div>
          <Scrollbar>
            <TableContainer className="tableResponsive">
              <Table>
                <TableHeadRow
                  numSelected={selected.length}
                  rowCount={dataFiltered.length}
                  headCells={headCells}
                  permissionType={
                    loginUserInfo?.roleid
                      ? isCompanyAdminRole
                        ? "CompanyUsers"
                        : "Users"
                      : "Users"
                  }
                  listData={dataFiltered}
                  setSelected={setSelected}
                />

                <TableBody>
                  {loader ? (
                    <TableRowsLoader
                      rowsNum={10}
                      columnNum={headCells.length}
                    />
                  ) : isEmpty(dataFiltered) ? (
                    <StyledTableNoData colSpan={headCells.length} />
                  ) : (
                    dataFiltered.map((row, index) => {
                      return (
                        <UserTableRow
                          key={index}
                          name={row.user_name}
                          role={row.roledetails.name}
                          status={row.is_active}
                          phoneNumber={row.phone_number}
                          companyid={row?.company_name}
                          companyidNum={row.companyid}
                          email={row.email}
                          id={row.id}
                          selected={selected.indexOf(row.id) !== -1}
                          handleClick={(event) => handleClick(event, row.id)}
                          roleid={row?.roleid}
                          created_at={formatDateFn(row?.created_at)}
                          updated_at={formatDateFn(row?.updated_at)}
                          apiCall={apiCall}
                          setOpen={setOpen}
                          open={open}
                          rolename={row?.rolename}
                          loginUserInfo={loginUserInfo}
                        />
                      );
                    })
                  )}

                  {userModal && (
                    <Modal
                      open={userModal}
                      onClose={() => setUserModal(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style} className="confirmModal">
                        <Grid container columnSpacing={2} rowSpacing={3}>
                          <Grid item lg={12}>
                            <h4
                              style={{
                                marginBottom: "20px",
                                fontWeight: "600",
                              }}
                            >
                              Upload Csv File
                            </h4>
                            {isSuperAdminRole && (
                              <Grid item lg={12}>
                                <SelectAsync
                                  onChange={handleImportCompanyChange}
                                  searchType="companyid"
                                  selectedValue={importCompanyName}
                                  placeholder="Select Company"
                                  className="asyncHeight training leftAlign"
                                />
                              </Grid>
                            )}
                            <Grid item lg={12}>
                              <Box className="inputFile">
                                <input
                                  type="file"
                                  id="csvFile"
                                  accept=".csv"
                                  onChange={handleFileChange}
                                  size="10"
                                  style={{
                                    marginTop: "30px",
                                    marginLeft: "-80px",
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "50px",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          <Button
                            className="blueButton"
                            sx={{ p: "8px 25px" }}
                            onClick={handleSampleDataFormat}
                          >
                            Download Sample
                          </Button>
                          <LoadingButton
                            autoFocus
                            disabled={uploading}
                            loading={uploading}
                            endIcon={<UploadIcon />}
                            loadingPosition="end"
                            variant="contained"
                            className="blueButton"
                            onClick={handleSubmitImport}
                          >
                            Upload
                          </LoadingButton>
                          <Button
                            className="blueButton cancel"
                            sx={{ p: "8px 25px" }}
                            onClick={() => setUserModal(false)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Box>
                    </Modal>
                  )}
                  {modalOpen && (
                    <Modal
                      open={modalOpen}
                      onClose={() => setModalOpen(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style} className="confirmModal errorModal">
                        <Grid container columnSpacing={2} rowSpacing={3}>
                          <Grid item lg={12}>
                            <h4
                              style={{
                                marginBottom: "20px",
                                fontWeight: "600",
                              }}
                            >
                              Here were multiple errors:
                            </h4>
                            <ol
                              style={{
                                listStyleType: "decimal",
                                textAlign: "start",
                              }}
                            >
                              {importData?.map((v, k) => {
                                return (
                                  <li style={{ color: "#ff0000" }}>{v}</li>
                                );
                              })}
                            </ol>
                          </Grid>
                        </Grid>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          <Button
                            className="blueButton cancel"
                            sx={{ p: "8px 25px" }}
                            onClick={() => setModalOpen(false)}
                          >
                            Close
                          </Button>
                        </div>
                      </Box>
                    </Modal>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePaginationCompo
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            setSelected = {setSelected}
          />
        </Card>
      </Box>
    </>
  );
}
