import {
  Box,
  TextField,
  Card,
  styled,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiGetMethod, apiPostMethod, apiPutMethod } from "src/api/rest";
import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { allApiUrl } from "src/api/apiRoute";
import SelectAsync from "src/components-form/shared/SelectAsync";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { COMPANYROLES, SUPERADMINROLES } from "src/core/constants";
import Button from "@mui/material/Button";
import PageHeader from "src/components-form/Common/PageHeader";
import { Helmet } from "react-helmet-async";
import { calculateTotalMarks } from "../../../shared/functions";

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
}));

export default function AddTrainingDirectory() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [FilterdForm, setFilteredForm] = useState([]);
  const [loader, setLoader] = useState(true);
  const [initialValues, setInitialValues] = useState({
    name: "",
    industry_id: "",
    totalmarks: "",
    passingmarks: "",
    certificatename: "",
    validityperiod: "",
    form_id: "",
    is_active: true,
  });
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Training Name is required!"),
    totalmarks: Yup.number().required("Total Marks is required!"),
    passingmarks: Yup.number().required("Passing Marks is required!"),
    certificatename: Yup.string().required("Certificate Name is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      try {
        setLoading(true);

        let obj = {
          name: values.name,
          industry_id: values.industry_id,
          totalmarks: parseInt(values.totalmarks),
          passingmarks: parseInt(values.passingmarks),
          certificatename: values.certificatename,
          validityperiod: values.validityperiod,
          is_active: values.is_active,
        };
        if (values.form_id) {
          obj = { ...obj, form_id: values.form_id };
        }
        if (Object.values(COMPANYROLES)?.includes(loginUserInfo?.roleid[0])) {
          location.pathname === "/training/add-training"
            ? apiPostMethod(`${allApiUrl.COMPANY_ADD_TRAINING}`, obj)
                .then((data) => {
                  toast.success("Training added Successfully.");
                  navigate("/training");
                  setLoading(false);
                })
                .catch((err) => {
                  toast.error(err.data.message);

                  setLoading(false);
                })
            : apiPutMethod(`${allApiUrl?.COMPANY_EDIT_TRAINING}/${id}`, obj)
                .then((data) => {
                  toast.success("Edit Successfully");
                  setLoader(false);
                  navigate("/training");
                })
                .catch((err) => {
                  toast.error(err?.data?.message);
                  setLoader(false);
                });
        } else {
          location.pathname === "/training/add-training"
            ? apiPostMethod(`${allApiUrl.ADD_TRAINING}`, obj)
                .then((data) => {
                  toast.success("Training added Successfully.");
                  navigate("/training");
                  setLoading(false);
                })
                .catch((err) => {
                  toast.error(err.data.message);

                  setLoading(false);
                })
            : apiPutMethod(`${allApiUrl?.EDIT_TRAINING}/${id}`, obj)
                .then((data) => {
                  toast.success("Edit Successfully");
                  setLoader(false);
                  navigate("/training");
                })
                .catch((err) => {
                  toast.error(err?.data?.message);
                  setLoader(false);
                });
        }
      } catch (error) {
        toast.error(error?.data?.message);
        setLoading(false);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });
  function apiTrainingCall() {
    setLoader(true);
    if (Object.values(COMPANYROLES)?.includes(loginUserInfo?.roleid[0])) {
      apiGetMethod(`${allApiUrl?.COMPANY_TRAINING_LIST}/${id}`).then((data) => {
        setInitialValues({ ...data?.data });
        setFilteredForm({
          label: data?.data?.form_title,
          id: data?.data?.form_id,
        });
        setLoader(false);
      });
    } else {
      apiGetMethod(`${allApiUrl?.GET_TRAINING}/${id}`).then((data) => {
        setInitialValues({ ...data?.data });
        setFilteredForm({
          label: data?.data?.form_title,
          id: data?.data?.form_id,
        });
        setLoader(false);
      });
    }
  }
  useEffect(() => {
    if (id) {
      apiTrainingCall();
    }
  }, [id]);
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const handleCancel = () => {
    navigate("/training");
  };
  return (
    <>
      <Helmet>
        <title>
          {" "}
          Trainings Catalogue | {process.env.REACT_APP_APPLICATION_NAME}{" "}
        </title>
      </Helmet>
      <div className="flexTop">
        <PageHeader
          header={
            location?.pathname === "/training/add-training"
              ? "Add Trainings Catalogue"
              : "Edit Trainings Catalogue"
          }
          links={[
            {
              name: "Dashboard",
              path: "/",
            },
            {
              name: "Trainings Catalogue",
              path: "/training",
            },
          ]}
        />
      </div>
      <Card elevation={3} sx={{ pt: "20px", mb: 3 }} className="cardFix">
        <CardHeader className="cardHeadBorder">
          <Title className="cardHead">
            {location?.pathname === "/training/add-training" ? "Add" : "Edit"}{" "}
            Trainings Catalogue
          </Title>
        </CardHeader>
        <Box p={4} height="100%">
          <FormikForm
            formik={formik}
            loading={loading}
            location={location}
            id={id}
            loginUserInfo={loginUserInfo}
            handleCancel={handleCancel}
            FilterdForm={FilterdForm}
            setFilteredForm={setFilteredForm}
          />
        </Box>
      </Card>
    </>
  );
}

function FormikForm({
  formik,
  loading,
  location,
  id,
  loginUserInfo,
  handleCancel,
  setFilteredForm,
  FilterdForm,
}) {
  const [industry, setIndustry] = useState([]);

  const [industryError, setIndustryError] = useState(true);
  const { values, errors, setFieldValue, submitForm, handleSubmit, touched } =
    formik;
  useEffect(() => {
    if (
      values.industry_id !== "" &&
      Object.values(SUPERADMINROLES)?.includes(loginUserInfo?.roleid[0])
    ) {
      getIndustryDetails(values.industry_id);
    }
  }, [values]);

  function getIndustryDetails(id) {
    apiGetMethod(`${allApiUrl?.GET_INDUSTRY_BYID}/${id}`)
      .then((data) => {
        setIndustry({ value: id, label: data?.data[0]?.name });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  const handleChange = (event, value) => {
    setIndustry(event);
    setFieldValue("industry_id", event.value);
    setIndustryError(true);
  };
  const handleFormChange = (event, value) => {
    setFilteredForm(event);
    setFieldValue("form_id", event.value);
    const ss = calculateTotalMarks(event.o?.structure);
    setFieldValue("totalmarks", ss);
    if(ss == 0){
      setFieldValue("passingmarks", 0);
    }
  };

  let dateOptions = [
    "1 month",
    "2 months",
    "3 months",
    "6 months",
    "1 year",
    "2 years",
    "3 years",
    "4 years",
    "5 years",
  ];
  const handlePassingMarks = (e) => {
    if (+e.target.value >= values?.totalmarks) {
      toast.error("The passing marks should be less than the total marks.");
    } else {
      setFieldValue("passingmarks", e.target.value);
    }
  };
  return (
    <FormikProvider value={formik}>
      <Form id="formIk" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container columnSpacing={2}>
          <Grid item lg={6} md={6} sm={12}>
            <TextField
              fullWidth
              type="text"
              name="name"
              label="Training Name"
              variant="outlined"
              value={values.name}
              className="inputHeight"
              onChange={(e) => {
                setFieldValue("name", e.target.value);
              }}
              helperText={touched.name && errors.name}
              error={Boolean(errors.name && touched.name)}
              sx={{ mb: 3 }}
            />
          </Grid>
          {Object.values(SUPERADMINROLES)?.includes(
            loginUserInfo?.roleid[0]
          ) && (
            <Grid item lg={6} md={6} sm={12}>
              <FormControl fullWidth>
                {values?.industry_id && (
                  <InputLabel
                    style={{ backgroundColor: "#fff", padding: "0 5px" }}
                    shrink={true}
                  >
                    Industry
                  </InputLabel>
                )}
                <SelectAsync
                  onChange={handleChange}
                  searchType="industry"
                  selectedValue={industry}
                  placeholder="Select Industry"
                  error={errors?.industry_id && industryError === false}
                  className="asyncHeight "
                />
              </FormControl>
              {errors?.industry_id && industryError === false && (
                <div
                  style={{
                    color: "#e74c3c",
                    fontSize: "12px",
                    paddingLeft: "12px",
                    paddingTop: "4px",
                  }}
                >
                  <span>{errors?.industry_id}</span>
                </div>
              )}
            </Grid>
          )}
          <Grid item lg={6} md={6} sm={12}>
            <FormControl fullWidth>
              <InputLabel
                style={{ backgroundColor: "#fff", padding: "0 5px" }}
                shrink={true}
              >
                Form
              </InputLabel>

              <SelectAsync
                onChange={handleFormChange}
                searchType={
                  Object.values(SUPERADMINROLES)?.includes(
                    loginUserInfo?.roleid[0]
                  )
                    ? "filtered_form"
                    : "filtered_company_form"
                }
                selectedValue={FilterdForm}
                placeholder="Select Form"
                error={errors?.form_id}
                className="asyncHeight fieldmarginBottom"
              />
            </FormControl>
            {errors?.form_id && (
              <div
                style={{
                  color: "#e74c3c",
                  fontSize: "12px",
                  paddingLeft: "12px",
                  paddingTop: "4px",
                }}
              >
                <span>{errors?.form_id}</span>
              </div>
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            <TextField
              fullWidth
              type="number"
              name="totalmarks"
              label=" Total Marks"
              variant="outlined"
              disabled={values?.form_id !== "" ? true : false}
              className="inputHeight"
              value={values.totalmarks}
              onChange={(e) => {
                if (+e.target.value < 0) {
                  toast.error("Total marks can't be negative");
                } else {
                  setFieldValue("totalmarks", e.target.value);
                }
              }}
              helperText={touched.totalmarks && errors.totalmarks}
              error={Boolean(errors.totalmarks && touched.totalmarks)}
              sx={{ mb: 3 }}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            <TextField
              fullWidth
              type="number"
              name="passingmarks"
              label="Passing Marks"
              variant="outlined"
              className="inputHeight"
              sx={{ mb: 3 }}
              value={values?.passingmarks}
              onChange={(e) => handlePassingMarks(e)}
              helperText={touched.passingmarks && errors?.passingmarks}
              error={Boolean(errors.passingmarks && touched.passingmarks)}
            />
          </Grid>
          <Grid item lg={6} md={6}>
            <TextField
              fullWidth
              type="text"
              name="certificatename"
              label="Certificate Name"
              variant="outlined"
              className="inputHeight"
              value={values?.certificatename}
              onChange={(e) => setFieldValue("certificatename", e.target.value)}
              helperText={touched.certificatename && errors?.certificatename}
              error={Boolean(errors.certificatename && touched.certificatename)}
              sx={{ mb: 3 }}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            <Autocomplete
              options={dateOptions}
              filterOptions={(options, params) => {
                return options;
              }}
              value={values?.validityperiod}
              onChange={(e, value) => {
                setFieldValue("validityperiod", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a Validation Period"
                  className="fieldHeight"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                  onChange={(e) => {
                    dateOptions[dateOptions?.length + 1] = e.target?.value;
                    setFieldValue("validityperiod", e?.target?.value);
                  }}
                />
              )}
            />
          </Grid>
          {location.pathname !== "/training/add-training" && (
            <Grid item lg={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.is_active}
                  label="Status"
                  onChange={(e) => {
                    setFieldValue("is_active", e.target.value);
                  }}
                  error={Boolean(errors.is_active && touched.is_active)}
                  sx={{ mb: 3, height: "46px" }}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item lg={12} md={12} sm={12} sx={{ mt: 4 }}>
            <LoadingButton
              className="blueButton"
              type="button"
              color="primary"
              loading={loading}
              onClick={() => {
                submitForm();
                setIndustryError(false);
              }}
              variant="contained"
              sx={{ mr: "10px" }}
            >
              {location?.pathname === "/training/add-training"
                ? "Add Training"
                : "Update"}
            </LoadingButton>
            <Button className="blueButton cancel" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
