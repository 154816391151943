import { Parser } from "@json2csv/plainjs";
import { toast } from "react-toastify";
import csvtojson from "csvtojson";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";

export const PermissionCheck = (page, access, loginUserInfo) => {
  let permission = loginUserInfo?.roleDetails?.permissions;
  let pagePermission =
    permission?.length > 0 && permission?.find((item) => item?.id === page);
  if (page && access) {
    return pagePermission?.permission?.[access]?.check || false;
  } else {
    return true;
  }
};

export const handleCSVExport = (data, opts, filename = "download") => {
  try {
    const jsonParser = new Parser(opts);
    const csv = jsonParser.parse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `${Date.now()}-${filename?.split(" ").join("-")}.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log("Error while exporting CSV", error);
  }
};
export const handleImportCsv = async (event, requiredColumns) => {
  const schema = yup.object().shape({
    columns: yup.array().of(yup.string()).required(),
  });
  const file = event.target.files[0];
  if (!file) return;
  try {
    const fileText = await file.text();
    const jsonArray = await csvtojson().fromString(fileText);
    const headers = Object.keys(jsonArray[0] || {});
    await schema.validate({ columns: headers });
    const missingColumns = requiredColumns.filter(
      (col) => !headers.includes(col)
    );
    if (missingColumns.length > 0) {
      toast.error(`Missing columns: ${missingColumns.join(", ")}`);
    } else {
    }
  } catch (error) {
    toast.error(`Error: ${error.message}`);
  }
};

const openNewTabWithState = (url, data, setStateForKey) => {
  const uniqueId = uuidv4();
  setStateForKey(uniqueId, data);

  window.open(`${url}?uuid=${uniqueId}`, "_blank", "noopener,noreferrer");
};

export const _handleChangePage = (
  event,
  newPage,
  setPaginationObject,
  setSelected
) => {
  if (setSelected !== undefined) {
    setSelected([]);
  }
  setPaginationObject((prevState) => {
    return {
      ...prevState,
      page: newPage,
      triggerApi: true,
    };
  });
};
export const _handleChangeRowsPerPage = (event, setPaginationObject,setSelected) => {
  setSelected([])
  setPaginationObject((prevState) => {
    return {
      ...prevState,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      triggerApi: true,
    };
  });
};
