import {replaceLabelStrings} from "src/shared/functions/string-conversion";
import {useEffect, useState} from "react";
import LabelCompo from "./LabelCompo";
import {TextField} from "@mui/material";
import {getPlaceholder} from "src/shared/functions/array-conversion";
import * as _ from "lodash";
import * as React from "react";
import {isEmpty} from "src/shared/functions/isEmpty";
import {greaterThanEqual, lessThanEqual} from "src/shared/functions";
import validator from 'validator'
import {matchIsValidTel, MuiTelInput} from "mui-tel-input";

export const TextInputCompo = ({ data, onChangeFields, form = null, isReadOnly = false, isSubmitClicked = false, isEntryPage = false, isDraftSaved = false }) => {

  const labelConv = !isReadOnly ? replaceLabelStrings(data?.label, form) : data?.label;

  const isNumRange = (data.setInputValueRange && (data.type === "number"))
  const isStringRange = (data.setInputValueRange && data.type !== "number")

  let inputProps = {
    onWheel: (e) => e.currentTarget.blur(),
  }
  if (!isEmpty(data.min_value) && (typeof data.min_value === "string" || typeof data.min_value === "number")) {
    if (isNumRange) {
      inputProps.min = _.toInteger(data.min_value)
    }
    if (isStringRange) {
      inputProps.minLength = _.toInteger(data.min_value)
    }
  }
  if (!isEmpty(data.max_value) && (typeof data.max_value === "string" || typeof data.max_value === "number")) {
    inputProps.max = _.toInteger(data.max_value)
    if (isNumRange) {
      inputProps.max = _.toInteger(data.max_value)
    }
    if (isStringRange) {
      inputProps.maxLength = _.toInteger(data.max_value)
    }
  }

  const [inputStr, setInputStr] = useState((isEntryPage ? data.userInputVal : data.value) || "");
  useEffect(() => {
    onChangeFields(inputStr)
  }, [inputStr]);

  return (
    <div key={data.id} id={data.id}>
      <LabelCompo data={data} labelConv={labelConv} />
      {data.type === "tel" ? (
        <MuiTelInput
          fullWidth
          forceCallingCode
          defaultCountry={"US"}
          size="small"
          placeholder={getPlaceholder(data)}
          disabled={!isDraftSaved && Boolean((!data.showRetakeField) && (isReadOnly || (isEntryPage && inputStr)))}
          required={data.required}
          id={`field_id_${data.id}`}
          name={`field_name_${data.id}`}
          value={inputStr}
          onChange={(value, info) => {
            if (!isReadOnly) {
              setInputStr(value)
            }
          }}
          error={Boolean(!isReadOnly && data.required && isSubmitClicked && (!inputStr || !matchIsValidTel(inputStr)))}
          helperText={Boolean(!isReadOnly && data.required && isSubmitClicked && (!inputStr || !matchIsValidTel(inputStr))) && ((inputStr && !matchIsValidTel(inputStr)) ? "Invalid phone number!" : "This Field is required!")}
        />
      ) : (
        <TextField
          fullWidth
          rows={4}
          multiline={data.type === "textarea"}
          type={data.type === "textarea" ? "text" : data.type}
          size="small"
          disabled={!isDraftSaved && Boolean((isReadOnly || (isEntryPage && inputStr)))}
          placeholder={getPlaceholder(data)}
          onChange={(e) => {
            setInputStr(e.target.value)
          }}
          onBlur={(e) => {
            if (isNumRange && data.max_value && e.target.value) {
              const val = _.toNumber(e.target.value);
              const min = data.min_value || 0;
              const max = data.max_value;
              if (!(greaterThanEqual(val, min) && lessThanEqual(val, max))) {
                const newValue = Math.min(Math.max(val, min), max)
                setInputStr(newValue)
              }
            }
          }}
          id={`field_id_${data.id}`}
          name={`field_name_${data.id}`}
          pattern={data.pattern}
          required={data.required}
          value={inputStr}
          InputProps={{inputProps}}
          InputLabelProps={{
            shrink: true
          }}
          error={Boolean(!isReadOnly && data.required && isSubmitClicked && (!inputStr || (data.type === "email" && !validator.isEmail(inputStr))))}
          helperText={Boolean(!isReadOnly && data.required && isSubmitClicked && (!inputStr || (data.type === "email" && !validator.isEmail(inputStr)))) && ((inputStr && data.type === "email" && !validator.isEmail(inputStr)) ? "Enter a valid email address!" : "This Field is required!")}
        />
      )}

    </div>
  );
}