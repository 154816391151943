import React, {useEffect, useState} from "react";

export const SetValueUi = ({editForm, editElementProp}) => {
  const [oldHasValues, setOldHasValues] = useState(editForm.hasValues);
  useEffect(() => {
    if (oldHasValues !== editForm.hasValues) {
      editElementProp("", ["value"])
      setOldHasValues(editForm.hasValues)
    }
  }, [editForm.hasValues]);

  return (
    <div className="form-group">
      <input
        id="hasValues"
        className="custom-control-input"
        type="checkbox"
        checked={!!editForm.hasValues}
        onChange={(e) => {
          editElementProp(!!e.target.checked, ["hasValues"])
        }}
      />
      <label
        className="custom-control-label"
        htmlFor="hasValues"
      >
        &nbsp;Set value
      </label>
    </div>
  )
}