import { Box, TextField, Card, styled, Grid } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiGetMethod, apiPostMethod, apiPutMethod } from "src/api/rest";
import { allApiUrl } from "src/api/apiRoute";
import SelectAsync from "src/components-form/shared/SelectAsync";
import Loader from "src/components/Loader";
import { MuiTelInput } from "mui-tel-input";
import Button from "@mui/material/Button";
import PageHeader from "src/components-form/Common/PageHeader";
import { Helmet } from "react-helmet-async";

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
}));

export default function AddCompany() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [industry, setIndustry] = useState("");
  const [initialValues, setInitialValues] = useState({
    email: "",
    name: "",
    phonenumber: "",
    billingaddress: "",
    industry_id: "",
    status: true,
  });
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required!"),
    phonenumber: Yup.string().required("Phone Number is required!"),
    billingaddress: Yup.string().required("Billing Address is required!"),
    industry_id: Yup.string().required("Industry is required!"),
    email: Yup.string()
      .email("Invalid Email address")
      .required("Email is required!"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      try {
        setLoading(true);

        let obj = {
          name: values.name,
          billingaddress: values.billingaddress,
          email: values.email,
          phonenumber: values.phonenumber.replace(/\s/g, ""),
          industry_id: values.industry_id,
          status: values.status,
        };
        {
          location.pathname === "/company/add-company"
            ? apiPostMethod(`${allApiUrl?.ADD_COMPANY}`, obj)
                .then((data) => {
                  if (data?.roleid) {
                    toast.success(data?.message);
                    navigate("/company");
                    setLoading(false);
                  } else {
                    toast.error(data?.message);
                    setLoading(false);
                  }
                })
                .catch((err) => {
                  toast.error(err?.data?.message);

                  setLoading(false);
                })
            : apiPutMethod(`${allApiUrl?.EDIT_COMPANY}/${id}`, obj)
                .then((data) => {
                  toast.success("Edit Successfully");
                  navigate("/company");
                  setLoading(false);
                })
                .catch((err) => {
                  toast.error(err?.data?.message);

                  setLoading(false);
                });
        }
      } catch (error) {
        toast.error(error.response.data.message);
        setLoading(false);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });
  useEffect(() => {
    if (id) {
      apiCall();
    }
  }, [id]);
  function apiCall() {
    setLoader(true);
    apiGetMethod(`${allApiUrl?.GET_COMPANY_BYID}/${id}`)
      .then((data) => {
        setLoader(false);
        setInitialValues(data.data[0]);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  }
  const handleCancel = () => {
    navigate("/company");
  };
  return (
    <>
      <Helmet>
        <title> Companies | {process.env.REACT_APP_APPLICATION_NAME} </title>
      </Helmet>
      <div className="flexTop">
        <PageHeader
          header={
            location?.pathname == "/company/add-company"
              ? "Add Company"
              : "Edit Company"
          }
          links={[
            {
              name: "Dashboard",
              path: "/",
            },
            {
              name: "Company",
              path: "/company",
            },
          ]}
        />
      </div>
      <Card elevation={3} sx={{ pt: "20px", mb: 3 }} className="cardFix">
        <CardHeader className="cardHeadBorder">
          <Title className="cardHead">
            {location.pathname === "/company/add-company" ? "New" : "Edit"}{" "}
            Company
          </Title>
        </CardHeader>
        {loader && location.pathname != "/company/add-company" ? (
          <Loader />
        ) : (
          <Box p={4} height="100%">
            <FormikForm
              formik={formik}
              loading={loading}
              location={location}
              setIndustry={setIndustry}
              industry={industry}
              handleCancel={handleCancel}
            />
          </Box>
        )}
      </Card>
    </>
  );
}

function FormikForm({
  formik,
  loading,
  location,
  setIndustry,
  industry,
  handleCancel,
}) {
  const { values, errors, setFieldValue, submitForm, handleSubmit, touched } =
    formik;
  const [industryError, setIndustryError] = useState(true);
  const handleChange = (event, value) => {
    setIndustry(event);
    setFieldValue("industry_id", event.value);
    setIndustryError(true);
  };
  useEffect(() => {
    if (values.industry_id !== "") {
      getIndustryDetails(values.industry_id);
    }
  }, [values.industry_id]);

  function getIndustryDetails(id) {
    apiGetMethod(`${allApiUrl?.GET_INDUSTRY_BYID}/${id}`)
      .then((data) => {
        setIndustry({ value: id, label: data?.data[0]?.name });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form id="formIk" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container columnSpacing={2}>
          <Grid item md={6} sm={12}>
            <TextField
              fullWidth
              type="text"
              name="name"
              label="Company Name"
              variant="outlined"
              className="inputHeight"
              value={values.name}
              onChange={(e) => {
                setFieldValue("name", e.target.value);
              }}
              helperText={touched.name && errors.name}
              error={Boolean(errors.name && touched.name)}
              sx={{ mb: 3 }}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField
              fullWidth
              type="email"
              name="email"
              label="Email"
              variant="outlined"
              className="inputHeight"
              value={values.email}
              onChange={(e) => {
                setFieldValue("email", e.target.value);
              }}
              helperText={touched && touched.email && errors && errors.email}
              error={Boolean(errors.email && touched.email)}
              sx={{ mb: 3 }}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <FormControl fullWidth>
              {values?.industry_id && (
                <InputLabel
                  style={{ backgroundColor: "#fff", padding: "0 5px" }}
                  shrink={true}
                >
                  Industry
                </InputLabel>
              )}
              <SelectAsync
                onChange={handleChange}
                searchType="industry"
                selectedValue={industry}
                placeholder="Select Industry"
                error={errors?.industry_id && industryError == false}
                className="asyncHeight"
              />
            </FormControl>
            {errors?.industry_id && industryError == false && (
              <div
                style={{
                  color: "#e74c3c",
                  fontSize: "12px",
                  paddingLeft: "12px",
                  paddingTop: "4px",
                }}
              >
                <span>{errors?.industry_id}</span>
              </div>
            )}
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField
              fullWidth
              name="billingaddress"
              type="text"
              label="Billing Address"
              variant="outlined"
              value={values.billingaddress}
              className="inputHeight"
              onChange={(e) => {
                setFieldValue("billingaddress", e.target.value);
              }}
              helperText={
                touched &&
                touched.billingaddress &&
                errors &&
                errors.billingaddress
              }
              error={Boolean(errors.billingaddress && touched.billingaddress)}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <MuiTelInput
              fullWidth
              forceCallingCode
              defaultCountry={"US"}
              size="small"
              placeholder="Phone Number"
              name="phonenumber"
              value={values?.phonenumber}
              className="phoneHeight"
              onChange={(value, info) => {
                setFieldValue("phonenumber", info?.numberValue);
              }}
              helperText={
                touched && touched.phonenumber && errors && errors.phonenumber
              }
              error={Boolean(
                touched && touched.phonenumber && errors.phonenumber
              )}
              sx={{ mb: 3 }}
            />
          </Grid>
          {location.pathname !== "/company/add-company" && (
            <Grid item md={6} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.status}
                  label="Status"
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                  }}
                  error={Boolean(errors.status && touched.status)}
                  sx={{ mb: 3, height: "46px" }}
                >
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Inactive"}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item md={12} sm={12}>
            <LoadingButton
              className="blueButton"
              type="button"
              color="primary"
              loading={loading}
              onClick={() => {
                submitForm();
                setIndustryError(false);
              }}
              variant="contained"
              sx={{ mr: "10px" }}
            >
              {location?.pathname == "/company/add-company"
                ? "  Add Company"
                : "Update"}
            </LoadingButton>
            <Button className="blueButton cancel" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
