import { Chip } from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
} from "src/components/mui-common/table-ui/TableHeadRow";

export default function AllocationTableRow({
  selected,
  userName,
  TrainingName,
  lastAttempt,
  allocatedDate,
  status,
  isSuperAdminRole,
  company_name,
}) {
  return (
    <>
      <StyledTableRow
        tabIndex={-1}
        role="checkbox"
        selected={selected}
        className="tableRow"
      >
        <StyledTableCell>{allocatedDate}</StyledTableCell>
        <StyledTableCell>{userName}</StyledTableCell>
        <StyledTableCell>{TrainingName}</StyledTableCell>
        {isSuperAdminRole && <StyledTableCell>{company_name}</StyledTableCell>}
        <StyledTableCell>{lastAttempt}</StyledTableCell>

        <StyledTableCell>
          {status === "PENDING" ? (
            <Chip label="PENDING" color="info" />
          ) : status === "COMPLETED" ? (
            <Chip label="PASS" color="success" />
          ) : (
            <Chip label="FAIL" color="error" />
          )}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}
