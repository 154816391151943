import { useContext, useState } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";

import Nav from "./nav";
import Main from "./main";
import Header from "./header";
import sidebarContext from "src/hooks/sidebarContext";
export default function DashboardLayout({ children }) {
  const [openNav, setOpenNav] = useState(false);
  const { toogle, setToogle } = useContext(sidebarContext);
  return (
    <>
      <Header onOpenNav={() => setOpenNav(true)} ontoogle={toogle} />

      <Box
        sx={{
          minHeight: 1,
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <div className={toogle === true ? "toggleInactive" : "toggleActive"} style={{transition:"all 0.3s ease-in-out"}}>
          <Nav
            openNav={openNav}
            onCloseNav={() => setOpenNav(false)}
            toogle={() => setToogle(!toogle)}
            ontoogle={toogle}
          />
        </div>

        <Main>{children}</Main>
      </Box>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
