import SvgColor from "src/components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/${name}.png`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: "Dashboard",
    path: "/",
    icon: "Dashboard",
    disabled: false,
  },
  {
    title: "User Roles",
    path: "/user-roles",
    key: "UserRoles",
    icon: "AssignmentInd",
    disabled: false,
  },
  {
    title: "Users",
    key: "Users",
    path: "/user",
    icon: "Person",
    disabled: false,
  },
  {
    title: "Users",
    key: "CompanyUsers",
    path: "/user",
    icon: "Person",
    disabled: false,
  },
  {
    title: "Companies",
    key: "Companies",
    path: "/company",
    icon: "Business",
    disabled: false,
  },
  {
    title: "Industries",
    path: "/industry",
    key: "Industries",
    icon: "Factory",
    disabled: false,
  },
  {
    title: "JSA Categories",
    key: "Categories",
    path: "/category",
    icon: "Category",
    disabled: false,
  },
  {
    title: "Trainings Catalogue",
    key: "UserCatalogue",
    path: "/training-catalogue",
    icon: "Grading",
    disabled: false,
  },
  {
    title: "Allocated Trainings",
    key: "AllocatedTrainings",
    path: "/allocated-training",
    icon: "Web",
    disabled: false,
  },
  {
    title: "Allocated Project Form",
    key: "AllocatedProjectForms",
    path: "/allocated-project-form",
    icon: "AccountTree",
    disabled: false,
  },
  {
    title: "Forms Catalogue",
    path: "/forms",
    key: "Forms",
    icon: "Grading",
    disabled: false,
  },

  {
    title: "Trainings Catalogue",
    key: "Trainings",
    path: "/training",
    icon: "ModelTrainingOutlined",
    disabled: false,
  },
  {
    title: "Training Attempts",
    key: "TrainingsAndCertificates",
    path: "/training-attempts",
    icon: "ModelTrainingOutlined",
    disabled: false,
  },
  {
    title: "Training History",
    path: "/training-certificate",
    key: "TrainingsAndCertificates",
    icon: "WorkspacePremium",
    disabled: false,
  },
  {
    title: "Trainings Allocation",
    key: "TrainingsAllocation",
    path: "/training-allocation",
    icon: "Web",
    disabled: false,
  },
  {
    title: "My Trainings & Certificates",
    key: "MyTraining",
    path: "/my-training",
    icon: "ModelTrainingOutlined",
    disabled: false,
  },
  {
    title: "Projects",
    key: "Projects",
    path: "/projects",
    icon: "AccountTree",
    disabled: false,
  },
];

export default navConfig;
