import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { allApiUrl } from "src/api/apiRoute";
import { apiGetMethod } from "src/api/rest";
import Scrollbar from "src/components/scrollbar";
import { CategoryTableRow } from "../CategoryTableRow";
import {
  _isSuperAdminRole,
  formatDateFn,
} from "src/shared/functions/string-conversion";
import {_handleChangePage, PermissionCheck} from "src/utils/common";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { handleCheckboxClick } from "src/sections/Table";
import PageHeader from "src/components-form/Common/PageHeader";
import { Helmet } from "react-helmet-async";
import SelectAsync from "src/components-form/shared/SelectAsync";
import { PAGINATION_OBJECT } from "src/core/constants";
import { headCellCategoryCatalogue } from "src/components/mui-common/table-ui/headCells";
import { removeItemByKeyValue } from "src/shared/functions/array-conversion";
import {
  _setPaginationObjectFn,
  createQueryString,
} from "src/shared/functions";
import TableHeadRow, {
  StyledTableNoData,
} from "src/components/mui-common/table-ui/TableHeadRow";
import TableToolbar from "src/components/mui-common/table-ui/TableToolbar";
import TableRowsLoader from "src/components/mui-common/table-ui/TableRowsLoader";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import { isEmpty } from "src/shared/functions/isEmpty";

const Category = () => {
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const isSuperAdminRole = _isSuperAdminRole(loginUserInfo);
  const [selected, setSelected] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);
  const [searchTerm, setSearchTerm] = useState("");
  const [loader, setLoader] = useState(true);
  const [statusFilter, setStatusFilter] = useState("all");
  const [industryName, setIndustryName] = useState(null);

  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const tempHeadCells =
    PermissionCheck("Categories", "update", loginUserInfo) ||
    PermissionCheck("Categories", "delete", loginUserInfo)
      ? PermissionCheck("Categories", "delete", loginUserInfo)
        ? headCellCategoryCatalogue
        : removeItemByKeyValue(headCellCategoryCatalogue, "checkbox")
      : removeItemByKeyValue(headCellCategoryCatalogue, [
          "checkbox",
          "actions",
        ]);
  const headCells = isSuperAdminRole
    ? tempHeadCells
    : removeItemByKeyValue(tempHeadCells, ["industry_name"]);

  useEffect(() => {
    if (paginationObject.triggerApi) {
      apiCall();
    }
  }, [paginationObject.triggerApi]);
  useEffect(() => {
    try {
      _handleChangePage({}, 0, setPaginationObject,setSelected)
    } catch (e) {}
  }, [searchTerm, statusFilter, industryName])

  function apiCall(value) {
    setLoader(true);
    let queryObject = {
      search: searchTerm || "",
      industryId: industryName?.value,
      pageNumber: paginationObject.page,
      limit: paginationObject.rowsPerPage,
      is_active: statusFilter === "all" ? null : statusFilter,
    };
    const qryStr = createQueryString(queryObject);

    apiGetMethod(`${allApiUrl?.ADMIN_CATEGORY}${qryStr}`)
      .then((data) => {
        setDataFiltered(data.data);
        setPaginationObject((prevState) => {
          return _setPaginationObjectFn(prevState, data.meta);
        });
      })
      .catch((err) => {
        setDataFiltered([...[]]);
        setPaginationObject(PAGINATION_OBJECT);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  const handleClick = (event, id) => {
    setSelected(handleCheckboxClick(event, id, selected));
  };

  const handleIndustryChange = (event, value) => {
    setIndustryName(event);
    setSelected([]);
  };
  const handleChangeStatus = (event) => {
    setStatusFilter(event.target.value);
    setSelected([]);
  };
  return (
    <>
      <Helmet>
        <title>
          JSA Categories | {process.env.REACT_APP_APPLICATION_NAME}{" "}
        </title>
      </Helmet>
      <Box>
        <div className="flexTop">
          <PageHeader
            header={"JSA Categories"}
            links={[
              {
                name: "Dashboard",
                path: "/",
              },
            ]}
          />
          <div>
            {PermissionCheck("Categories", "create", loginUserInfo) && (
              <Button
                variant="contained"
                color="inherit"
                startIcon={<AddIcon />}
                onClick={() => navigate("/category/add-category")}
                className="blueButton"
              >
                New JSA Categories
              </Button>
            )}
          </div>
        </div>
        <Card className="cardFix">
          <TableToolbar
            title={`JSA Categories`}
            numSelected={selected.length}
            onSearch={(query) => setSearchTerm(query)}
            searchPlaceholder={`Search by Name, Desc.`}
            deleteUrl={allApiUrl.MULTIPLE_DELETE_CATEGORY}
            selected={selected}
            setSelected={setSelected}
            apiCall={apiCall}
          />

          <div style={{ padding: "20px 15px 20px 15px" }}>
            <h6 style={{ marginBottom: "15px" }}>Filters:</h6>
            <Grid container columnSpacing={2}>
              <Grid item lg={3}>
                <FormControl sx={{ width: "100%", marginBottom: "5px" }}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    value={statusFilter}
                    onChange={handleChangeStatus}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>In Active</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {isSuperAdminRole && (
                <Grid item lg={3}>
                  <SelectAsync
                    isClearable
                    onChange={handleIndustryChange}
                    searchType="industry"
                    selectedValue={industryName}
                    placeholder="Select Industry"
                    className="asyncHeight training"
                  />
                </Grid>
              )}
            </Grid>
          </div>
          <Scrollbar>
            <TableContainer className="tableResponsive">
              <Table>
                <TableHeadRow
                  numSelected={selected.length}
                  rowCount={dataFiltered.length}
                  headCells={headCells}
                  permissionType="Categories"
                  listData={dataFiltered}
                  setSelected={setSelected}
                />

                <TableBody>
                  {loader ? (
                    <TableRowsLoader
                      rowsNum={10}
                      columnNum={headCells.length}
                    />
                  ) : isEmpty(dataFiltered) ? (
                    <StyledTableNoData colSpan={headCells.length} />
                  ) : (
                    dataFiltered?.map((item, index) => {
                      return (
                        <CategoryTableRow
                          key={index}
                          id={item?.id}
                          name={item?.name}
                          description={item?.description}
                          industry_name={item?.industry_name}
                          created_at={formatDateFn(item?.created_at)}
                          status={item?.is_active}
                          selected={selected.indexOf(item.id) !== -1}
                          handleClick={(event) => handleClick(event, item.id)}
                          loginUserInfo={loginUserInfo}
                          industry_id={item?.industry_id}
                          open={open}
                          setOpen={setOpen}
                          apiCall={apiCall}
                        />
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePaginationCompo
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            setSelected={setSelected}
          />
        </Card>
      </Box>
    </>
  );
};
export default Category;
