
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiGetMethod } from "src/api/rest";
import { jwtDecode } from "jwt-decode";
import Loader from "src/components/Loader";
import { toast } from "react-toastify";
export const VerificationPage = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const urlToken = queryParams.get("token");
  const decoded = jwtDecode(urlToken);
  const verificationCompanyApi = () => {
    apiGetMethod(`/company/company-verify/${urlToken}`)
      .then((data) => { 
      navigate("/payment", { state: { email: decoded?.email } });
        toast.success(data?.message);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.data?.message);
        navigate("/login");
      });
  };
  useEffect(() => {
    if (urlToken) {
      verificationCompanyApi();
    }
  }, [urlToken]);
  return (
    <>
      <Loader />
    </>
  );
};
