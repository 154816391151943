import {styled} from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";

export const CustomAccordion = styled(Accordion)(({ theme }) => ({
  '& .MuiAccordionSummary-root': {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    minHeight: "48px !important"
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.primary.contrastText,
  },
  '& .MuiAccordionSummary-content': {
    margin: "0 !important",
  },
  '& .MuiAccordionDetails-root': {
    padding: "8px 10px 16px",
    textAlign: "left"
  },
}));