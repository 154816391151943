import { Helmet } from 'react-helmet-async';
import {UnauthorizedView} from 'src/sections/error';

export default function Unauthorized() {
  return (
    <>
      <Helmet>
        <title>{`Unauthorized Page | ${process.env.REACT_APP_APPLICATION_NAME}`} </title>
      </Helmet>
      <UnauthorizedView />
    </>
  );
}
