import LabelCompo from "./LabelCompo";
import * as React from "react";
import { useEffect, useState } from "react";
import { replaceLabelStrings } from "../../shared/functions/string-conversion";
import { fetchImage, UploadFilesS3 } from "src/shared/functions";
import MediaPreviewer from "./ui/MediaPreview";
import { isEmpty } from "src/shared/functions/isEmpty";
import { MultipleFileUploadCompo } from "./MultipleFileUploadCompo";
import { Box } from "@mui/material";

export const FileUploadCompo = ({
  isReadOnly = false,
  data,
  onChangeFields,
  form,
  isEntryPage = false,
  showSubmitButton = true,
}) => {
  const labelConv = isReadOnly
    ? data.label
    : replaceLabelStrings(data?.label, form);
  let [fileName, setFileName] = useState(
    isEntryPage ? data?.userInputVal : data.value || ""
  );
  const [loading, setLoading] = useState(false);
  let [fileSrc, setFileSrc] = useState([]);
  useEffect(() => {
    if (fileName?.length > 0) {
      try {
        if (data?.chooseFile === "multiple") {
          JSON.parse(fileName)?.map((name, index) => {
            fetchImage(name).then((res) => {
              setFileSrc((prevData) => {
                prevData[index] = res;
                return [...prevData];
              });
            });
          });
        } else {
          fetchImage(fileName).then(async (res) => {
            const response = await fetch(res);
            const blob = await response.blob();
            setFileSrc([URL.createObjectURL(blob)]);
          });
        }
      } catch (e) {}
    }
  }, [fileName]);
  useEffect(() => {
    if (fileSrc && fileName) {
      onChangeFields(fileName);
    }
  }, [fileSrc]);
  return (
    <>
      {data?.chooseFile === "single" ? (
        <div key={data.id} id={data.id} className="imageChanges">
          <LabelCompo data={data} labelConv={labelConv} />
          <input
            hidden
            id={`field_id_${data.id}`}
            name={`field_name_${data.id}`}
            value={fileName}
            readOnly
          />

          <br />
          <Box className="inputFile compo">
            {((!fileSrc && isEntryPage) || !isEntryPage) && (
              <input
                type="file"
                required={data.required}
                // accept={data.fileType?.value ?? ".png, .jpeg, .jpg, .mp4"}
                onChange={async (e) => {
                  if (
                    !isReadOnly &&
                    !isEmpty(e.target.files) &&
                    e.target.files[0]
                  ) {
                    setLoading(true);
                    let afterUploadFileName = await UploadFilesS3(
                      e.target.files[0]
                    );
                    if (afterUploadFileName?.length > 0) {
                      setLoading(false);
                    }
                    setFileName(afterUploadFileName);
                  }
                }}
              />
            )}
          </Box>
          {fileSrc && (
            <MediaPreviewer
              fileName={fileName}
              fileUrl={fileSrc[0]}
              loading={loading}
            />
          )}
        </div>
      ) : (
        <MultipleFileUploadCompo
          data={data}
          isReadOnly={isReadOnly}
          labelConv={labelConv}
          setFileSrc={setFileSrc}
          setFileName={setFileName}
          fileName={fileName}
          fileSrc={fileSrc}
          isEntryPage={isEntryPage}
          showSubmitButton={showSubmitButton}
        />
      )}
    </>
  );
};
