// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  /* position */
  --relative: relative;
  --absolute: absolute;
  --static: static;
  --fixed: fixed;
  --grid: grid;

  /* alignment */
  --center: center;
  --left: left;
  --right: right;

  /* display */
  --flex: flex;
  --inline-flex: inline-flex;
  --inline-block: inline-block;
  --block: block;
  --none: none;

  /* colours */
  --color-white: #fff;
  --dark: #333;
  --bg-gray: #f5f5f5;
  --dark-bg: #1d2630;
  --dark-gray: #ccc;
  --grat-tint1: #f2f2f2;
  --grat-tint2: #ddd;
  --grat-tint3: #ededed;

  /* dimensions */
  --full: 100%;
  --half: 50%;

  /* sizes */
  --font-12: 12px;
  --font-13: 13px;
  --font-14: 14px;
  --font-15: 15px;
  --font-16: 16px;
  --font-18: 18px;
  --font-20: 20px;
  --font-30: 30px;

  /* weight */
  --normal: 400;
  --regular: 500;
  --semibold: 600;
  --bold: 700;

  /* cursor */
  --pointer: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/shared/constants/cssVariables.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oBAAoB;EACpB,oBAAoB;EACpB,gBAAgB;EAChB,cAAc;EACd,YAAY;;EAEZ,cAAc;EACd,gBAAgB;EAChB,YAAY;EACZ,cAAc;;EAEd,YAAY;EACZ,YAAY;EACZ,0BAA0B;EAC1B,4BAA4B;EAC5B,cAAc;EACd,YAAY;;EAEZ,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,qBAAqB;EACrB,kBAAkB;EAClB,qBAAqB;;EAErB,eAAe;EACf,YAAY;EACZ,WAAW;;EAEX,UAAU;EACV,eAAe;EACf,eAAe;EACf,eAAe;EACf,eAAe;EACf,eAAe;EACf,eAAe;EACf,eAAe;EACf,eAAe;;EAEf,WAAW;EACX,aAAa;EACb,cAAc;EACd,eAAe;EACf,WAAW;;EAEX,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[":root {\n  /* position */\n  --relative: relative;\n  --absolute: absolute;\n  --static: static;\n  --fixed: fixed;\n  --grid: grid;\n\n  /* alignment */\n  --center: center;\n  --left: left;\n  --right: right;\n\n  /* display */\n  --flex: flex;\n  --inline-flex: inline-flex;\n  --inline-block: inline-block;\n  --block: block;\n  --none: none;\n\n  /* colours */\n  --color-white: #fff;\n  --dark: #333;\n  --bg-gray: #f5f5f5;\n  --dark-bg: #1d2630;\n  --dark-gray: #ccc;\n  --grat-tint1: #f2f2f2;\n  --grat-tint2: #ddd;\n  --grat-tint3: #ededed;\n\n  /* dimensions */\n  --full: 100%;\n  --half: 50%;\n\n  /* sizes */\n  --font-12: 12px;\n  --font-13: 13px;\n  --font-14: 14px;\n  --font-15: 15px;\n  --font-16: 16px;\n  --font-18: 18px;\n  --font-20: 20px;\n  --font-30: 30px;\n\n  /* weight */\n  --normal: 400;\n  --regular: 500;\n  --semibold: 600;\n  --bold: 700;\n\n  /* cursor */\n  --pointer: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
