import {
  Card,
  Table,
  TableBody,
  TableContainer,
  Button,
  Chip,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { allApiUrl } from "src/api/apiRoute";
import { apiGetMethod } from "src/api/rest";
import PageHeader from "src/components-form/Common/PageHeader";
import Scrollbar from "src/components/scrollbar";
import { Helmet } from "react-helmet-async";
import { PAGINATION_OBJECT } from "src/core/constants";
import {
  _setPaginationObjectFn,
  createQueryString,
} from "src/shared/functions";
import { isEmpty } from "src/shared/functions/isEmpty";
import TableHeadRow, {
  StyledTableCell,
  StyledTableNoData,
  StyledTableRow,
} from "src/components/mui-common/table-ui/TableHeadRow";
import TableToolbar from "src/components/mui-common/table-ui/TableToolbar";
import TableRowsLoader from "src/components/mui-common/table-ui/TableRowsLoader";
import TablePaginationCompo from "src/components/mui-common/table-ui/TablePaginationCompo";
import { headCellsAllocatedTraining } from "src/components/mui-common/table-ui/headCells";
import { formatDateFn } from "src/shared/functions/string-conversion";
import { _handleChangePage } from "../../utils/common";

const AllocatedTrainings = () => {
  const [selected, setSelected] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);

  const [paginationObject, setPaginationObject] = useState(PAGINATION_OBJECT);
  const [searchTerm, setSearchTerm] = useState("");

  const [loader, setLoader] = useState(true);
  const headCells = headCellsAllocatedTraining;

  useEffect(() => {
    if (paginationObject.triggerApi) {
      apiCall();
    }
  }, [paginationObject.triggerApi]);
  useEffect(() => {
    try {
      _handleChangePage({}, 0, setPaginationObject, setSelected);
    } catch (e) {}
  }, [searchTerm]);

  function apiCall() {
    setLoader(true);
    let queryObject = {
      search: searchTerm || "",
      pageNumber: paginationObject.page,
      limit: paginationObject.rowsPerPage,
    };
    const qryStr = createQueryString(queryObject);

    apiGetMethod(`${allApiUrl?.GET_TRAINING_ALLOCATED}${qryStr}`)
      .then((data) => {
        setDataFiltered(data.data);
        setPaginationObject((prevState) => {
          return _setPaginationObjectFn(prevState, data.meta);
        });
      })
      .catch((err) => {
        setDataFiltered([...[]]);
        setPaginationObject(PAGINATION_OBJECT);
      })
      .finally(() => {
        setLoader(false);
      });
  }
  const handleClick = (item) => {
    if (item?.training_status == "PENDING") {
      window.open(`/allocated-training/form-detail/${item?.id}`, "_blank");
    } else {
      window.open(`/allocated-training/retake-form/${item?.entry_form_data?.[0]?.entry_id}`, "_blank");
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Allocated Trainings | {process.env.REACT_APP_APPLICATION_NAME}
        </title>
      </Helmet>
      <Box>
        <PageHeader
          header={"Allocated Trainings"}
          links={[
            {
              name: "Dashboard",
              path: "/",
            },
          ]}
        />
        <Card className="cardFix">
          <TableToolbar
            title={`Allocated Trainings`}
            numSelected={selected.length}
            onSearch={(query) => setSearchTerm(query)}
            selected={selected}
            setSelected={setSelected}
            apiCall={apiCall}
          />

          <Scrollbar className="cardFix">
            <TableContainer>
              <Table>
                <TableHeadRow
                  numSelected={selected.length}
                  rowCount={dataFiltered.length}
                  headCells={headCells}
                  permissionType="Forms"
                  listData={dataFiltered}
                  setSelected={setSelected}
                />

                <TableBody>
                  {loader ? (
                    <TableRowsLoader
                      rowsNum={10}
                      columnNum={headCells.length}
                    />
                  ) : isEmpty(dataFiltered) ? (
                    <StyledTableNoData colSpan={headCells.length} />
                  ) : (
                    dataFiltered?.map((item, index) => {
                      return (
                        <StyledTableRow
                          role="checkbox"
                          tabIndex={-1}
                          key={`styledTableRow_${index}`}
                        >
                          {headCells.map((hCell) => (
                            <StyledTableCell
                              padding={
                                hCell.id === "checkbox" ? "checkbox" : "normal"
                              }
                              className="nowrapFull"
                              key={`tableCell_${hCell.id}`}
                            >
                              {hCell.id === "status" ? (
                                <>
                                  {item?.training_status === "INPROGRESS" ? (
                                    <Chip label="FAIL" color="error" />
                                  ) : item?.training_status === "PENDING" ? (
                                    <Chip label="PENDING" color="error" />
                                  ) : (
                                    <Chip label="PASS" color="success" />
                                  )}
                                </>
                              ) : hCell.id === "actions" ? (
                                <Button
                                  disabled={
                                    item?.training_status === "COMPLETED"
                                  }
                                  onClick={() => {
                                    handleClick(item);
                                  }}
                                >
                                  {item?.training_status === "PENDING"
                                    ? "START TRAINING"
                                    : "RETAKE"}
                                </Button>
                              ) : hCell?.isDate ? (
                                formatDateFn(item[hCell.id])
                              ) : (
                                item[hCell.id]
                              )}
                            </StyledTableCell>
                          ))}
                        </StyledTableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePaginationCompo
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            setSelected={setSelected}
          />
        </Card>
      </Box>
    </>
  );
};

export default AllocatedTrainings;
