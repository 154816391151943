import {useEffect, useRef, useState} from "react";
import {replaceLabelStrings} from "src/shared/functions/string-conversion";
import LabelCompo from "./LabelCompo";
import SignatureCanvas from "react-signature-canvas";
import {ButtonGroup, FormControl, FormHelperText, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";
import {isEmpty} from "src/shared/functions/isEmpty";
import * as _ from "lodash";

export const SignatureCompo = ({ isReadOnly = false, data, onChangeFields, formData, form, isSubmitClicked = false, isEntryPage = false, isDraftSaved = false }) => {
  const [labelConv, setLabelConv] = useState(
    isReadOnly ? data?.label : replaceLabelStrings(data?.label, form)
  );
  const [updatedFields, setUpdatedFields] = useState([]);

  useEffect(() => {
    if (!isReadOnly) {
      setLabelConv(replaceLabelStrings(data?.label, form));
    } else {
      setLabelConv(data.label)
    }
  }, [form, data]);

  const [fields, setFields] = useState(isEntryPage ? data?.userInputVal : formData);
  useEffect(() => {
    if (!isReadOnly) {
      onChangeFields(fields);
    }
  }, [fields]);

  const [signatureContainer, setSignatureContainer] = useState({
    height: 0,
    width: 0
  })
  useEffect(() => {
    const elSignContainer = document.getElementById(`signature_container_${data.id}`);
    setSignatureContainer({
      height: elSignContainer.offsetHeight,
      width: elSignContainer.offsetWidth
    })
    setTimeout(() => {
      if (isEntryPage && !isEmpty(data?.userInputVal?.SignatureImage)) {
        try {
          sigCanvas.current?.fromDataURL(data?.userInputVal?.SignatureImage)
          if (!isDraftSaved) {
          sigCanvas.current?.off();
          }
        } catch (e) {
          console.log('error', e, data?.userInputVal)
        }
      }
    }, 500)

  }, []);

  const [signatureType, setSignatureType] = useState((isEntryPage && data?.userInputVal && data?.userInputVal?.SignatureButtonType) ? data?.userInputVal?.SignatureButtonType : "Draw");
  const sigCanvas = useRef();

  const resetCanvas = (type = null) => {
    try {
      sigCanvas.current?.clear()
      setFields(prevState => {
        return {
          ...prevState,
          SignatureButtonType: type,
          SignatureText: ((fields && fields["Name"]) ? replaceLabelStrings(fields["Name"], form, "") : replaceLabelStrings(data.fields["Name"].value, form, ""))
        }
      });
    } catch (e) {}
  }
  return (
    <div id={data.id} key={data.id}>
      <LabelCompo data={data} labelConv={labelConv}/>
      <div id={`signature_container_${data.id}`}>
        {signatureType === "Draw" && (
          <>
          <FormControl sx={{width: "100%"}} error={Boolean(!isReadOnly && data.required && isSubmitClicked && !(fields && fields["SignatureImage"]))}>

            <div className={signatureType === "Draw" ? "signatureSection":""}>
              <SignatureCanvas
                canvasProps={{
                  width: signatureContainer.width,
                  height: "auto",
                  className: "sigCanvas"
                }}
                ref={sigCanvas}
                onEnd={async () => {
                  if (!isReadOnly) {
                    const base64Data = sigCanvas.current?.getTrimmedCanvas().toDataURL("image/png");
                    setFields(prevState => {
                      return {...prevState, SignatureImage: base64Data, SignatureButtonType: "Draw"}
                    });
                  }
                }}
              />
            </div>
            <FormHelperText>{Boolean(!isReadOnly && data.required && isSubmitClicked && !(fields && fields["SignatureImage"])) && "This Field is required!"}</FormHelperText>
          </FormControl>
          </>
        )}
      </div>
      {signatureType === "Text" && (
        <FormControl sx={{width: "100%"}} error={Boolean(!isReadOnly && data.required && isSubmitClicked && !(fields && fields["SignatureText"]))}>
          <textarea
            id={`field_id_${data.id}`}
            className="form-control signatureText"
            readOnly={Boolean(isReadOnly || (isEntryPage && data.userInputVal["Name"]))}
            onChange={(e) => {
              if (!isReadOnly) {
                setFields(prevState => {
                  return {...prevState, SignatureText: e.target.value, SignatureButtonType: "Text"}
                });
                setUpdatedFields(prevState => {
                  return _.uniq([...prevState, "SignatureText"])
                })
              }
            }}
            name="SignatureText"
            value={(fields && fields["SignatureText"]) ? fields["SignatureText"] : (fields && fields["SignatureText"]) ? replaceLabelStrings(fields["SignatureText"], form, "") : ""}
          />
          <FormHelperText>{Boolean(!isReadOnly && data.required && isSubmitClicked && !(fields && fields["SignatureText"])) && "This Field is required!"}</FormHelperText>
        </FormControl>
      )}
      <div className="col-12 my-2">
        <label>Name</label>
        <TextField
          fullWidth
          size="small"
          onChange={(e) => {
            if (!isReadOnly) {
              setFields(prevState => {
                return {...prevState, Name: e.target.value, SignatureText: e.target.value }
              });
              setUpdatedFields(prevState => {
                return _.uniq([...prevState, "Name"])
              })
            }
          }}
          disabled={!isDraftSaved && Boolean(isReadOnly || (isEntryPage && data.fields["Name"].userInputVal))}
          id={data.fields && `field_id_${data.fields["Name"].id}`}
          name={`field_name_${data.id}_Name`}
          value={isEntryPage ? ((isDraftSaved && _.includes(updatedFields, "Name")) ? (fields && fields["Name"] ? replaceLabelStrings(fields["Name"], form, "") : replaceLabelStrings(data.fields["Name"].value, form, "")) : data.fields["Name"].userInputVal) : (isReadOnly ? data.fields["Name"].value : (fields && fields["Name"] ? replaceLabelStrings(fields["Name"], form, "") : replaceLabelStrings(data.fields["Name"].value, form, "")))}
          InputLabelProps={{
            shrink: true
          }}
        />
      </div>
      <ButtonGroup disabled={!isDraftSaved && Boolean(isReadOnly || (isEntryPage && data?.userInputVal && data?.userInputVal?.SignatureButtonType))}>
        <Button onClick={() => {
          if (!isReadOnly) {
            resetCanvas("Draw")
          }
        }}>Clear</Button>
        <Button
          color={signatureType === "Draw" ? "success" : "primary"}
          onClick={() => {
            if (!isReadOnly) {
              resetCanvas("Draw")
              setSignatureType("Draw")
            }
          }}>Draw</Button>
        <Button
          color={signatureType === "Text" ? "success" : "primary"}
          onClick={() => {
            if (!isReadOnly) {
              resetCanvas("Text")
              setSignatureType("Text")
            }
          }}>Text</Button>
      </ButtonGroup>
    </div>
  );
};