import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import AppWidgetSummary from "../app-widget-summary";
import { useEffect, useState } from "react";
import { apiGetDashboardStats, formListData, userListData } from "src/api/rest";
import Loader from "src/components/Loader";
import { useSelector } from "react-redux";
import { COMPANYROLES, ROLES, SUPERADMINROLES } from "src/core/constants";
import Box from "@mui/material/Box";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
} from "@mui/material";
import Scrollbar from "src/components/scrollbar";
import moment from "moment";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  addSpaceBeforeCapitalLetters,
  formatDateFn,
  formatUpperLetterWithSpace,
} from "src/shared/functions/string-conversion";
import { PermissionCheck } from "src/utils/common";
import SimpleLineChart from "./lineChart";

// ----------------------------------------------------------------------

export default function AppView() {
  const [loader, setLoader] = useState(false);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [formFiltered, setFormFiltered] = useState([]);
  const [dashboardData, setDashboardData] = useState();
  useEffect(() => {
    getStats();
  }, []);
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const navigate = useNavigate();
  const getStats = () => {
    setLoader(true);
    apiGetDashboardStats(null, null, loginUserInfo)
      .then((response) => {
        setDashboardData(response?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const getUserStatus = () => {
    userListData(null, null, loginUserInfo, page, rowsPerPage)
      .then((data) => {
        setDataFiltered(data?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getFormStatus = () => {
    formListData(null, null, loginUserInfo, page, rowsPerPage)
      .then((data) => {
        setFormFiltered(data?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const userHandleLabel = [
    { id: "user_name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "business", label: "Company" },
    { id: "role", label: "Role" },
    { id: "status", label: "Status" },
  ];
  const headCellsJsaCode = [
    {
      id: "title",
      label: "Title",
    },
    {
      id: "created_at",
      label: "Created At",
    },
    {
      id: "updated_at",
      label: "Updated At",
    },
    {
      id: "actions",
      label: "Actions",
    },
  ];
  useEffect(() => {
    if (
      loginUserInfo?.roleid &&
      (Object.values(COMPANYROLES)
        ?.filter((e) => e !== ROLES.FORM_USER)
        ?.includes(loginUserInfo?.roleid[0]) ||
        Object.values(SUPERADMINROLES)?.includes(loginUserInfo?.roleid[0]))
    ) {
      getUserStatus();
      getFormStatus();
    }
  }, []);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Box className="dashBoardSetting">
          <Typography variant="h4" sx={{ mb: 3 }}>
            Hi, Welcome back 👋
          </Typography>
          <Grid container spacing={3}>
            {Object?.keys(dashboardData || {})?.map((item, index) => {
              return (
                dashboardData[item] > -1 && (
                  <Grid xs={12} sm={6} md={6} lg={3} key={index}>
                    <AppWidgetSummary
                      title={
                        item?.includes("Cert")
                          ? "Training Certificates"
                          : formatUpperLetterWithSpace(item)
                      }
                      total={dashboardData[item]}
                      color="success"
                      icon={
                        item.includes("Companies") ? (
                          <img alt="icon" src="/assets/icons/building.svg" />
                        ) : item?.includes("Users") ? (
                          <img alt="icon" src="/assets/icons/users.svg" />
                        ) : item?.includes("Industries") ? (
                          <img alt="icon" src="/assets/icons/industry.svg" />
                        ) : item?.includes("Catalogue") ? (
                          <img alt="icon" src="/assets/img/catalog2.png" />
                        ) : item?.includes("Forms") ? (
                          <img alt="icon" src="/assets/icons/forms.svg" />
                        ) : item?.includes("Training") ? (
                          <img
                            alt="icon"
                            src="/assets/img/certificate.svg"
                            width={64}
                            height={64}
                          />
                        ) : item?.includes("Cert") ? (
                          <img
                            alt="icon"
                            src="/assets/img/achievement.svg"
                            width={64}
                            height={64}
                          />
                        ) : (
                          ""
                        )
                      }
                    />
                  </Grid>
                )
              );
            })}
          </Grid>

          <Grid container spacing={4} className="dashboardTable">
            {(loginUserInfo?.roleid
              ? Object?.values(COMPANYROLES)?.includes(loginUserInfo?.roleid[0])
                ? PermissionCheck("CompanyUsers", "read", loginUserInfo)
                : PermissionCheck("Users", "read", loginUserInfo)
              : PermissionCheck("Users", "read", loginUserInfo)) && (
              <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                <Card className="cardFix">
                  <Toolbar
                    sx={{
                      justifyContent: "space-between",
                      padding: "25px 20px",
                    }}
                  >
                    <Typography variant="h5" id="tableTitle" component="div">
                      Last 10 Users
                    </Typography>
                    <div>
                      <Button
                        variant="contained"
                        color="inherit"
                        endIcon={<ChevronRightIcon />}
                        onClick={() => navigate("/user")}
                        className="blueButton"
                      >
                        View All
                      </Button>
                    </div>
                  </Toolbar>
                  <Scrollbar>
                    <TableContainer
                      className="scrollBar"
                      sx={{ overflowY: "auto", maxHeight: "500px" }}
                    >
                      <Table
                        sx={{
                          minWidth: 500,
                        }}
                      >
                        <TableHead>
                          <TableRow className="tableHead dashboardMain">
                            {userHandleLabel?.length > 0 &&
                              userHandleLabel?.map((item, index) => {
                                return (
                                  <TableCell padding="checkbox" key={index}>
                                    {item?.label}
                                  </TableCell>
                                );
                              })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dataFiltered?.length > 0 ? (
                            dataFiltered?.map((item, index) => {
                              return (
                                <TableRow
                                  key={index}
                                >
                                  <TableCell sx={{ fontWeight: "700" }}>
                                    {item?.user_name}
                                  </TableCell>
                                  <TableCell>{item?.email}</TableCell>
                                  <TableCell>{item?.company_name}</TableCell>
                                  <TableCell>{item?.rolename}</TableCell>
                                  <TableCell>
                                    {item?.is_active == true
                                      ? "Active"
                                      : "InActive"}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow
                            >
                              <TableCell colSpan={5}>No Data Found</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                </Card>
              </Grid>
            )}
            {PermissionCheck("Forms", "read", loginUserInfo) && (
              <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                <Card className="cardFix">
                  <Toolbar
                    sx={{
                      justifyContent: "space-between",
                      padding: "25px 20px",
                    }}
                  >
                    <Typography variant="h5" id="tableTitle" component="div">
                      Last 10 Forms
                    </Typography>
                    <div>
                      <Button
                        variant="contained"
                        color="inherit"
                        endIcon={<ChevronRightIcon />}
                        onClick={() => navigate("/forms?currentTab=FORM")}
                        className="blueButton"
                      >
                        View All
                      </Button>
                    </div>
                  </Toolbar>
                  <Scrollbar>
                    <TableContainer
                      className="scrollBar"
                      sx={{ overflow: "auto", maxHeight: "500px" }}
                    >
                      <Table
                        sx={{
                          minWidth: 500,
                        }}
                      >
                        <TableHead>
                          <TableRow className="tableHead dashboardMain">
                            {headCellsJsaCode?.length > 0 &&
                              headCellsJsaCode?.map((item, index) => {
                                return (
                                  <TableCell padding="checkbox" key={index}>
                                    {item?.label}
                                  </TableCell>
                                );
                              })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {formFiltered?.length > 0 ? (
                            formFiltered?.map((item, index) => {
                              return (
                                <TableRow
                                  key={index}
                                >
                                  <TableCell sx={{ fontWeight: "700" }}>
                                    {item?.title}
                                  </TableCell>
                                  <TableCell>
                                    {moment(item?.created_at).format(
                                      "MMM DD, YYYY hh:mm A "
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {formatDateFn(item?.updated_at)}
                                  </TableCell>
                                  <TableCell>
                                    {item?.is_active == true
                                      ? "Active"
                                      : "InActive"}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow
                            >
                              <TableCell colSpan={5}>No Data Found</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                </Card>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
}
