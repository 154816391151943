import { useState } from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import { alpha } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import useAuth from "src/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { COMPANYROLES, ROLES, SUPERADMINROLES } from "src/core/constants";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Home",
    icon: "eva:home-fill",
  },
  {
    label: "Profile",
    icon: "eva:person-fill",
  },
  {
    label: "Settings",
    icon: "eva:settings-2-fill",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { logout } = useAuth();
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  return (
    <>
      <Box
        className="d-flex justify-content-center align-items-center"
        onClick={handleOpen}
      >
        <IconButton
          sx={{
            width: 36,
            height: 36,
            backgroundColor: "#ee7000",
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          <Avatar
            src={loginUserInfo.photoURL}
            alt={loginUserInfo.user_name}
            sx={{
              width: 36,
              height: 36,
              border: (theme) =>
                `solid 2px ${theme.palette.background.default}`,
            }}
          >
            {loginUserInfo.user_name?.charAt(0).toUpperCase()}
          </Avatar>
        </IconButton>
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography
            variant="header_name"
            sx={{ color: "black", cursor: "pointer" }}
            noWrap
          >
            {loginUserInfo?.user_name}
          </Typography>
        </Box>
      </Box>
      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 200,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography variant="header_name" sx={{ color: "black" }}>
            {loginUserInfo?.user_name}
          </Typography>
          <Typography variant="body1" sx={{ color: "black" }} noWrap>
            ({loginUserInfo?.roleDetails?.name})
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {loginUserInfo.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography
            variant="subtitle2"
            noWrap
            onClick={() => navigate("/profile")}
            style={{ cursor: "pointer" }}
          >
            Profile
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: "dashed" }} />
        {Object.values(COMPANYROLES)
          ?.filter((e) => e == ROLES.COMPANY)
          ?.includes(loginUserInfo?.roleid[0]) && (
          <>
            <Box sx={{ my: 1.5, px: 2 }}>
              <Typography
                variant="subtitle2"
                noWrap
                onClick={() => navigate("/subscription")}
                style={{ cursor: "pointer" }}
              >
                Manage Plan
              </Typography>
            </Box>
            <Divider sx={{ borderStyle: "dashed" }} />
          </>
        )}
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography
            variant="subtitle2"
            noWrap
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/change-password")}
          >
            Change Password
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: "dashed" }} />
        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={() => {
            logout();
            handleClose();
          }}
          sx={{ typography: "body2", color: "error.main", py: 1.5 }}
        >
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
