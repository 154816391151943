import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import * as React from "react";
import { FormControl, Grid } from "@mui/material";
import SelectAsync from "src/components-form/shared/SelectAsync";
import { allApiUrl } from "src/api/apiRoute";
import { useNavigate } from "react-router-dom";
import { apiGetMethod, apiPostMethod } from "src/api/rest";
import InputAdornment from "@mui/material/InputAdornment";
import Iconify from "src/components/iconify";
import IconButton from "@mui/material/IconButton";
import { ROLES } from "src/core/constants";
import { MuiTelInput } from "mui-tel-input";

const loginSx = {
  color: "#ee7000",
  textDecoration: "none",
  fontWeight: "100",
  "&:hover": {
    color: "#ee7000",
  },
};
const initialValues = {
  email: "",
  name: "",
  phonenumber: "",
  billingaddress: "",
  industry_id: "",
  password: "",
};
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Company Name is required!"),
  industry_id: Yup.string().required("Industry is required!"),
  email: Yup.string()
    .email("Invalid Email address")
    .required("Email is required!"),
  password: Yup.string()
    .min(6, "Password must be 6 character length")
    .required("Password is required!"),
  confirm_password: Yup.string()
    .required("Confirm Password is required!")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

export default function CompanyRegisterView() {
  const navigate = useNavigate();
  const [industry, setIndustry] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleFormSubmit = (values, { setSubmitting, setErrors }) => {
    try {
      setLoading(true);

      let obj = {
        name: values.name,
        billingaddress: values.billingaddress,
        email: values.email,
        phonenumber: values.phonenumber?.replace(/\s/g, ""),
        industry_id: values.industry_id,
        status: values.status,
        password: values.password,
      };
      {
        apiPostMethod(`${allApiUrl?.ADD_COMPANY}`, obj)
          .then((data) => {
            if (data?.roleid) {
              toast.success(data?.message);
              navigate("/login");
              setLoading(false);
            } else {
              toast.error(data.message);
            }
          })
          .catch((err) => {
            toast.error(err?.data?.message);

            setLoading(false);
          });
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
      setSubmitting(false);
      setErrors(error);
    }
  };
  useEffect(() => {
    if (initialValues.industry_id !== "") {
      getIndustryDetails(initialValues.industry_id);
    }
  }, [initialValues]);

  function getIndustryDetails(id) {
    apiGetMethod(`${allApiUrl?.GET_INDUSTRY_BYID}/${id}`)
      .then((data) => {
        const results = [];
        setIndustry({ value: id, label: data?.data[0]?.name });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  const renderForm = (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({
        isSubmitting,
        values,
        errors,
        touched,
        handleBlur,
        handleChange,

        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              type="text"
              name="name"
              label="Company Name"
              variant="outlined"
              value={values.name}
              onChange={(e) => {
                setFieldValue("name", e.target.value);
              }}
              helperText={touched.name && errors.name}
              error={Boolean(errors.name && touched.name)}
            />

            <TextField
              fullWidth
              type="email"
              name="email"
              label="Email"
              variant="outlined"
              value={values.email}
              onChange={(e) => {
                setFieldValue("email", e.target.value);
              }}
              helperText={touched && touched.email && errors && errors.email}
              error={Boolean(errors.email && touched.email)}
            />

            <FormControl fullWidth>
              <SelectAsync
                onChange={(event) => {
                  setIndustry(event);
                  setFieldValue("industry_id", event.value);
                }}
                searchType="industry"
                selectedValue={industry}
                placeholder="Select Industry"
                error={errors?.industry_id}
              />
              {errors?.industry_id && (
                <div
                  style={{
                    color: "#e74c3c",
                    fontSize: "12px",
                    paddingLeft: "12px",
                    paddingTop: "4px",
                  }}
                >
                  <span>{errors?.industry_id}</span>
                </div>
              )}
            </FormControl>
            <TextField
              fullWidth
              name="billingaddress"
              type="text"
              label="Billing Address"
              variant="outlined"
              value={values.billingaddress}
              onChange={(e) => {
                setFieldValue("billingaddress", e.target.value);
              }}
              helperText={
                touched &&
                touched.billingaddress &&
                errors &&
                errors.billingaddress
              }
              error={Boolean(errors.billingaddress && touched.billingaddress)}
              sx={{ mb: 2 }}
            />
            <MuiTelInput
              fullWidth
              forceCallingCode
              defaultCountry={"US"}
              size="small"
              placeholder="Phone Number"
              name="phone_number"
              value={values?.phonenumber}
              className="phoneHeight"
              onChange={(value, info) => {
                setFieldValue("phonenumber", info?.numberValue);
              }}
              helperText={errors && errors.phonenumber}
              error={Boolean(errors.phonenumber)}
              sx={{ mb: 3 }}
            />
            <TextField
              autoComplete={``}
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              onBlur={handleBlur}
              value={values.password}
              onChange={handleChange}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password ? errors.password : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              autoComplete={``}
              name="confirm_password"
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              onBlur={handleBlur}
              value={values.confirm_password}
              onChange={handleChange}
              error={Boolean(
                touched.confirm_password && errors.confirm_password
              )}
              helperText={
                touched.confirm_password ? errors.confirm_password : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showConfirmPassword
                            ? "eva:eye-fill"
                            : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <LoadingButton
            sx={{ mt: 3 }}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="inherit"
            loading={isSubmitting}
            className=" blueButton"
          >
            Register
          </LoadingButton>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ my: "13px" }}
          >
            <div
              variant="subtitle2"
              // href="/login"
              onClick={() => navigate("/login")}
              className="loginfx"
            >
              Already have an account? Login
            </div>
          </Stack>
        </form>
      )}
    </Formik>
  );

  return (
    <Box height="100%">
      <Grid container height="100%" className="loginSection">
        <Grid item lg={6} className="imageSection">
          <span className="dot1">
            <img src="/assets/background/dots.svg" style={{ width: "200px" }} />
          </span>
          <span className="dot2">
            <img src="/assets/background/dots.svg" style={{ width: "200px" }} />
          </span>
          <span className="blob1">
            <img
              src="/assets/background/blob1.svg"
              style={{ width: "400px" }}
            />
          </span>
          <span className="blob2">
            <img
              src="/assets/background/blob1.svg"
              style={{ width: "400px" }}
            />
          </span>
          <img
            src="/assets/img/register3.svg"
            style={{ height: "650px", width: "650px" }}
            alt="login"
          />
        </Grid>
        <Grid item lg={6}>
          <Stack>
            <Card
              className="cardBack"
              sx={{
                p: 5,
                width: 1,
                maxWidth: 500,
              }}
            >
              <Typography
                variant="h4"
                sx={{ textAlign: "center", color: "#000" }}
              >
                Sign up as a company.
              </Typography>
              <Typography variant="body2" sx={{ mt: 2, mb: 5 }}></Typography>
              {renderForm}
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
