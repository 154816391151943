import React from "react";
import {createFormUserInputData, getConditionalStringResponse} from "../../shared/functions";
import {isEmpty} from "../../shared/functions/isEmpty";

export const PageBreakCompo = ({data}) => {
  return (
    <div key={data.id} id={data.id}>
      <div className="pagebreak">
        <PageBreakButtonsHtml buttonsArray={data.buttons} />
        <div className="pagesplit-top"></div>
        <div className="pagesplit-bottom"></div>
      </div>
    </div>
  )
}
export const PageBreakButtonsHtml = (
  {
    buttonsArray = [],
    readOnly = true,
    setActivePageIndex = (e) => {},
    activePageIndex = 0,
    formRef = null,
    setIsSubmitClicked = (e) => {},
    splitForm= null,
    form= null,
    obtainedMarksProp= 0,
    setObtainedMarksProp = (e) => {}
  }) => {
  return (
    <div className="d-flex flex-row-reverse">
      {buttonsArray.map((button, index) => {
        if (button.hidden) {
          return null;
        }
        return (
          <button
            key={`choice00__${index}`}
            type="button"
            disabled={readOnly ? false : (
              !isEmpty(button.conditionalOperation.rules.rules) ? !getConditionalStringResponse(button.conditionalOperation.rules) : false
            )}
            onClick={(e) => {
              e.preventDefault();
              try {
                setIsSubmitClicked(true)
                if (button.type === "Next") {
                  const isFormValid = formRef.current.checkValidity();

                  setTimeout(() => {
                    const hasNoError = !formRef.current?.querySelector('.Mui-error');

                    if (isFormValid && hasNoError && !readOnly) {
                      setIsSubmitClicked(false)

                      let {obtainedMarks} = createFormUserInputData(form, formRef, splitForm, true, obtainedMarksProp)

                      setObtainedMarksProp(obtainedMarks)
                      let newPageIndex = button.type === "Previous" ? activePageIndex - 1: activePageIndex + 1;
                      newPageIndex = (newPageIndex > 0 ? newPageIndex : 0)
                      setActivePageIndex(newPageIndex);
                    }
                  }, 500)
                } else {
                  let newPageIndex = button.type === "Previous" ? activePageIndex - 1: activePageIndex + 1;
                  newPageIndex = (newPageIndex > 0 ? newPageIndex : 0)
                  setActivePageIndex(newPageIndex);
                }
              } catch (e) {
              }
            }}
          >
            {button?.label}
          </button>
        );
      })}
    </div>
  )
}