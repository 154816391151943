import { Helmet } from "react-helmet-async";

import { UserRolesView } from "src/sections/usersRole/view";

// ----------------------------------------------------------------------

export default function UserRoles() {
  return (
    <>
      <UserRolesView />
    </>
  );
}
