import {useEffect, useState} from "react";
import * as React from "react";
import {fetchImage} from "../../shared/functions";
import {replaceLabelStrings} from "../../shared/functions/string-conversion";
import LabelCompo from "./LabelCompo";

export const ImageCompo = ({ isReadOnly, data, form }) => {
  const labelConv = isReadOnly ? data.label : replaceLabelStrings(data?.label, form);
  let [imagSrc, setImageSrc] = useState("");
  let altImgSrc = "/assets/img/imageIcon.svg";

  useEffect(() => {
    if (data.src) {
      fetchImage(data.src).then((res) => {
        setImageSrc(res);
      });
    }
  }, [data.src]);
  return (
    <div key={data.id} id={data.id}>
      <LabelCompo data={data} labelConv={labelConv}/>

      <div className={`mediaContainer ${data?.properties?.center ? "text-center":"text-center"}`}>
        {data.src ? (
          <img
            src={imagSrc} 
            width={data?.properties?.width ? data?.properties?.width : "100%"}
            height={data?.properties?.height ? data?.properties?.height : "100%"}
            alt="Image" className="DynamicImg"
          />
        ) : (
          <span className="defaultIcon">
          <img src={altImgSrc}  alt={`Image`}/>
        </span>
        )}
      </div>
    </div>
  );
};
