import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import "./App.css";
import "./responsive.css";
import { COMPANYROLES } from "src/core/constants";
import Layout from "src/components/Layout";
import RequireAuth from "src/routes/auth/RequireAuth";
import { useScrollToTop } from "src/hooks/use-scroll-to-top";
import AddUser from "./sections/user/add-user";
import AddCompany from "./sections/company/add-company";
import AddIndustry from "./sections/industry/add-industry";
import EditRole from "./sections/usersRole/edit-role";
import ViewTrainingDirectory from "./sections/Training-directory/view";
import AddTrainingDirectory from "./sections/Training-directory/add-training";
import { TrainingCertificatePage } from "./sections/Training-certificate/view-list";
import useAuth from "./hooks/useAuth";
import { useSelector } from "react-redux";
import CompanyRegisterView from "./sections/signup/company-signup";
import { VerificationPage } from "./sections/verification";
import { ViewTrainingAllocation } from "./sections/Training-allocation/view-list";
import GuestAuth from "./routes/auth/GuestAuth";
import EditProfile from "./pages/EditProfile";
import ChangePassword from "./pages/ChangePassword";
import MyTraining from "./sections/My-training/view";
import MyCertificate from "./sections/My-certificate/view";
import searchContext from "./hooks/searchContext";
import sidebarContext from "./hooks/sidebarContext";
import EditCertificatePage from "./sections/Training-certificate/edit-certificate";
import { ViewTrainingCatalogue } from "./sections/Training-Catalogue/view-list";
import ViewEntry from "./pages/BuildForm/ViewEntry";
import LoginPage from "src/pages/login";
import SignupPage from "src/pages/signup";
import Page404 from "src/pages/page-not-found";
import Unauthorized from "src/pages/unauthorized";
import LandingPage from "src/pages/LandingPage";
import SavedFormPage from "src/pages/SavedFormPage";
import ReactFormBuilder from "src/components-form/ReactFormBuilder";
import Dashboard from "src/pages/dashboard";
import User from "src/pages/user";
import CompanyPage from "src/pages/company";
import UserRoles from "src/pages/user-roles";
import FormListingPage from "src/pages/FormListing";
import IndustryView from "src/pages/industry";
import ResetPassword from "src/pages/reset-password";
import ForgotPassword from "src/pages/forgot-password";
import StripePricingTable from "./sections/Payment";
import Category from "./sections/Category/View";
import AddCategory from "./sections/Category/add-category";
import ProfilePage from "src/pages/ProfilePage";
import { SuccessPaymentPage } from "./sections/Payment/SuccessPaymentPage";
import SubscriptionPage from "./sections/Payment/SubscriptionPage";
import { Projects } from "./sections/projects/view";
import { AddProject } from "./sections/projects/add-project";
import { ProjectAddress } from "./sections/projects/view/ProjectAddress";
import AllocatedTrainings from "./sections/allocated-training";
import { AllocatedProjectForm } from "./sections/allocated-project-form";
import ViewEntryCompo from "./pages/BuildForm/ViewEntryCompo";
import { TrainingsAttempts } from "./sections/TrainingsAttempts";

function App() {
  useScrollToTop();
  const { setAuth } = useAuth();
  const [search, setSearch] = useState(false);
  const [toogle, setToogle] = useState(false);
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  useEffect(() => {
    setAuth(loginUserInfo);
  }, []);
  return (
    <searchContext.Provider value={{ search, setSearch }}>
      <sidebarContext.Provider value={{ toogle, setToogle }}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route
              path="login"
              element={
                <GuestAuth>
                  <LoginPage />
                </GuestAuth>
              }
            />
            <Route
              path="admin/login"
              element={
                <GuestAuth>
                  <LoginPage />
                </GuestAuth>
              }
            />

            <Route
              path="register"
              element={
                <GuestAuth>
                  <SignupPage />
                </GuestAuth>
              }
            />
            <Route
              path="company/register"
              element={
                <GuestAuth>
                  <CompanyRegisterView />
                </GuestAuth>
              }
            />
            <Route
              path="forgot-password"
              element={
                <GuestAuth>
                  <ForgotPassword />
                </GuestAuth>
              }
            />
            <Route
              path="reset-password"
              element={
                <GuestAuth>
                  <ResetPassword />
                </GuestAuth>
              }
            />
            <Route path="unauthorized" element={<Unauthorized />} />
            <Route
              path="email-verification"
              element={
                <GuestAuth>
                  <VerificationPage />
                </GuestAuth>
              }
            />
            {loginUserInfo?.token ? (
              <Route
                path="/allocated-training/form-detail/:id"
                element={<ViewEntryCompo isEntryPage={false} />}
              />
            ) : (
              <Route
                path="allocated-training/form-detail/:id"
                element={
                  <GuestAuth>
                    <ViewEntryCompo />
                  </GuestAuth>
                }
              />
            )}
            {loginUserInfo?.token ? (
              <Route
                path="/allocated-training/retake-form/:id"
                element={<ViewEntryCompo isRetakePage />}
              />
            ) : (
              <Route
                path="allocated-training/retake-form/:id"
                element={
                  <GuestAuth>
                    <ViewEntryCompo />
                  </GuestAuth>
                }
              />
            )}
            {loginUserInfo?.token ? (
              <Route
                path="/allocated-project-form/form-detail/:id"
                element={<ViewEntryCompo isEntryPage={false} />}
              />
            ) : (
              <Route
                path="allocated-project-form/form-detail/:id"
                element={
                  <GuestAuth>
                    <ViewEntryCompo />
                  </GuestAuth>
                }
              />
            )}
            {loginUserInfo?.token ? (
              <Route
                path="/forms/form-detail-shareable/:id"
                element={<ViewEntryCompo />}
              />
            ) : (
              <Route
                path="forms/form-detail-shareable/:id"
                element={
                  <GuestAuth>
                    <ViewEntryCompo />
                  </GuestAuth>
                }
              />
            )}
            {loginUserInfo?.token ? (
              <Route
                path="/allocated-project-form/view-detail/:id"
                element={<ViewEntryCompo isEntryPage />}
              />
            ) : (
              <Route
                path="allocated-project-form/view-detail/:id"
                element={
                  <GuestAuth>
                    <ViewEntryCompo />
                  </GuestAuth>
                }
              />
            )}

            <Route element={<RequireAuth page="Dashboard" permission="read" />}>
              <Route path="/" element={<LandingPage />} />
            </Route>
            <Route element={<RequireAuth page="Dashboard" permission="read" />}>
              <Route path="/profile" element={<EditProfile />} />
            </Route>
            <Route element={<RequireAuth page="Dashboard" permission="read" />}>
              <Route path="/change-password" element={<ChangePassword />} />
            </Route>
            <Route element={<RequireAuth page="Forms" permission="read" />}>
              <Route
                path="/forms/form-builder/:form/:id"
                element={<ReactFormBuilder />}
              />
            </Route>
            <Route
              element={<RequireAuth page="MyTraining" permission="read" />}
            >
              <Route path="/my-training" element={<MyTraining />} />
            </Route>
            <Route
              element={<RequireAuth page="MyCertificate" permission="read" />}
            >
              <Route path="/my-certificate" element={<MyCertificate />} />
            </Route>
            <Route element={<RequireAuth page="Forms" permission="read" />}>
              <Route
                path="/forms/saved-entries/:id"
                element={<SavedFormPage />}
              />
            </Route>
            <Route element={<RequireAuth page="Forms" permission="read" />}>
              <Route
                path="/forms/form-detail/:id"
                element={<ViewEntryCompo isEntryPage={false} />}
              />
            </Route>
            <Route
              element={<RequireAuth page="UserCatalogue" permission="read" />}
            >
              <Route
                path="/training-catalogue/saved-entries/:id"
                element={<SavedFormPage />}
              />
            </Route>
            <Route element={<RequireAuth page="Forms" permission="read" />}>
              <Route
                path="/forms/view-entry/:id"
                element={<ViewEntry isEntryPage />}
              />
            </Route>
            <Route element={<RequireAuth page="Forms" permission="read" />}>
              <Route path="/forms/view-entry" element={<FormListingPage />} />
            </Route>
            <Route
              element={<RequireAuth page="MyTraining" permission="read" />}
            >
              <Route
                path="/my-training/view-entry/:id"
                element={
                  <ViewEntryCompo
                    isEntryPage
                    propsState={{ isTraining: true }}
                  />
                }
              />
            </Route>
            <Route
              element={<RequireAuth page="UserCatalogue" permission="read" />}
            >
              <Route
                path="/forms/retake-form/:id"
                element={<ViewEntryCompo isRetakePage />}
              />
            </Route>
            <Route element={<RequireAuth page="Forms" permission="read" />}>
              <Route
                path="/forms/form-builder"
                element={<ReactFormBuilder />}
              />
            </Route>

            <Route
              element={<RequireAuth page="UserCatalogue" permission="read" />}
            >
              <Route
                path="/training-catalogue/form-detail/:id"
                element={<ViewEntryCompo isEntryPage={false} />}
              />
            </Route>

            <Route element={<RequireAuth />}>
              <Route path="/dashboard" element={<Dashboard />} />
            </Route>
            <Route
              element={
                <RequireAuth
                  page={
                    loginUserInfo?.roleid
                      ? Object?.values(COMPANYROLES)?.includes(
                          loginUserInfo?.roleid[0]
                        )
                        ? "CompanyUsers"
                        : "Users"
                      : "Users"
                  }
                  permission="read"
                />
              }
            >
              <Route path="/user" element={<User />} />
            </Route>
            <Route
              element={
                <RequireAuth
                  page={
                    loginUserInfo?.roleid
                      ? Object?.values(COMPANYROLES)?.includes(
                          loginUserInfo?.roleid[0]
                        )
                        ? "CompanyUsers"
                        : "Users"
                      : "Users"
                  }
                  permission="create"
                />
              }
            >
              <Route path="/user/add-user" element={<AddUser />} />
            </Route>
            <Route
              element={
                <RequireAuth
                  page={
                    loginUserInfo?.roleid
                      ? Object?.values(COMPANYROLES)?.includes(
                          loginUserInfo?.roleid[0]
                        )
                        ? "CompanyUsers"
                        : "Users"
                      : "Users"
                  }
                  permission="update"
                />
              }
            >
              <Route path="/user/edit-user/:id" element={<AddUser />} />
            </Route>
            <Route element={<RequireAuth page="Companies" permission="read" />}>
              <Route path="/company" element={<CompanyPage />} />
            </Route>
            <Route
              element={<RequireAuth page="Companies" permission="create" />}
            >
              <Route path="/company/add-company" element={<AddCompany />} />
            </Route>
            <Route
              element={<RequireAuth page="Companies" permission="update" />}
            >
              <Route
                path="/company/edit-company/:id"
                element={<AddCompany />}
              />
            </Route>
            <Route element={<RequireAuth page="UserRoles" permission="read" />}>
              <Route path="/user-roles" element={<UserRoles />} />
            </Route>
            <Route element={<RequireAuth page="UserRoles" permission="read" />}>
              <Route path="/edit-role/:id" element={<EditRole />} />
            </Route>
            <Route element={<RequireAuth page="Forms" permission="read" />}>
              <Route path="/forms" element={<FormListingPage />} />
            </Route>

            <Route
              element={<RequireAuth page="Industries" permission="read" />}
            >
              <Route path="/industry" element={<IndustryView />} />
            </Route>
            <Route
              element={<RequireAuth page="Industries" permission="create" />}
            >
              <Route path="/industry/add-industry" element={<AddIndustry />} />
            </Route>
            <Route
              element={<RequireAuth page="Industries" permission="update" />}
            >
              <Route
                path="/industry/edit-industry/:id"
                element={<AddIndustry />}
              />
            </Route>
            <Route element={<RequireAuth page="Trainings" permission="read" />}>
              <Route path="/training" element={<ViewTrainingDirectory />} />
            </Route>
            <Route element={<RequireAuth page="Trainings" permission="read" />}>
              <Route
                path="/training/forms/form-detail/:id"
                element={<ViewEntryCompo isEntryPage={false} />}
              />
            </Route>
            <Route
              element={
                <RequireAuth
                  page="TrainingsAndCertificates"
                  permission="read"
                />
              }
            >
              <Route
                path="/training-attempts"
                element={<TrainingsAttempts />}
              />
            </Route>
            <Route
              element={
                <RequireAuth
                  page="TrainingsAndCertificates"
                  permission="read"
                />
              }
            >
              <Route
                path="/training-attempts/form-details/:id"
                element={<ViewEntryCompo isEntryPage={true} />}
              />
            </Route>
            <Route
              element={<RequireAuth page="Trainings" permission="create" />}
            >
              <Route
                path="/training/add-training"
                element={<AddTrainingDirectory />}
              />
            </Route>
            <Route
              element={<RequireAuth page="Trainings" permission="update" />}
            >
              <Route
                path="/training/update-training/:id"
                element={<AddTrainingDirectory />}
              />
            </Route>
            <Route element={<RequireAuth page="Trainings" permission="read" />}>
              <Route
                path="/training/form-detail/:id"
                element={<ViewEntryCompo isEntryPage={false} />}
              />
            </Route>
            <Route
              element={
                <RequireAuth
                  page="TrainingsAndCertificates"
                  permission="read"
                />
              }
            >
              <Route
                path="/training-certificate"
                element={<TrainingCertificatePage />}
              />
            </Route>
            <Route
              element={
                <RequireAuth
                  page="TrainingsAndCertificates"
                  permission="read"
                />
              }
            >
              <Route
                path="/training/view-entry/:id"
                element={
                  <ViewEntryCompo
                    isEntryPage
                    propsState={{ isTraining: true }}
                  />
                }
              />
            </Route>
            <Route element={<RequireAuth page="Trainings" permission="read" />}>
              <Route
                path="/training/form-detail/:id"
                element={<ViewEntryCompo isEntryPage={false} />}
              />
            </Route>
            <Route
              element={
                <RequireAuth
                  page="TrainingsAndCertificates"
                  permission="create"
                />
              }
            >
              <Route
                path="/training-certificate/add-training-certificate"
                element={<EditCertificatePage />}
              />
            </Route>
            <Route
              element={<RequireAuth page="UserCatalogue" permission="read" />}
            >
              <Route
                path="/training-catalogue"
                element={<ViewTrainingCatalogue />}
              />
            </Route>
            <Route
              element={
                <RequireAuth
                  page="TrainingsAndCertificates"
                  permission="update"
                />
              }
            >
              <Route
                path="/training-certificate/edit-training-certificate/:id"
                element={<EditCertificatePage />}
              />
            </Route>
            <Route
              element={
                <RequireAuth page="TrainingsAllocation" permission="read" />
              }
            >
              <Route
                path="/training-allocation"
                element={<ViewTrainingAllocation />}
              />
            </Route>
            <Route
              element={
                <RequireAuth page="AllocatedTrainings" permission="read" />
              }
            >
              <Route
                path="/allocated-training"
                element={<AllocatedTrainings />}
              />
            </Route>
            <Route
              element={<RequireAuth page="Categories" permission="read" />}
            >
              <Route path="/Category" element={<Category />} />
            </Route>
            <Route
              element={<RequireAuth page="Categories" permission="create" />}
            >
              <Route path="/category/add-category" element={<AddCategory />} />
            </Route>
            <Route
              element={<RequireAuth page="Categories" permission="update" />}
            >
              <Route
                path="/category/edit-category/:id"
                element={<AddCategory />}
              />
            </Route>
            <Route
              element={<RequireAuth page="Subscription" permission="read" />}
            >
              <Route path="/subscription" element={<SubscriptionPage />} />
            </Route>
            <Route path="/payment" element={<StripePricingTable />} />
            <Route path="/payment/success" element={<SuccessPaymentPage />} />
            <Route path="*" element={<Page404 />} />
            <Route path="/profile1" element={<ProfilePage />} />
            <Route element={<RequireAuth page="Projects" permission="read" />}>
              <Route path="/projects" element={<Projects />} />
            </Route>
            <Route
              element={<RequireAuth page="Projects" permission="create" />}
            >
              <Route path="/projects/add-projects" element={<AddProject />} />
            </Route>
            <Route
              element={<RequireAuth page="Projects" permission="update" />}
            >
              <Route
                path="/projects/edit-projects/:id"
                element={<AddProject />}
              />
            </Route>
            <Route element={<RequireAuth page="Projects" permission="read" />}>
              <Route
                path="/projects/address-projects/:id"
                element={<ProjectAddress />}
              />
            </Route>
            <Route
              element={
                <RequireAuth page="AllocatedProjectForms" permission="read" />
              }
            >
              <Route
                path="/allocated-project-form"
                element={<AllocatedProjectForm />}
              />
            </Route>
            <Route element={<RequireAuth page="Projects" permission="read" />}>
              <Route
                path="/projects/form-detail/:id"
                element={<ViewEntryCompo isEntryPage={false} />}
              />
            </Route>
            <Route element={<RequireAuth page="Projects" permission="read" />}>
              <Route
                path="/projects/form-edit-detail/:id"
                element={<ViewEntryCompo isEntryPage={false} />}
              />
            </Route>
            <Route element={<RequireAuth page="Projects" permission="read" />}>
              <Route
                path="/projects/form-builder/:form/:id"
                element={<ReactFormBuilder />}
              />
            </Route>
            <Route element={<RequireAuth page="Projects" permission="read" />}>
              <Route
                path="/projects/retake-form/:id"
                element={<ViewEntryCompo isEntryPage={false} />}
              />
            </Route>
            <Route
              element={
                <RequireAuth
                  page="TrainingsAndCertificates"
                  permission="read"
                />
              }
            >
              <Route
                path="/training-certificate/view-detail/:id"
                element={<ViewEntryCompo isEntryPage />}
              />
            </Route>
          </Route>
        </Routes>
      </sidebarContext.Provider>
    </searchContext.Provider>
  );
}

export default App;
