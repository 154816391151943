import React, {useEffect, useRef, useState} from 'react';
import {createInsertFieldsList} from "../../shared/functions/array-conversion";
import {ClickAwayListener, InputAdornment, TextField} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const InputWithInsert = ({selectedWidgets, inputValProp, onChange, labelTitle = "Label", editElementProp, editForm, showEyeIcon = true}) => {
  const inputRef = useRef(null);
  const tempSelectedList = createInsertFieldsList(selectedWidgets);
  const [inputValue, setInputValue] = useState(inputValProp);

  const [showInsertFieldBox, setShowInsertFieldBox] = useState(false);
  const [showInsertFieldList, setShowInsertFieldList] = useState(false);

  useEffect(() => {
    setInputValue(inputValProp)
  }, [inputValProp]);
  const handleChange = (value) => {
    setInputValue(value);
    onChange(value)
  };

  return (
    <ClickAwayListener onClickAway={() => {
      setShowInsertFieldBox(false)
    }}>

      <div style={{position: 'relative', marginTop: "12px"}}
           onFocus={(e) => {
             setShowInsertFieldBox(true)
           }}
      >

        {showInsertFieldBox && (
          <div className="insertFieldBoxSelect">
            <div className="insertText" onClick={() => { setShowInsertFieldList(prevState => !(prevState)) }}>
              <span>Insert Field</span>
              {showInsertFieldList ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
            </div>
            {showInsertFieldList && <ul className="custom-select">
              {tempSelectedList.map((option, index) => (
                <li
                  key={index}
                  onClick={() => {
                    try {
                      const input = inputRef.current;
                      const startPos = input.selectionStart;
                      const endPos = input.selectionEnd;

                      const str = `{{${option.id}}}`;
                      const newValue = inputValue.substring(0, startPos) + str //+ inputValue.substring(endPos);

                      handleChange(newValue)

                      try {
                        const newPosition = startPos + str.length;
                        input.setSelectionRange(newPosition, newPosition);
                        input.focus();
                      } catch (e) {
                      }

                      setShowInsertFieldList(false)
                    } catch (e) {
                    }

                  }}
                  className="option"
                >
                  <span dangerouslySetInnerHTML={{__html: `${option.label}`}} />
                </li>
              ))}
            </ul>}
          </div>
        )}
        <TextField
          fullWidth
          multiline
          label={labelTitle}
          type="text"
          size="small"
          id="insertFieldBoxId"
          className="mt_35"
          value={inputValue}
          ref={inputRef}
          onChange={(e) => handleChange(e.target.value)}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            endAdornment: showEyeIcon ? <InputAdornment position="end">
              <IconButton
                edge="end"
                onClick={() => {
                  editElementProp(!(editForm.hideLabel), ["hideLabel"])
                }}
              >
                {editForm.hideLabel ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment> : <> </>,
            inputProps: {
              onWheel: (e) => e.currentTarget.blur()
            }
          }}
        />
      </div>


    </ClickAwayListener>
  );
};

export default InputWithInsert;
