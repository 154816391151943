import {isEmpty} from "../../shared/functions/isEmpty";
import {highlightTextWithBackground} from "../../shared/functions/string-conversion";

const LabelCompo = ({data, labelConv = "", className = "", defaultLabel = "Untitled", showOptional = true}) => {
  const labelTxt = labelConv ? labelConv : data.label;
  return (
    <label className={className}>
      {data.hideLabel ? <>&nbsp;</> :
        <>
        <span
          dangerouslySetInnerHTML={{
            __html: !isEmpty(labelTxt)
              ? highlightTextWithBackground(labelTxt)
              : defaultLabel,
          }}
        />
          <small className={data.required ? "asteriskAfter": ""}>{data.required ? "" : showOptional ? " (Optional)" : ""}</small>
        </>
      }
    </label>
  )
}
export default LabelCompo;